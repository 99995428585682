// Containers ops permissions
export const PERMISSION_ROLLOVER_CONTAINER = 'rollover';
export const PERMISSION_SHUTOUT_CONTAINER = 'Shutout Container';
export const PERMISSION_ESTIMATES = 'estimate_tab';
export const PERMISSION_ESTIMATES_VIEW = 'view';
export const PERMISSION_ACCOUNTING_TAB = 'accounting_tab';
export const PERMISSION_SHIPMENTS = 'Shipments';
export const PERMISSION_SHIPMENT_VIEW = 'view';
export const PERMISSION_BOOKINGS = 'Bookings';

export const PERMISSION_SALES_PERSON = 'Sales Person';
export const PERMISSION_WAREHOUSE_CREATE = 'create';
export const PERMISSION_WAREHOUSE_EDIT = 'edit';
export const PERMISSION_WAREHOUSE_DELETE = 'delete';
export const PERMISSION_WAREHOUSE_VIEW = 'view';
