import { gql } from '@apollo/client';
import { shipmentContainerFields } from 'operations/modules/booking/graphql';

export const OFFLOAD_CONTAINERS = gql`
  mutation offload_containers(
    $shipment_container_ids: [ID!]!
    $offloaded_at: Time!
    $cancelled_by: String!
    $cancellation_reason: String!
    $return_location_id: ID
    $return_expected_at: Time
    $customer_company_id: ID
    $job_date: Time
  ) {
    offload_containers(
      shipment_container_ids: $shipment_container_ids
      offloaded_at: $offloaded_at
      cancelled_by: $cancelled_by
      cancellation_reason: $cancellation_reason
      return_location_id: $return_location_id
      return_expected_at: $return_expected_at
      customer_company_id: $customer_company_id
      job_date: $job_date
    ) {
      ...shipmentContainerFields
    }
  }
  ${shipmentContainerFields}
`;

export const GET_BULK_ACTIONS_BY_CONTAINER_IDS = gql`
  query get_bulk_actions_by_container_ids($container_ids: [ID!]!) {
    get_bulk_actions_by_container_ids(container_ids: $container_ids)
  }
`;
