import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Card,
  Button,
  Descriptions,
  Tooltip,
  message,
  Input,
  Form,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { BookingType } from 'operations/modules/booking/constants';
import { RichTextEditorWrapper } from 'common';
import { TemplateValue } from 'operations/models/Template';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';

const OtherDetailsCard = observer(function OtherDetailsCard(props: {
  parent_id: string;
  parent_type: BookingType;
  remarks?: string | null;
  onUpdate?: (remarks: string) => void;
  disabled?: boolean;
  disableReason?: string;
  terms_and_condition?: TemplateValue | null;
  terms_and_condition_description?: string | null;
  booking_type?: string | null;
}): JSX.Element {
  const {
    remarks,
    onUpdate,
    parent_id,
    parent_type,
    disabled,
    disableReason,
    terms_and_condition,
    terms_and_condition_description,
    booking_type,
  } = props;
  const [otherDetailsEdit, setOtherDetailsEdit] = useState<{
    edit: boolean;
    beforeUpdate?: { remarks?: string | null };
  }>({ edit: false });

  const mutation = UPDATE_OCEAN_TRANSPORT_ORDER;

  const [form] = Form.useForm();

  const [updateParent, { data, loading, error }] = useMutation(mutation);

  useEffect(() => {
    if (!error && data && data[`update_${parent_type}`]) {
      const { remarks } = data[`update_${parent_type}`];
      message.success('Other Details Updated!');
      if (onUpdate) onUpdate(remarks);
      setOtherDetailsEdit({ edit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, parent_type, remarks]);

  if (otherDetailsEdit.edit) {
    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          remarks: otherDetailsEdit?.beforeUpdate?.remarks,
          terms_and_condition: terms_and_condition,
          terms_and_condition_description: terms_and_condition_description,
        }}
        onFinish={(values) => {
          updateParent({
            variables: {
              [parent_type]: {
                id: parent_id,
                remarks: values.remarks,
                terms_and_condition_id: values?.terms_and_condition?.id,
                terms_and_condition_description: values?.terms_and_condition_description,
              },
            },
          });
        }}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.terms_and_condition) {
            form.setFieldsValue({
              terms_and_condition_description: changedValues.terms_and_condition?.content,
            });
          }
        }}
      >
        <Card
          title="Other Details"
          extra={[
            <Button
              style={{ marginRight: '5px' }}
              key="cancel"
              disabled={loading}
              onClick={() => setOtherDetailsEdit({ edit: false })}
            >
              Cancel
            </Button>,
            <Button loading={loading} key="save" htmlType="submit" type="primary">
              Save
            </Button>,
          ]}
        >
          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea rows={2} />
          </Form.Item>
          {booking_type === BOOKING_TYPE_SELF && (
            <>
              <Form.Item
                labelCol={{ span: 24 }}
                name="terms_and_condition"
                label="Terms And Condition"
                style={{ width: '100%' }}
              >
                <GlobalSearch
                  doc_type="Network::Template"
                  disabled={false}
                  searchProps={{
                    template_types: ['terms_and_condition'],
                    resource_type: 'Shipment::OceanTransportOrder',
                  }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                name="terms_and_condition_description"
                label="Terms And Condition Description"
                style={{ width: '100%' }}
              >
                <RichTextEditorWrapper
                  toolbarOptions={{
                    container: [
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ color: [] }, { background: [] }],
                      ['emoji'],
                      [{ indent: '-1' }, { indent: '+1' }],
                    ],
                  }}
                />
              </Form.Item>
            </>
          )}
        </Card>
      </Form>
    );
  }

  return (
    <Card
      id="other_details"
      title="Other Details"
      className="left-align-description"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              setOtherDetailsEdit({
                edit: true,
                beforeUpdate: { remarks },
              });
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <Descriptions>
        <Descriptions.Item key="remarks" label="Remarks">
          {remarks}
        </Descriptions.Item>
        {booking_type === BOOKING_TYPE_SELF && (
          <Descriptions.Item key="terms_and_conditions" label="Terms and Condition">
            {terms_and_condition?.name}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  );
});

export default OtherDetailsCard;
