import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';

import { TagsOutlined } from '@shipmnts/pixel-hub';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  Drawer,
  Space,
  Button,
  Form,
  message,
  Skeleton,
  CustomIcon,
  useActivityContext,
  Checkbox,
  dayjs,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { pick as _pick, isNil } from 'lodash';
import { UPSERT_INQUIRY_OPTION, GET_INQUIRY_OPTION } from 'sales_hub/graphql/inquiryOption';
import { InquiryValue } from 'sales_hub/models/inquiry';
import {
  DRAFT_STATUS,
  QuotationValue,
  QuotationWithInquiryValue,
  WON_STATUS,
} from 'sales_hub/models/quotation';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';

import { useInquiryDetail } from '../Inquiry/InquiryDetail/InquiryDetailLayout';
import { convertDateToUnix } from '../Inquiry/InquiryLostLayout';
import { UPDATE_TRACKING_EVENT } from 'common/components/Tracking/graphql/queries';
import { FETCH_EVENT_AND_MILESTONES } from 'common/components/Tracking/graphql/queries';

import InquiryOptionForm from './InquiryOptionForm';
import { useQuery } from '@apollo/client';
interface inquiryOptionDrawerProp {
  inquiry: InquiryValue;
  isVisible?: boolean;
  setIsVisible?: (isVisible: boolean) => void;
  onSuccess?: () => void;
  onClose?: () => void;
  inquiryOptionId?: string | null;
  visible?: boolean;
  performDuplicate?: boolean;
  disabledResource?: boolean;
  title?: string;
  inquiryOption?: QuotationWithInquiryValue;
}
export const getUpsertInquiryOptionsPayload = (
  inquiryFormValue: any,
  estimatesRef: any,
  inquiryOption: QuotationValue | undefined,
  inquiryOptionId: string | undefined | null
) => {
  const shipmentEstimates = estimatesRef.current?.getValue();
  const estimatesToSync = shipmentEstimates?.map((est: any) =>
    _pick(est, [
      'id',
      'item',
      'supplier_company_id',
      'customer_company_id',
      'uom',
      'quantity',
      'buy_exchange_rate',
      'buy_rate',
      'buy_currency',
      'buy_terms',
      'sell_exchange_rate',
      'sell_rate',
      'sell_currency',
      'sell_terms',
      'remarks',
      'equipment_type',
      'equipment_name',
      'tag',
      'tax_percentage',
      'taxable',
      'taxability',
      'sequence',
      'item_group',
      'item_description',
      'markup_type',
      'markup_value',
    ])
  );
  const estimateIdsToSync = estimatesToSync?.map((sest: any) => sest.id);

  (inquiryOption?.shipment_estimates || []).forEach((est: any) => {
    if (!estimateIdsToSync?.includes(est.id)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      estimatesToSync?.push({ id: est.id, _destroy: true });
    }
  });

  const payload = {
    id: inquiryOptionId || inquiryOption?.id,
    port_of_loading_id: inquiryFormValue?.port_of_loading?.id || null,
    port_of_discharge_id: inquiryFormValue?.port_of_discharge?.id || null,
    place_of_carrier_receipt_id: inquiryFormValue?.place_of_carrier_receipt?.id || null,
    place_of_carrier_delivery_id: inquiryFormValue?.place_of_carrier_delivery?.id || null,
    ocean_vessel_id: inquiryFormValue?.ocean_vessel?.imo || null,
    carrier_id: inquiryFormValue?.carrier?.id || null,
    booking_vendor_id: inquiryFormValue?.booking_vendor?.id || null,
    voyage_number: inquiryFormValue?.voyage_number || null,
    vendor_rate_agreement_number: inquiryFormValue?.vendor_rate_agreement_number || null,
    valid_from: inquiryFormValue?.validity
      ? convertDateToUnix(inquiryFormValue?.validity[0])
      : null,
    valid_to: inquiryFormValue?.validity ? convertDateToUnix(inquiryFormValue?.validity[1]) : null,
    tariff_rate: inquiryFormValue?.tariff_rate || null,
    routing: inquiryFormValue?.routing || null,
    quote_currency: inquiryFormValue?.quote_currency || null,
    origin_carrier_free_days: inquiryFormValue?.origin_carrier_free_days || null,
    destination_carrier_free_days: inquiryFormValue?.destination_carrier_free_days || null,
    destination_port_free_days: inquiryFormValue?.destination_port_free_days || null,
    exchange_rate: inquiryFormValue?.exchange_rate || null,
    carrier_product: inquiryFormValue?.carrier_product || null,
    chargeable_weight: inquiryFormValue?.chargeable_weight || null,
    estimated_time_of_departure: inquiryFormValue?.estimated_time_of_departure
      ? convertDateToUnix(inquiryFormValue?.estimated_time_of_departure)
      : null,
    estimated_time_of_arrival: inquiryFormValue?.estimated_time_of_arrival
      ? convertDateToUnix(inquiryFormValue?.estimated_time_of_arrival)
      : null,
    shipment_estimates: estimatesToSync || null,
    terms_and_cond_id: inquiryFormValue?.terms_and_cond?.id || null,
    terms_and_cond_desc: inquiryFormValue?.terms_and_cond_desc || null,
    remarks: inquiryFormValue?.remarks || null,
    validity_basis: inquiryFormValue?.validity_basis || null,
    transit_days: inquiryFormValue?.transit_days || null,
  };

  return payload;
};

export const getDefaults = (inquiry: InquiryValue) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sessionData = useSession();
  const today = dayjs.unix(Date.now() / 1000);
  return {
    port_of_loading: inquiry?.port_of_loading,
    port_of_discharge: inquiry?.port_of_discharge,
    destination_port_free_days: inquiry?.destination_port_free_days,
    origin_carrier_free_days: inquiry?.origin_carrier_free_days,
    destination_carrier_free_days: inquiry?.destination_carrier_free_days,
    valid_from: today,
    valid_to: today.add(7, 'days'),
    quote_currency: sessionData?.company_account?.default_currency,
    status: DRAFT_STATUS,
  };
};

const getDateValue = (date?: number) => {
  if (!date) return null;
  if (dayjs.isDayjs(date)) return date;
  return dayjs.unix(date);
};

export const getInquiryOptionPayload = (recivedPayload: Record<string, any> | undefined) => {
  if (!recivedPayload) return;

  const payload = {
    id: recivedPayload?.id,
    status: recivedPayload?.status,
    shipment_estimates: recivedPayload?.shipment_estimates,
    inquiry: recivedPayload?.inquiry,
    chargeable_weight: recivedPayload?.chargeable_weight,
    quotation_number: recivedPayload?.quotation_number,
    port_of_loading: recivedPayload?.port_of_loading,
    port_of_discharge: recivedPayload?.port_of_discharge,
    place_of_carrier_receipt: recivedPayload?.place_of_carrier_receipt,
    place_of_carrier_delivery: recivedPayload?.place_of_carrier_delivery,
    ocean_vessel: recivedPayload?.ocean_vessel,
    carrier: recivedPayload?.carrier,
    booking_vendor: recivedPayload?.booking_vendor,
    voyage_number: recivedPayload?.voyage_number,
    vendor_rate_agreement_number: recivedPayload?.vendor_rate_agreement_number,
    valid_from: getDateValue(recivedPayload?.valid_from),
    valid_to: getDateValue(recivedPayload?.valid_to),
    tariff_rate: recivedPayload?.tariff_rate,
    routing: recivedPayload?.routing,
    quote_currency: recivedPayload?.quote_currency,
    origin_carrier_free_days: recivedPayload?.origin_carrier_free_days,
    destination_carrier_free_days: recivedPayload?.destination_carrier_free_days,
    destination_port_free_days: recivedPayload?.destination_port_free_days,
    exchange_rate: recivedPayload?.exchange_rate,
    carrier_product: recivedPayload?.carrier_product,
    validity_basis: recivedPayload?.validity_basis,
    estimated_time_of_arrival: getDateValue(recivedPayload?.estimated_time_of_arrival),
    estimated_time_of_departure: getDateValue(recivedPayload?.estimated_time_of_departure),
    terms_and_cond: recivedPayload?.terms_and_cond || undefined,
    terms_and_cond_desc: recivedPayload?.terms_and_cond_desc || undefined,
    transit_days: recivedPayload?.transit_days || undefined,
    remarks: recivedPayload?.remarks || null,
  };

  return payload as QuotationWithInquiryValue;
};
export function InquiryOptionDrawer(props: inquiryOptionDrawerProp) {
  const {
    inquiry,
    inquiryOptionId,
    isVisible,
    setIsVisible,
    onSuccess,
    onClose: closeDrawer,
    performDuplicate,
    disabledResource,
    title,
  } = props;
  // Contexts
  const { refetchInquiry } = useInquiryDetail();
  const { setActivityProps } = useActivityContext();
  const [inquiryOption, setInquiryOption] = useState<QuotationWithInquiryValue | undefined>(
    props?.inquiryOption
  );
  const newInquiry = inquiryOption ? inquiryOption.inquiry : inquiry;
  const [isChecked, setIsChecked] = useState(
    newInquiry?.last_action_status === 'Pricing shared with Sales'
  );
  const estimatesRef = useRef<{ getValue: () => ShipmentEstimateValue[] }>();
  const [updateEvent] = useMutation(UPDATE_TRACKING_EVENT);
  const [upsertInquiryOptions, { data, error, loading }] = useMutation(UPSERT_INQUIRY_OPTION);
  const [getInquiryOption, { data: getInquiryOptionData, error: getInquiryOptionError }] =
    useLazyQuery(GET_INQUIRY_OPTION);

  const [form] = Form.useForm();
  const onClose = () => {
    if (closeDrawer) closeDrawer();
    if (setIsVisible) setIsVisible(false);
  };
  useEffect(() => {
    if (inquiryOption) {
      setIsChecked(newInquiry?.last_action_status === 'Pricing shared with Sales');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryOption]);

  useEffect(() => {
    if (data && !error) {
      message.success(
        ` ${
          !inquiryOption ? ' Quotation Created Successfully.' : 'Quotation Updated Successfully.'
        }`
      );
      if (onSuccess) onSuccess();
      if (setIsVisible) setIsVisible(false);
      if (refetchInquiry) refetchInquiry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (!inquiryOption && inquiryOptionId)
      getInquiryOption({
        variables: {
          id: inquiryOptionId,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryOptionId]);

  let disabled =
    !!getInquiryOptionError || (inquiryOption && inquiryOption?.status !== DRAFT_STATUS);
  if (!isNil(disabledResource)) disabled = disabledResource;

  useEffect(() => {
    if (getInquiryOptionError) message.error('unable to fetch data');
    if (getInquiryOptionData && !getInquiryOptionError) {
      const payload = getInquiryOptionPayload(getInquiryOptionData?.get_inquiry_option);
      setInquiryOption(payload);
    }
  }, [getInquiryOptionData, getInquiryOptionError]);

  const getTitle = () => {
    if (title) return `${title} #${inquiryOption?.quotation_number || ''}`;
    return !inquiryOption
      ? 'Create Quotation'
      : `Edit Quotation #${inquiryOption?.quotation_number || ''}`;
  };

  const { data: mileStoneData } = useQuery(FETCH_EVENT_AND_MILESTONES, {
    variables: {
      ids: Array.isArray(inquiry?.inquiry_global_id)
        ? inquiry?.inquiry_global_id?.map((i) => parseInt(i))
        : [parseInt(inquiry?.inquiry_global_id || '')],
      reference_type: 'SalesHub::InquiryGlobal',
      workflow_type: 'main',
    },
  });

  return (
    <Drawer
      width={'90%'}
      open={isVisible}
      destroyOnClose={true}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TagsOutlined style={{ fontSize: '18px' }} /> &nbsp; {getTitle()}
          {inquiryOption && (
            <Button
              key="shipment_header_activity" // updated
              size={'small'}
              style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: '16px' }}
              onClick={() => {
                setActivityProps({
                  visible: true,
                  resource_id: inquiryOption?.id,
                  resource_type: 'SalesHub::InquiryOption',
                  showDrawer: true,
                });
              }}
            >
              Activity
              <span style={{ marginLeft: '5px', display: 'flex' }}>
                <CustomIcon icon="ActivityIcon" />
              </span>
            </Button>
          )}
        </div>
      }
      onClose={onClose}
      footer={
        <Space style={{ float: 'right' }}>
          <Checkbox
            checked={isChecked}
            disabled={newInquiry?.last_action_status !== 'Pricing Pending'}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            Mark as Pricing shared with sales
          </Checkbox>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            loading={loading}
            onClick={form.submit}
          >
            Save
          </Button>
          <Button type="default" onClick={onClose}>
            Cancel
          </Button>
        </Space>
      }
    >
      {!inquiryOption && inquiryOptionId && <Skeleton />}

      {((inquiryOption && inquiryOptionId) || !inquiryOptionId) && (
        <InquiryOptionForm
          disabledResource={disabledResource}
          form={form}
          inquiry={inquiry}
          estimatesRef={estimatesRef}
          onFinish={(formValues: any) => {
            const upsertPayload: any = getUpsertInquiryOptionsPayload(
              formValues,
              estimatesRef,
              inquiryOption,
              inquiryOptionId
            );
            if (performDuplicate) {
              upsertPayload['id'] = undefined;
              upsertPayload['quotation_number'] = undefined;
              upsertPayload['amend_from_id'] =
                inquiryOption?.status === WON_STATUS ? inquiryOptionId : undefined;

              (upsertPayload.shipment_estimates || []).forEach(
                (estimate: ShipmentEstimateValue) => {
                  estimate.inquiry_option_id = undefined;
                  estimate.id = undefined;
                }
              );
            }

            const events = get(mileStoneData, 'fetch_tracking_events', []);
            const eventsToBeEdited = events.filter(
              (o: { name: string }) => o.name === 'Pricing shared with Sales'
            );
            if (isChecked && newInquiry?.last_action_status === 'Pricing Pending') {
              updateEvent({
                variables: {
                  ids: [parseInt(eventsToBeEdited[0]?.id)],
                  input: {
                    actual_date: convertDateToUnix(new Date().toISOString()),
                    event_data: {},
                  },
                },
              });
            }
            upsertInquiryOptions({
              variables: {
                inquiry_id: inquiry?.id,
                inquiry_option: upsertPayload,
              },
            });
          }}
          inquiryOption={inquiryOption || getDefaults(inquiry)}
        />
      )}
    </Drawer>
  );
}

export default InquiryOptionDrawer;
