import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { ShipmentValue } from 'operations/models/Shipment';
import { omit as _omit, filter as _filter, keyBy as _keyBy, pick as _pick } from 'lodash';
import {
  ASSET_TYPE_OWN,
  BUSINESS_TYPE_DIRECT,
  CARGO_TYPE_CONTAINER,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_ROAD,
  MOVEMENT_MODE_AIRPORT_DELIVERY,
  MOVEMENT_MODE_AIRPORT_PICKUP,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
  MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP,
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_HOUSE,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  SHIPMENT_TYPE_BACK_TO_BACK,
} from '../../constants';
import {
  containerTypes,
  LOAD_TYPE_FCL,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_KGS,
  WEIGHT_UNIT_MTS,
} from 'operations/baseConstants';
import { RoutingNodesHashValue } from 'operations/components/RoutingDetails/RoutingDetails';
import { transformPartyDetails } from 'operations/models/ShipmentParty';
import BasicDetails from './BasicDetails';
import VehicleDetails from './VehicleDetails';
import RoadRoutingDetails from './RoadRoutingDetails/RoadRoutingDetails';
import PickupContainerDetails from './PickupContainerDetails';
import ContainerQuantity from './ContainerQuantity';
import ShipmentServiceDetails from '../ShipmentServiceDetails';
import ExportClearanceDetails from '../ExportClearanceDetails';
import React from 'react';
import { DriverValue } from 'operations/models/Driver';
import { getRoutingDetailsFormValue } from 'operations/modules/booking/helpers/DuplicateBookingHelper';
import LinkShipmentDetails from './LinkShipmentDetails';
import CargoNewComponent, {
  getInitialValueForCargoProperties,
} from '../DetailLayout/Cargo/CargoNewComponent';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { getNewOtoPayload } from 'operations/modules/reports/helpers/allocationHelper';
import {
  Dayjs,
  FormInstance,
  convertToDayJs,
  convertToStringFormat,
  dayjs,
  utcStringToDayjs,
} from '@shipmnts/pixel-hub';
import { ShipmentRouteValue } from 'operations/models/ShipmentRoute';
import { getInitialNodes } from 'operations/components/RoutingDetails/helpers';
import { FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';
import { InquiryOptionValue } from 'operations/models/InquiryOption';

// initialValues
export const getRoutingDetails = (
  shipment: ShipmentValue | ShipmentRouteValue | undefined | null
) => {
  const routingLegs = shipment?.routing_legs;
  let routing_nodes: RoutingNodesHashValue = {};
  let routing_legs = (routingLegs || []).map((rl: any) => {
    if (rl?.origin?.id) routing_nodes[rl.origin.id] = rl.origin;
    if (rl?.destination?.id) routing_nodes[rl.destination.id] = rl.destination;
    return {
      ...rl,
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });
  if (routing_legs.length === 0) {
    const routingDetails = getInitialNodes();
    routing_legs = [
      {
        routing_type: 'pickup',
        origin_id: routingDetails.origin._id,
        destination_id: routingDetails.destination._id,
        mode_of_transit: 'road',
        sequence_number: 2 + 0.1,
      },
    ];
    routing_nodes = {
      [routingDetails.origin._id || '']: routingDetails.origin,
      [routingDetails.destination._id || '']: routingDetails.destination,
    };
  }
  return {
    routing_legs,
    routing_nodes,
  };
};

export const getDriverContactDetails = (shipment: ShipmentValue | undefined | null) => {
  const driver_number = (shipment?.driver?.driver_contact_number || '').split(' ');
  const driver_contact_number =
    driver_number.length > 1
      ? {
          dialCode: driver_number[0],
          number: driver_number[1],
        }
      : undefined;
  return driver_contact_number;
};
export const getDefaultInitialValue = () => {
  return {
    business_type: BUSINESS_TYPE_DIRECT,
    movement_mode: MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
    load_type: LOAD_TYPE_FCL,
    cargo_type: CARGO_TYPE_CONTAINER,
    freight_type: FREIGHT_TYPE_ROAD,
    trade_type: TRADE_TYPE_EXPORT,
    shipment_type: SHIPMENT_TYPE_DIRECT,
    shipment_containers: [{}, {}],
  };
};

export const getLinkedShipmentDetails = (shipment?: ShipmentValue | null) => {
  const linkedShipmentData = shipment?.link_shipment_json;
  let linkShipmentJson = {};
  if (linkedShipmentData && shipment.freight_type === FREIGHT_TYPE_ROAD)
    linkShipmentJson = {
      ...linkedShipmentData,
      lfd_at_empty_return: utcStringToDayjs(linkedShipmentData?.lfd_at_empty_return),
      estimated_time_of_arrival: utcStringToDayjs(linkedShipmentData?.estimated_time_of_arrival),
      estimated_time_of_departure: utcStringToDayjs(
        linkedShipmentData?.estimated_time_of_departure
      ),
      voyage_number: linkedShipmentData?.voyage_number,
      valid_till_date: utcStringToDayjs(linkedShipmentData?.valid_till_date),
      booking_number: linkedShipmentData?.booking_number,
      gate_close_date: utcStringToDayjs(linkedShipmentData?.gate_close_date),
      lfd_at_pod: utcStringToDayjs(linkedShipmentData?.lfd_at_pod),
    };
  return linkShipmentJson;
};

const getShipmentEventInitialValue = (
  shipment: ShipmentValue | undefined | null,
  isDuplicate?: boolean
) => {
  const shipment_events: any = {};
  shipment?.shipment_events?.forEach((event: any) => {
    if (!(isDuplicate && event.name === 'bank_release_order')) {
      shipment_events[event.name] = {
        event_date: convertToDayJs(event?.event_date),
        event_number: event?.event_number,
      };
    }
  });
  return shipment_events;
};

const getIntialValuesFromInquiryOption = (inquiryOption: InquiryOptionValue) => {
  const inquiry = inquiryOption.inquiry;
  const inquiryValues: any = _pick(inquiry, [
    'freight_type',
    'trade_type',
    'load_type',
    'business_received_through',
    'billing_party',
    'billing_party_address',
    'cargos',
    // 'movement_mode',
  ]);

  //business_type
  if (inquiryValues.business_received_through !== 'direct') {
    const business_received_through_party_name =
      inquiryValues.business_received_through === 'agent_nomination'
        ? 'overseas_agent'
        : inquiryValues.business_received_through;
    inquiryValues['party'] = {
      [`${business_received_through_party_name}`]: {
        party_company: inquiryValues.billing_party,
        party_address: inquiryValues.billing_party_address,
      },
    };
  }

  // shipment_parties
  if (!!inquiry?.shipment_parties) {
    inquiryValues['party'] = {
      ...(inquiryValues['party'] || {}),
      ...(inquiry?.shipment_parties || []).reduce((acc: any, party: any) => {
        const updatedParty = _omit(party, ['id']);
        acc[party.name] = updatedParty;
        return acc;
      }, {}),
    };

    inquiryValues.shipper_same_as_customer =
      inquiry?.customer_company?.id === inquiryValues['party']?.shipper?.party_company?.id;

    inquiryValues.consignee_same_as_customer =
      inquiry?.customer_company?.id === inquiryValues['party']?.consignee?.party_company?.id;

    delete inquiryValues['shipment_parties'];
  }

  // const routingDetails: any = {};

  // if (!!inquiry?.origin) {
  //   getNewRoutingNode({ ...(inquiry?.origin || {}), locationType: 'Address' });
  // }

  // if (!!inquiry?.destination) {
  //   routingDetails['destination'] = getNewRoutingNode({
  //     ...(inquiry?.destination || {}),
  //     locationType: 'Address',
  //   });
  // }

  return {
    ...inquiryValues,
    inquiry_option: inquiryOption,
    involved_branch_id: inquiry?.involved_branch?.id,
    business_type: inquiry?.business_received_through,
    cargo_properties: getInitialValueForCargoProperties(inquiry?.cargos),
    sales_agent: inquiry?.sales_person,
    shipment_containers: inquiry?.container_requests,
    customer: {
      party_company: inquiry?.customer_company,
      party_address: inquiry?.customer_address,
    },
    // routing_details: {
    //   routing_legs: [
    //     {
    //       routing_type: 'pickup',
    //       origin_id: routingDetails.origin?._id,
    //       destination_id: routingDetails.destination?._id,
    //       mode_of_transit: 'road',
    //       sequence_number: 2 + 0.1,
    //     },
    //   ],
    //   routing_nodes: {
    //     [routingDetails.origin?._id || '']: routingDetails.origin,
    //     [routingDetails.destination?._id || '']: routingDetails.destination,
    //   },
    // },
  };
};

export const getGenericInitialValue = (
  shipment: ShipmentValue | undefined | null,
  extraValue?: any,
  inquiryOption?: InquiryOptionValue
) => {
  if (inquiryOption) {
    return getIntialValuesFromInquiryOption(inquiryOption);
  }

  const job_date = new Date(shipment?.job_date || '').getTime() / 1000;
  const driverContactNumber = getDriverContactDetails(shipment);
  const customer_company_id = shipment?.customer_company?.id;
  const shipper_company_id = shipment?.getShipmentPartyByName('shipper')?.party_company?.id;
  const consignee_company_id = shipment?.getShipmentPartyByName('consignee')?.party_company?.id;
  const consignee_same_as_customer = customer_company_id === consignee_company_id;
  const shipper_same_as_customer = customer_company_id === shipper_company_id;

  const initialValue = {
    ...getDefaultInitialValue(),
    ...shipment,
    vehicle: {
      vehicle_details: shipment?.vehicle,
      ...(shipment?.vehicle || {}),
      ownership_type: shipment?.vehicle?.ownership_type || ASSET_TYPE_OWN,
    },
    driver: {
      driver_details: {
        ...shipment?.driver,
        driver_contact_number: driverContactNumber,
      },
      ...shipment?.driver,
      driver_contact_number: driverContactNumber,
    },
    job_date: convertToDayJs(job_date) || dayjs(),
    customer: shipment?.customer_company
      ? {
          party_company: shipment?.customer_company,
          party_address: shipment?.customer_address,
        }
      : undefined,
    involved_branch_id: shipment?.involved_branch?.id,
    shipper: shipment?.getShipmentPartyByName('shipper'),
    consignee: shipment?.getShipmentPartyByName('consignee'),
    vessel: shipment?.ocean_vessel,
    carrier: shipment?.carrier,
    lfd_at_empty_return: convertToDayJs(shipment?.lfd_at_empty_return),
    estimated_time_of_arrival: convertToDayJs(shipment?.estimated_time_of_arrival),
    estimated_time_of_departure: convertToDayJs(shipment?.estimated_time_of_departure),
    ocean_transport_order: {
      valid_till_date: convertToDayJs(shipment?.getOtoBookingFromShipment()?.valid_till_date),
      booking_number: shipment?.getOtoBookingFromShipment()?.booking_number,
      gate_close_date: convertToDayJs(shipment?.getOtoBookingFromShipment()?.gate_close_date),
    },
    shipping_bill_details: _filter(shipment?.shipment_custom_details, {
      trade_type: 'export',
    }).map((sb: any) => ({
      id: sb.id,
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    import_custom_details: _filter(shipment?.shipment_custom_details, {
      trade_type: 'import',
    }).map((sb: any) => ({
      id: sb.id,
      document_number: sb.custom_document_number,
      document_date: convertToDayJs(sb.custom_document_date),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location: sb.custom_clearance_location,
      trade_type: sb.trade_type,
    })),
    shipment_invoices: (shipment?.shipment_invoices || []).map((si) => ({
      id: si.id,
      document_number: si.invoice_number,
      document_date: convertToDayJs(si.invoice_date),
    })),
    lfd_at_pod: convertToDayJs(shipment?.lfd_at_pod),
    cargos: shipment?.cargos || [
      {
        outer_package_type: 'Box',
        weight_unit: WEIGHT_UNIT_MTS,
        volume_unit: VOLUME_UNIT_CBM,
      },
    ],
    cargo_properties: getInitialValueForCargoProperties(shipment?.cargos),
    party: {
      ..._keyBy(shipment?.shipment_parties, 'name'),
    },
    routing_details: getRoutingDetails(shipment),
    shipper_same_as_customer: shipper_same_as_customer,
    consignee_same_as_customer: consignee_same_as_customer,
    shipment_events: getShipmentEventInitialValue(shipment),
    ...extraValue,
    ...getLinkedShipmentDetails(shipment),
  };
  return initialValue;
};
export const getGenericInitialValueForDuplicate = (
  shipment: ShipmentValue | undefined | null,
  extraValue?: any
) => {
  const job_date = new Date(shipment?.job_date || '').getTime() / 1000;
  const driverContactNumber = getDriverContactDetails(shipment);
  const initialValue: any = {
    ...extraValue,
    freight_type: shipment?.freight_type,
    trade_type: shipment?.trade_type,
    cargo_type: shipment?.cargo_type || CARGO_TYPE_CONTAINER,
    load_type: shipment?.load_type || LOAD_TYPE_FCL,
    vehicle: {
      vehicle_details: shipment?.vehicle,
      ...(shipment?.vehicle || {}),
      ownership_type: shipment?.vehicle?.ownership_type || ASSET_TYPE_OWN,
    },
    driver: {
      driver_details: {
        ...shipment?.driver,
        driver_contact_number: driverContactNumber,
      },
      ...shipment?.driver,
      driver_contact_number: driverContactNumber,
    },
    customer: {
      party_company: shipment?.customer_company,
      party_address: shipment?.customer_address,
    },
    party: {
      shipper: _omit(shipment?.getShipmentPartyByName('shipper'), 'id'),
      consignee: _omit(shipment?.getShipmentPartyByName('consignee'), 'id'),
    },
    sales_agent: shipment?.sales_agent,
    job_date: convertToDayJs(job_date) || dayjs(),
    business_type: shipment?.business_type || BUSINESS_TYPE_DIRECT,
    involved_branch_id: shipment?.involved_branch?.id,
    movement_mode: shipment?.movement_mode || MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
    cargos: (shipment?.cargos || []).map((c) => ({
      ..._omit(c, 'id'),
    })) || [
      {
        outer_package_type: 'Box',
        weight_unit: WEIGHT_UNIT_KGS,
        volume_unit: VOLUME_UNIT_CBM,
      },
    ],
    cargo_properties: getInitialValueForCargoProperties(shipment?.cargos),
    shipment_containers: [{}, {}],
    shipment_events: getShipmentEventInitialValue(shipment, true),
  };
  if (shipment?.routing_legs) {
    const routingDetails = getRoutingDetailsFormValue(shipment?.routing_legs);
    initialValue['routing_details'] = {
      routing_nodes: routingDetails?.routing_nodes,
      routing_legs: (routingDetails?.routing_legs || []).map((rl) => _omit(rl, 'id')),
    };
  }
  return initialValue;
};

//payload
export const getRoutingLegsPayload = (values: any, shipment: ShipmentValue | undefined | null) => {
  const routingLegs = (values.routing_details?.routing_legs || []).map((rl: RoutingLegValue) => {
    const {
      vessel,
      estimated_time_of_departure,
      estimated_time_of_arrival,
      global_carrier,
      ...restRL
    } = rl;
    return {
      ...restRL,
      global_carrier_id: global_carrier?.id,
      vessel_id: vessel?.imo,
      estimated_time_of_departure: estimated_time_of_departure
        ? dayjs(estimated_time_of_departure).unix()
        : undefined,
      estimated_time_of_arrival: estimated_time_of_arrival
        ? dayjs(estimated_time_of_arrival).unix()
        : undefined,
    };
  });

  (shipment?.routing_legs || []).forEach((rl: RoutingLegValue) => {
    const {
      vessel,
      origin,
      destination,
      estimated_time_of_departure,
      estimated_time_of_arrival,
      global_carrier,
      ...restRL
    } = rl;
    const routingLegExsist = (values.routing_details?.routing_legs || []).find(
      (c: any) => c.id === rl.id
    );
    if (!routingLegExsist) {
      const formatedRoutingLeg = {
        ...restRL,
        global_carrier_id: global_carrier?.id,
        vessel_id: vessel?.imo,
        origin_id: origin?.id,
        destination_id: destination?.id,
        estimated_time_of_departure: estimated_time_of_departure
          ? dayjs(estimated_time_of_departure).unix()
          : undefined,
        estimated_time_of_arrival: estimated_time_of_arrival
          ? dayjs(estimated_time_of_arrival).unix()
          : undefined,
      };
      routingLegs.push({ ...formatedRoutingLeg, _destroy: true });
    }
  });

  return routingLegs;
};
export const getShipmentInvoicePayload = (
  values: any,
  shipment: ShipmentValue | undefined | null
) => {
  const shipmentInvoices = (values.shipment_invoices || []).map((si: any) => ({
    id: si.id,
    invoice_number: si.document_number,
    invoice_date: si.document_date?.format('YYYY-MM-DD'),
    invoice_currency: si.document_currency,
    invoice_amount: si.document_amount,
  }));

  (shipment?.shipment_invoices || []).forEach((si) => {
    const siExists = (values.shipment_invoices || []).find((c: any) => c.id === si.id);
    if (!siExists) {
      shipmentInvoices.push({
        id: si.id,
        invoice_number: si.invoice_number,
        _destroy: true,
      });
    }
  });
  return shipmentInvoices;
};

export const getCargoPayload = (cargos: any[]) => {
  const updatedCargos: any[] = (cargos || []).map((cargo: any) => {
    return {
      ..._pick(cargo, [
        'id',
        'linked_to_id',
        'cargo_properties',
        'product_name',
        'outer_package_type',
        'outer_package_qty',
        'gross_weight',
        'gross_volume',
        'volume_unit',
        'weight_unit',
        'net_weight',
        'invoice_number',
        'invoice_date',
        'batch_number',
        'custom_ref',
        'serial_number',
        'dimension_unit',
        'gross_volume',
        'height',
        'length',
        'outer_package_qty',
        'outer_per_packet_wt',
        'outer_package_type',
        'width',
        'eway_bill_no',
        'eway_bill_validity',
        'volumetric_weight',
        'chargeable_weight',
        'commodity_type',
      ]),
      commodity_id: cargo?.commodity?.id || cargo?.commodity_id || null,
      // booking_request_id: cargo?.booking_request?.id,
      // ocean_transport_order_id: cargo?.ocean_transport_order?.id,
      // road_transport_order_id: cargo?.road_transport_order?.id,
      // shipment_id: cargo?.shipment?.id,
    };
  });
  return updatedCargos;
};

export const getCargoPayloadWithExistingCargo = (
  values: any,
  shipment: ShipmentValue | undefined | null
) => {
  const cargos = getCargoPayload(values.cargos);

  (shipment?.cargos || []).forEach((shipmentCargo: ProductOrderItemValue) => {
    // const { commodity, id, ...restCargos } = shipmentCargo;

    const cargoValExsist = cargos.find((c: any) => c.id === shipmentCargo.id);
    if (!cargoValExsist) {
      // const formatedCargo = {
      //   ...restCargos,
      //   commodity_id: commodity?.id,
      // };
      cargos.push({
        // ...formatedCargo,
        id: shipmentCargo.id,
        _destroy: true,
      });
    }
  });

  const cargoPayload = cargos.map((cargo: any) => {
    return {
      ..._omit(cargo, ['__typename', 'allocation_pending_quantity', 'ordered_qty']),
      cargo_properties: _omit(cargo.cargo_properties, '__typename'),
    };
  });

  return cargoPayload.length > 0 ? cargoPayload : undefined;
};
export const shipmentCustomDetailPayload = (
  values: any,
  shipment: ShipmentValue | undefined | null
) => {
  if (shipment?.shipment_type === SHIPMENT_TYPE_CONSOL) return undefined;
  const shipmentCustoms = (values.shipping_bill_details || [])
    .concat(values.import_custom_details || [])
    .map((sb: any) => ({
      id: sb.id,
      custom_document_number: sb.document_number,
      custom_document_date: sb.document_date?.format('YYYY-MM-DD'),
      country: sb.country || sb.custom_clearance_location?.country_code,
      custom_clearance_location_id: sb.custom_clearance_location?.id,
      trade_type: values?.trade_type || shipment?.trade_type,
      reward_scheme: sb?.reward_scheme,
      filing_type: sb?.filing_type,
      shipping_bill_type: sb?.shipping_bill_type,
      bill_of_entry_type: sb?.bill_of_entry_type,
      warehouse_be_no: sb?.warehouse_be_no,
      warehouse_be_date: sb?.warehouse_be_date,
      warehouse_location_id: sb?.warehouse_location?.id,
    }));

  (shipment?.shipment_custom_details || []).forEach((sb) => {
    const scExists = (shipmentCustoms || []).find((c: any) => c.id === sb.id);
    if (!scExists) {
      shipmentCustoms.push({
        id: sb.id,
        custom_document_number: sb.custom_document_number,
        custom_document_date: sb.custom_document_date?.toString(),
        country: sb.country || sb.custom_clearance_location?.country_code,
        custom_clearance_location_id: sb.custom_clearance_location?.id,
        trade_type: values?.trade_type || shipment?.trade_type,
        reward_scheme: sb?.reward_scheme,
        filing_type: sb?.filing_type,
        shipping_bill_type: sb?.shipping_bill_type,
        bill_of_entry_type: sb?.bill_of_entry_type,
        warehouse_be_no: sb?.warehouse_be_no,
        warehouse_be_date: sb?.warehouse_be_date,
        warehouse_location_id: sb?.warehouse_location?.id,
        _destroy: true,
      });
    }
  });

  return shipmentCustoms.length > 0 ? shipmentCustoms : undefined;
};
export const getVehiclePayload = (values: any) => {
  let vehicle = values.vehicle;
  if (!vehicle?.vehicle_details?.vehicle_license_plate_number) {
    return undefined;
  }
  vehicle = {
    ...vehicle,
    vehicle_license_plate_number: vehicle?.vehicle_details?.vehicle_license_plate_number,
    id: vehicle?.vehicle_details?.id,
    company_id: vehicle?.company?.id,
  };
  return _omit(vehicle, ['vehicle_details', 'company']);
};
const getDriverContact = (value: { dialCode: string; number: string }) => {
  const contactNumber = [];
  if (value?.dialCode) contactNumber.push(value.dialCode);
  if (value?.number) contactNumber.push(value.number);
  const mergedContactNumber = contactNumber.join(' ');
  return mergedContactNumber ? mergedContactNumber : undefined;
};
export const getDriverPayload = (values: any) => {
  let driver = values.driver;
  if (!driver?.driver_details?.driver_name) {
    return undefined;
  }
  driver = {
    ...driver,
    driver_name: driver?.driver_details?.driver_name,
    driver_contact_number: getDriverContact(driver?.driver_contact_number),
    id: driver?.driver_details?.id,
  };
  return _omit(driver, 'driver_details');
};
export const getContainerFromContainerQty = (values: any) => {
  const container_qty = values?.shipment_container_quantity;
  const shipment_container_from_qty: any[] = [];

  (container_qty || []).forEach((q: any) => {
    for (let i = 0; i < q.quantity; i++) {
      shipment_container_from_qty.push({
        container_type_code: q.container_type_code,
        container_type: containerTypes[q.container_type_code],
        container_settings: q.container_settings,
        cargo_gross_weight: q.weight_per_container,
        maximum_cargo_volume: q.maximum_cargo_volume,
        maximum_cargo_weight: q.maximum_cargo_weight,
        is_shipper_owned: q.is_shipper_owned,
      });
    }
  });
  return shipment_container_from_qty;
};

const convertToDayjsUnix = (value: any) => {
  if (!value) return value;
  return dayjs(value, 'DD-MM-YYYY HH:mm')?.unix();
};

function updateLinkShipmentJsonData(data: any) {
  // Create the linked_shipment_data object
  const linkedShipmentData = {
    carrier_id: data['carrier_id']?.toString() || undefined,
    lfd_at_empty_return: convertToDayjsUnix(data['lfd_at_empty_return']) || undefined,
    lfd_at_pod: convertToDayjsUnix(data['lfd_at_pod']) || undefined,
    ocean_vessel_id: data['ocean_vessel_id']?.toString() || undefined,
    voyage_number: data['voyage_number'] || undefined,
    estimated_time_of_arrival: convertToDayjsUnix(data['estimated_time_of_arrival']) || undefined,
    estimated_time_of_departure:
      convertToDayjsUnix(data['estimated_time_of_departure']) || undefined,
    valid_till_date: convertToDayjsUnix(data?.valid_till_date) || undefined,
    gate_close_date: convertToDayjsUnix(data?.gate_close_date) || undefined,
    booking_number: data?.booking_number,
  };
  // Update link_shipment_json in the original data object
  data.link_shipment_json = linkedShipmentData;

  return _omit(data, [
    'carrier_id',
    'lfd_at_empty_return',
    'lfd_at_pod',
    'ocean_vessel_id',
    'voyage_number',
    'estimated_time_of_arrival',
    'estimated_time_of_departure',
    'ocean_transport_order',
    'booking_number',
    'gate_close_date',
    'valid_till_date',
  ]);
}

export const getRoutingNodePayload = (values: any) => {
  return Object.values(values.routing_details?.routing_nodes || {}).map((rn: any) => {
    const { location, address, company, terminal, ...restRN } = _pick(rn, [
      'company',
      'id',
      'address',
      'terminal',
      'location',
      'tags',
      '_id',
      'waiting_time',
      'waiting_time_unit',
    ]);
    return {
      ...restRN,
      location_id: location?.id || null,
      terminal_id: terminal?.id || null,
      address_id: address?.id || null,
      company_id: company?.id || null,
    };
  });
};

export const transformShipmentEventDetails = (
  events: { [key: string]: any },
  shipment: ShipmentValue | null | undefined,
  session: any
) => {
  if (!events) return [];
  return Object.keys(events).map((partyKey) => {
    const event = events[partyKey];
    const shpEvent = shipment?.shipment_events?.find((e) => e.name === partyKey);
    return {
      ...event,
      id: shpEvent?.id,
      event_by_id: shpEvent?.event_by?.id || session?.id,
      name: partyKey,
    };
  });
};

export const getGenericPayloadValue = (
  values: any,
  shipment?: ShipmentValue | undefined | null,
  clearanceShipment?: boolean
) => {
  // extract sales agent id & remove sales_agent from values
  const oto: any = getNewOtoPayload(values, clearanceShipment);
  if (clearanceShipment) {
    oto.id = shipment?.ocean_transport_order?.id;
  }

  // extract sales agent id & remove sales_agent from values
  const routingLegs = getRoutingLegsPayload(values, shipment);
  const routingNodes = getRoutingNodePayload(values);

  const sales_agent_id = values?.sales_agent?.id;
  shipment = JSON.parse(JSON.stringify(shipment || null));
  // values = JSON.parse(JSON.stringify(values));
  let payload = {
    ...values,
    sales_agent_id,
    consol_type: 'agent',
    job_date: values.job_date ? convertToStringFormat(values.job_date) : undefined,
    consignee_party_name: values?.consignee_party_name,
    ocean_transport_order: oto,
    vehicle: getVehiclePayload(values),
    driver: getDriverPayload(values),
    customer_company_id: values?.customer?.party_company?.id,
    customer_address_id: values?.customer?.party_address?.id,
    inquiry_option_id: values?.inquiry_option?.id,
    carrier_id: values.carrier?.id,
    ocean_vessel_id: values.ocean_vessel?.imo,
    cargos: getCargoPayloadWithExistingCargo(values, shipment),
    routing_legs: routingLegs?.length && routingLegs.length > 0 ? routingLegs : undefined,
    routing_nodes: routingNodes?.length && routingNodes.length > 0 ? routingNodes : undefined,
    shipment_parties: transformPartyDetails(Object.assign({}, values?.party)),
    shipment_invoices: getShipmentInvoicePayload(values, shipment),
    shipment_containers: values.shipment_containers
      ? (values.shipment_containers || [])
          .filter((c: { container_number: string; pickup_date: Dayjs }) => c.container_number)
          .map((c: { container_number: string; pickup_date: Dayjs }) => ({
            ..._pick(c, [
              'id',
              'is_non_iso_container',
              'container_number',
              'carrier_seal_number',
              'cargo_gross_weight',
              'verified_gross_mass',
              'cargo_net_weight',
              'commercial_invoice_number',
              'purchase_order_number',
              'container_type',
              'container_type_code',
              'remarks',
            ]),
          }))
      : undefined,
    shipment_custom_details: shipmentCustomDetailPayload(values, shipment),
    linked_shipment_id: values?.linked_to_shipment?.id,
    shipment_events: transformShipmentEventDetails(
      values?.shipment_events,
      shipment,
      values?.sessionData
    ),
  };
  if (values?.shipment_container_quantity && values?.shipment_container_quantity.length > 0) {
    payload.shipment_containers = getContainerFromContainerQty(values);
  }
  const omitFields = [
    'routing_details',
    'ocean_vessel',
    'carrier',
    'party',
    'customer',
    'pickup_containers',
    'import_custom_details',
    'shipping_bill_details',
    'linked_to_shipment',
    'inquiry_option',
    'estimated_time_of_departure',
    'estimated_time_of_arrival',
    'shipment_container_quantity',
    'sales_agent',
    'shipper_same_as_customer',
    'consignee_same_as_customer',
    'id',
    'routing',
    'sessionData',
  ];
  if (payload.shipment_type !== SHIPMENT_TYPE_CONSOL) {
    omitFields.push('consol_type');
  }
  if (!shipment?.job_number && payload.freight_type !== FREIGHT_TYPE_ROAD) {
    omitFields.push('shipment_containers');
  }
  if (payload.freight_type === 'road') payload = updateLinkShipmentJsonData(payload);
  return _omit(payload, omitFields);
};

export const getServices = (tradeType: string) => {
  let services: any[] = [];

  if (tradeType === TRADE_TYPE_DOMESTIC) {
    services = [
      {
        name: ['origin', 'palletization'],
      },
      {
        name: ['cargo_insurance'],
      },
    ];
  } else {
    services = [
      {
        name: ['origin', 'clearance'],
      },
      {
        name: ['cargo_insurance'],
      },
      {
        name: ['destination', 'clearance'],
      },
      {
        name: ['origin', 'palletization'],
      },
    ];
  }
  return services;
};

export const getShipmentComponents = (props: any) => {
  const { shipmentType, shipment, form, type, cargoRef } = props;

  const loadType = form?.getFieldValue('load_type');
  const tradeType = form?.getFieldValue('trade_type');
  const freightType = form?.getFieldValue('freight_type');
  const services = getServices(tradeType || '');

  const isEnable = () => {
    if (freightType === FREIGHT_TYPE_ROAD && !shipmentType) return false;
    return shipmentType !== SHIPMENT_TYPE_CONSOL;
  };

  const isCustomEnabled = () => {
    if (
      freightType === FREIGHT_TYPE_ROAD &&
      (!shipmentType ||
        [SHIPMENT_TYPE_BACK_TO_BACK, SHIPMENT_TYPE_DIRECT].includes(shipmentType)) &&
      tradeType !== TRADE_TYPE_DOMESTIC
    )
      return true;
    return false;
  };
  return [
    {
      title: 'Basic Details',
      component: BasicDetails,
      props: {
        form,
        shipment,
        showRouteSelection: freightType === FREIGHT_TYPE_ROAD && !shipment?.id,
      },
      isVisible: true,
    },
    {
      title: 'Vehicle & Driver Details',
      component: VehicleDetails,
      props: { shipment, form },
      isVisible: isEnable(),
    },
    {
      title: 'Link Shipment Details',
      component: LinkShipmentDetails,
      props: { shipment, form, disableShipmentSearch: shipmentType === SHIPMENT_TYPE_HOUSE },
      isVisible: shipmentType !== SHIPMENT_TYPE_HOUSE,
    },
    {
      title: 'Routing Details',
      component: RoadRoutingDetails,
      props: { value: shipment, form, allowAddNew: type !== 'update' },
      isVisible: shipmentType !== SHIPMENT_TYPE_HOUSE,
    },
    {
      title: 'Cargo Details',
      component: CargoNewComponent,
      props: { form, ref: cargoRef },
      isVisible: true,
    },
    {
      title: 'Container Details',
      component: PickupContainerDetails,
      props: {},
      isVisible: loadType === LOAD_TYPE_FCL && type !== 'update' && isEnable(),
    },
    {
      title: 'Container Details',
      component: ContainerQuantity,
      isVisible:
        loadType === LOAD_TYPE_FCL &&
        type !== 'update' &&
        (shipmentType === SHIPMENT_TYPE_CONSOL || (freightType === 'road' && !shipmentType)),
    },

    {
      title: 'Services Provided',
      component: ShipmentServiceDetails,
      props: { shipmentType, services },
      isVisible: isEnable(),
    },
    {
      title: 'Custom Clearance',
      component: ExportClearanceDetails,
      props: { bookingRequest: shipment, form },
      isVisible: isCustomEnabled(),
    },
  ];
};

export const DISABLE_CELL_STYLE = {
  color: `rgba(0, 0, 0, 0.5)`,
  background: '#f5f5f5',
};
export function RequireHeaderWrapper({ text }: { text: string }) {
  return <span style={{ whiteSpace: 'pre-line', fontSize: '14px', color: '#c23934' }}>{text}</span>;
}

export const updateDriverDetailsWithContact = (driver_details: DriverValue) => {
  if (!driver_details) return;
  let driver_contact_number: string | undefined | { dialCode: string; number: string } =
    driver_details?.driver_contact_number || '';
  if (driver_contact_number) {
    const driver_number = (driver_details?.driver_contact_number || '').split(' ');
    driver_contact_number =
      driver_number.length > 1 && driver_number[0].length
        ? {
            dialCode: driver_number[0],
            number: driver_number[1],
          }
        : {
            dialCode: '',
            number: driver_details?.driver_contact_number || '',
          };
  }
  const driver = {
    ...driver_details,
    driver_details: driver_details,
    driver_contact_number: driver_contact_number || driver_details?.driver_contact_number,
  };
  return driver;
};

export const getGenericRouteValueDuplicate = (routeValue: ShipmentRouteValue) => {
  const routing_nodes: RoutingNodesHashValue = {};
  const routing_legs = (routeValue?.routing_legs || []).map((rl: any) => {
    const originId = rl?.origin?.id;
    const destinationId = rl?.destination?.id;
    if (originId) routing_nodes[originId] = { ...rl.origin, id: undefined, _id: originId };
    if (destinationId)
      routing_nodes[destinationId] = {
        ...rl.destination,
        id: undefined,
        _id: destinationId,
      };
    return {
      ...rl,
      id: undefined,
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
      estimated_time_of_arrival: convertToDayJs(rl?.estimated_time_of_arrival),
      actual_time_of_arrival: convertToDayJs(rl?.actual_time_of_arrival),
      estimated_time_of_departure: convertToDayJs(rl?.estimated_time_of_departure),
      actual_time_of_departure: convertToDayJs(rl?.actual_time_of_departure),
    };
  });

  const routingDetails = {
    routing_legs,
    routing_nodes,
  };
  const updatedRouteValue = {
    ...routeValue,
    routing_legs,
    routing_details: routingDetails,
  };

  const updateFormValue = {
    ..._omit(updatedRouteValue, ['routing_legs', 'name', 'id']),
  };
  return updateFormValue;
};

export const prefilFormWithRouteValue = (
  routeValue: ShipmentRouteValue,
  form: FormInstance<any> | undefined
) => {
  const formValue = form?.getFieldsValue(true);
  let routingLegs = null;

  if (!routeValue) {
    routingLegs = getRoutingDetails(routeValue);
  } else {
    const routeValueFormDuplicate = getGenericRouteValueDuplicate(routeValue);
    const allValues = {
      ...formValue,
      ...routeValueFormDuplicate,
    };
    form?.setFieldsValue(allValues);
    routingLegs = allValues.routing_details;
  }
  form?.setFieldValue('routing_details', routingLegs);
};

export const getRoadMovementMode = ({
  freightType,
  tradeType,
  loadType,
}: {
  freightType?: string;
  tradeType?: string;
  loadType?: string;
}) => {
  let movementMode = MOVEMENT_MODE_CARGO_PICKUP_AND_DROP;
  if (freightType === FREIGHT_TYPE_OCEAN) {
    if (tradeType === TRADE_TYPE_IMPORT) movementMode = MOVEMENT_MODE_CONTAINER_DESTUFFING;
    else movementMode = MOVEMENT_MODE_CONTAINER_STUFFING;
  } else if (freightType === FREIGHT_TYPE_AIR) {
    if (tradeType === TRADE_TYPE_IMPORT) movementMode = MOVEMENT_MODE_AIRPORT_PICKUP;
    else movementMode = MOVEMENT_MODE_AIRPORT_DELIVERY;
  }
  if (loadType === LOAD_TYPE_FCL && freightType === FREIGHT_TYPE_ROAD)
    movementMode = MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP;
  return movementMode;
};
