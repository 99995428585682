import { ApolloError, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  CreditLimitCheckContext,
  CreditLimitCheckWrapper,
  Form,
  Skeleton,
  message,
} from '@shipmnts/pixel-hub';
import { useForm } from 'antd/lib/form/Form';
import { LOAD_TYPE_FCL } from 'network/baseConstants';
import { SHIPMENT } from 'operations/graphql/shipment';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'wouter';
import {
  FREIGHT_TYPE_OCEAN,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
  TRADE_TYPE_EXPORT,
} from '../../constants';
import FormLayout from '../ShipmentForm/FormLayout';
import {
  getShipmentApiHelper,
  getShipmentComponents,
  handleShipperConsigneeOnMBLPrefilling,
  redirectToShipment,
} from './helpers';
import {
  getGenericPayloadValue,
  getHouseDocumentPayload,
  getMasterDocumentPayload,
} from './getGenericPayloadHelper';
import { getInitialValue } from './getInitialValueHelper';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { useApplicationContentContext, useEmailDrawerContext, useSession } from 'common';
import { getJsonFromError } from 'operations/helpers/ruleEngineHelper';
import { getFetchDocumentFunction } from 'operations/modules/helpers';
import { BOOKING_TYPE_SHIPMENT } from 'operations/modules/booking/constants';
import { omit } from 'lodash';

function useQuerySearch(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

interface DuplicateNewShipmentFormProps {
  showCreditPopupWithAction?: (payload: any) => void;
}

function DuplicateNewShipmentForm(props: DuplicateNewShipmentFormProps) {
  const { showCreditPopupWithAction } = props;
  const [form] = useForm();
  const params = useParams<{ id?: string; shipmentStatus?: string }>();
  const query = useQuerySearch();
  const { 1: navigate } = useLocation();
  const sessionData = useSession();
  const client = useApolloClient();
  const cargoRef = useRef<{
    runValidation: () => boolean;
    getPayload: () => ProductOrderItemValue[];
  }>();
  const { setVisible, setEmailProps } = useEmailDrawerContext();

  const is_customer_order = query.get('is_customer_order');
  const { config_data } = useApplicationContentContext();
  const { id: shipmentId } = params;
  const [shipment, setShipment] = useState<ShipmentValue>();
  const [shipmentType, setShipmentType] = useState<string | null | undefined>(
    query.get('shipment_type')
  );
  const [freightType, setFreightType] = useState<string>(
    query.get('freight_type') || FREIGHT_TYPE_OCEAN
  );
  const clearanceShipment = query.get('clearance_shipment') === 'true' ? true : false;
  const [tradeType, setTradeType] = useState<string>(query.get('trade_type') || TRADE_TYPE_EXPORT);
  const [components, setComponents] = useState<any[]>([]);
  const [sendNotification, setSendNotification] = useState(false);

  const loadType = Form.useWatch('load_type', form);
  const bookingType = Form.useWatch('booking_type', form);

  const uploadDocRef = useRef<any>();

  const type = 'create';

  // queries
  const [fetchShipment, { data: fetchedShipment }] = useLazyQuery(SHIPMENT);

  // mutations
  const [createOrUpdateShipment, { data: createData, loading, error }] = useMutation(
    getShipmentApiHelper(type, shipmentType),
    { context: { skipShowError: true } }
  );

  /**
   * Handle Shipment Form Submission
   * @param values: form values
   * @param throw_error_on_credit_fail: boolean, default false, if true, then throw error on credit fail
   * @returns void : => call createOrUpdateShipment mutation
   */
  const handleShipmentFormSubmission = useCallback(
    (values: any, throw_error_on_credit_fail = false) => {
      const variables: any = {};
      if (values.house?.shipment_document) {
        variables['shipment_document_house'] = getHouseDocumentPayload(values);
      }
      if (values.master?.shipment_document) {
        variables['shipment_document_master'] = getMasterDocumentPayload(values);
      }
      const payload: any = getGenericPayloadValue({
        ...values,
        shipment_type: shipmentType,
      });
      if (!shipmentType) variables['planned_shipment'] = payload;
      else if (shipmentType === SHIPMENT_TYPE_HOUSE) {
        variables['house_shipment'] = {
          master_shipment_id: shipment?.master_shipment_id,
          ...omit(payload, [
            'freight_type',
            'trade_type',
            'involved_branch_id',
            'job_date',
            'shipment_type',
            'carrier_id',
          ]),
        };
        variables['master_shipment_id'] = shipment?.master_shipment_id;
      } else variables['shipment'] = payload;
      variables['throw_error_on_credit_fail'] = throw_error_on_credit_fail;
      createOrUpdateShipment({ variables: variables });
    },
    [createOrUpdateShipment, shipment, shipmentType]
  );

  // fetching data
  useEffect(() => {
    if (shipmentId !== 'new') {
      fetchShipment({ variables: { id: shipmentId } });
    }
  }, [fetchShipment, shipmentId]);
  useEffect(() => {
    if (fetchedShipment?.shipment) {
      const shipmentMbx = Shipment.create(fetchedShipment.shipment);
      setShipment(shipmentMbx);
      if (shipmentMbx.freight_type) setFreightType(shipmentMbx.freight_type);
      if (shipmentMbx.trade_type) setTradeType(shipmentMbx.trade_type);
      setShipmentType(is_customer_order ? undefined : shipmentMbx.shipment_type);
    }
  }, [fetchedShipment, is_customer_order]);

  useEffect(() => {
    const _shipment =
      createData?.update_shipment_new ||
      createData?.create_house_shipment ||
      createData?.create_planned_shipment ||
      createData?.create_shipment?.shipment ||
      createData?.create_shipment?.house_shipment;
    if (sendNotification && _shipment) {
      openSendEmailDrawer(_shipment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData]);

  const openSendEmailDrawer = async (_shipment: ShipmentValue) => {
    const { data } = await client.query({
      query: SHIPMENT,
      variables: { id: _shipment.id },
    });
    const shipment = Shipment.create(data.shipment);

    if (!shipment) return;
    const isBookingConfirmed = shipment?.ocean_transport_orders?.length;
    setEmailProps({
      title: `${isBookingConfirmed ? 'Booking Confirmation' : 'Customer Order Acknowledgement'}`,
      action_name: `${
        isBookingConfirmed ? 'booking_confirmation' : 'booking_request_acknowledgement'
      }`,
      resource_ids: [shipment?.id],
      fetchDocumentParents: [
        {
          parent_type: BOOKING_TYPE_SHIPMENT,
          parent_ids: [shipment?.id],
        },
      ],
      companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
      showMarkDown: !isBookingConfirmed ? true : false,
      showEmail: isBookingConfirmed ? true : false,
      fetchDocuments: client
        ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
        : undefined,
    });
    setVisible(true);
  };

  // get components
  useEffect(() => {
    setComponents(
      getShipmentComponents({
        freightType: freightType,
        tradeType: tradeType,
        form,
        shipment: shipment,
        type: type,
        shipmentType: shipmentType,
        loadType: loadType || shipment?.load_type || LOAD_TYPE_FCL,
        bookingType,
        cargoRef: cargoRef,
        isClearance: clearanceShipment,
        uploadDocRef,
        isDup: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, freightType, tradeType, loadType, form, bookingType, setComponents, shipmentId]);

  const formInitialValue = getInitialValue(
    shipment,
    {
      trade_type: tradeType,
      freight_type: freightType,
      shipment_type: shipmentType,
    },
    true,
    undefined,
    sessionData
  );

  useEffect(() => {
    if (!!shipment) {
      form.setFieldsValue(
        getInitialValue(
          shipment,
          {
            trade_type: tradeType,
            freight_type: freightType,
            shipment_type: shipmentType,
          },
          true
        )
      );
    }
  }, [shipment, form, freightType, shipmentType, tradeType]);

  useEffect(() => {
    const uploadDocinAsync = async (createdShpId: any) => {
      await uploadDocRef.current?.updateDocs(createdShpId);
    };
    if (loading) return;
    if (error) {
      const graphQLErrors = error instanceof ApolloError ? error.graphQLErrors : error;
      const data: any = getJsonFromError(graphQLErrors);
      if (data && showCreditPopupWithAction) {
        const values: any = form.getFieldsValue();
        setSendNotification(false);
        showCreditPopupWithAction({
          data: data,
          onSucess: () => {
            handleShipmentFormSubmission(values);
          },
        });
      } else {
        message.error(error.message);
      }
    }
    const shipment =
      createData?.update_shipment_new ||
      createData?.create_house_shipment ||
      createData?.create_planned_shipment ||
      createData?.create_shipment?.shipment ||
      createData?.create_shipment?.house_shipment;
    if (createData && shipment) {
      const createdShipmentId = shipment.id;
      uploadDocinAsync(createdShipmentId);
      redirectToShipment(type, shipmentType ? 'Shipment' : 'Customer Order', createData, navigate);
    }
  }, [
    navigate,
    createData,
    loading,
    error,
    shipment,
    createOrUpdateShipment,
    form,
    showCreditPopupWithAction,
    shipmentId,
    shipmentType,
    type,
    handleShipmentFormSubmission,
  ]);

  if (shipmentId !== 'new' && !shipment) {
    return <Skeleton />;
  }

  return (
    <Form
      form={form}
      initialValues={formInitialValue}
      onFinish={(values: any): any => {
        const error = Boolean(cargoRef?.current?.runValidation());
        if (error) return;
        handleShipmentFormSubmission(
          { ...values, cargos: cargoRef?.current?.getPayload(), cargo_properties: undefined },
          true
        );
      }}
      layout="vertical"
      style={{
        height: '100%',
      }}
      //   disabled={shipment ? !shipment?.canEdit() : false}
      requiredMark={true}
      onValuesChange={async (changedValues: any) => {
        const formValues: any = {};
        if (changedValues.hasOwnProperty('customer')) {
          const branch_id = form.getFieldValue('involved_branch_id');
          const customer = changedValues.customer;
          if (branch_id && customer) {
            const { response } = await getSalesPersonForCustomer(
              customer?.party_company?.id,
              branch_id,
              sessionData,
              client
            );
            if (response) {
              formValues['sales_agent'] = response;
            }
          }
        }
        if (changedValues?.consol_type && shipmentType === SHIPMENT_TYPE_CONSOL)
          handleShipperConsigneeOnMBLPrefilling(changedValues?.consol_type, form);
        if (Object.keys(formValues).length > 0) form.setFieldsValue(formValues);
      }}
    >
      <FormLayout
        form={form}
        components={components}
        formTitle={<>Create {shipmentType ? 'Shipment' : 'Customer Order'} </>}
        loading={loading}
        formSubTitle={shipmentType || ''}
        formOnSubmitText={'Create'}
        onBack={() => {
          if (shipment) {
            navigate('~/view/shipment/' + shipment?.id);
          } else {
            navigate('~/workspace?doc_type=Shipment::Shipment');
          }
        }}
        sendEmailProps={{
          label: 'Send Notification',
          sendNotification: sendNotification,
          setSendNotification: setSendNotification,
        }}
      />
    </Form>
  );
}

export default function DuplicateNewShipmentFormWrapper() {
  const sessionData = useSession();

  return (
    <CreditLimitCheckWrapper sessionData={sessionData}>
      <CreditLimitCheckContext.Consumer>
        {(contextProps: any) => {
          return (
            <DuplicateNewShipmentForm
              showCreditPopupWithAction={contextProps?.showCreditPopupWithAction}
            />
          );
        }}
      </CreditLimitCheckContext.Consumer>
    </CreditLimitCheckWrapper>
  );
}
