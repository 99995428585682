import { gql } from '@apollo/client';
import { oceanTransportOrderFields } from 'operations/modules/booking/graphql';

export const ALLOCATE_OTO_REPORT = gql`
  query allocate_oto_report($allocate_oto_report: AllocateOtoReportInputType!) {
    allocate_oto_report(allocate_oto_report: $allocate_oto_report) {
      ...oceanTransportOrderFields
    }
  }
  ${oceanTransportOrderFields}
`;
