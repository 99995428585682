import { FormTable } from '@shipmnts/pixel-hub';
import { Column } from 'operations/models/Report';
import ShipmentEstimate, { ShipmentEstimateValue } from 'operations/models/ShipmentEstimate';

import React from 'react';
import { isStateTreeNode } from 'mobx-state-tree';
import { useSession } from 'common';

interface ShipmentEstimateSummaryProps {
  shipment_estimates: ShipmentEstimateValue[];
}

export default function ShipmentEstimateSummary(props: ShipmentEstimateSummaryProps) {
  const user = useSession();
  const columnDefs: Column[] = [
    {
      headerName: 'Item Name',
      field: 'item',
      colId: 'item',
      minWidth: 180,
      resizable: true,
    },

    {
      headerName: 'Basis',
      field: 'uom',
      colId: 'uom',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'QTY',
      field: 'quantity',
      colId: 'quantity',
      minWidth: 100,
      resizable: true,
    },
    {
      headerName: 'Sell Rate',
      field: 'sell_rate',
      colId: 'sell_rate',
      columnType: 'Currency',
      resizable: true,
      minWidth: 100,
      aggFunc: undefined,
      cellRendererSelector: (params) => {
        return params.data.is_sell_view_allowed
          ? {
              component: 'CurrencyTypeRenderer',
              params: {
                precision: 4,
                currency: params.data.buy_currency,
                showCurrency: true,
              },
            }
          : {
              component: 'NotPermittedRenderer',
            };
      },
    },
    {
      headerName: 'Total Sell Amount',
      field: 'total_sell_amount',
      colId: 'total_sell_amount',
      columnType: 'Currency',
      minWidth: 100,
      resizable: true,
      aggFunc: undefined,
      cellRendererSelector: (params) => {
        return params.data.is_sell_view_allowed
          ? {
              component: 'CurrencyTypeRenderer',
              params: {
                precision: 4,
                currency: user?.company_account?.default_currency,
                showCurrency: true,
              },
            }
          : {
              component: 'NotPermittedRenderer',
            };
      },
    },

    {
      headerName: 'Buy Rate',
      field: 'buy_rate',
      colId: 'buy_rate',
      columnType: 'Currency',
      minWidth: 100,
      resizable: true,
      aggFunc: undefined,
      cellRendererSelector: (params) => {
        return params.data.is_buy_view_allowed
          ? {
              component: 'CurrencyTypeRenderer',
              params: {
                precision: 4,
                currency: params.data.buy_currency,
                showCurrency: true,
              },
            }
          : {
              component: 'NotPermittedRenderer',
            };
      },
    },
    {
      headerName: 'Total Buy Amount',
      field: 'total_buy_amount',
      colId: 'total_buy_amount',
      columnType: 'Currency',
      minWidth: 100,
      resizable: true,
      aggFunc: undefined,
      cellRendererSelector: (params) => {
        return params.data.is_buy_view_allowed
          ? {
              component: 'CurrencyTypeRenderer',
              params: {
                precision: 4,
                currency: user?.company_account?.default_currency,
                showCurrency: true,
              },
            }
          : {
              component: 'NotPermittedRenderer',
            };
      },
    },
  ];

  return (
    <FormTable
      reportName="rate_table"
      height={200}
      columns={columnDefs}
      rowData={(props?.shipment_estimates || []).map((est: ShipmentEstimateValue) => {
        return isStateTreeNode(est) ? est : ShipmentEstimate.create(est);
      })}
      reportConfig={{}}
    />
  );
}
