import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { useSession } from 'common';
import { RichTextEditorWrapper } from 'common';
import {
  Card,
  Button,
  Table,
  InputNumber,
  Typography,
  message,
  Drawer,
  Form,
  Alert,
  Skeleton,
  dayjsGenerateConfig,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import {
  LOAD_TYPE_FCL,
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_SELF,
} from 'operations/baseConstants';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { RoutingNodesHashValue, RoutingDetailsValue } from 'operations/components/RoutingDetails';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { CarrierValue } from 'operations/models/Carrier';
import { RoutingLegValue, getMainHopEtd, hasPrecarriageLeg } from 'operations/models/RoutingLeg';

import { LocationValue } from 'operations/models/Location';
import BookingOrderBasicDetails from 'operations/modules/booking/components/BookingOrderForm/BookingOrderBasicDetails';
import BookingOrderConfirmation from 'operations/modules/booking/components/BookingOrderForm/BookingOrderConfirmation';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { omit as _omit, pick } from 'lodash';
import { validateContainerRequests } from 'operations/modules/reports/helpers/allocationHelper';
import { getOceanTransportOrderPayload } from 'operations/modules/booking/components/BookingOrderForm/BookingOrderForm';
import { CREATE_OCEAN_TRANSPORT_ORDER_FROM_SHIPMENT } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { renderContainerSettings } from 'operations/modules/reports/oldCellRenderers';
import { errorMessageHandlerGraphQL } from 'common';
import { getRoutingValues } from './getInitialValueHelper';
import {
  getCarriageWiseRoutingLegs,
  ROUTING_TYPE_MAIN_CARRIAGE,
} from 'operations/models/RoutingLeg';
import {
  UploadDocumentModal,
  DocumentsList,
  UploadDocumentPayload,
  UploadedDocumentType,
  documentsStore,
  uploadDocument,
  deleteUploadedDocument,
  addDocumentParents,
} from '@shipmnts/pixel-hub';
import { dayjs, Dayjs } from '@shipmnts/pixel-hub';
import { BOOKING_TYPE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/constants';
import { getDisbaledCutoffDateMapping } from 'operations/modules/booking/helpers/CutoffDateHelper';
import {
  getRoutingDetailsOnVoyageReset,
  checkVoyageResetOnValuesChange,
} from 'operations/modules/booking/helpers/RoutingDetailsHelper';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
import { ShipmentValue } from 'operations/models/Shipment';
import { getContainerRequestFromShipmentContainers } from './helpers';
import { FETCH_SHIPMENT_CONTAINERS } from 'operations/modules/booking/graphql/shipmentContainer';
import BookingRoutingDetailsNew from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetailsNew';
import { SHIPPING_LINE_SERVICE_TYPES, TRADE_TYPE_IMPORT } from '../../constants';
import { getWhyCarrierIsDisabled } from 'operations/modules/helpers';
import { FieldDisableReason } from 'operations/commonTypeDefs';

const { Text } = Typography;

export interface CreateOTOFormValue {
  carrier: CarrierValue;
  vendor: PartyValue;
  customer: PartyValue;
  // sales_person: SalesPersonValue;
  sales_person: SalesPersonValue;
  booking_number: string;
  booking_date: Dayjs;
  contract_number?: string;
  container_requests?: Record<string, number>;
  routing_details?: RoutingDetailsValue;
  empty_pickup_location?: LocationValue;
  confirmed_booking?: boolean;
  valid_till_date: Dayjs;
  gate_open_date: Dayjs;
  si_cutoff_date: Dayjs;
  gate_close_date: Dayjs;
  doc_cutoff_date: Dayjs;
  origin_detention_free_days?: number;
  origin_demurrage_free_days?: number;
  destination_detention_free_days?: number;
  destination_demurrage_free_days?: number;
  voyage_schedule_id?: string;
  stuffing_buffer_service_order?: {
    stuffing_cutoff_date?: Dayjs;
    location_node?: RoutingNodeValue;
  };
  terms_and_condition_description?: string;
  terms_and_condition?: {
    id?: string;
    content?: string;
    is_default?: boolean;
    name?: string;
  };
  surveyor: PartyValue;
}

const columns: any = (booking_type: string) => [
  {
    title: 'Container Type',
    dataIndex: 'container_type',
    width: '30%',
    render: function render(text: string) {
      return <Text className="primary-color">{text}</Text>;
    },
  },
  {
    title: 'Available Quantity',
    dataIndex: 'quantity_unfulfilled',
  },
  {
    title: 'Allocate Quantity',
    dataIndex: 'quantity_allocated',
    render: function render(text: string, record: ContainerRequestValue, index: number) {
      return (
        <Form.Item style={{ margin: 0 }} name={['container_requests', record.id || '']}>
          <InputNumber
            disabled={booking_type === BOOKING_TYPE_SELF || !record.quantity}
            min={0}
            defaultValue={record.quantity}
            max={record.quantity_unfulfilled || 0}
            precision={0}
          />
        </Form.Item>
      );
    },
  },
  {
    title: 'Settings',
    dataIndex: 'container_settings',
    render: function render(text: string, record: ContainerRequestValue, index: number) {
      return <span>{renderContainerSettings([record])}</span>;
    },
  },
];

const getBookingOrderPayload = (values: CreateOTOFormValue) => {
  const { container_requests, stuffing_buffer_service_order, sales_person, ...restProps } = values;
  const omitFields = [
    'cargos',
    'surveyor',
    'terms_and_condition',
    'sales_person',
    'stuffing_location',
    'destuffing_location',
    'stuffing_type',
    'destuffing_type',
    'pol_buffer_location',
    'pod_buffer_location',
  ];
  let payload = getOceanTransportOrderPayload(restProps);

  if (values?.routing_details) {
    const routing_nodes = payload.routing_nodes.map((rn: any) => {
      const new_rn = { ...rn };
      new_rn['_id'] = rn.id;
      return _omit(new_rn, ['id', 'terminal', 'address']);
    });
    const routing_legs = payload.routing_legs.map((rl: RoutingLegValue) => _omit(rl, ['id']));
    payload['routing_nodes'] = routing_nodes;
    payload['routing_legs'] = routing_legs;
  } else {
    omitFields.concat(['routing_nodes', 'routing_legs']);
  }

  payload['surveyor_company_id'] = values?.surveyor?.party_company?.id;
  payload['surveyor_address_id'] = values?.surveyor?.party_address?.id;
  payload['terms_and_condition_id'] = values?.terms_and_condition?.id;
  payload['sales_person_id'] = values?.sales_person?.id;

  payload = _omit(payload, omitFields);
  return payload;
};

// const getStuffingBufferServicePayload = (values: CreateOTOFormValue) => {
//   const { stuffing_buffer_service_order } = values;
//   return {
//     ...stuffing_buffer_service_order,
//     location_node: stuffing_buffer_service_order?.location_node && {
//       id: stuffing_buffer_service_order?.location_node?.id,
//       location_id: stuffing_buffer_service_order?.location_node?.location?.id,
//       tags: stuffing_buffer_service_order?.location_node?.tags,
//     },
//     stuffing_cutoff_date: stuffing_buffer_service_order?.stuffing_cutoff_date?.unix(),
//   };
// };

const getOtoDetails = (shipment: ShipmentValue) => {
  if ((shipment.ocean_transport_orders || []).length > 0) {
    return {
      ...pick(shipment.ocean_transport_orders?.[0], [
        'gate_open_date',
        'gate_close_date',
        'si_cutoff_date',
        'doc_cutoff_date',
        'origin_detention_free_days',
        'origin_demurrage_free_days',
        'destination_detention_free_days',
        'destination_demurrage_free_days',
        'voyage_schedule_id',
        'booking_type',
        'service_type',
      ]),
    };
  }
  return {};
};
const CreateBookingOrderFromBR = observer(function CreateBookingOrderFromBR(props: {
  shipment: ShipmentValue;
  onClose: () => void;
  booking_type: string;
  load_type: string;
  onSuccess?: (booking_request: BookingRequestValue, sendEmail: boolean) => void;
}): JSX.Element {
  const { shipment, onClose, booking_type, load_type, onSuccess } = props;
  const routingLegs = shipment?.routing_legs;
  const [containerError, setContainerError] = useState<string>();
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const [confirmBooking, setConfirmBooking] = useState<boolean>(true);
  const [previousValue, setPreviousValue] = useState<{
    isFirstContainerTypeReefer?: boolean;
    mainCarriage?: {
      locationId?: string;
      vessel?: string;
    };
    carrierId?: string;
  }>({
    isFirstContainerTypeReefer: false,
    mainCarriage: {},
  });
  const sessionData = useSession();

  const [createOTOFromShipment, { data, loading, error }] = useMutation(
    CREATE_OCEAN_TRANSPORT_ORDER_FROM_SHIPMENT
  );
  const { data: shipmentContainers, loading: shipmentContainerLoading } = useQuery(
    FETCH_SHIPMENT_CONTAINERS,
    {
      variables: { shipment_ids: [shipment?.id] },
      fetchPolicy: 'no-cache',
    }
  );

  const routing_details_ref = useRef<{ runValidation: () => boolean }>();

  const [documentUpdate, setDocumentUpdate] = useState<{
    updating: boolean;
    error?: boolean | string;
  }>({ updating: false, error: false });

  const [updateDocIds, setUpdateDocIds] = useState<Array<string>>([]);

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });
  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({ type: 'upload_failure', payload: { doc, error: error.message } });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    [sessionData.id]
  );

  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const [form] = Form.useForm();

  const updateDocs = useCallback(
    async (booking_order_id: string, document_ids: Array<string>) => {
      setDocumentUpdate({ updating: true, error: false });
      if (document_ids.length !== 0) {
        const { response, error } = await addDocumentParents(
          document_ids,
          [{ parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER, parent_id: booking_order_id }],
          sessionData.id,
          process.env.DOCGEN_URL || ''
        );
        if (error) {
          message.error('Document upload failed');
          setDocumentUpdate({ updating: false, error: !!error || error.message });
        } else if (response) {
          setDocumentUpdate({ updating: false, error: false });
          dispatch({ type: 'reset_state' });
          setUpdateDocIds([]);
          onClose();
          if (onSuccess)
            onSuccess(
              data?.create_ocean_transport_order_from_shipment?.ocean_transport_order,
              sendEmail
            );
        }
      }
    },
    [data, onClose, onSuccess, sendEmail, sessionData.id]
  );

  const resetMainHopVoyageNumber = useCallback(
    (routing_details: RoutingDetailsValue, voyage_schedule_id?: string) => {
      form.setFieldsValue({
        routing_details,
        voyage_schedule_id,
      });
    },
    [form]
  );

  useEffect(() => {
    if (!error && data?.create_ocean_transport_order_from_shipment) {
      message.success('Ocean Transporter Order Created !');
      const createdOtoId =
        data.create_ocean_transport_order_from_shipment?.ocean_transport_order?.id;
      updateDocs(createdOtoId, updateDocIds);
      onClose();
      if (onSuccess)
        onSuccess(data?.create_ocean_transport_order_from_shipment?.shipment, sendEmail);
    }
  }, [
    shipment.ocean_transport_order,
    data,
    error,
    onClose,
    onSuccess,
    sendEmail,
    updateDocIds,
    updateDocs,
  ]);

  const containerRequests = getContainerRequestFromShipmentContainers(
    shipmentContainers?.fetch_shipment_containers ?? []
  );

  const routing_nodes: RoutingNodesHashValue = {};
  const routing_legs = routingLegs.map((rl: RoutingLegValue, i: number) => {
    if (rl?.origin?.id) routing_nodes[rl.origin.id] = rl.origin;
    if (rl?.destination?.id) routing_nodes[rl.destination.id] = rl.destination;
    return {
      ...rl,
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
      voyage_number: i !== 0 ? rl.voyage_number : undefined,
    };
  });
  const etd = getMainHopEtd(routing_legs);
  const drawerTitle = (
    <>
      {`Create Booking For Order ${shipment?.shipment_booking_number || ''}`}
      {shipment.quotation_number && shipment.inquiry_option?.id && (
        <Alert
          showIcon
          style={{ marginTop: '5px', width: 'fit-content' }}
          message={`Carrier / RA number / Free Days are prefilled from Quote #
        ${shipment.quotation_number}`}
          type={'warning'}
        />
      )}
    </>
  );

  useEffect(() => {
    form.setFieldValue('confirmed_booking', true);
    setConfirmBooking(true);
  }, [form]);

  if (shipmentContainerLoading) {
    return (
      <Drawer title={drawerTitle} width={'60%'} open={true} onClose={onClose}>
        <Skeleton />
      </Drawer>
    );
  }

  const shipment_carrier = shipment?.inquiry_option?.carrier || shipment?.carrier;

  const disabledFields: Record<string, FieldDisableReason> = {
    ...getWhyCarrierIsDisabled(shipment),
  };

  return (
    <Drawer
      title={drawerTitle}
      width={'60%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Create & Confirm Allocation"
          onSave={() => form.submit()}
          loading={loading}
          disabled={
            uploadingDocuments.length > 0 || documentUpdate.updating || !!documentUpdate.error
          }
          onClose={onClose}
          showSendEmail={confirmBooking && booking_type !== BOOKING_TYPE_SELF}
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Booking Confirmation"
        />
      }
    >
      <div className="custom-card">
        {containerError && (
          <div style={{ marginBottom: '10px' }}>
            <Text type="danger">{containerError}</Text>
          </div>
        )}
        {error && errorMessageHandlerGraphQL(error)}
        {documentUpdate.error && (
          <span>
            Something went wrong while uploading the documents.
            {documentUpdate.error}
            <Button
              onClick={() =>
                updateDocs(
                  data?.create_ocean_transport_order_from_booking_request?.id,
                  updateDocIds
                )
              }
              size="small"
              type="link"
            >
              Retry
            </Button>
          </span>
        )}

        {/* <BookingRequestSummaryCard shipment={shipment} /> */}
        <Form
          name="booking_order"
          layout="vertical"
          form={form}
          initialValues={{
            ...getOtoDetails(shipment),
            ...getRoutingValues(shipment),
            booking_type,
            sales_person: shipment.sales_agent,
            customer: {
              party_company: sessionData.company_account.default_company,
              party_address: shipment?.involved_branch?.default_address,
            },
            vendor:
              shipment?.trade_type === TRADE_TYPE_IMPORT
                ? {
                    party_company: shipment.getShipmentPartyByName('origin_agent')?.party_company,
                    party_address: shipment.getShipmentPartyByName('origin_agent')?.party_address,
                  }
                : null,
            confirmed_booking: true,
            valid_till_date: etd?.isBefore(dayjsGenerateConfig.getNow().add(3, 'day'))
              ? etd.subtract(1, 'day')
              : dayjsGenerateConfig.getNow().add(3, 'day'),
            booking_date: dayjsGenerateConfig.getNow(),
            routing_details: { routing_legs, routing_nodes },
            load_type,
            container_requests: containerRequests.reduce(
              (oto_containers: { [key: string]: number }, cr: ContainerRequestValue) => {
                oto_containers[cr.id || ''] = cr.quantity_unfulfilled || 0;
                return oto_containers;
              },
              {}
            ),
            // ...(!shipment.isFullyUnallocated
            //   ? {
            //       carrier: shipment.carrier,
            //       ...(shipment.voyage_number
            //         ? _pick(shipment, [
            //             'gate_open_date',
            //             'gate_close_date',
            //             'si_cutoff_date',
            //             'doc_cutoff_date',
            //           ])
            //         : {}),
            //     }
            //   : {}),
            // stuffing_buffer_service_order: shipment?.stuffing_buffer_service_orders[0] && {
            //   stuffing_cutoff_date:
            //     shipment?.stuffing_buffer_service_orders[0]?.stuffing_cutoff_date,
            //   location_node: shipment?.stuffing_buffer_service_orders[0]?.location_node,
            // },
            carrier: shipment_carrier,
            origin_detention_free_days: shipment?.inquiry_option?.origin_carrier_free_days,
            destination_detention_free_days:
              shipment?.inquiry_option?.destination_carrier_free_days,
            destination_demurrage_free_days: shipment?.inquiry_option?.destination_port_free_days,
            contract_number: shipment?.inquiry_option?.vendor_rate_agreement_number,
            service_type: SHIPPING_LINE_SERVICE_TYPES[0].key,
          }}
          onValuesChange={(changedValues, allValues) => {
            if (changedValues.routing_details) {
              const etd = getMainHopEtd(changedValues.routing_details.routing_legs);
              const current_valid_till_date = form.getFieldValue('valid_till_date');
              if (etd && etd.isBefore(current_valid_till_date))
                form.setFieldsValue({ valid_till_date: etd.subtract(1, 'day') });
            }
            if (changedValues.terms_and_condition) {
              form.setFieldsValue({
                terms_and_condition_description: changedValues.terms_and_condition?.content,
              });
            }
            const { shouldReset, currentValue } = checkVoyageResetOnValuesChange({
              changedValues,
              allValues,
              previousValue,
            });
            setPreviousValue(currentValue);
            if (shouldReset) {
              getRoutingDetailsOnVoyageReset(allValues.routing_details, resetMainHopVoyageNumber);
            }
          }}
          onFinish={(values: any) => {
            const noContainerAllocated = validateContainerRequests(values);
            if (load_type === LOAD_TYPE_FCL && noContainerAllocated)
              setContainerError('Please allocate Container and then create customer order.');
            else {
              const container_requests_payload: any[] = [];
              Object.keys(values.container_requests).forEach((k) => {
                if (values.container_requests[k]) {
                  const foundContainerRequest = (containerRequests || []).find(
                    (cr: ContainerRequestValue) => cr.id === k
                  );
                  container_requests_payload.push({
                    container_settings: foundContainerRequest.container_settings,
                    container_type: foundContainerRequest.container_type,
                    container_type_code: foundContainerRequest.container_type_code,
                    weight_unit: foundContainerRequest.weight_unit,
                    weight_per_container: foundContainerRequest.weight_per_container,
                    quantity: values.container_requests[k],
                  });
                }
              });
              const payload = getBookingOrderPayload(values);
              // booking_number is set to shipment_booking_number in case of Self-Booking
              if (payload.booking_type === BOOKING_TYPE_SELF) {
                payload['booking_number'] = shipment.shipment_booking_number;
              }

              const routingLegs = (values.routing_details?.routing_legs || []).map(
                (rl: RoutingLegValue) => {
                  const {
                    vessel,
                    estimated_time_of_departure,
                    estimated_time_of_arrival,
                    global_carrier,
                    ...restRL
                  } = rl;
                  return {
                    ...restRL,
                    global_carrier_id: global_carrier?.id,
                    vessel_id: vessel?.imo,
                    estimated_time_of_departure: estimated_time_of_departure
                      ? dayjs(estimated_time_of_departure).unix()
                      : undefined,
                    estimated_time_of_arrival: estimated_time_of_arrival
                      ? dayjs(estimated_time_of_arrival).unix()
                      : undefined,
                  };
                }
              );

              (shipment?.routing_legs || []).forEach((rl: RoutingLegValue) => {
                const {
                  vessel,
                  origin,
                  destination,
                  estimated_time_of_departure,
                  estimated_time_of_arrival,
                  global_carrier,
                  ...restRL
                } = rl;
                const routingLegExsist = (values.routing_details?.routing_legs || []).find(
                  (c: any) => c.id === rl.id
                );
                if (!routingLegExsist) {
                  const formatedRoutingLeg = {
                    ...restRL,
                    global_carrier_id: global_carrier?.id,
                    vessel_id: vessel?.imo,
                    origin_id: origin?.id,
                    destination_id: destination?.id,
                    estimated_time_of_departure: estimated_time_of_departure
                      ? dayjs(estimated_time_of_departure).unix()
                      : undefined,
                    estimated_time_of_arrival: estimated_time_of_arrival
                      ? dayjs(estimated_time_of_arrival).unix()
                      : undefined,
                  };
                  routingLegs.push({ ...formatedRoutingLeg, _destroy: true });
                }
              });

              const routingNodes = Object.values(values.routing_details?.routing_nodes || {}).map(
                (rn: any) => {
                  const { location, address, company, terminal, ...restRN } = pick(rn, [
                    'company',
                    'id',
                    'address',
                    'terminal',
                    'location',
                    'tags',
                    '_id',
                  ]);
                  return {
                    ...restRN,
                    location_id: location?.id || null,
                    terminal_id: terminal?.id || null,
                    address_id: address?.id || null,
                    company_id: company?.id || null,
                  };
                }
              );

              createOTOFromShipment({
                variables: {
                  shipment_id: shipment.id,
                  ocean_transport_order: {
                    ...payload,
                    routing_legs: routingLegs,
                    routing_nodes: routingNodes,
                    container_requests: container_requests_payload,
                  },
                },
              });
              setUpdateDocIds(documents.map((d) => d.id));
            }
          }}
        >
          <Form.Item noStyle name="booking_type">
            <span />
          </Form.Item>
          <Form.Item noStyle name="load_type">
            <span />
          </Form.Item>
          <Form.Item noStyle name="voyage_schedule_id">
            <span />
          </Form.Item>
          <BookingOrderBasicDetails
            shipment={shipment}
            load_type={load_type}
            booking_type={booking_type}
            areCutoffRequired={booking_type === BOOKING_TYPE_SHIPPING_LINE}
            disabledFields={disabledFields}
          />
          <Card title="Routing Details" className="custom-card" style={{ marginTop: '16px' }}>
            <Form.Item
              name="routing_details"
              rules={[
                {
                  validator: (rule, value) => {
                    if (!routing_details_ref?.current?.runValidation()) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
              noStyle
            >
              <BookingRoutingDetailsNew
                form={form}
                ref={routing_details_ref}
                validateVesselVoyage={load_type === LOAD_TYPE_FCL}
                bookingType={booking_type}
                isReeferContainer={shipment?.container_requests?.[0]?.is_reefer_container}
              />
            </Form.Item>
          </Card>
          {load_type === LOAD_TYPE_FCL && (
            <Card title="Container Details" className="custom-card" style={{ marginTop: '16px' }}>
              <Table
                dataSource={containerRequests}
                columns={columns(booking_type)}
                pagination={false}
                rowKey={(record: ContainerRequestValue) => record.id || ''}
                size="small"
              />
            </Card>
          )}
          <Card
            title="Booking Cutoff Details"
            className="custom-card"
            style={{ marginTop: '16px' }}
          >
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.confirmed_booking !== currentValues.confirmed_booking ||
                prevValues.routing_details !== currentValues.routing_details
              }
            >
              {({ getFieldValue }) => {
                const confirmed_booking = getFieldValue('confirmed_booking');
                const routing_legs = getFieldValue('routing_details')?.routing_legs;
                const carriageWiseRoutingLegs = routing_legs
                  ? getCarriageWiseRoutingLegs(routing_legs)
                  : {};
                const etd =
                  carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE]?.[0]
                    ?.estimated_time_of_departure;
                const voyage_schedule_id = getFieldValue('voyage_schedule_id');
                const hasPrecarriage = hasPrecarriageLeg(routing_legs);

                return (
                  <BookingOrderConfirmation
                    etd={etd}
                    areCutoffRequired={
                      confirmed_booking && booking_type === BOOKING_TYPE_SHIPPING_LINE
                    }
                    disableCutoff={getDisbaledCutoffDateMapping(
                      Boolean(voyage_schedule_id),
                      hasPrecarriage
                    )}
                    load_type={load_type}
                    stuffing_location_type={shipment.stuffing_type || undefined}
                    booking_type={booking_type}
                  />
                );
              }}
            </Form.Item>
          </Card>
          {booking_type === BOOKING_TYPE_SELF && (
            <Card
              title="Terms and Conditions"
              className="custom-card"
              style={{ marginTop: '16px' }}
            >
              {' '}
              <Form.Item
                labelCol={{ span: 24 }}
                name="terms_and_condition"
                label="Terms And Condition"
                style={{ width: '100%' }}
              >
                <GlobalSearch
                  doc_type="Network::Template"
                  disabled={false}
                  searchProps={{
                    template_types: ['terms_and_condition'],
                    resource_type: 'Shipment::OceanTransportOrder',
                  }}
                  extraProps={{
                    prefillDefault: true,
                  }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                name="terms_and_condition_description"
                label="Terms And Condition Description"
                style={{ width: '100%' }}
              >
                <RichTextEditorWrapper
                  toolbarOptions={{
                    container: [
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ color: [] }, { background: [] }],
                      ['emoji'],
                      [{ indent: '-1' }, { indent: '+1' }],
                    ],
                  }}
                />
              </Form.Item>
            </Card>
          )}
          <div style={{ margin: '16px 0 44px 0' }}>
            <UploadDocumentModal
              onUpload={uploadDoc}
              sessionData={sessionData}
              initialUploadDocumentTags={['Booking confirmation']}
            />
            <DocumentsList
              uploadingDocuments={uploadingDocuments}
              uploadedDocuments={documents}
              uploadingError={uploadingError}
              onUpload={uploadDoc}
              onDelete={deleteDoc}
              sessionData={sessionData}
            />
          </div>
        </Form>
      </div>
    </Drawer>
  );
});

export default CreateBookingOrderFromBR;
