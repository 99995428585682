import React, { useState, useEffect } from 'react';
import {
  Input,
  message,
  Drawer,
  Select,
  Row,
  Col,
  Badge,
  Table,
  Radio,
  Form,
  Card,
} from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { startCase as _startCase } from 'lodash';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import {
  BOOKING_CANCELLED_BY,
  BOOKING_CANCELLATION_REASONS,
} from 'operations/modules/reports/constants';
import { CANCEL_OCEAN_SHIPMENT } from 'operations/graphql/shipment';
import { errorMessageHandlerGraphQL } from 'common';

import { LOAD_TYPE_FCL, LOAD_TYPE_LCL } from 'operations/baseConstants';
import { observer } from 'mobx-react-lite';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { STATUSES_MAPPING } from 'operations/modules/reports/constants';
import { showContainerAllocation } from 'operations/models/ShipmentContainer';
import { ShipmentValue } from 'operations/models/Shipment';
import { SHIPMENT_TYPE_CONSOL } from 'operations/modules/shipment/constants';
import ConfirmCancelShipmentModal from './ConfirmCancelShipmentModal';

const { TextArea } = Input;

export interface CancelShipmentFormProps {
  onClose: () => void;
  shipment: ShipmentValue;
  onSuccess?: (booking: ShipmentValue, sendEmail?: boolean) => void;
}

const CancelShipmentForm = React.memo(function CancelShipmentForm(
  props: CancelShipmentFormProps
): JSX.Element {
  const { onClose, shipment, onSuccess } = props;
  const [cancelAllocatedResources, setCancelAllocatedResources] = useState<boolean>(
    !shipment.shipment_type
      ? shipment.isFullyUnallocated || shipment.load_type === LOAD_TYPE_LCL
      : false
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [cancelBooking, { data, loading, error }] = useMutation(CANCEL_OCEAN_SHIPMENT);
  useEffect(() => {
    if (!error && data?.cancel_ocean_shipment?.message) {
      message.success('Booking Cancelled Successfully!');
      onClose();
      if (data?.cancel_ocean_shipment?.customer_order?.id) {
        window.open(
          `${process.env.OPERATIONS_URL}/view/shipment/${data?.cancel_ocean_shipment?.customer_order?.id}`,
          '_blank'
        );
      }
      if (onSuccess) onSuccess(shipment, sendEmail);
    }
  }, [error, data, onClose, onSuccess, sendEmail, shipment]);

  const [form] = Form.useForm();
  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  return (
    <>
      <Drawer
        title={`Cancel ${shipment?.shipment_type ? 'Shipment' : 'Customer Order'}`}
        width={'60%'}
        open={true}
        onClose={onClose}
        footer={
          <DrawerFooter
            saveText={`Cancel ${shipment?.shipment_type ? 'Shipment' : 'Customer Order'}`}
            showSendEmail
            sendEmail={sendEmail}
            setSendEmail={setSendEmail}
            sendEmailText="Send Cancel Booking Confirmation"
            loading={loading}
            onClose={onClose}
            onSave={openConfirmationModal}
          />
        }
      >
        <Form
          name="booking_cancellation"
          form={form}
          layout="vertical"
          onFinish={(values) => {
            const variables = {
              shipment_id: shipment?.id,
              cancelled_by: values.cancellation_reason.split('----')[0],
              cancellation_reason: values.cancellation_reason.split('----')[1],
              remarks: values.remarks,
              cancel_allocated_resources: shipment?.shipment_type ? true : cancelAllocatedResources,
              create_customer_order:
                values?.create_customer_order === 'preserve_customer_order' ? true : false,
            };
            cancelBooking({ variables });
          }}
          initialValues={{
            remarks: shipment.remarks,
            create_cancelled_shipment: false,
          }}
        >
          {error && errorMessageHandlerGraphQL(error)}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="cancellation_reason"
                label="Select Reason For Cancellation"
                required
                rules={[{ required: true }]}
              >
                <Select showSearch allowClear={false} placeholder="Select Cancellation Reason">
                  {BOOKING_CANCELLED_BY.map((cancelled_by: string) => (
                    <Select.OptGroup key={cancelled_by} label={_startCase(cancelled_by)}>
                      {BOOKING_CANCELLATION_REASONS[cancelled_by].map((option, index) => (
                        <Select.Option
                          key={`${cancelled_by}_${index}`}
                          value={`${cancelled_by}----${option}`}
                        >
                          {_startCase(option)}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="remarks" label="Remarks For Cancellation">
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          {!shipment.shipment_type && (
            <div>
              <Row>
                <div className="ant-descriptions-title">
                  Select Ocean Bookings to Delink or Cancel
                </div>
              </Row>
              <BookingOrderTable
                shipment={shipment}
                cancelAllocatedResources={cancelAllocatedResources}
                setCancelAllocatedResources={setCancelAllocatedResources}
              />
            </div>
          )}
          {shipment.shipment_type && shipment.shipment_type !== SHIPMENT_TYPE_CONSOL && (
            <Form.Item name="create_customer_order" label="Preserve / Cancel Customer Order">
              <Radio.Group>
                <Radio value="preserve_customer_order">
                  <Card
                    style={{
                      fontSize: '12px',
                      border: 'none',
                      padding: '0px',
                      marginBottom: '5px',
                    }}
                    headStyle={{
                      borderBottom: 'none',
                      padding: '0px',
                    }}
                    bodyStyle={{
                      padding: '0px',
                    }}
                    title="Preserve Customer Order"
                    size="small"
                  >
                    <div>
                      {`A new customer order with booking number - ${shipment.shipment_booking_number} `}
                      will be created. All existing bookings and containers linked to shipment will
                      be moved to new customer order. Existing Shipment will be marked as cancelled.
                    </div>
                  </Card>
                </Radio>
                <Radio value="cancel_customer_order">
                  <Card
                    style={{
                      fontSize: '12px',
                      border: 'none',
                      padding: '0px',
                      marginBottom: '5px',
                    }}
                    headStyle={{
                      borderBottom: 'none',
                      padding: '0px',
                    }}
                    bodyStyle={{
                      padding: '0px',
                    }}
                    title="Cancel Customer Order"
                    size="small"
                  >
                    <div>
                      All existing bookings will be marked as cancelled. Existing Shipment will be
                      marked as cancelled.
                    </div>
                  </Card>
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {showConfirmationModal && (
            <ConfirmCancelShipmentModal
              showConfirmationModal={showConfirmationModal}
              setShowConfirmationModal={setShowConfirmationModal}
              form={form}
            />
          )}
        </Form>
      </Drawer>
    </>
  );
});

const BookingOrderTable = observer(function BookingOrderTable(props: {
  shipment: ShipmentValue;
  cancelAllocatedResources: boolean;
  setCancelAllocatedResources: (arg0: boolean) => void;
}): JSX.Element {
  const { shipment, cancelAllocatedResources, setCancelAllocatedResources } = props;

  const getColumns = () => {
    let columns: Array<{
      title: string;
      dataIndex: string;
      render?: (
        text: string,
        record: OceanTransportOrderValue,
        index: number
      ) => JSX.Element | null;
    }> = [
      {
        title: 'Booking Number',
        dataIndex: 'booking_number',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          return <span>{record.booking_number || record?.id}</span>;
        },
      },
      {
        title: 'Shipping Line/Vendor',
        dataIndex: 'vendorName',
      },
    ];
    if (shipment.load_type === LOAD_TYPE_FCL) {
      columns = [
        ...columns,
        {
          title: 'Allocated',
          dataIndex: 'allocated',
          render: function render(text: string, record: OceanTransportOrderValue, index: number) {
            const allocated = record.parentShipmentContainersCountByTypeAndSetting(shipment?.id);
            return (
              <span>
                {Object.keys(allocated)
                  .map((container_type) => showContainerAllocation(allocated[container_type]))
                  .join(', ')}
              </span>
            );
          },
        },
      ];
    }
    columns = [
      ...columns,
      {
        title: 'Status',
        dataIndex: 'status',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          return (
            <Badge
              status={record.status ? STATUSES_MAPPING[record.status] : 'default'}
              text={_startCase(record.status)}
            />
          );
        },
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          return (
            <Radio.Group
              onChange={(e) => setCancelAllocatedResources(e.target.value)}
              value={cancelAllocatedResources}
            >
              <Radio value={false}>Delink</Radio>
              <Radio value={true}>Cancel</Radio>
            </Radio.Group>
          );
        },
      },
    ];
    return columns;
  };

  return (
    <Table
      dataSource={shipment?.ocean_transport_orders?.slice()}
      columns={getColumns()}
      pagination={false}
      rowKey={(record) => record?.id || ''}
      size="small"
    />
  );
});

export default CancelShipmentForm;
