import { isOnlyClearance } from 'sales_hub/utils/utils';
import { RateExplorerResource, RateExplorerResourceType } from './RateExplorer.type';
import { omitBy as _omitBy, isNil as _isNil, uniq as _uniq } from 'lodash';
import { getCommodityTypeFromCargo } from 'common/baseHelper';
import { FREIGHT_TYPE_OCEAN, FREIGHT_TYPE_ROAD } from 'common/baseConstants';
import { dayjs } from '@shipmnts/pixel-hub';
import { InquiryValue } from 'sales_hub/models/inquiry';

// Constants
export const INQUIRY_RESOURCE_TYPE = 'inquiry';
export const SHIPMENT_RESOURCE_TYPE = 'inquiry';

// helpers
export function isInquiryResource(resource: RateExplorerResourceType): resource is InquiryValue {
  return (resource as InquiryValue).inquiry_global_id !== undefined;
}

export function getRateExplorerResouce(resource: RateExplorerResourceType): RateExplorerResource {
  const isInquiry = isInquiryResource(resource);
  return {
    freight_type: resource.freight_type,
    customer_company: resource.customer_company,
    shipper: resource.shipment_parties?.find((party) => party.name === 'shipper'),
    consignee: resource.shipment_parties?.find((party) => party.name === 'consignee'),
    trade_type: resource.trade_type,
    is_custom: isInquiry ? isOnlyClearance(resource.services) : false,
    service_type: isInquiry ? resource.service_type : null,
  } as RateExplorerResource;
}

export const getInitialFilters = (resource: RateExplorerResourceType) => {
  const isInquiry = isInquiryResource(resource);
  const today = dayjs.unix(Date.now() / 1000);
  let data: any = {
    quick_filters: {
      customer_company: resource.customer_company?.id ? [resource.customer_company?.id] : null,
      created_at: [today.subtract(45, 'days'), today],
    },
    filters: {
      quote_status: ['won', 'lost'],
      incoterms: resource.incoterms || undefined,
    },
  };

  // NOTE: In case other types of resources are used, we can change if...else to switch...case
  if (isInquiry) {
    data = {
      ...data,
      quick_filters: {
        ...data.quick_filters,
        container_type_code:
          resource.freight_type === FREIGHT_TYPE_OCEAN
            ? resource.container_requests?.length
              ? resource.container_requests?.map((ele) => ele.container_type_code)
              : undefined
            : undefined,
      },
    };
  } else {
    data = {
      ...data,
      quick_filters: {
        ...data.quick_filters,
        container_type_code:
          resource.freight_type === FREIGHT_TYPE_OCEAN
            ? _uniq((resource.shipment_containers || []).map((ele) => ele.container_type_code))
            : undefined,
      },
    };
  }
  return _omitBy(data, _isNil);
};

export const getDefaultFilters = (resource: RateExplorerResourceType) => {
  const isCustom = isOnlyClearance(
    isInquiryResource(resource) ? resource.services : resource.getServices()
  );
  const isRoad = resource.freight_type === FREIGHT_TYPE_ROAD;
  let data: any = {
    freight_type: resource.freight_type,
    trade_type: resource.trade_type,
  };

  // only for inquiry
  if (isInquiryResource(resource)) {
    data = {
      ...data,
      inquiry_number: ['!=', resource.inquiry_number],
      port_of_loading_id: !isCustom && !isRoad ? resource.port_of_loading?.id : undefined,
      port_of_discharge_id: !isCustom && !isRoad ? resource.port_of_discharge?.id : undefined,
      origin_custom_clearance_location_id:
        isCustom && !isRoad ? resource.origin_custom_clearance_location?.id : undefined,
      destination_custom_clearance_location_id:
        isCustom && !isRoad ? resource.destination_custom_clearance_location?.id : undefined,
      load_type: [resource.load_type],
      cargo_properties: getCommodityTypeFromCargo(
        resource?.cargos?.map((ele) => ele.cargo_properties)
      ),
    };
  } else {
    data = {
      ...data,
      port_of_loading_id: !isCustom && !isRoad ? resource.port_of_loading?.id : undefined,
      port_of_discharge_id: !isCustom && !isRoad ? resource.port_of_discharge?.id : undefined,
      load_type: [resource.load_type],
      cargo_properties: getCommodityTypeFromCargo(
        resource?.cargos?.map((ele) => ele.cargo_properties)
      ),
    };
  }

  return data;
};
