/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import RoutingLeg from 'operations/models/RoutingLeg';
import ContainerCargoDetails from 'operations/models/ContainerCargoDetails';
import Activity from 'operations/models/Activity';
import {
  ContainerSetting,
  ContainerSettingValue,
  containerSettingFields,
} from 'operations/models/ContainerRequest';
import { getContainerSetting } from 'operations/modules/reports/oldCellRenderers';
import {
  STATUS_PICKUP_PENDING,
  STATUS_ACTIVE,
  STATUS_COMPLETED,
  STATUS_OFFLOADED,
} from 'operations/modules/reports/constants';
import { containerSettingsMap } from 'operations/baseConstants';
import { pick as _pick, omitBy as _omitBy, isNil as _isNil } from 'lodash';
import TrackingEvent from './TrackingEvent';
import Address from './Address';
import Company from './Company';

const TrackingStatus = types.model({
  id: types.maybe(types.maybeNull(types.string)),
  last_tracked_at: types.maybe(types.maybeNull(types.number)),
  status: types.maybe(
    types.maybeNull(
      types.union(
        types.literal('active'),
        types.literal('inactive'),
        types.literal('error'),
        types.literal('disabled'),
        types.literal('not_applicable'),
        types.literal('completed'),
        types.literal('carrier_not_supported'),
        types.literal('error_will_retry'),
        types.literal('error_no_retry'),
        types.literal('waiting_for_bl_number'),
        types.literal('waiting_for_carrier')
      )
    )
  ),
  last_event: types.maybe(
    types.maybeNull(
      types.model({
        actual_date: types.maybe(types.maybeNull(types.number)),
        current_location: types.maybe(types.maybeNull(types.string)),
        estimated_date: types.maybe(types.maybeNull(types.number)),
        name: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
});

const ShipmentContainer = types
  .model({
    id: types.identifier,
    cargo_gross_volume: types.maybe(types.maybeNull(types.number)),
    gross_volume: types.maybe(types.maybeNull(types.number)),
    cargo_gross_weight: types.maybe(types.maybeNull(types.number)),
    gross_weight: types.maybe(types.maybeNull(types.number)),
    cargo_net_weight: types.maybe(types.maybeNull(types.number)),
    carrier_seal_number: types.maybe(types.maybeNull(types.string)),
    container_number: types.maybe(types.maybeNull(types.string)),
    container_type: types.maybe(types.maybeNull(types.string)),
    container_type_code: types.maybe(types.maybeNull(types.string)),
    container_tare_weight: types.maybe(types.maybeNull(types.number)),
    customs_seal_number: types.maybe(types.maybeNull(types.string)),
    container_settings: types.maybe(ContainerSetting),
    shipper_seal_number: types.maybe(types.maybeNull(types.string)),
    remarks: types.maybe(types.maybeNull(types.string)),
    commercial_invoice_number: types.maybe(types.maybeNull(types.string)),
    purchase_order_number: types.maybe(types.maybeNull(types.string)),
    weight_slip_number: types.maybe(types.maybeNull(types.string)),
    is_non_iso_container: types.maybe(types.maybeNull(types.boolean)),
    routing_legs: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => RoutingLeg)))
    ),
    booking_number: types.maybe(types.maybeNull(types.string)),
    job_number: types.maybe(types.maybeNull(types.string)),
    ocean_transport_order_ids: types.maybe(types.maybeNull(types.array(types.string))),
    is_linked_with_shipments: types.maybe(types.boolean),
    verified_gross_mass: types.maybe(types.maybeNull(types.number)),
    container_cargo_details: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => ContainerCargoDetails)))
    ),
    maximum_cargo_weight: types.maybe(types.maybeNull(types.number)),
    maximum_cargo_volume: types.maybe(types.maybeNull(types.number)),
    status: types.maybe(
      types.maybeNull(
        types.union(
          types.literal(STATUS_PICKUP_PENDING),
          types.literal(STATUS_ACTIVE),
          types.literal(STATUS_COMPLETED),
          types.literal(STATUS_OFFLOADED)
        )
      )
    ),
    last_comment: types.maybe(types.maybeNull(Activity)),
    last_status_update: types.maybe(types.maybeNull(Activity)),
    last_action_status: types.maybe(types.maybeNull(types.string)),
    tracking_events: types.maybe(types.maybeNull(types.array(TrackingEvent))),
    next_actions: types.maybe(types.maybeNull(types.array(types.string))),
    tracking_statuses: types.maybe(
      types.maybeNull(
        types.model({
          ldb_tracker: types.maybe(types.maybeNull(TrackingStatus)),
          dockflow_tracker: types.maybe(types.maybeNull(TrackingStatus)),
        })
      )
    ),
    custom_field_values: types.maybe(types.maybeNull(types.string)),
    surveyor_company: types.maybe(types.maybeNull(Company)),
    surveyor_address: types.maybe(types.maybeNull(Address)),
    eway_bill_no: types.maybe(types.maybeNull(types.string)),
    eway_bill_validity: types.maybe(types.maybeNull(types.number)),
  })
  .views((self) => ({
    // isContainerAddDisabled: function (shipment: any) {
    //   return (
    //     isJobClosed(shipment) ||
    //     shipment.isShipmentCancelled() ||
    //     shipment.isExportMasterExecuted() ||
    //     (shipment.load_type === LOAD_TYPE_FCL && shipment?.is_linked_with_booking)
    //   );
    // },
  }));

const pattern = /^([A-Z]{3})(U|J|Z)(\d{6})(\d)$/;
const alphabetNumerical: { [key: string]: number } = {
  A: 10,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 23,
  M: 24,
  N: 25,
  O: 26,
  P: 27,
  Q: 28,
  R: 29,
  S: 30,
  T: 31,
  U: 32,
  V: 34,
  W: 35,
  X: 36,
  Y: 37,
  Z: 38,
};

export function validateContainerNumber(containerNumber: string) {
  if (containerNumber.length !== 11) throw new Error('container number should be 11 digits');

  if (!containerNumber.match(pattern)) throw new Error('Invalid container number');
  const newContainerNumber = containerNumber.toUpperCase();
  const sumDigit: number =
    alphabetNumerical[newContainerNumber[0]] +
    alphabetNumerical[newContainerNumber[1]] * 2 +
    alphabetNumerical[newContainerNumber[2]] * 4 +
    alphabetNumerical[newContainerNumber[3]] * 8 +
    parseInt(newContainerNumber[4]) * 16 +
    parseInt(newContainerNumber[5]) * 32 +
    parseInt(newContainerNumber[6]) * 64 +
    parseInt(newContainerNumber[7]) * 128 +
    parseInt(newContainerNumber[8]) * 256 +
    parseInt(newContainerNumber[9]) * 512;
  const sumDigitDiff = Math.floor(sumDigit / 11) * 11;
  const checkDigit = sumDigit - sumDigitDiff;
  const checkAgainst = checkDigit === 10 ? 0 : checkDigit;
  if (checkAgainst + '' !== newContainerNumber[10]) throw new Error('check digit doesnt match');
  return true;
}

export interface ContainerTypeAndSetting {
  container_settings?: ContainerSettingValue;
  container_type: string;
  container_type_code: string;
  count: number;
}

export const getContainerTypeSetting = (
  container_type_code: string,
  container_settings?: ContainerSettingValue
): string => {
  const is_reefer = Object.keys(containerSettingsMap.reefer).includes(container_type_code || '');

  if (is_reefer && container_settings) {
    const new_container_setting = _omitBy(
      _pick(container_settings, containerSettingFields),
      _isNil
    );

    return `${container_type_code}-${JSON.stringify(new_container_setting)}`;
  }
  return container_type_code || '';
};

export const areContainerSettingsSame = (
  container_settings_1?: ContainerSettingValue,
  container_settings_2?: ContainerSettingValue
) => {
  const containerSettings1 = JSON.stringify(
    _omitBy(_pick(container_settings_1, containerSettingFields), _isNil)
  );
  const containerSettings2 = JSON.stringify(
    _omitBy(_pick(container_settings_2, containerSettingFields), _isNil)
  );
  return containerSettings1 === containerSettings2;
};

export const showContainerAllocation = (allocation: ContainerTypeAndSetting) => {
  const { container_settings, container_type_code } = allocation;
  const containerSetting = getContainerSetting({ container_settings, container_type_code });
  const containerSettingString = containerSetting ? ` (${containerSetting})` : '';

  return `${allocation.count}x${allocation.container_type}${containerSettingString}`;
};

export const containersCountByTypeAndSetting = (shipment_containers: ShipmentContainerValue[]) => {
  return shipment_containers.reduce(
    (
      containers_obj: { [key: string]: ContainerTypeAndSetting },
      shipment_container: ShipmentContainerValue
    ) => {
      if (shipment_container.container_type && shipment_container.container_type_code) {
        const objKey = getContainerTypeSetting(
          shipment_container.container_type_code,
          shipment_container.container_settings
        );
        if (!containers_obj[objKey]) {
          containers_obj[objKey] = {
            container_settings: shipment_container.container_settings,
            container_type: shipment_container.container_type,
            container_type_code: shipment_container.container_type_code,
            count: 0,
          };
        }
        containers_obj[objKey]['count']++;
      }
      return containers_obj;
    },
    {}
  );
};

export interface ShipmentContainerValue extends Instance<typeof ShipmentContainer> {}
export interface ShipmentContainerIn extends SnapshotIn<typeof ShipmentContainer> {}
export interface ShipmentContainerOut extends SnapshotOut<typeof ShipmentContainer> {}

export const validContainers = (shipment_containers: ShipmentContainerValue[]) => {
  return (shipment_containers || []).filter((sc) => sc.status !== STATUS_OFFLOADED);
};

export default ShipmentContainer;
