/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import Company from 'common/models/Company';
import BusinessVertical from './BusinessVertical';
import Subscription from './Subscription';

export type FeatureKey =
  | 'stock_management'
  | 'enforce_credit_control'
  | 'allow_house_summary_screen'
  | 'consol_helper'
  | 'inventory_management'
  | 'show_only_permitted_branch_est'
  | 'sales_person_disabled';

const CompanyAccount = types.model({
  id: types.identifier,
  display_name: types.maybe(types.maybeNull(types.string)),
  business_type: types.maybe(types.maybeNull(types.array(types.string))),
  registered_name: types.maybe(types.maybeNull(types.string)),
  subdomain: types.maybe(types.maybeNull(types.string)),
  default_currency: types.maybe(types.maybeNull(types.string)),
  country_of_incorporation: types.maybe(types.maybeNull(types.string)),
  default_company: types.maybe(types.maybeNull(types.late((): IAnyModelType => Company))),
  primary_business: types.maybe(types.maybeNull(types.string)),
  features: types.maybeNull(
    types.model({
      stock_management: types.maybe(types.maybeNull(types.boolean)),
      enforce_credit_control: types.maybe(types.maybeNull(types.boolean)),
      allow_house_summary_screen: types.maybe(types.maybeNull(types.boolean)),
      consol_helper: types.maybe(types.maybeNull(types.boolean)),
      inventory_management: types.maybe(types.maybeNull(types.boolean)),
      sales_person_disabled: types.maybe(types.maybeNull(types.boolean)),
      show_only_permitted_branch_est: types.maybe(types.maybeNull(types.boolean)),
    })
  ),
  is_iata_agent: types.maybe(types.maybeNull(types.boolean)),
  agent_iata_code: types.maybe(types.maybeNull(types.string)),
  business_verticals: types.maybe(types.array(types.late((): IAnyModelType => BusinessVertical))),
  subscriptions: types.maybe(
    types.maybeNull(types.array(types.late((): IAnyModelType => Subscription)))
  ),
  fin_database: types.maybe(types.maybeNull(types.string)),
});

export interface CompanyAccountValue extends Instance<typeof CompanyAccount> {}
export interface CompanyAccountIn extends SnapshotIn<typeof CompanyAccount> {}
export interface CompanyAccountOut extends SnapshotOut<typeof CompanyAccount> {}

export default CompanyAccount;
