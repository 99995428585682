/**
 * Manifest Tab in Shipments View
 */

import React from 'react';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import {
  Button,
  Layout,
  Result,
  BaseTable,
  DateTypeRenderer,
  TrackingStatusRenderer,
  TrackerContextProvider,
  LocationRenderer,
  StaticHeaderComponent,
} from '@shipmnts/pixel-hub';
import { useApplicationContentContext } from 'common';
import { useQuery } from '@apollo/client';
import { FETCH_SHIPMENT_MANIFEST } from './graphql';
import { get } from 'lodash';
import { Column } from 'operations/models/Report';
import {
  ActionRenderer,
  ActionRendererDetailReport,
} from 'operations/modules/actionHelper/ActionRenderer';
import { addShipmentManifestAction } from 'operations/modules/actionHelper/ShipmentManifestActions/shipmentManifestActionRenderer';
import { ShipmentManifestValue } from 'operations/models/ShipmentManifest';
import DocTypeNumberRenderer from '../Common/DocTypeNumberRenderer';
import Spinner from 'src/components/Spinner';

type Props = {
  externalLink?: boolean;
};

const ManifestTab = (props: Props) => {
  // Contexts
  const { shipment } = useShipmentDetail();
  const { config_data } = useApplicationContentContext();

  // Queries and Mutations
  const { data, loading, error, refetch } = useQuery(FETCH_SHIPMENT_MANIFEST, {
    variables: {
      id: shipment?.id,
    },
  });

  if (loading) {
    return (
      <div className="centered fullscreen">
        <Spinner tip="Loading..." />
      </div>
    );
  }
  if (error) {
    <Result
      status="500"
      title="Something unexpected happened"
      subTitle="Don't worry our team has been notified and is working on solving the issue."
      extra={
        <>
          <Button type={'link'}>
            <a href={`mailto:support@shipmnts.com?subject=Error Occurred&body=${error.toString()}`}>
              Contact Support
            </a>
          </Button>
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </>
      }
    />;
  }

  if (!shipment) {
    return <></>;
  }

  const manifests: [ShipmentManifestValue] = get(data, 'shipment.shipment_manifests', []);

  const manifestTranslation = get(config_data, 'field_country_translations');

  const getManifestType = (manifest: ShipmentManifestValue) => {
    let type;
    if (manifest.country)
      if (manifest.location_type === 'icd') {
        type = manifestTranslation?.icd_import_general_manifest[manifest.country] || 'Manifest';
      } else if (manifest.location_type === 'port') {
        type = manifestTranslation?.import_general_manifest[manifest.country] || 'Manifest';
      }
    return type;
  };

  let columnDefs: Column[] = [
    {
      headerName: 'Manifest Type',
      field: 'manifest_type',
      colId: 'manifest_type',
      cellRenderer: 'render_tracker_drawer',
      suppressColumnsToolPanel: true,
      cellStyle: {
        'text-transform': 'capitalize',
      },
      valueGetter: ({ data }) => getManifestType(data),
      pinned: 'left',
    },
    {
      headerName: 'Manifest Number',
      field: 'manifest_number',
      colId: 'manifest_number',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Inward Date',
      field: 'inward_date',
      colId: 'inward_date',
      cellRenderer: 'date_type_renderer',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Manifest Date',
      field: 'manifest_date',
      colId: 'manifest_date',
      cellRenderer: 'date_type_renderer',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Location',
      field: 'location.name',
      colId: 'location.name',
      cellRenderer: 'location_renderer',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Document Number',
      field: 'document_number',
      colId: 'document_number',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Document Type',
      field: 'document_type',
      colId: 'document_type',
      suppressColumnsToolPanel: true,
      cellStyle: {
        'text-transform': 'capitalize',
      },
    },
    {
      headerName: 'Estimated Time Of Arrival',
      field: 'estimated_time_of_arrival',
      colId: 'estimated_time_of_arrival',
      cellRenderer: 'date_type_renderer',
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Trade Type',
      field: 'trade_type',
      colId: 'trade_type',
      suppressColumnsToolPanel: true,
      cellStyle: {
        'text-transform': 'capitalize',
      },
    },
    {
      headerName: 'Actions',
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      width: 100,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      headerComponent: 'static_header_component',
      cellRenderer: 'actions_render',
      cellRendererParams: {
        doc_type_id: 'Shipment::ShipmentManifest',
        refetchData: refetch,
      },
    },
  ];

  if (manifests.some((i) => i.country === 'IN')) {
    columnDefs = columnDefs.concat([
      {
        headerName: 'Line Number',
        field: 'line_number',
        colId: 'line_number',
        suppressColumnsToolPanel: true,
      },
      {
        headerName: 'Subline Number',
        field: 'subline_number',
        colId: 'subline_number',
        suppressColumnsToolPanel: true,
      },
    ]);
  }
  if (manifests.some((i) => i.tracking_statuses?.indian_customs_igm_tracking?.last_tracked_at)) {
    columnDefs = columnDefs.concat([
      {
        headerName: 'Indian Customs IGM Tracker Status',
        field: 'tracking_statuses',
        colId: 'tracking_statuses',
        cellRenderer: 'tracking_status_renderer',
        valueGetter: (params) => ({
          value: params.data?.tracking_statuses?.indian_customs_igm_tracking?.status,
          extra_fields: params.data,
        }),
        suppressColumnsToolPanel: true,
        cellRendererParams: {
          tracker_name: 'indian_customs_igm_tracking',
        },
      },
    ]);
  }

  return (
    <>
      <TrackerContextProvider referenceType="Shipment::ShipmentManifest">
        <div className="flex-row" style={{ justifyContent: 'space-between', padding: '0px 12px' }}>
          <h3>Manifests</h3>
          <div style={{ marginLeft: 'auto' }}>
            <ActionRenderer
              id={shipment.id}
              doc_type_id="Shipment::Shipment"
              isDetailScreen
              selectedActions={addShipmentManifestAction(shipment)}
              data={shipment}
              refetchData={refetch}
            />
          </div>
        </div>
        <Layout style={{ height: '100%' }}>
          <BaseTable
            reportName={'manifest_details'}
            columns={columnDefs}
            rowData={manifests}
            reportConfig={{
              components: {
                date_type_renderer: DateTypeRenderer,
                actions_render: ActionRendererDetailReport,
                tracking_status_renderer: TrackingStatusRenderer,
                render_tracker_drawer: DocTypeNumberRenderer,
                location_renderer: LocationRenderer,
                static_header_component: StaticHeaderComponent,
              },
              rowHeight: 40,
              stopEditingWhenCellsLoseFocus: true,
              enableCellChangeFlash: true,
            }}
            // gridRef={gridRef}
            // onSelectionChanged={onSelectionChange}
            rowSelection="multiple"
            checkbox_always_visible={false}
          />
        </Layout>
      </TrackerContextProvider>
    </>
  );
};

export default ManifestTab;
