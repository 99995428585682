import { STATUS_DRAFT, WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import {
  EditOutlined,
  CustomIcon,
  Button,
  Divider,
  PageHeader,
  Tooltip,
} from '@shipmnts/pixel-hub';
import { dividerStyles } from '../shipment/components/DetailLayout/Common/common';
import { transactionTypeToDocType } from './helpers';

type WMSDetailHeaderProps = {
  onClose?: () => void;
  externalLink?: boolean;
  warehouseTransaction: WarehouseTransactionValue;
};

const WMSDetailHeader = (props: WMSDetailHeaderProps) => {
  const { onClose, externalLink, warehouseTransaction } = props;
  const { 1: navigate } = useLocation();

  const [editDisabled, setEditDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (warehouseTransaction) {
      if (warehouseTransaction.status === STATUS_DRAFT) setEditDisabled(false);
      else setEditDisabled(true);
    }
  }, [warehouseTransaction]);

  const transaction_number = warehouseTransaction?.transaction_number;
  const transaction_type = warehouseTransaction.type;

  const extras = [
    <Button
      key={'edit'}
      size={'small'}
      disabled={editDisabled}
      onClick={() => {
        if (externalLink) {
          window.open(`/form/${transaction_type}/${warehouseTransaction?.id}`);
        } else {
          navigate(`~/form/${transaction_type}/${warehouseTransaction?.id}`);
        }
      }}
    >
      Edit
      <span style={{ marginLeft: '5px' }}>
        <EditOutlined />
      </span>
    </Button>,
    <ActionRenderer
      id={warehouseTransaction?.id}
      doc_type_id={'Wms::WarehouseTransaction'}
      isDetailScreen
      data={warehouseTransaction}
      key="actions"
    />,
  ];

  const titleAndBackButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0px',
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 600,
            color: 'var(--color-primary)', //Todo: constants
            marginLeft: '14px',
          }}
        >
          {transaction_number}

          {externalLink && (
            <span>
              <Tooltip title="Open Detail Screen">
                <a
                  href={`/view/warehouse_transaction/${warehouseTransaction.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CustomIcon icon="ExternalLinkIcon" />
                </a>
              </Tooltip>
            </span>
          )}
        </div>
      </div>
    );
  };
  const handleOnClose = () => {
    if (onClose) onClose();
    else {
      navigate(
        `~/workspace?doc_type=${transactionTypeToDocType[warehouseTransaction.type]}&resource_id=${
          warehouseTransaction.id
        }`
      );
    }
  };
  return (
    <PageHeader
      ghost={false}
      onBack={handleOnClose}
      title={titleAndBackButton()}
      extra={extras}
      className={'shipment-detail-header'}
      style={{
        background: '#ffffff',
      }}
    >
      <Divider style={dividerStyles} />
    </PageHeader>
  );
};

export default WMSDetailHeader;
