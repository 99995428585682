import { DocFieldValue } from 'common/models/DocField';
import { getDateFromUnix, getEditComponentProps, dayjs } from '@shipmnts/pixel-hub';
import { RecordType } from './types';

// constant
const FIELD_TYPE_VALUE_MAP: Record<
  string,
  (val: any, field?: any) => { type?: string; value?: any; defaultValue?: any; fieldName?: any }
> = {
  DateTime: (val, field) => {
    if (!isNaN(val)) {
      return {
        type: 'Date',
        value: val ? dayjs(val).format('ddd, D MMM YYYY, HH:mm') : '-',
        defaultValue: val ? dayjs(getDateFromUnix(val)) : null,
        fieldName: field?.db_column_name,
      };
    }
    return {
      type: 'Date',
      value: val ? dayjs(val).format('ddd, D MMM YYYY, HH:mm') : '-',
      defaultValue: val ? dayjs(val) : null,
      fieldName: field?.db_column_name,
    };
  },
  Dropdown: (val, field) => {
    let filterOptions = field?.filter_options;
    if (!!!filterOptions) return val;
    if (typeof filterOptions === 'string') filterOptions = JSON.parse(filterOptions);
    let displayHash = filterOptions?.display_hash;
    if (!!!displayHash) return val;
    if (typeof displayHash === 'string') displayHash = JSON.parse(displayHash);
    return {
      type: 'Dropdown',
      value: displayHash[val as keyof typeof displayHash],
      defaultValue: val,
      fieldName: field?.db_column_name,
      extraProps: {
        options: filterOptions?.values?.map((v: any) => ({
          label: displayHash?.[v],
          value: v,
        })),
      },
    };
  },
  Boolean: (val, field) => ({
    type: 'Boolean',
    value: val === 'true' ? 'Yes' : val === 'false' ? 'No' : null,
    defaultValue: val,
    fieldName: field?.db_column_name,
  }),
  Date: (val, field) => ({
    type: 'Date',
    value: val ? dayjs(val).format('ddd, D MMM YYYY') : null,
    defaultValue: val ? dayjs(val) : null,
    fieldName: field?.db_column_name,
  }),
  MultiSearch: (val, field) => {
    let filterOptions = field?.filter_options;
    if (!!!filterOptions) return val;
    if (typeof filterOptions === 'string') filterOptions = JSON.parse(filterOptions);
    let displayHash = filterOptions?.display_hash;
    if (!!displayHash && typeof displayHash === 'string') displayHash = JSON.parse(displayHash);

    if (filterOptions?.doc_type) {
      return {
        type: 'Search',
        value: Object.keys(RECORD_FIELD_VALUE_MAP).includes(filterOptions?.doc_type)
          ? val?.map((v: any) => {
              return RECORD_FIELD_VALUE_MAP[
                filterOptions?.doc_type as keyof typeof RECORD_FIELD_VALUE_MAP
              ](v?.record_details || v);
            })
          : val?.map((v: any) => v?.record_details || (typeof v !== 'object' ? v : '-')),
        defaultValue: val,
        fieldName: field?.db_column_name,
        extraProps: field?.cell_editor
          ? {
              ...getEditComponentProps(JSON.parse(field?.cell_editor)),
              mode: 'multiple',
              autoFocus: false,
              openSearch: false,
            }
          : { mode: 'multiple', autoFocus: false, openSearch: false },
      };
    }
    return {
      type: 'Dropdown',
      value: val?.map((v: any) => displayHash[v as keyof typeof displayHash]),
      defaultValue: val,
      fieldName: field?.db_column_name,
      extraProps: {
        options: filterOptions?.values?.map((v: any) => ({
          label: displayHash?.[v],
          value: v,
        })),
        mode: 'multiple',
      },
    };
  },
  Search: (val, field) => {
    let filterOptions = field?.filter_options;
    if (typeof filterOptions === 'string') filterOptions = JSON.parse(filterOptions);
    return {
      type: 'Search',
      value: Object.keys(RECORD_FIELD_VALUE_MAP).includes(filterOptions?.doc_type)
        ? RECORD_FIELD_VALUE_MAP[filterOptions?.doc_type as keyof typeof RECORD_FIELD_VALUE_MAP](
            val?.record_details || val
          )
        : val && typeof val !== 'object'
        ? val
        : '-',
      defaultValue: val,
      fieldName: field?.db_column_name,
      extraProps: field?.cell_editor
        ? {
            ...getEditComponentProps(JSON.parse(field?.cell_editor)),
            autoFocus: false,
            openSearch: false,
          }
        : { autoFocus: false, openSearch: false },
    };
  },
  String: (val, field) => ({
    type: 'String',
    value: val,
    defaultValue: val,
    fieldName: field?.db_column_name,
  }),
  Integer: (val, field) => ({
    type: 'Number',
    value: val,
    defaultValue: val,
    fieldName: field?.db_column_name,
  }),
  Float: (val, field) => ({
    type: 'Float',
    value: val,
    defaultValue: val,
    fieldName: field?.db_column_name,
  }),
};

const FIELD_TYPES_TO_IGNORE = ['Attachment', 'MultiAttachment'];

const RECORD_FIELD_VALUE_MAP: Record<string, (val: any) => any> = {
  'Global::Location': (val) => val?.name || '-',
  'Network::Company': (val) => val?.registered_name || '-',
  'Network::UserContact': (val) => [val?.first_name, val?.last_name]?.join(' ') || '-',
  'Network::Address': (val) =>
    val?.title || val?.city || val?.country || val?.pretty_display || '-',
  'Network::SalesPerson': (val) => val?.name || [val?.first_name, val?.last_name]?.join(' ') || '-',
  'NewAccounting::ShipmentEstimate': (val) => val?.item_name || val?.name || '-',
  'OrderManagement::ProductOrderItem': (val) => val?.item_name || val?.name || '-',
};

export const PAYLOAD_FIELD_MAP: Record<string, (val: any, recordField?: string) => any> = {
  Date: (val) => {
    if (!val) return '';
    if (new Date(val?.unix() * 1000).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0))
      return val?.unix();
    return val?.unix() + 5.5 * 60 * 60;
  }, // converting GTM to current timezone (05:30 hours of diff)
  DateTime: (val) => val?.unix() || '',
  Search: (val, recordField) => val?.id || (!!recordField ? val?.[recordField] : val?.name) || null,
  MultiSearch: (val, recordField) =>
    val?.map((v: any) => v?.id || (!!recordField ? v[recordField] : v)),
};

// helpers
// extract values from Standartd fields
export const extractStandardFieldValues = (record: RecordType, fields?: DocFieldValue[]) => {
  if (!!!fields || !!!record) return {};
  fields = fields.filter((field) => field.type === 'Blueprint::GlobalField');
  const data: Record<string, any> = {};
  fields
    ?.filter((field) => !FIELD_TYPES_TO_IGNORE.includes(field?.field_type))
    .forEach((field) => {
      const field_type = field.field_type;
      // assuming will recieve [field.db_column_name] prop in record
      if (field.db_column_name && record?.[field.db_column_name as keyof RecordType]) {
        let value = record[field.db_column_name as keyof RecordType];
        if (Object.keys(FIELD_TYPE_VALUE_MAP).includes(field_type)) {
          value = FIELD_TYPE_VALUE_MAP[field_type as keyof typeof FIELD_TYPE_VALUE_MAP](
            value,
            field
          );
          if (field?.cell_editor)
            value.extraProps = {
              ...value?.extraProps,
              ...getEditComponentProps(JSON.parse(field.cell_editor)),
              field: { ...field, cell_editor: JSON.parse(field.cell_editor) },
            };
        }
        data[field?.label] = value;
      }
    });
  return data;
};

// extract values from Custom fields
export const extractCustomFieldValues = (record: any, fields: DocFieldValue[]) => {
  if (!!!record) return {};
  record = JSON.parse(record);
  fields = fields
    ?.filter((field) => !FIELD_TYPES_TO_IGNORE.includes(field?.field_type))
    ?.filter((field: any) => field?.type === 'Blueprint::CustomField');
  const data: any = {};
  fields?.forEach((field: any) => {
    const field_type = field?.field_type;
    if (field?.db_column_name) {
      let value = record?.[field.db_column_name];

      // if type is there in value map
      if (Object.keys(FIELD_TYPE_VALUE_MAP).includes(field_type)) {
        value = FIELD_TYPE_VALUE_MAP[field_type as keyof typeof FIELD_TYPE_VALUE_MAP](value, field);
        if (field?.cell_editor)
          value.extraProps = {
            ...value?.extraProps,
            ...getEditComponentProps(JSON.parse(field.cell_editor)),
            field: { ...field, cell_editor: JSON.parse(field.cell_editor) },
            autoFocus: false,
            openSearch: false,
          };
      }
      data[field?.label] = value || '-';
    }
  });
  return data;
};
