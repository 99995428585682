import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import {
  Drawer,
  Row,
  Col,
  Typography,
  message,
  Checkbox,
  DrawerFooter,
  CompassOutlined,
} from '@shipmnts/pixel-hub';
import { ProductOrderShipmentBasicInfo } from './ProductOrderShipmentBasicInfo';
import ProductGrid from 'operations/modules/orderManagement/ProductGridRender';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import ProductOrderSearch from './ProductOrderSearch';
import { omit as _omit, pick as _pick } from 'lodash';
import { useMutation } from '@apollo/client';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { useLocation } from 'wouter';
import { UPDATE_SHIPMENT_FIELDS } from '../shipment/components/DetailLayout/Containers/graphql';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { useLazyQuery } from '@apollo/client';
import { PRODUCT_ORDERS_SEARCH } from './graphql/productOrder';

const CreateShipmentFromProductOrder = React.memo(function CreateShipmentFromProductOrder(props: {
  productOrder: ProductOrderValue;
  spoi?: ProductOrderItemValue[];
  shipment?: ShipmentValue;
  onClose: () => void;
  onSuccess?: () => void;
}): JSX.Element {
  const { 1: navigate } = useLocation();
  const { productOrder, spoi, shipment, onClose, onSuccess } = props;
  const [current, setCurrent] = React.useState(0);

  const gridRef = useRef<GridOptions>();
  const [isNextActionDisabled, setIsNextActionDisabled] = useState<boolean>(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [selectedProductItems, setSelectedProductItems] = useState<ProductOrderItemValue[]>();
  const [selectedPOs, setSelectedPOs] = useState<ProductOrderValue[]>([]);
  const [productItems, setProductItems] = useState<any[]>([productOrder] || []);
  const [searchProductOrders, { data: searchData }] = useLazyQuery(PRODUCT_ORDERS_SEARCH);
  const [isChecked, setIsChecked] = useState(false);

  const [updateShipment, { data }] = useMutation(UPDATE_SHIPMENT_FIELDS);
  const productColumnNames = [
    'product_name',
    'product_description',
    'buyer_product_code',
    'sales_order_number',
    'purchase_order_number',
    'uom',
    'qty',
    'shipment_planned_qty',
    'balance',
    'target_delivery_date',
    'booking_qty',
  ];
  let orderValue;
  if (!productOrder) {
    orderValue = {
      type: shipment?.trade_type === 'export' ? 'sales' : 'purchase',
      buyer: shipment?.shipment_parties?.find((sp: ShipmentPartyValue) => sp.name === 'buyer')
        ?.party_company,
      seller: shipment?.shipment_parties?.find((sp: ShipmentPartyValue) => sp.name === 'seller')
        ?.party_company,
      ship_to_company: shipment?.shipment_parties?.find(
        (sp: ShipmentPartyValue) => sp.name === 'consignee'
      )?.party_company,
      ship_from_company: shipment?.shipment_parties?.find(
        (sp: ShipmentPartyValue) => sp.name === 'shipper'
      )?.party_company,
      port_of_loading: shipment?.port_of_loading,
      port_of_discharge: shipment?.port_of_discharge,
      freight_type: shipment?.freight_type,
      incoterm: shipment?.incoterms,
      split_shipment_allowed: true,
    };
  }
  const onSelectProductOrder = useCallback(
    (pos: ProductOrderValue | Array<ProductOrderValue> | undefined | null) => {
      if (pos) {
        if (Array.isArray(pos)) {
          const items = [productOrder];
          pos.forEach((po) => {
            items.push(po);
          });
          setProductItems(items);
          setSelectedPOs(pos);
        } else {
          setProductItems({ ...[productOrder], ...[pos] });
          setSelectedPOs([pos]);
        }
      }
    },
    [productOrder]
  );

  useEffect(() => {
    if (isChecked && searchData && searchData.product_orders_search) {
      onSelectProductOrder(
        searchData.product_orders_search.filter(
          (po: any) =>
            po.sales_order_number !== productOrder?.sales_order_number &&
            po.freight_type === productOrder?.freight_type &&
            po.incoterm === productOrder?.incoterm
        )
      );
    }
  }, [
    isChecked,
    productOrder?.sales_order_number,
    productOrder?.freight_type,
    productOrder?.incoterm,
    searchData,
    onSelectProductOrder,
  ]);
  useEffect(() => {
    if (selectedProductItems) {
      selectedProductItems.forEach((items) => {
        if (!items.shipment_planned_qty) {
          setIsNextActionDisabled(true);
        }
      });
    }
  }, [selectedProductItems]);

  useEffect(() => {
    if (!productOrder.split_shipment_allowed) {
      const selectedProductItemIds: any[] =
        productOrder.product_order_items?.map((item: any) => {
          const innerQty =
            (Number(item?.qty - item?.shipment_planned_qty) * Number(item.inner_package_qty)) /
            item.qty;
          return {
            ..._omit(item, [
              'id',
              'balance',
              'booking_qty',
              'split_shipment_allowed',
              'parent_id',
              'parent_type',
              '_destroy',
              'custom_field_values',
              'commodity',
            ]),
            invoice_qty: 0,
            sales_order_number: item?.sales_order_number || null,
            purchase_order_number: item?.purchase_order_number || null,
            linked_to_id: Number(item.id),
            existing_shipment_planned_qty: item?.shipment_planned_qty,
            shipment_planned_qty: item?.qty ? Number(item?.qty - item?.shipment_planned_qty) : 0,
            amount: item?.qty
              ? Number(item?.qty - item?.shipment_planned_qty) * Number(item?.unit_rate)
              : 0,
            inner_package_qty: innerQty,
            packing_remarks: `${innerQty} ${item?.inner_package_type}s of ${item?.inner_per_packet_wt} kgs each`,
            net_weight: Number(Number(item?.inner_per_packet_wt * innerQty).toFixed(3)),
            product_order: {
              ..._pick(productOrder, [
                'id',
                'business_through',
                'load_type',
                'branch',
                'port_of_loading',
                'port_of_discharge',
                'place_of_carrier_receipt',
                'place_of_carrier_delivery',
                'buyer',
                'buyer_address',
                'seller',
                'seller_address',
                'incoterm',
                'ship_from_address',
                'ship_from_company',
                'ship_to_address',
                'ship_to_company',
                'freight_type',
                'trade_type',
                'sales_person',
                'type',
                'print_as',
              ]),
            },
          };
        }) || [];
      setIsNextActionDisabled(false);
      setSelectedProductItems(selectedProductItemIds);
    }
  }, [productOrder]);

  useEffect(() => {
    if (!!data?.update_shipment) {
      setIsDrawerOpen(false);
      message.success(`Successfully Added Products`);
      onSuccess && onSuccess();
    }
  }, [data, onSuccess]);

  useEffect(() => {
    if (!spoi && current === 1 && selectedProductItems) {
      navigate('~/form/shipment/new', {
        state: { items: JSON.stringify(selectedProductItems) },
      });
    }
  }, [current, spoi, selectedProductItems, navigate]);

  // on product items selection change
  const onSelectionChanged = useCallback(
    () => {
      const items: any[] = [];
      gridRef?.current?.api?.forEachNode((node: any) => {
        if (node.data.booking_qty > 0) {
          items.push({
            ...node,
          });
        }
      });

      const rowCount = items.length || 0;
      // const rowCount = gridRef?.current?.api?.getSelectedNodes().length || 0;
      setIsNextActionDisabled(rowCount === 0);

      const selectedProductItemIds: any[] =
        items.map((node) => {
          const innerQty =
            (Number(node.data.booking_qty) * Number(node.data.inner_package_qty)) / node.data.qty;
          return {
            ..._omit(node.data, [
              'id',
              'balance',
              'booking_qty',
              'split_shipment_allowed',
              '__typename',
              'parent_id',
              'parent_type',
              '_destroy',
              'custom_field_values',
              'commodity',
            ]),
            invoice_qty: 0,
            linked_to_id: Number(node.data.id),
            existing_shipment_planned_qty: node.data.shipment_planned_qty,
            shipment_planned_qty: Number(node.data.booking_qty) || 0,
            amount: Number(node.data.booking_qty) * Number(node.data.unit_rate) || 0,
            inner_package_qty: innerQty,
            packing_remarks: `${innerQty} ${node.data.inner_package_type}s of ${node.data.inner_per_packet_wt} kgs each`,
            net_weight: Number(Number(node.data?.inner_per_packet_wt * innerQty).toFixed(3)),
            product_order: {
              ..._pick(productOrder, [
                'id',
                'business_through',
                'load_type',
                'branch',
                'port_of_loading',
                'port_of_discharge',
                'place_of_carrier_receipt',
                'place_of_carrier_delivery',
                'buyer',
                'buyer_address',
                'seller',
                'seller_address',
                'incoterm',
                'ship_from_address',
                'ship_from_company',
                'ship_to_address',
                'ship_to_company',
                'freight_type',
                'trade_type',
                'sales_person',
                'type',
                'print_as',
              ]),
            },
          };
        }) || [];
      setSelectedProductItems(selectedProductItemIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onConfirmNextStep = useCallback(() => {
    if (!!spoi) {
      const updateItemIds: any = [];
      const newSpoi: any = [];
      selectedProductItems?.forEach((selected_item: ProductOrderItemValue) => {
        spoi.forEach((old_spoi: ProductOrderItemValue) => {
          if (Number(old_spoi.linked_to_id) === Number(selected_item.linked_to_id)) {
            newSpoi.push({
              ..._omit(old_spoi, [
                '__typename',
                'linked_product_order_items',
                'product_order',
                'linked_to',
                'existing_shipment_planned_qty',
                'custom_field_values',
              ]),
              id: old_spoi.id,
              shipment_planned_qty:
                Number(old_spoi?.shipment_planned_qty || 0) +
                (selected_item?.shipment_planned_qty || 0),
            });
            updateItemIds.push(selected_item.linked_to_id);
          }
        });
      });
      selectedProductItems?.forEach((selected_item) => {
        if (!updateItemIds?.includes(selected_item.linked_to_id)) {
          newSpoi.push({
            ..._omit(selected_item, ['product_order', 'existing_shipment_planned_qty']),
            shipment_planned_qty: selected_item.shipment_planned_qty,
            parent_id: shipment?.id,
            parent_type: 'Shipment::Shipment',
            linked_to_id: selected_item.linked_to_id,
          });
        }
      });
      updateShipment({
        variables: {
          id: shipment?.id,
          shipment: {
            cargos: newSpoi,
          },
        },
      });
    } else setCurrent(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spoi, selectedProductItems]);

  const onChangeShowMatchingOrders = (e: any) => {
    if (e.target.checked) {
      setIsChecked(true);
      searchProductOrders({
        variables: {
          search_text: '',
          buyer_id: productOrder.buyer?.id,
          type: productOrder.type,
          ship_to_company_id: productOrder.ship_to_company?.id,
          ship_from_company_id: productOrder.ship_from_company?.id,
          port_of_loading_id: productOrder?.port_of_loading?.id,
          port_of_discharge_id: productOrder?.port_of_discharge?.id,
        },
      });
      // onSelectProductOrder
    } else {
      setIsChecked(false);
      setSelectedPOs([]);
      setProductItems(productOrder ? [productOrder] : []);
    }
  };

  return (
    <Drawer
      placement="right"
      title={
        <div>
          <Row>
            <Col span={16}>
              <div
                style={{
                  color: '#353F52',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                <CompassOutlined />
                <span style={{ marginLeft: '5px' }}>
                  {!spoi && <b>Book a New Shipment</b>}
                  {spoi && <b>Add Products from Orders</b>}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      }
      onClose={onClose}
      open={isDrawerOpen}
      styles={{ header: { padding: '16px 16px 0px 24px' } }}
      width="70%"
      footer={
        current === 0 && (
          <DrawerFooter
            saveText={!spoi ? 'Confirm & Go to Next Step' : 'Add Products'}
            closeText="Cancel"
            disabled={isNextActionDisabled}
            onClose={onClose}
            onSave={onConfirmNextStep}
          />
        )
      }
    >
      <div className="steps-content">
        {current === 0 && (
          <>
            <ProductOrderShipmentBasicInfo
              productOrder={productOrder}
            ></ProductOrderShipmentBasicInfo>
            <div>
              <Typography.Title level={4} style={{ color: 'var(--color-primary)' }}>
                Product Details
              </Typography.Title>
              <div style={{ marginTop: '15px', marginBottom: '20px' }}>
                <p style={{ fontWeight: 500 }}>
                  {`Select other order number(s) of this ${
                    productOrder.type === 'sales' ? 'Buyer' : 'Seller'
                  } to create a shipment`}
                </p>
                <ProductOrderSearch
                  value={selectedPOs}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onSelectProductOrder={onSelectProductOrder}
                  productOrder={productOrder || orderValue}
                />
              </div>
              <div style={{ marginBottom: 20, fontWeight: 500 }}>
                <Checkbox onChange={onChangeShowMatchingOrders}>
                  {`Show Other Matching Orders of this ${
                    productOrder.type === 'sales' ? 'buyer' : 'seller'
                  }`}
                </Checkbox>
              </div>

              <ProductGrid
                tableHeading="Select product(s) to create shipment"
                productOrder={productItems}
                columnNames={productColumnNames}
                gridRef={gridRef}
                onSelectionChanged={onSelectionChanged}
                renderFormatedData
              />
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
});

export default CreateShipmentFromProductOrder;
