/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';

import Address from './Address';
import CompanyAccount from './CompanyAccount';
import CompanyEmailPreference from './CompanyEmailPreference';
import UserAccount from './UserAccount';
import RegulatoryDetails from './RegulatoryDetails';
import Team from 'common/models/Team';
import ConnectionRequest from 'network/models/ConectionRequest';
import { UserContact } from 'common';

export declare type CompanyStatus =
  | 'contact_created'
  | 'approved'
  | 'moderate_risk'
  | 'high_risk'
  | 'legal_dispute'
  | 'no_credit'
  | 'lead_created';

export const COMPANY_STATUS_CONTACT_CREATED = 'contact_created';
export const COMPANY_STATUS_APPROVED = 'approved';
export const COMPANY_STATUS_MODERATE_RISK = 'moderate_risk';
export const COMPANY_STATUS_HIGH_RISK = 'high_risk';
export const COMPANY_STATUS_LEGAL_DISPUTE = 'legal_dispute';
export const COMPANY_STATUS_NO_CREDIT = 'no_credit';
export const COMPANY_LEAD_CREATED = 'lead_created';

// company status filter array
export const COMPANY_STATUS_ARRAY: CompanyStatus[] = [
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_MODERATE_RISK,
  COMPANY_STATUS_HIGH_RISK,
  COMPANY_STATUS_NO_CREDIT,
  COMPANY_STATUS_LEGAL_DISPUTE,
];

export const COMPANY_STATUSES = [
  COMPANY_STATUS_CONTACT_CREATED,
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_MODERATE_RISK,
  COMPANY_STATUS_HIGH_RISK,
  COMPANY_STATUS_LEGAL_DISPUTE,
  COMPANY_STATUS_NO_CREDIT,
];

export const COMPANY_STATUS_DISPLAY_NAMES: { [id: string]: string } = {
  [COMPANY_STATUS_CONTACT_CREATED]: 'Contact Created',
  [COMPANY_STATUS_APPROVED]: 'Approved',
  [COMPANY_STATUS_MODERATE_RISK]: 'Moderate Risk',
  [COMPANY_STATUS_HIGH_RISK]: 'High Risk',
  [COMPANY_STATUS_LEGAL_DISPUTE]: 'Legal Dispute',
  [COMPANY_STATUS_NO_CREDIT]: 'No Credit',
};

export const COMPANY_STATUS_TAG_COLORS: { [id: string]: string } = {
  [COMPANY_STATUS_CONTACT_CREATED]: 'blue',
  [COMPANY_STATUS_APPROVED]: 'green',
  [COMPANY_STATUS_MODERATE_RISK]: 'orange',
  [COMPANY_STATUS_HIGH_RISK]: 'red',
  [COMPANY_STATUS_NO_CREDIT]: 'orange',
  [COMPANY_STATUS_LEGAL_DISPUTE]: 'red',
};

export interface AddressAccountsData {
  name?: string;
  operations_address_id?: string;
  preferred_invoice_type?: string;
  gstin?: string;
}

export interface ExemptionAccountsData {
  erpnext_name?: string;
  certificate_number?: string;
  exemption_limit?: number;
  exemption_rate?: number;
  valid_from?: string;
  valid_to?: string;
}

export interface CompanyAccountsData {
  name?: string;
  cin_no?: string;
  default_currency?: string;
  is_customer?: boolean;
  is_supplier?: boolean;
  operations_company_id?: string;
  receivable_accounts?: Array<{ account?: string; company?: string }>;
  receivable_credit_limit?: number;
  is_msme?: number;
  credit_overdue_limit?: number;
  receivable_credit_control_type?: string;
  receivable_payment_terms?: string;
  receivable_tax_withholding_category?: string;
  payable_accounts?: Array<{ account?: string; company?: string }>;
  payable_payment_terms?: string;
  payable_tax_withholding_category?: string;
  addresses?: Array<AddressAccountsData>;
  payable_exemptions?: Array<ExemptionAccountsData>;
  receivable_exemptions?: Array<ExemptionAccountsData>;
}

const Company = types.model({
  id: types.identifier,
  registered_name: types.string,
  logo: types.maybe(types.maybeNull(types.string)),
  business_type: types.maybe(types.maybeNull(types.array(types.string))),
  country_of_incorporation: types.maybe(types.string),
  company_identification_number: types.maybe(types.maybeNull(types.string)),
  company_type: types.maybe(types.string),
  company_stage: types.maybe(types.maybeNull(types.string)),
  current_state: types.maybe(
    types.maybeNull(
      types.model({
        id: types.identifier,
        name: types.string,
      })
    )
  ),
  is_iata_agent: types.maybe(types.maybeNull(types.boolean)),
  default_currency: types.maybe(types.maybeNull(types.string)),
  domain: types.maybe(types.maybeNull(types.string)),
  status: types.maybe(
    types.maybeNull(
      types.union(
        types.literal(COMPANY_STATUS_CONTACT_CREATED),
        types.literal(COMPANY_STATUS_APPROVED),
        types.literal(COMPANY_STATUS_MODERATE_RISK),
        types.literal(COMPANY_STATUS_HIGH_RISK),
        types.literal(COMPANY_STATUS_LEGAL_DISPUTE),
        types.literal(COMPANY_STATUS_NO_CREDIT),
        types.literal(COMPANY_LEAD_CREATED)
      )
    )
  ),
  receivable_credit_control_type: types.maybe(types.maybeNull(types.string)),
  created_by: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
  created_at: types.maybe(types.number),
  company_group: types.maybe(types.string),
  entity_type: types.maybe(types.maybeNull(types.string)),
  pipeline: types.maybe(
    types.maybeNull(
      types.model({
        id: types.identifier,
        name: types.string,
      })
    )
  ),
  remarks: types.maybe(types.maybeNull(types.string)),
  addresses: types.maybe(types.array(types.late((): IAnyModelType => Address))),
  is_disabled: types.maybe(types.boolean),
  tax_deduction_id: types.maybe(types.maybeNull(types.string)),
  tax_registration_number: types.maybe(types.maybeNull(types.string)),
  company_email_preferences: types.maybe(
    types.array(types.late((): IAnyModelType => CompanyEmailPreference))
  ),
  teams: types.maybe(types.maybeNull(types.array(types.late((): IAnyModelType => Team)))),
  global_company_account: types.maybe(
    types.maybeNull(types.late((): IAnyModelType => CompanyAccount))
  ),
  regulatory_details_data: types.maybe(types.array(RegulatoryDetails)),
  sales_partner: types.maybe(
    types.maybeNull(
      types.model({
        id: types.identifier,
        registered_name: types.string,
        country_of_incorporation: types.maybe(types.string),
      })
    )
  ),
  connection_request: types.maybe(
    types.maybeNull(types.late((): IAnyModelType => ConnectionRequest))
  ),
  contacts: types.maybe(types.array(types.late((): IAnyModelType => UserContact))),
  is_customer: types.maybe(types.boolean),
  is_vendor: types.maybe(types.boolean),
});
export interface CompanyValue extends Instance<typeof Company> {}
export interface CompanyIn extends SnapshotIn<typeof Company> {}
export interface CompanyOut extends SnapshotOut<typeof Company> {}

export default Company;
