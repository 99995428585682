import React, { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
  Checkbox,
  Col,
  Drawer,
  Input,
  message,
  Row,
  Select,
  Form,
  InputNumber,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { containerTypes, containerTypesArray } from 'operations/baseConstants';
import { validateContainerNumber } from 'operations/models/ShipmentContainer';
import { UPDATE_SHIPMENT_CONTAINER } from '../graphql';
import { omit as _omit, pick as _pick } from 'lodash';
import { useSession, AddressCompanySearch } from 'common';
import { FormInstance, RuleObject, DrawerFooter } from '@shipmnts/pixel-hub';
import {
  ACCOUNTING_STATUS_CLOSED,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
  TRADE_TYPE_EXPORT,
  FREIGHT_TYPE_ROAD,
} from 'operations/modules/shipment/constants';
import LinkedShipments from './LinkedShipments';
import {
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
  SHIPMENT_STATUS_CANCELLED,
  SHIPMENT_STATUS_EXECUTED,
} from 'operations/modules/reports/constants';
import ContainerSettings from 'operations/components/ContainerSettings';
import { ContainerSettingValue } from 'operations/models/ContainerRequest';
import { CustomIcon, dayjs } from '@shipmnts/pixel-hub';
import LinkedShipmentInvoice from './LinkedShipmentInvoice';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { CompanyValue } from 'operations/models/Company';
import { AddressValue } from 'operations/models/Address';
import { ShipmentServiceValue } from 'operations/models/Shipment';

const { Option, OptGroup } = Select;

interface ShipmentDocumentType {
  id: string;
  shipment_number?: string;
  document_status: string;
}

interface ShipmentType {
  id: string;
  job_number: string;
  shipment_type: string;
  status: string;
  accounting_status: string;
  gross_weight?: number;
  net_weight?: number;
  gross_volumen?: number;
  total_number_of_packages?: number;
  shipment_documents: ShipmentDocumentType[];
  trade_type?: string;
  services?: ShipmentServiceValue;
}

interface ShipmentMiniType extends ShipmentType {
  house_shipments: ShipmentType[];
}

const getDateValue = (date: Date | undefined) =>
  date ? dayjs.unix(new Date(date).getTime() / 1000) : null;

export interface ContainerForEditType {
  id: string;
  container_number?: string;
  is_non_iso_container: boolean;
  container_type: string;
  container_type_code: string;
  container_settings: ContainerSettingValue;
  cargo_gross_volume?: number;
  cargo_gross_weight?: number;
  cargo_net_weight?: number;
  verified_gross_mass?: number;
  carrier_seal_number?: string;
  acceptance_date?: Date;
  shipper_seal_number?: string;
  customs_seal_number?: string;
  container_tare_weight?: number;
  remarks?: string;
  commercial_invoice_number?: string;
  purchase_order_number?: string;
  ocean_transport_orders: {
    id: string;
  }[];
  container_cargo_details: {
    id: string;
    gross_volume?: number;
    gross_weight?: number;
    net_weight?: number;
    package_str?: string;
    total_number_of_packages?: number;
    type_of_package?: string;
    shipment: {
      id: string;
      job_number: string;
      trade_type: string;
      freight_type: string;
      shipment_type: string;
      accounting_status: string;
      status: string;
      shipment_documents: {
        id: string;
        shipment_number?: string;
        document_status: string;
      }[];
      services?: ShipmentServiceValue;
    };
  }[];
  containers_commercial_invoices: {
    id: string;
    shipment_invoice: {
      id: string;
      invoice_number: string;
      status: string;
      product_order_items: {
        gross_weight: number;
        net_weight: number;
        linked_to: {
          product_order: {
            purchase_order_number: string;
          };
        };
      }[];
    };
  }[];
  master_shipment?: ShipmentMiniType;
  surveyor_company: CompanyValue;
  surveyor_address: AddressValue;
  status?: string;
}

interface EditContainerDetailsProps {
  container: ContainerForEditType;
  onClose: () => void;
  onSuccess: () => void;
}

// BR cancelled
// or shipment cancelled or executed or closed
export function getIsContainerDisabled(container: ContainerForEditType) {
  const shipments = container.container_cargo_details.map((ccd) => ccd.shipment);
  const documents = container.container_cargo_details
    .filter((ccd) => ccd.shipment.shipment_type !== SHIPMENT_TYPE_HOUSE)
    .map((ccd) => ccd.shipment.shipment_documents)
    .flat();
  const jobClosed = !!shipments.find(
    (shipment) => shipment.accounting_status === ACCOUNTING_STATUS_CLOSED
  );
  const shipmentCancelled = !!shipments.find(
    (shipment) => shipment.status === SHIPMENT_STATUS_CANCELLED
  );
  const isShipmentExport = !!shipments.find(
    (shipment) => shipment.trade_type === TRADE_TYPE_EXPORT
  );
  const isDocumentExecuted = !!documents.find((document) =>
    [
      SHIPMENT_STATUS_EXECUTED,
      DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
      DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
      DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
    ].includes(document?.document_status)
  );
  return jobClosed || (isShipmentExport && isDocumentExecuted) || shipmentCancelled;
}

export const isFieldDisabled = (shipment: any) => {
  const document = shipment.shipment_documents[0];
  return (
    shipment.trade_type === TRADE_TYPE_EXPORT &&
    [
      SHIPMENT_STATUS_EXECUTED,
      DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
      DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
      DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
    ].includes(document?.document_status)
  );
};

export default function EditContainerForm({
  container,
  onClose,
  onSuccess,
}: EditContainerDetailsProps): JSX.Element {
  const [form] = Form.useForm();
  const sessionData = useSession();
  const { shipment } = useShipmentDetail();

  const [updateShipmentContainer, { data, loading, error }] =
    useMutation(UPDATE_SHIPMENT_CONTAINER);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
    if (data?.update_shipment_container) {
      message.success('Container updated successfully');
      onSuccess();
    }
  }, [data, loading, error, onSuccess]);

  const commercialInvoice = container.containers_commercial_invoices.map((invoice: any) => {
    let grossWeight = 0;
    let netWeight = 0;

    invoice.shipment_invoice.product_order_items.forEach((ci_poi: any) => {
      grossWeight += ci_poi.gross_weight;
      netWeight += ci_poi.net_weight;
    });
    invoice.shipment_invoice.gross_weight = grossWeight;
    invoice.shipment_invoice.net_weight = netWeight;
    return invoice;
  });

  const purchaseNumber =
    container?.containers_commercial_invoices[0]?.shipment_invoice?.product_order_items[0]
      ?.linked_to?.product_order?.purchase_order_number;

  const shipments = useMemo(() => {
    if (container.master_shipment) {
      return [container.master_shipment, ...container.master_shipment.house_shipments];
    }
    return container.container_cargo_details.map((ccd) => ccd.shipment);
  }, [container]);

  const isContainerEditDisabled = getIsContainerDisabled(container);

  const removeDisabledProp = (el: any) => _omit(el, 'is_disabled');

  const updateShipmentContainerHandler = (values: any) => {
    (values.containers_commercial_invoices || []).forEach((new_cci: any) => {
      const old_cci = container.containers_commercial_invoices.find(
        (old_cci) => old_cci.shipment_invoice.id === new_cci.shipment_invoice.id
      );
      if (old_cci) new_cci.id = old_cci.id;
      new_cci.shipment_invoice_id = new_cci.shipment_invoice.id;
    });
    const new_shipment_invoice = (values.containers_commercial_invoices || []).map((el: any) =>
      _pick(el, 'id', 'shipment_invoice_id')
    );

    const new_containers_commercial_invoices = new_shipment_invoice.map(
      (ccd: any) => ccd.shipment_invoice_id
    );
    container.containers_commercial_invoices.forEach((ccd) => {
      if (!new_containers_commercial_invoices.includes(ccd.shipment_invoice.id)) {
        new_shipment_invoice.push({ id: ccd.id, _destroy: true });
      }
    });

    const cargo_details =
      (values.container_cargo_details || []).map((ccd: any) => {
        return {
          ...ccd,
          gross_weight: Number(ccd?.gross_weight?.toFixed(3)),
          net_weight: Number(ccd?.net_weight?.toFixed(3)),
          gross_volume: Number(ccd?.gross_volume?.toFixed(3)),
        };
      }) || [];
    const new_cargo_details = cargo_details.map(removeDisabledProp);
    new_cargo_details.forEach((ccd: any) => {
      const old_ccd = container.container_cargo_details.find(
        (old_ccd) => old_ccd.shipment.id === ccd.shipment_id
      );
      if (old_ccd) ccd.id = old_ccd.id;
    });
    const new_shipments = new_cargo_details.map((ccd: any) => ccd.shipment_id);
    container.container_cargo_details.forEach((ccd) => {
      const isConsolShipment = ccd.shipment.shipment_type === SHIPMENT_TYPE_CONSOL;
      const isRoadCustomerOrder =
        !ccd.shipment.job_number && ccd.shipment.freight_type === FREIGHT_TYPE_ROAD;
      const isTripAssigned = container.container_cargo_details.length > 1;
      if (
        !new_shipments.includes(ccd.shipment.id) &&
        !isConsolShipment &&
        !(isRoadCustomerOrder && isTripAssigned)
      ) {
        new_cargo_details.push({ id: ccd.id, _destroy: true });
      }
    });
    values.container_type = containerTypes[values.container_type_code];
    const container_payload = {
      ...values,
      acceptance_date: values?.acceptance_date?.format('YYYY-MM-DD') || null,
      verified_gross_mass: Number(values?.verified_gross_mass?.toFixed(3)),
      container_tare_weight: Number(values?.container_tare_weight?.toFixed(3)),
      container_cargo_details: new_cargo_details,
      containers_commercial_invoices: new_shipment_invoice,
      surveyor_company_id: values.surveyor?.party_company?.id || null,
      surveyor_address_id: values.surveyor?.party_address?.id || null,
    };

    const payload = _omit(container_payload, ['surveyor']);
    updateShipmentContainer({
      variables: {
        id: container.id,
        shipment_container: payload,
      },
    });
  };
  return (
    <Drawer
      key="edit_container_details"
      onClose={onClose}
      visible
      title={
        <div style={{ display: 'flex' }} className="edit-container-drawer-title">
          <CustomIcon icon="Edit" /> <div>Edit Container Details</div>
        </div>
      }
      placement="right"
      width={'80%'}
      headerStyle={{
        fontSize: '18px',
      }}
      className="edit-container-drawer"
      footer={
        <DrawerFooter
          onClose={onClose}
          onSave={form.submit}
          saveText="Update Container"
          closeText="Close"
          disabled={isContainerEditDisabled}
          loading={loading}
          saveButtonHTMLType={'submit'}
          sendEmail={false}
        />
      }
    >
      <Form
        form={form}
        onFinish={(values: any) => {
          updateShipmentContainerHandler(values);
        }}
        layout={'vertical'}
        disabled={isContainerEditDisabled}
        initialValues={{
          container_number: container.container_number,
          is_non_iso_container: container.is_non_iso_container,
          container_type_code: container.container_type_code,
          container_settings: _omit(container.container_settings, '__typename'),
          carrier_seal_number: container.carrier_seal_number,
          acceptance_date: getDateValue(container?.acceptance_date),
          customs_seal_number: container.customs_seal_number,
          shipper_seal_number: container.shipper_seal_number,
          verified_gross_mass: container.verified_gross_mass,
          cargo_gross_weight: container.cargo_gross_weight,
          cargo_net_weight: container.cargo_net_weight,
          container_tare_weight: container.container_tare_weight,
          remarks: container.remarks,
          commercial_invoice_number: container.commercial_invoice_number,
          purchase_order_number:
            shipment?.trade_type === TRADE_TYPE_EXPORT &&
            (sessionData.company_account.primary_business === 'manufacturer' ||
              sessionData.company_account.primary_business === 'trader')
              ? purchaseNumber
              : container.purchase_order_number,
          containers_commercial_invoices: commercialInvoice,
          container_cargo_details: container.container_cargo_details
            .filter((ccd) => {
              const isConsolShipment = ccd.shipment.shipment_type === SHIPMENT_TYPE_CONSOL;
              const isRoadCustomerOrder =
                !ccd.shipment.job_number && ccd.shipment.freight_type === FREIGHT_TYPE_ROAD;
              const isTripAssigned = container.container_cargo_details.length > 1;
              return !isConsolShipment && !(isRoadCustomerOrder && isTripAssigned);
            })
            .map((ccd) => ({
              id: ccd.id,
              gross_volume: ccd.gross_volume,
              gross_weight: ccd.gross_weight,
              net_weight: ccd.net_weight,
              package_str: ccd.package_str,
              total_number_of_packages: ccd.total_number_of_packages,
              type_of_package: ccd.type_of_package,
              shipment_id: ccd.shipment.id,
              is_disabled: isFieldDisabled(ccd.shipment),
            })),
          surveyor: {
            party_company: container.surveyor_company,
            party_address: container.surveyor_address,
          },
        }}
        onValuesChange={(changedValues, _allValues) => {
          if ('containers_commercial_invoices' in changedValues) {
            const commercialInvoiceNum = changedValues.containers_commercial_invoices.map(
              (ele: any) => {
                return ele.shipment_invoice.invoice_number;
              }
            );
            form.setFieldsValue({
              commercial_invoice_number: commercialInvoiceNum.length
                ? commercialInvoiceNum.join(', ').concat(', ')
                : '',
            });
          }
        }}
      >
        <Row gutter={24}>
          {container.container_number && (
            <Col span={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues?.is_non_iso_container !== currentValues?.is_non_iso_container
                }
              >
                {({ getFieldValue }) => {
                  const is_non_iso_container = getFieldValue(['is_non_iso_container']);
                  const rules = is_non_iso_container
                    ? [{ required: true }]
                    : [
                        {
                          validator: (rule: RuleObject, value: string) => {
                            let error: string | boolean = false;
                            try {
                              value && validateContainerNumber(value);
                            } catch (e) {
                              if (e instanceof Error) error = e.message;
                            }
                            if (!error) {
                              return Promise.resolve();
                            }
                            return Promise.reject(error);
                          },
                        },
                        { required: true },
                      ];
                  return (
                    <Form.Item
                      required
                      name={['container_number']}
                      label="Container Number"
                      rules={rules}
                      style={{ marginBottom: 0 }}
                    >
                      <Input placeholder="Container number" />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item name={['is_non_iso_container']} valuePropName="checked">
                <Checkbox onChange={() => form.validateFields(['container_number'])}>
                  Non ISO container
                </Checkbox>
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item name="container_type_code" label="Container Type">
              <Select
                showSearch
                filterOption={(input, option): boolean => {
                  return `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {Object.keys(containerTypesArray).map((optionGrp, index) => (
                  <OptGroup key={index} label={optionGrp}>
                    {containerTypesArray[optionGrp].map((option: { key: string; name: string }) => (
                      <Option key={option.key} value={option.key}>
                        {option.name}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <ContainerSettingsComponent form={form} />
          </Col>
        </Row>
        {container.container_number && (
          <>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="customs_seal_number" label="Custom Seal">
                  <Input placeholder="Customs Seal #" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="carrier_seal_number" label="Shipping Line Seal">
                  <Input placeholder="Carrier Seal #" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="shipper_seal_number" label="Shipper Seal">
                  <Input placeholder="Shipper Seal #" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="commercial_invoice_number" label="Commercial Invoice #">
                  <Input
                    placeholder="Commercial Invoice Number"
                    disabled={
                      shipment?.trade_type === TRADE_TYPE_EXPORT &&
                      (sessionData.company_account.primary_business === 'manufacturer' ||
                        sessionData.company_account.primary_business === 'trader')
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="purchase_order_number" label="Purchase Order #">
                  <Input
                    placeholder="Purchase Order Number"
                    disabled={
                      shipment?.trade_type === TRADE_TYPE_EXPORT &&
                      (sessionData.company_account.primary_business === 'manufacturer' ||
                        sessionData.company_account.primary_business === 'trader')
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="remarks" label="Remarks">
                  <Input placeholder="Remarks" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="acceptance_date" label="Acceptance Date">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="surveyor" label="Surveyor">
                  <AddressCompanySearch
                    companySearchProps={{
                      searchProps: { is_vendor: true },
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {container.container_cargo_details.length === 0 && (
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item name="cargo_gross_weight" label="Gross Weight / Container">
                <InputNumber
                  placeholder="Gross Weight"
                  addonAfter={'kg'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="cargo_net_weight" label="Net Weight / Container">
                <InputNumber placeholder="Net Weight" addonAfter={'kg'} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="verified_gross_mass" label="VGM Weight / Container">
                <InputNumber
                  placeholder="VGM Weight"
                  addonAfter={'kg'}
                  style={{ width: '100%' }}
                  precision={3}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="container_tare_weight" label="Tare Weight / Container">
                <InputNumber
                  placeholder="Tare weight"
                  addonAfter={'kg'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {container.container_cargo_details.length !== 0 && (
          <>
            {container.container_cargo_details.length !== 0 && container?.container_number && (
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="verified_gross_mass" label="VGM Weight / Container">
                    <InputNumber
                      placeholder="VGM Weight"
                      addonAfter={'kg'}
                      style={{ width: '100%' }}
                      precision={3}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="container_tare_weight" label="Tare Weight / Container">
                    <InputNumber
                      placeholder="Tare weight"
                      addonAfter={'kg'}
                      style={{ width: '100%' }}
                      precision={3}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <h2>Link House Shipment</h2>
            <Form.List name={['container_cargo_details']}>
              {(fields, { add, remove }) => {
                return (
                  <LinkedShipments
                    shipments={shipments}
                    form={form}
                    fields={fields}
                    removeRow={remove}
                    addRow={add}
                  />
                );
              }}
            </Form.List>
          </>
        )}
        {shipment?.trade_type === TRADE_TYPE_EXPORT &&
          container.container_number &&
          (sessionData.company_account.primary_business === 'manufacturer' ||
            sessionData.company_account.primary_business === 'trader') && (
            <>
              <h2 style={{ paddingTop: '40px' }}>Link Commercial Invoices</h2>
              <Form.List name={['containers_commercial_invoices']}>
                {(fields, { add, remove }) => {
                  return (
                    <LinkedShipmentInvoice
                      form={form}
                      fields={fields}
                      removeRow={remove}
                      addRow={add}
                      shipmentId={container?.master_shipment?.id || ''}
                    />
                  );
                }}
              </Form.List>
            </>
          )}
      </Form>
    </Drawer>
  );
}

const ContainerSettingsComponent = (props: { form: FormInstance }) => {
  const { form } = props;
  const container_type_code_value = Form.useWatch('container_type_code', form);
  if (!container_type_code_value) return null;

  return (
    <Form.Item name="container_settings" label="Container Settings">
      <ContainerSettings container_type_code={container_type_code_value} />
    </Form.Item>
  );
};
