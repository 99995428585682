import { gql } from '@apollo/client';
import { templateFields } from 'src/graphQL/template';

const locationFields = gql`
  fragment locationFields on LocationObjectType {
    id
    type
    city {
      name
    }
    name
    country_code
    state
    country {
      name
    }
    unlocode
    iata_code
  }
`;

const portFields = gql`
  fragment portFields on PortObjectType {
    id
    name
    type
    iata_code
    unlocode
    customs_code
    address
    parent_id
    city {
      name
      code
    }
    country {
      name
      code
    }
    country_code
  }
`;

const contractChargeFields = gql`
  fragment contractChargeFields on ContractChargeObjectType {
    id
    container_type
    start_weight
    end_weight
    weight_unit
    charge_amount
    min_charge_amount
    discount
    discount_type
    charge_name
    charge_tag
  }
`;

const carrierFields = gql`
  fragment carrierFields on CarrierObjectType {
    id
    name
    iata_carrier_code
  }
`;

const contractFields = gql`
  fragment contractFields on ContractObjectType {
    id
    type
    rate_type
    load_type
    trade_type
    applicability
    accessible_by_branches {
      id
      name
    }
    charge_basis
    freight_charge
    customer_tier
    commodity_type
    container_types
    contract_number
    contract_name
    contract_ref
    contract_type
    is_configured_by_weight
    remarks
    status
    terms_and_cond {
      ...templateFields
    }
    terms_and_conditions
    valid_from
    valid_till
    customer_companies {
      id
      registered_name
    }
    supplier_company {
      id
      registered_name
    }
    vehicle_type
    included_surcharges
    subject_to_surcharges
    has_discounts
  }
  ${templateFields}
`;

const roadContractLineItems = gql`
  fragment roadContractLineItems on RoadContractLineItemObjectType {
    id
    is_empty
    port_of_loading {
      ...locationFields
    }
    port_of_discharge {
      ...locationFields
    }
    return_location {
      ...locationFields
    }
    currency
    contract_charges {
      ...contractChargeFields
    }
  }
`;

const oceanContractLineItems = gql`
  fragment oceanContractLineItems on OceanContractLineItemObjectType {
    id
    carrier {
      ...carrierFields
    }
    port_of_loading {
      ...portFields
    }
    port_of_discharge {
      ...portFields
    }
    transit_days
    route
    contract_ref
    charge_terms
    origin_free_days
    destination_free_days
    currency
    remarks
    contract_charges {
      ...contractChargeFields
    }
  }
`;

const airContractLineItems = gql`
  fragment airContractLineItems on AirContractLineItemObjectType {
    id
    port_of_loading {
      ...portFields
    }
    ports_of_discharge {
      ...portFields
    }
    flight_number
    flight_date
    min_charge
    normal_charge
    commodity_type
    currency
    charge_terms
    carrier {
      ...carrierFields
    }
    carrier_product
    contract_charges {
      ...contractChargeFields
    }
    supplier_company {
      id
      registered_name
    }
  }
`;

const additionalChargeLineItems = gql`
  fragment additionalChargeLineItems on AdditionalChargeLineItemObjectType {
    id
    charge_name
    charge_basis
    charge_terms
    commodity_type
    remarks
    carriers {
      ...carrierFields
    }
    locations {
      ...locationFields
    }
    terminals {
      ...locationFields
    }
    currency
    contract_charges {
      ...contractChargeFields
    }
  }
`;

export const FETCH_CONTRACT = gql`
  query fetch_contract($id: ID!) {
    get_contract(id: $id) {
      ...contractFields
      road_contract_line_items {
        ...roadContractLineItems
      }
      ocean_contract_line_items {
        ...oceanContractLineItems
      }
      air_contract_line_items {
        ...airContractLineItems
      }
      additional_charge_line_items {
        ...additionalChargeLineItems
      }
    }
  }
  ${locationFields}
  ${portFields}
  ${contractChargeFields}
  ${carrierFields}
  ${contractFields}
  ${roadContractLineItems}
  ${oceanContractLineItems}
  ${airContractLineItems}
  ${additionalChargeLineItems}
`;
