import { gql } from '@apollo/client';
import {
  shipmentPartyFields,
  branchAccountFields,
  globalLocationFields,
  userAccountFields,
} from 'operations/graphql/shipment';
import { globalCarrierFields } from 'operations/graphql/inquiryOption';
import { minimumCompanyFields } from 'network/graphql/company';
import { warehouseUnitFields } from './warehouseUnit';
import { productFields, productPropertyFields } from 'operations/graphql/item';

export const warehouseTransactionProductFields = gql`
  fragment warehouseTransactionProductFields on WarehouseTransactionProductObjectType {
    id
    warehouse_transaction {
      id
      transaction_number
      type
      status
    }
    warehouse_transaction_type
    pallet_no
    customer_ref_code
    shipment_document_number
    receipt_number
    receipt_date
    country_of_origin
    exp_date
    prod_date
    product_name
    product_code
    to_location {
      ...warehouseUnitFields
    }
    from_location {
      ...warehouseUnitFields
    }
    damage_location {
      ...warehouseUnitFields
    }
    product {
      ...productFields
    }
    packing_type
    outer_packing_type
    inner_by_outer_qty
    packing_desc
    qty
    damage_qty
    issued_qty
    available_qty
    dimension_unit
    length
    breadth
    height
    weight_unit
    gross_weight
    per_pack_weight
    per_pack_volume
    outer_qty
    total_qty
    gross_volume
    volumetric_weight
    chargeable_weight
    invoice_number
    invoice_date
    container_number
    out_container_number
    container_type
    seal_number
    serial_number
    country_of_origin
    prod_date
    exp_date
    customs_ref_code
    batch_number
    lot_number
    remarks
    product_property {
      ...productPropertyFields
    }
    linked_to {
      id
      warehouse_transaction_type
      warehouse_transaction {
        id
        transaction_number
        type
        status
      }
    }
    linked_products {
      id
      warehouse_transaction {
        id
        transaction_number
        type
        status
        transaction_date
      }
      warehouse_transaction_type
      pallet_no
      country_of_origin
      exp_date
      prod_date
      product_name
      product_code
      to_location {
        ...warehouseUnitFields
      }
      from_location {
        ...warehouseUnitFields
      }
      packing_type
      qty
      outer_packing_type
      inner_by_outer_qty
      packing_desc
      issued_qty
      available_qty
      dimension_unit
      length
      breadth
      height
      weight_unit
      gross_weight
      per_pack_weight
      per_pack_volume
      outer_qty
      total_qty
      gross_volume
      volumetric_weight
      chargeable_weight
      invoice_number
      invoice_date
      container_number
      serial_number
      customer_ref_code
      shipment_document_number
      receipt_number
      receipt_date
      country_of_origin
      prod_date
      exp_date
      customs_ref_code
      batch_number
      lot_number
      remarks
      product_property {
        ...productPropertyFields
      }
    }
  }
  ${productFields}
  ${warehouseUnitFields}
  ${productPropertyFields}
`;
export const warehouseTransactionFields = gql`
  fragment warehouseTransactionFields on WarehouseTransactionObjectType {
    id
    transaction_number
    type
    status
    branch {
      ...branchAccountFields
    }
    target_warehouse {
      ...warehouseUnitFields
    }
    transaction_date
    target_date
    transaction_by {
      ...userAccountFields
    }
    transaction_date
    created_by {
      ...userAccountFields
    }
    created_at
    customer_ref_code
    customer_ref_date
    freight_type
    shipment_document_number
    origin {
      ...globalLocationFields
    }
    destination {
      ...globalLocationFields
    }
    origin_etd_date
    destination_eta_date
    customs_ref_code
    carrier {
      ...globalCarrierFields
    }
    received_transporter {
      ...minimumCompanyFields
    }
    delivered_transporter {
      ...minimumCompanyFields
    }
    purpose_of_transfer
    vehicle_number
    driver_name
    delivery_ref_code
    services
    total_gross_wt
    total_volume_wt
    total_gross_volume
    total_chargeable_wt
    total_packages
    warehouse_transaction_products {
      ...warehouseTransactionProductFields
    }
    warehouse_transaction_events {
      id
      event_by {
        id
        first_name
        last_name
      }
      event_date
      name
      event_data
      created_at
    }
    shipment_parties {
      ...shipmentPartyFields
    }
    custom_clearance_date
    product_property {
      ...productPropertyFields
    }
    remarks
    vehicle_type
  }
  ${userAccountFields}
  ${globalLocationFields}
  ${warehouseTransactionProductFields}
  ${branchAccountFields}
  ${globalCarrierFields}
  ${shipmentPartyFields}
  ${minimumCompanyFields}
  ${warehouseUnitFields}
  ${productPropertyFields}
`;

export const WAREHOUSE_TRANSACTION_PRODUCT = gql`
  query warehouse_transaction_product($search_text: String!, $type: String!) {
    warehouse_transaction_product(search_text: $search_text, type: $type) {
      ...warehouseTransactionProductFields
    }
  }
  ${warehouseTransactionProductFields}
`;

export const CREATE_WAREHOUSE_TRANSACTION = gql`
  mutation create_warehouse_transaction($warehouse_transaction: WarehouseTransactionInputType!) {
    create_warehouse_transaction(warehouse_transaction: $warehouse_transaction) {
      id
    }
  }
`;

export const UPDATE_WAREHOUSE_TRANSACTION = gql`
  mutation update_warehouse_transaction(
    $id: ID!
    $warehouse_transaction: WarehouseTransactionInputType!
  ) {
    update_warehouse_transaction(id: $id, warehouse_transaction: $warehouse_transaction) {
      id
    }
  }
`;

export const FETCH_WMS_TRANSACTION = gql`
  query get_wms_transaction($id: ID!) {
    get_wms_transaction(id: $id) {
      ...warehouseTransactionFields
    }
  }
  ${warehouseTransactionFields}
`;

export const FETCH_WMS_TRANSACTION_PRODUCT = gql`
  query get_wms_transaction_product($id: [ID!]!) {
    get_wms_transaction_product(id: $id) {
      ...warehouseTransactionProductFields
    }
  }
  ${warehouseTransactionProductFields}
`;

export const WAREHOUSE_TRANSACTION_ACTIONS = gql`
  mutation warehouse_transaction_actions(
    $id: ID!
    $action: WarehouseTransactionActionEnum!
    $event_data: JSON
  ) {
    warehouse_transaction_actions(id: $id, action: $action, event_data: $event_data) {
      ...warehouseTransactionFields
    }
  }
  ${warehouseTransactionFields}
`;
