import React from 'react';
import {
  Col,
  DatePicker,
  Form,
  GlobalSearch,
  Input,
  InputNumber,
  Radio,
  Row,
} from '@shipmnts/pixel-hub';
import { useInquiryForm } from './InquiryForm';
import { TagsSearch } from 'common';
import { FREIGHT_TYPE_OCEAN, FREIGHT_TYPE_ROAD } from 'sales_hub/utils/constants';

const InquiryAdditionalDetails = () => {
  const { freightType } = useInquiryForm();
  return (
    <div style={{ marginTop: '1.5rem' }}>
      <h3 className="inq-form-section-title">{'Additional Details'}</h3>
      <Row gutter={{ lg: 16 }}>
        {freightType !== FREIGHT_TYPE_ROAD && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.freight_type !== currentValues.freight_type
              }
            >
              {() => {
                return (
                  <Form.Item name="preferredCarrier" label="Preferred Carrier(s)">
                    <GlobalSearch
                      doc_type="Global::Carrier"
                      selectMode="multiple"
                      searchProps={{ carrier_type: [freightType] }}
                      // disabled={editInquiryDisabled}
                      selectProps={{ popupMatchSelectWidth: true }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={12} lg={6}>
          <Form.Item
            name="target_rate"
            label="Target Rate"
            tooltip="The desired rate at which customer expects the Price"
          >
            <Input placeholder="Target Rate" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item
            name="tags"
            label="Tags"
            tooltip="Categorize your Inquiries by providing custom tags and get better visibility on them."
          >
            <TagsSearch purpose={'inquiry'} autoFocus={false} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item
            name="targetClosingDate"
            label="Target Closing Date"
            tooltip="Target date to get closure on the Inquiry"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="targetDeliveryDate" label="Target Delivery Date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        {freightType !== FREIGHT_TYPE_ROAD && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item
              name="cargoReadyDate"
              label="Cargo Ready Date"
              tooltip="The date when the goods will be ready for pickup"
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={12} lg={6}>
          <Form.Item label="Freight Terms" name="freight_terms">
            <Radio.Group
              options={[
                { label: 'PP - Prepaid', value: 'prepaid' },
                { label: 'CC - Collect', value: 'collect' },
              ]}
              // disabled={editInquiryDisabled}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item label="Other Terms" name="other_terms">
            <Radio.Group
              options={[
                { label: 'PP - Prepaid', value: 'prepaid' },
                { label: 'CC - Collect', value: 'collect' },
              ]}
              // disabled={editInquiryDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      {freightType === FREIGHT_TYPE_OCEAN && (
        <Row gutter={16}>
          <Col sm={24}>
            <label> Free Days</label>
          </Col>
          <Col lg={4} sm={12}>
            <Form.Item name="origin_carrier_free_days" label="Carrier Origin">
              <InputNumber style={{ width: '100%' }} placeholder="Enter No. of Days" />
            </Form.Item>
          </Col>
          <Col lg={4} sm={12}>
            <Form.Item name="destination_carrier_free_days" label="Carrier Destination">
              <InputNumber style={{ width: '100%' }} placeholder="Detention" />
            </Form.Item>
          </Col>
          <Col lg={4} sm={12}>
            <Form.Item name="destination_port_free_days" label="Destination Port">
              <InputNumber style={{ width: '100%' }} placeholder="Demurrage" />
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InquiryAdditionalDetails;
