import { AddressLocationOptionType, LOCATION_PORT_TYPE_OPTION } from '@shipmnts/pixel-hub';
import {
  AIRPORT_DROP_MOVEMENT_MODE,
  AIRPORT_PICKUP_MOVEMENT_MODE,
  CONTAINER_DESTUFFING_MOVEMENT_MODE,
  CONTAINER_STUFFING_MOVEMENT_MODE,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  TypeFreightType,
} from 'sales_hub/utils/constants';
// import { DOOR_TO_DOOR_SERVICE, FREIGHT_TYPE_OCEAN } from 'sales_hub/utils/constants';

// constants
export const PICKUP_NODE = 'origin';
export const PCR_NODE = 'place_of_carrier_receipt';
export const POL_NODE = 'port_of_loading';
export const CONTAINER_STUFFING_NODE = 'container_stuffing_location';
export const CONTAINER_DESTUFFING_NODE = 'container_destuffing_location';
export const POD_NODE = 'port_of_discharge';
export const PCD_NODE = 'place_of_carrier_delivery';
export const DELIVERY_NODE = 'destination';

// helpers
export const shouldShow = (freigthType?: TypeFreightType, service?: string, node?: string) => {
  if (!freigthType || !service) return false;
  switch (node) {
    case PICKUP_NODE:
      return (
        service.startsWith('door_to_') ||
        service.endsWith('_pickup') ||
        freigthType === FREIGHT_TYPE_ROAD
      );
    case PCR_NODE:
      return freigthType === FREIGHT_TYPE_OCEAN && service.startsWith('door_to_');
    case POL_NODE:
      return [FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN].includes(freigthType);
    case CONTAINER_STUFFING_NODE:
      return freigthType === FREIGHT_TYPE_ROAD && service === CONTAINER_STUFFING_MOVEMENT_MODE;
    case CONTAINER_DESTUFFING_NODE:
      return freigthType === FREIGHT_TYPE_ROAD && service === CONTAINER_DESTUFFING_MOVEMENT_MODE;
    case POD_NODE:
      return [FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN].includes(freigthType);
    case PCD_NODE:
      return freigthType === FREIGHT_TYPE_OCEAN && service.endsWith('_to_door');
    case DELIVERY_NODE:
      return service.endsWith('_to_door') || freigthType === FREIGHT_TYPE_ROAD;
    default:
      return false;
  }
};

export const getDefaultNodeType = (
  freigthType?: TypeFreightType,
  service?: string | null,
  node?: string
): AddressLocationOptionType | null => {
  if (freigthType === FREIGHT_TYPE_ROAD) {
    switch (node) {
      case PICKUP_NODE:
        return service?.startsWith('container_') || service === AIRPORT_PICKUP_MOVEMENT_MODE
          ? LOCATION_PORT_TYPE_OPTION
          : null;
      case DELIVERY_NODE:
        return service?.startsWith('container_') || service === AIRPORT_DROP_MOVEMENT_MODE
          ? LOCATION_PORT_TYPE_OPTION
          : null;
    }
  }
  return null;
};
