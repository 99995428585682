import { Form, Radio, Row } from '@shipmnts/pixel-hub';
import ContainerDetails from 'operations/components/ContainerDetails';
import { isLiveReeferCargo } from 'operations/models/Cargo';
import { ShipmentValue } from 'operations/models/Shipment';
import { LOAD_TYPE_OPTIONS } from 'operations/utils/constants';
import React, { useRef } from 'react';

function ChangeLoadTypeContent(props: { shipment?: ShipmentValue }) {
  const { shipment } = props;
  const LoadTypeOptions = LOAD_TYPE_OPTIONS[shipment?.freight_type || 'air'].filter(
    (option: any) => option.value !== shipment?.load_type
  );
  const isLiveReefer = shipment?.cargos ? isLiveReeferCargo(shipment?.cargos) : false;
  const container_requests_ref = useRef<{ runValidation: () => boolean }>();

  return (
    <>
      <Row>
        <Form.Item
          name="load_type"
          label="Load Type"
          required
          rules={[{ required: true, message: 'Please select load type' }]}
        >
          <Radio.Group defaultValue={LoadTypeOptions?.[0]?.value} options={LoadTypeOptions} />
        </Form.Item>
      </Row>
      {shipment?.shipment_containers?.length === 0 && (
        <Row>
          <div style={{ width: '100%' }}>
            <h3>Add Container</h3>
          </div>
          <Form.Item name="container_requests" noStyle>
            <ContainerDetails ref={container_requests_ref} isLiveReefer={isLiveReefer} />
          </Form.Item>
        </Row>
      )}
    </>
  );
}

export default ChangeLoadTypeContent;
