import { TeamValue } from 'common/models/Team';
import { erpnextApiHelper } from 'network';
import { FETCH_USER_CONTACT } from 'operations/graphql/user';
import { GET_COMPANY } from 'network/graphql/company';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { TEAM_ROLE_SALES } from 'network/baseConstants';
import { SalesPersonValue } from 'common/models/SalesPerson';

export const fetchPartyByNameApi = async (
  name: string,
  type: string,
  optionsParam: {
    fields: string[];
    filters: (string | number)[][];
    or_filters?: (string | number)[][];
  }
) => {
  const options = optionsParam || { fields: ['name'], filters: [] };
  try {
    const url = `/api/resource/${type}`;
    const { fields, filters, or_filters = [] } = options;
    const new_filters = [...filters, [type, 'name', 'like', `%${name}%`]];
    const new_or_filters = [...or_filters, [type, 'name', 'like', `%${name}%`]];
    const response = await erpnextApiHelper.erpNextAxiosCall({
      action: 'get',
      url,
      params: {
        fields: JSON.stringify(fields),
        filters: or_filters.length > 0 ? [] : JSON.stringify(new_filters),
        or_filters: or_filters.length > 0 ? JSON.stringify(new_or_filters) : [],
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return { error };
  }
};

/**
 * Get payload for teams attribute
 * @param teams : Form values
 * @param reference_type : Reference type of your object
 * @param reference_id : Reference id of your object
 * @param initialValues : Initial values of teams on your object, helps to find which teams to destroy
 * @returns Mapped payload of teams
 */
export const getUpsertTeamPayload = (
  teams: any,
  reference_type: string,
  reference_id: string | undefined,
  initialValues?: TeamValue[]
) => {
  let _teams = teams?.filter(
    (team: any) => team.sales_person || team.user_contact || team._destroy === true || team?.id
  );
  if (reference_type === 'Shipment::Shipment')
    _teams = _teams?.filter((team: any) => team.role !== 'sales');

  const teamIds = teams?.map((team: any) => team?.id);
  initialValues?.forEach((team: any) => {
    if (!teamIds.includes(team?.id)) {
      _teams?.push({
        id: team?.id,
        _destroy: true,
      });
    }
  });

  return {
    reference_type,
    reference_id,
    teams: _teams?.map((team: any) => ({
      reference_type,
      reference_id,
      id: team?.id,
      _destroy: team?._destroy || (team.id && !team.sales_person && !team.user_contact),
      role: team?.role,
      sales_person_id: team.sales_person?.id,
      user_contact_id: team.user_contact?.id,
      branch_account_ids:
        reference_type !== 'Shipment::Shipment' && reference_type !== 'SalesHub::Inquiry'
          ? team.branch_accounts?.map((ba: any) => ba.id)
          : undefined,
    })),
  };
};

export type FetchResourceTypes = 'Network::Company' | 'Network::UserContact';
interface FetchResourceProps {
  resource_id?: string | number;
  resource_type?: FetchResourceTypes;
}

const resourceMap = {
  'Network::Company': {
    query: GET_COMPANY,
    dataName: 'company',
  },
  'Network::UserContact': {
    query: FETCH_USER_CONTACT,
    dataName: 'fetch_user_contact',
  },
};

//Fetch Resource based on resource_id and resource_type
export const useFetchResource = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();

  const fetchResource = async (props: FetchResourceProps) => {
    const { resource_id, resource_type } = props;
    setLoading(true);

    // If resource_id or resource_type is not present
    if (!resource_id || !resource_type || !resourceMap[resource_type]) {
      setLoading(false);
      return;
    }
    // Fetch Resource based on resource_id and resource_type
    const { query, dataName } = resourceMap[resource_type];

    // Execute the GraphQL query
    try {
      const { data } = await client.query({
        query,
        variables: { id: resource_id },
      });
      return data[dataName];
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { fetchResource, loading };
};

export const isSalesPersonPresentInTeam = (teams: any[]) => {
  const find = teams.find((team: any) => team.role === TEAM_ROLE_SALES && !team._destroy);
  return find;
};

export const getPrimarySalesPersonFromTeams = (
  teams?: TeamValue[] | null
): SalesPersonValue | null => {
  if (!teams || teams.length === 0) return null;

  const salesTeams = teams.filter((team: TeamValue) => team.role === 'sales');

  if (salesTeams.length === 0) {
    // No sales teams available
    return null;
  }

  // Multiple sales teams: prefer one without branch accounts, otherwise return the first
  const primarySalesTeam = salesTeams.find(
    (team: TeamValue) => !team.branch_accounts || team.branch_accounts.length === 0
  );

  return primarySalesTeam?.sales_person || salesTeams[0].sales_person;
};
