/* eslint-disable no-lone-blocks */
import React from 'react';
import {
  Input,
  Button,
  InputNumber,
  Dropdown,
  Select,
  Table,
  Tooltip,
  Form,
  FormListFieldData,
  FormInstance,
} from '@shipmnts/pixel-hub';
import { LinkOutlined, CustomIcon } from '@shipmnts/pixel-hub';
import { oceanPackageTypes } from 'operations/modules/reports/constants';
// import { DelinkLinkedShipment } from 'operations/icons';
import { SHIPMENT_TYPE_CONSOL, SHIPMENT_TYPE_HOUSE } from 'operations/modules/shipment/constants';
import { isFieldDisabled } from './EditContainerForm';
import type { MenuProps } from '@shipmnts/pixel-hub';
import { LOAD_TYPE_FCL } from 'network/baseConstants';
type MenuItem = Required<MenuProps>['items'][number];

const { Option } = Select;

interface LinkedShipmentsProps {
  shipments: {
    id: string;
    job_number: string;
    shipment_type: string;
    load_type?: string;
    gross_weight?: number;
    net_weight?: number;
    gross_volume?: number;
    total_number_of_packages?: number;
    master_shipment_id?: string;
    shipment_documents: {
      id: string;
      shipment_number?: string | undefined;
      document_status: string;
    }[];
  }[];
  delinking_enabled?: boolean;
  form: FormInstance;
  fields: FormListFieldData[];
  removeRow: (index: number | number[]) => void;
  addRow: (defaultValue?: any, insertIndex?: number | undefined) => void;
}

export default function LinkedShipments(props: LinkedShipmentsProps): JSX.Element {
  const { shipments, form, fields, removeRow, addRow } = props;
  const cargo_details = form.getFieldValue('container_cargo_details');

  const updatePackageStr = (record_name: number) => {
    const cargo_details = form.getFieldValue('container_cargo_details');
    const detail = cargo_details[record_name];
    detail['package_str'] =
      detail['total_number_of_packages'] && detail['type_of_package']
        ? `${detail['total_number_of_packages']} ${detail['type_of_package']}`
        : '';

    form.setFieldsValue({
      container_cargo_details: cargo_details,
    });
  };

  const added_shipments = form
    .getFieldValue('container_cargo_details')
    .map((ccd: any) => ccd.shipment_id);
  const fcl_shipments_added = shipments.filter(
    (shipment) =>
      added_shipments.includes(shipment.id) &&
      shipment.load_type === LOAD_TYPE_FCL &&
      shipment.shipment_type === SHIPMENT_TYPE_HOUSE
  );
  const pending_shipments =
    fcl_shipments_added.length > 0
      ? []
      : shipments.filter(
          (shipment) =>
            !added_shipments.includes(shipment.id) &&
            shipment.shipment_type !== SHIPMENT_TYPE_CONSOL
        );
  const getItems = () => {
    const items: MenuItem[] = [];
    {
      // eslint-disable-next-line array-callback-return
      pending_shipments.map((shipment, index) => {
        items.push({
          key: index,
          onClick: () => {
            addRow({
              shipment_id: shipment.id,
              is_disabled: isFieldDisabled(shipment),
            });
          },
          label: (
            <div>
              <div>
                <div style={{ fontWeight: 'bold' }}>{shipment?.job_number}</div>
                <div style={{ color: '#586988' }}>
                  {shipment?.shipment_documents[0]?.shipment_number || ''}
                </div>
              </div>
            </div>
          ),
        });
      });
    }
    return items;
  };

  const columns = [
    {
      title: 'Shipment Number',
      dataIndex: 'name',
      key: 'shipment_number',
      render: (text: any, record: FormListFieldData, index: string | number) => {
        return (
          <Form.Item
            noStyle
            dependencies={[['container_cargo_details', record.name, 'shipment_id']]}
          >
            {({ getFieldValue }) => {
              const shipment_id = getFieldValue([
                'container_cargo_details',
                record.name,
                'shipment_id',
              ]);
              const shipment = shipments.find((s) => s.id === shipment_id);
              return (
                <>
                  <Form.Item name={[record.name, 'shipment_id']} noStyle>
                    <span />
                  </Form.Item>
                  <Form.Item name={[record.name, 'id']} noStyle>
                    <span />
                  </Form.Item>
                  <div>
                    <div style={{ fontWeight: 'bold' }}>{shipment?.job_number}</div>
                    <div style={{ color: '#586988' }}>
                      {shipment?.shipment_documents[0]?.shipment_number || ''}
                    </div>
                  </div>
                </>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: 'Weight',
      children: [
        {
          title: 'Gross Wt (kgs)',
          dataIndex: 'gross_weight',
          key: 'gross_weight',
          width: 130,
          render: (text: any, record: FormListFieldData, index: string | number) => {
            const { is_disabled } = cargo_details[record.name];
            return (
              <Form.Item
                noStyle
                dependencies={[['container_cargo_details', record.name, 'shipment_id']]}
              >
                {({ getFieldValue }) => {
                  const shipment_id = getFieldValue([
                    'container_cargo_details',
                    record.name,
                    'shipment_id',
                  ]);
                  const shipment = shipments.find((s) => s.id === shipment_id);
                  return (
                    <Form.Item
                      name={[record.name, `gross_weight`]}
                      style={{ marginBottom: '0px' }}
                      dependencies={['shipment_id']}
                      initialValue={shipment?.gross_weight}
                    >
                      <InputNumber
                        disabled={is_disabled}
                        placeholder="Gross Weight"
                        min={0.00001}
                        step={0.00001}
                        precision={3}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          },
        },
        {
          title: 'Net Wt (kgs)',
          dataIndex: 'net_weight',
          key: 'net_weight',
          width: 130,
          render: (text: any, record: FormListFieldData, index: string | number) => {
            const { is_disabled } = cargo_details[record.name];
            return (
              <Form.Item
                noStyle
                dependencies={[['container_cargo_details', record.name, 'shipment_id']]}
              >
                {({ getFieldValue }) => {
                  const shipment_id = getFieldValue([
                    'container_cargo_details',
                    record.name,
                    'shipment_id',
                  ]);
                  const shipment = shipments.find((s) => s.id === shipment_id);
                  return (
                    <Form.Item
                      name={[record.name, `net_weight`]}
                      style={{ marginBottom: '0px' }}
                      initialValue={shipment?.net_weight}
                    >
                      <InputNumber
                        disabled={is_disabled}
                        placeholder="Net Weight"
                        min={0.00001}
                        step={0.00001}
                        precision={3}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          },
        },
      ],
    },

    {
      title: 'Volume',
      children: [
        {
          title: 'Gross Vol (cbm)',
          key: 'gross_volume',
          dataIndex: 'gross_volume',
          width: 130,
          render: (text: any, record: FormListFieldData, index: string | number) => {
            const { is_disabled } = cargo_details[record.name];

            return (
              <Form.Item
                noStyle
                dependencies={[['container_cargo_details', record.name, 'shipment_id']]}
              >
                {({ getFieldValue }) => {
                  const shipment_id = getFieldValue([
                    'container_cargo_details',
                    record.name,
                    'shipment_id',
                  ]);
                  const shipment = shipments.find((s) => s.id === shipment_id);
                  return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Form.Item
                        name={[record.name, `gross_volume`]}
                        style={{ marginBottom: '0px' }}
                        initialValue={shipment?.gross_volume}
                      >
                        <InputNumber
                          disabled={is_disabled}
                          placeholder="Gross Volume"
                          min={0.00001}
                          step={0.00001}
                          precision={3}
                        />
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.Item>
            );
          },
        },
      ],
    },
    {
      title: 'Packages',
      children: [
        {
          title: 'No. & Type',
          dataIndex: 'total_number_of_packages',
          key: 'total_number_of_packages',
          width: 220,
          render: (text: any, record: FormListFieldData, index: any) => {
            const { is_disabled } = cargo_details[record.name];
            return (
              <Form.Item
                noStyle
                dependencies={[['container_cargo_details', record.name, 'shipment_id']]}
              >
                {({ getFieldValue }) => {
                  const shipment_id = getFieldValue([
                    'container_cargo_details',
                    record.name,
                    'shipment_id',
                  ]);
                  const shipment = shipments.find((s) => s.id === shipment_id);
                  return (
                    <Form.Item
                      style={{ marginBottom: '0px' }}
                      name={[record.name, 'total_number_of_packages']}
                      initialValue={shipment?.total_number_of_packages}
                    >
                      <InputNumber
                        disabled={is_disabled}
                        addonAfter={
                          <Form.Item
                            name={[record.name, 'type_of_package']}
                            style={{ marginBottom: '0px' }}
                          >
                            <Select
                              showSearch
                              placeholder="Package Type"
                              onBlur={() => updatePackageStr(record.name)}
                              style={{ width: 100 }}
                              disabled={is_disabled}
                            >
                              {oceanPackageTypes.map((option, index) => (
                                <Option key={index} value={option.name}>
                                  {option.key}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        placeholder="No. of Packages"
                        min={0}
                        onBlur={() => updatePackageStr(record.name)}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          },
        },
        {
          title: 'Details',
          dataIndex: 'package_str',
          key: 'package_str',
          render: (text: any, record: FormListFieldData, index: any) => {
            const { is_disabled } = cargo_details[record.name];
            return (
              <Form.Item
                dependencies={[
                  ['container_cargo_details', record.name, 'type_of_package'],
                  ['container_cargo_details', record.name, 'total_number_of_packages'],
                ]}
                noStyle
              >
                {({ getFieldValue }) => {
                  const type_of_package = getFieldValue([
                    'container_cargo_details',
                    record.name,
                    'type_of_package',
                  ]);
                  const total_number_of_packages = getFieldValue([
                    'container_cargo_details',
                    record.name,
                    'total_number_of_packages',
                  ]);
                  const enabled = total_number_of_packages && type_of_package;

                  return (
                    <Form.Item style={{ marginBottom: '0px' }} name={[record.name, 'package_str']}>
                      <Input placeholder="Package Details" disabled={!enabled || is_disabled} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          },
        },
      ],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: FormListFieldData, index: any) => {
        return (
          <Form.Item
            noStyle
            dependencies={[['container_cargo_details', record.name, 'shipment_id']]}
          >
            {({ getFieldValue }) => {
              const shipment_id = getFieldValue([
                'container_cargo_details',
                record.name,
                'shipment_id',
              ]);
              const shipment = shipments.find((s) => s.id === shipment_id);
              if (shipment?.shipment_type === SHIPMENT_TYPE_HOUSE && shipment?.master_shipment_id) {
                return (
                  <Tooltip title="Delink">
                    <div style={{ cursor: 'pointer' }} onClick={() => removeRow(record.name)}>
                      <CustomIcon icon="Delink" />
                    </div>
                  </Tooltip>
                );
              }
              return <></>;
            }}
          </Form.Item>
        );
      },
    },
  ];
  return (
    <>
      <Table columns={columns} dataSource={fields} pagination={false} />
      <div style={{ paddingBottom: '30px' }}>
        <Dropdown
          disabled={pending_shipments.length === 0}
          menu={{ items: getItems() }}
          placement="bottomLeft"
          arrow
        >
          <Button icon={<LinkOutlined />} size="small">
            Link House Shipment
          </Button>
        </Dropdown>
      </div>
    </>
  );
}
