/* eslint-disable camelcase */
import { dayjs } from '@shipmnts/pixel-hub';

import { InquiryValue } from 'sales_hub/models/inquiry';

import { convertDateToUnix } from './InquiryLostLayout';
import { pick as _pick } from 'lodash';
import { getUpsertTeamPayload } from 'common';
import { TEAM_ROLE_PRICING, TEAM_ROLE_SALES } from 'network/baseConstants';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';

const getLocationId = (val: any) => val?.address?.id || val?.location?.id;
const getLocationType = (val: any) => (!!val ? (!!val.address ? 'Address' : 'Location') : null);

export const createInquiryParamsFromForm = (values: any, inquiry?: InquiryValue) => {
  const _values = values;
  const _inquiry = JSON.parse(JSON.stringify(inquiry || null));
  const {
    branchAccount,
    customer,
    received_at,
    priority = 'lowest',
    inquiryDescription,
    target_rate,
    cargos = [],
    containerRequests,
    preferredCarrier,
    originCustomClearance,
    destinationCustomClearance,
    cargoReadyDate,
    targetDeliveryDate,
    services,
    targetClosingDate,
    billing_party,
    teams = [],
    salesPerson,
    pricingPerson,
    cargo,
    shipment_parties,
    consignee_same_as_customer, // just to delete from rest params
    shipper_same_as_customer,
    origin, // routing params
    destination,
    port_of_loading,
    port_of_discharge,
    place_of_carrier_receipt,
    place_of_carrier_delivery,
    container_stuffing_location,
    container_destuffing_location,
    other_terms = 'prepaid',
    freight_terms = 'prepaid',
    ...restParams
  } = _values;
  const cargoIds = cargos?.map((c: any) => c.id);
  const containerIds = containerRequests?.map((c: any) => c.id);
  const teamIds = teams?.map((team: any) => team.id);

  _inquiry?.cargos?.forEach((cargo: any) => {
    if (!cargoIds?.includes(cargo.id)) {
      cargos?.push({ id: cargo.id, _destroy: true });
    }
  });
  _inquiry?.container_requests?.forEach((cr: any) => {
    if (!containerIds?.includes(cr.id)) {
      containerRequests?.push({ id: cr.id, _destroy: true });
    }
  });
  _inquiry?.teams?.forEach((team: any) => {
    if (!teamIds?.includes(team.id)) {
      teams?.push({ id: team.id, _destroy: true });
    }
  });

  // passing sales_person & pricing person inside team, which comes from Quick form.
  [
    { val: salesPerson, role: TEAM_ROLE_SALES },
    { val: pricingPerson, role: TEAM_ROLE_PRICING },
  ].forEach((person) => {
    if (!!person.val) {
      teams.push({
        branch_accounts: !!branchAccount ? [branchAccount] : [],
        preFilled: true,
        reference_type: 'SalesHub::Inquiry',
        reference_id: _inquiry?.id,
        role: person.role,
        sales_person: person.role === TEAM_ROLE_SALES ? person.val : null,
        user_contact: person.role === TEAM_ROLE_PRICING ? person.val : person.val.user_contact,
      });
    }
  });

  if (!!cargo) cargos.push(cargo);

  const services_arr: any = [];
  Object.keys(services || {}).forEach((key) => {
    if (services?.[key]) services_arr.push(key);
  });

  // currently, only these fields have possibility of having remarks
  let routing_remarks = {};
  ['origin', 'destination', 'container_stuffing_location', 'container_destuffing_location'].forEach(
    (location) => {
      const val = values?.[location];
      if (!!val && val?.remarks) {
        routing_remarks = {
          ...routing_remarks,
          [location]: val.remarks,
        };
      }
    }
  );

  return {
    involved_branch_id: branchAccount.id,
    customer_company_id: customer.party_company?.id,
    customer_address_id: customer.party_address?.id,
    received_at: convertDateToUnix(received_at),
    priority: priority,
    description: inquiryDescription || null,
    target_rate: target_rate,
    cargos: cargos?.map((i: any) => {
      const cargo = {
        id: i.id,
        _destroy: i._destroy,
        linked_to_id: i.linked_to_id,
        cargo_properties: _pick(i.cargo_properties, [
          'is_perishable',
          'is_tiltable',
          'is_palletisable',
          'is_stackable',
          'is_temp_controlled',
          'is_hazardous',
          'is_battery',
          'un_number',
          'un_imo_class',
          'proper_shipping_name',
          'cooling_type',
          'technical_name',
          'packaging_group',
          'net_weight',
          'net_weight_unit',
          'flash_point',
          'flash_point_unit',
          'temperature',
          'min_temperature',
          'max_temperature',
          'temperature_range',
          'temperature_unit',
          'ems_number',
          'emergency_contact_name',
          'emergency_contact_number',
          'battery_type',
        ]),
        product_name: i.product_name || 'General Cargo',
        gross_weight: i.gross_weight,
        volume_unit: i.volume_unit,
        weight_unit: i.weight_unit,
        commodity_id: i.commodity?.id,
        booking_request_id: i.booking_request?.id,
        ocean_transport_order_id: i.ocean_transport_order?.id,
        road_transport_order_id: i.road_transport_order?.id,
        shipment_id: i.shipment?.id,
        ..._pick(i, [
          'dimension_unit',
          'gross_volume',
          'height',
          'length',
          'outer_package_qty',
          'outer_per_packet_wt',
          'outer_package_type',
          'width',
          'commodity_type',
          'volumetric_weight',
          'chargeable_weight',
          'invoice_number',
          'invoice_date',
          'batch_number',
          'custom_ref',
          'serial_number',
          'eway_bill_no',
          'eway_bill_validity',
        ]),
        commodity: undefined,
        allocation_pending_quantity: undefined,
      };
      return cargo;
    }),
    container_requests: containerRequests?.map(
      ({
        id,
        is_shipper_owned,
        quantity,
        weight_per_container,
        weight_unit,
        container_type,
        container_type_code,
        container_settings,
        _destroy,
      }: any) => {
        const req = {
          id,
          is_shipper_owned,
          quantity,
          weight_per_container,
          weight_unit,
          container_type,
          container_type_code,
          container_settings,
          _destroy,
        };

        return req;
      }
    ),
    preferred_carrier_ids: preferredCarrier?.map((i: any) => i.id),
    cargo_ready_date: convertDateToUnix(cargoReadyDate),
    target_delivery_date: convertDateToUnix(targetDeliveryDate),
    services: services_arr,
    target_closing_date: convertDateToUnix(targetClosingDate),
    billing_party_id: billing_party?.party_company?.id,
    billing_party_address_id: billing_party?.party_address?.id,
    teams: getUpsertTeamPayload(teams, 'SalesHub::Inquiry', _inquiry?.id, _inquiry?.teams)?.teams,
    shipment_parties: Object.keys(shipment_parties || {}).map((party) => {
      const details = shipment_parties[party];
      return {
        id: inquiry?.id ? details?.id : null,
        name: party,
        party_address_id: details?.party_address?.id,
        party_company_id: details?.party_company?.id,
      };
    }),
    // routing prams
    origin_id: getLocationId(origin),
    origin_type: getLocationType(origin),
    destination_id: getLocationId(destination),
    destination_type: getLocationType(destination),
    container_stuffing_location_id: getLocationId(container_stuffing_location),
    container_stuffing_location_type: getLocationType(container_stuffing_location),
    container_destuffing_location_id: getLocationId(container_destuffing_location),
    container_destuffing_location_type: getLocationType(container_destuffing_location),
    origin_custom_clearance_location_id: originCustomClearance?.id,
    destination_custom_clearance_location_id: destinationCustomClearance?.id,
    // considering, PCR & PCD can't be address, it will be always location (ICD or CFS)
    place_of_carrier_receipt_id: place_of_carrier_receipt?.location?.id,
    place_of_carrier_delivery_id: place_of_carrier_delivery?.location?.id,
    port_of_loading_id: port_of_loading?.id,
    port_of_discharge_id: port_of_discharge?.id,
    routing_remarks: JSON.stringify(routing_remarks),
    other_terms,
    freight_terms,
    ...restParams,
  };
};

export const getInquiryParamsFromInquiry = (values: any) => {
  if (!values) return {};
  const {
    involved_branch,
    customer_company,
    customer_address,
    received_at,
    description,
    container_requests,
    preferred_carriers,
    origin_custom_clearance_location,
    destination_custom_clearance_location,
    cargo_ready_date,
    target_delivery_date,
    pricing_person,
    target_closing_date,
    sales_person,
    services,
    billing_party,
    billing_party_address,
    place_of_carrier_receipt,
    place_of_carrier_delivery,
  } = values;

  const shipper = values?.shipment_parties?.find(
    (party: ShipmentPartyValue) => party.name === 'shipper'
  );

  const consignee = values?.shipment_parties?.find(
    (party: ShipmentPartyValue) => party.name === 'consignee'
  );

  const routing_remarks = JSON.parse(values?.routing_remarks);

  return {
    branchAccount: involved_branch,
    customer: {
      party_company: customer_company,
      party_address: customer_address,
    },
    received_at: received_at ? dayjs.unix(received_at) : null,
    inquiryDescription: description,
    containerRequests: container_requests,
    preferredCarrier: preferred_carriers,
    originCustomClearance: origin_custom_clearance_location,
    destinationCustomClearance: destination_custom_clearance_location,
    cargoReadyDate: cargo_ready_date ? dayjs.unix(cargo_ready_date) : null,
    targetDeliveryDate: target_delivery_date ? dayjs.unix(target_delivery_date) : null,
    pricingPerson: pricing_person,
    targetClosingDate: target_closing_date ? dayjs.unix(target_closing_date) : null,
    salesPerson: sales_person,
    services: (services || []).reduce((ser: any, ele: any) => {
      ser[ele] = true;
      return ser;
    }, {}),
    billing_party: {
      party_company: billing_party,
      party_address: billing_party_address,
    },
    teams: [...(values?.teams as any)],
    shipment_parties: {
      shipper,
      consignee,
    },
    shipper_same_as_customer: shipper?.party_company?.id === values?.customer_company?.id,
    consignee_same_as_customer: consignee?.party_company?.id === values?.customer_company?.id,
    place_of_carrier_receipt: {
      type: place_of_carrier_receipt?.type,
      location: place_of_carrier_receipt,
    },
    place_of_carrier_delivery: {
      type: place_of_carrier_delivery?.type,
      location: place_of_carrier_delivery,
    },
    // currently, these are the fields which can be either location or address
    ...[
      'origin',
      'destination',
      'container_stuffing_location',
      'container_destuffing_location',
    ].reduce((acc, field) => {
      const val = values?.[field];
      if (!val) return acc;
      return {
        ...acc,
        [field]: {
          type: val.type || 'Address',
          location: val.type ? val : null,
          address: val.type ? null : val,
          company: val.type ? null : val.company,
          remarks: routing_remarks?.[field],
        },
      };
    }, {}),
  };
};

const TAG_COLORS = [
  'blue',
  'purple',
  'cyan',
  'green',
  'magenta',
  'pink',
  'red',
  'orange',
  'yellow',
  'volcano',
  'geekblue',
  'lime',
  'gold',
];

// Returns bg and color for tag based on length of string
export const getTagColor = (title: string) => {
  return TAG_COLORS[title.length % 14];
};
