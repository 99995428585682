import { gql } from '@apollo/client';

import {
  addressFields,
  companyFields,
  globalCarrierFields,
  inquiryOptionFields,
} from './inquiryOption';
import { templateFields } from 'src/graphQL/template';
import { salesPersonFields } from 'operations/graphql/salesPerson';

export const inquiryFields = gql`
  fragment inquiryFields on InquiryObject {
    id
    inquiry_global_id
    created_at
    trade_type
    load_type
    freight_type
    priority
    last_action_status
    inquiry_number
    cargos {
      id
      cargo_properties {
        is_perishable
        is_temp_controlled
        is_hazardous
        is_battery
        un_number
        un_imo_class
        proper_shipping_name
        technical_name
        packaging_group
        net_weight
        net_weight_unit
        flash_point
        flash_point_unit
        ems_number
        emergency_contact_name
        emergency_contact_number
        aircraft_type
        is_radioactive
        temperature_unit
        temperature
      }
      product_name
      outer_package_qty
      outer_package_type
      gross_volume
      gross_weight
      weight_unit
      volume_unit
      commodity_id
      commodity_type
      volumetric_weight
      chargeable_weight
      invoice_number
      invoice_date
      batch_number
      custom_ref
      serial_number
      eway_bill_no
      eway_bill_validity
      commodity {
        id
        name
        commodity_classification_code
        commodity_coding_system
        dangerous_cargo
        dry_cargo
        live_reefer_cargo
        oog_cargo
      }
    }
    container_requests {
      id
      container_settings {
        is_active_reefer
        temperature
        temperature_unit
        ventilation_requested
        air_flow
        air_flow_unit
        humidity_control_requested
        relative_humidity_percent
        vent_setting
        additional_instructions
        genset_requested
        controlled_atmosphere_requested
        oxygen_level_percent
        nitrogen_level_percent
        carbon_dioxide_level_percent
        length
        width
        height
        lbh_unit
      }
      container_type
      container_type_code
      is_shipper_owned
      quantity
      quantity_fulfilled
      quantity_unfulfilled
      quantity_picked_up
      quantity_origin_port_gated_in
      quantity_loaded_on_vessel
      quantity_shutout
      quantity_cancelled
      quantity_offloaded
      weight_per_container
      weight_unit
    }
    customer_company {
      id
      registered_name
      status
      logo
      company_type
      company_group
      country_of_incorporation
    }
    customer_address {
      id
      name
      print_address
    }
    sales_person {
      ...salesPersonFields
    }
    pricing_person {
      id
      first_name
      last_name
    }
    origin {
      ... on LocationObjectType {
        id
        name
        type
        city {
          name
        }
        state
        country {
          name
        }
        country_code
        unlocode
      }
      ... on AddressObjectType {
        ...addressFields
      }
    }
    destination {
      ... on LocationObjectType {
        id
        name
        type
        city {
          name
        }
        state
        country {
          name
        }
        country_code
        unlocode
      }
      ... on AddressObjectType {
        ...addressFields
      }
    }
    origin_custom_clearance_location {
      id
      type
      name
      unlocode
    }
    destination_custom_clearance_location {
      id
      name
      unlocode
    }
    created_by {
      id
      first_name
      last_name
    }
    incoterms
    description
    target_rate
    target_closing_date
    received_at
    cargo_ready_date
    target_delivery_date
    preferred_carriers {
      id
      carrier_type
      iata_awb_prefix
      iata_carrier_code
      name
      standard_alpha_carrier_code
      meta_information
      short_code
    }
    services
    tags
    next_actions
    business_vertical {
      name
      id
    }
    involved_branch {
      id
      name
    }

    freight_terms
    other_terms
    destination_port_free_days
    origin_carrier_free_days
    destination_carrier_free_days

    inquiry_options {
      ...inquiryOptionFields
    }
    billing_party {
      id
      registered_name
      status
      logo
      company_group
      country_of_incorporation
    }
    billing_party_address {
      id
      name
      print_address
    }
    place_of_carrier_receipt {
      id
      name
      type
      unlocode
    }
    place_of_carrier_delivery {
      id
      name
      type
      unlocode
    }
    additional_loss_remarks
    business_received_through
    teams {
      id
      role
      user_contact {
        id
        first_name
        last_name
        name
      }
      sales_person {
        ...salesPersonFields
      }
      reference_id
      reference_type
    }
    container_stuffing_location {
      ... on LocationObjectType {
        id
        name
        type
        city {
          name
        }
        state
        country {
          name
        }
        country_code
        unlocode
      }
      ... on AddressObjectType {
        ...addressFields
      }
    }
    container_destuffing_location {
      ... on LocationObjectType {
        id
        name
        type
        city {
          name
        }
        state
        country {
          name
        }
        country_code
        unlocode
      }
      ... on AddressObjectType {
        ...addressFields
      }
    }
  }
  ${salesPersonFields}
  ${inquiryOptionFields}
  ${addressFields}
`;

export const CREATE_INQUIRY = gql`
  mutation create_inquiry($inquiry: InquiryCreateInputType!) {
    create_inquiry(inquiry: $inquiry) {
      id
      inquiry_global_id
      freight_type
      inquiry_number
    }
  }
`;

export const rateExplorerFields = gql`
  fragment rateExplorerFields on RateExploreObjectType {
    id
    vendor_rate_agreement_number
    quote_currency
    exchange_rate
    carrier_product
    valid_from
    valid_to
    status
    resource_number
    resource_type
    total_sell
    total_buy
    freight_buy_unit_rate
    freight_sell_unit_rate
    freight_buy_unit_cur
    freight_sell_unit_cur
    routing
    voyage_number
    transit_days
    terms_and_cond {
      ...templateFields
    }
    terms_and_cond_desc
    port_of_loading {
      id
      type
      name
      unlocode
    }
    port_of_discharge {
      id
      type
      name
      unlocode
    }
    place_of_carrier_receipt {
      id
      type
      name
      unlocode
    }
    place_of_carrier_delivery {
      id
      type
      name
      unlocode
    }
    carrier {
      ...globalCarrierFields
    }
    booking_vendor {
      ...companyFields
    }
    customer_company {
      ...companyFields
    }
    services
    container_types
    estimated_time_of_departure
    estimated_time_of_arrival
    shipment_estimates {
      id
      item
      inquiry_option_id
      buy_branch_id
      sell_branch_id
      supplier_company_id
      supplier_company {
        ...companyFields
      }
      customer_company_id
      customer_company {
        ...companyFields
      }
      uom
      quantity
      buy_rate
      buy_exchange_rate
      buy_terms
      buy_currency
      total_buy_amount
      total_buy_billed_amount
      sell_rate
      sell_exchange_rate
      sell_terms
      sell_currency
      total_sell_amount
      total_sell_billed_amount
      remarks
      buy_status
      sell_status
      buy_transaction_identifier
      buy_transaction_doctype
      sell_transaction_identifier
      equipment_type
      equipment_name
      tag
      tax_percentage
      taxable
      taxability
      sequence
      item_group
      item_description
      markup_type
      markup_value
    }
  }
  ${companyFields}
  ${globalCarrierFields}
  ${templateFields}
`;

export const WIN_INQUIRY = gql`
  mutation win_inquiry($inquiry_id: ID!, $input: TrackingEventInputType!, $inquiry_option_id: ID!) {
    win_inquiry(inquiry_id: $inquiry_id, input: $input, inquiry_option_id: $inquiry_option_id) {
      id
      freight_type
    }
  }
`;

export const LOSE_INQUIRY = gql`
  mutation lose_inquiry(
    $inquiry_id: [ID!]!
    $actual_date: Time!
    $event_data: InquiryTrackingEventDataInputType!
  ) {
    lose_inquiry(inquiry_id: $inquiry_id, actual_date: $actual_date, event_data: $event_data) {
      id
      freight_type
    }
  }
`;

export const CANCEL_INQUIRY = gql`
  mutation cancel_inquiry($inquiry_id: ID!) {
    cancel_inquiry(inquiry_id: $inquiry_id) {
      id
      freight_type
    }
  }
`;

export const UPDATE_INQUIRY = gql`
  mutation update_inquiry($inquiry: UpdateInquiryInputType!, $id: ID!) {
    update_inquiry(inquiry: $inquiry, id: $id) {
      ...inquiryFields
    }
  }
  ${inquiryFields}
`;

export const GET_INQUIRY = gql`
  query get_inquiry($id: ID!) {
    get_inquiry(id: $id) {
      ...inquiryFields
    }
  }
  ${inquiryFields}
`;

export const GET_RATE_EXPLORER_DATA = gql`
  query get_rate_explorer_data($filters: RateExplorerFilterType!, $sort: [RateExplorerSortType!]) {
    get_rate_explorer_data(filters: $filters, sort: $sort) {
      ...rateExplorerFields
    }
  }
  ${rateExplorerFields}
`;

export const GET_INQUIRY_FROM_GLOBAL_ID = gql`
  query get_inquiry_from_global_id($id: ID!) {
    get_inquiry_from_global_id(id: $id) {
      id
      inquiry_global_id
      created_at
      trade_type
      load_type
      freight_type
      priority
      last_action_status
      inquiry_number
      cargos {
        id
        cargo_properties {
          is_perishable
          is_temp_controlled
          is_hazardous
          is_battery
          un_number
          un_imo_class
          proper_shipping_name
          technical_name
          packaging_group
          net_weight
          net_weight_unit
          flash_point
          flash_point_unit
          ems_number
          emergency_contact_name
          emergency_contact_number
          aircraft_type
          is_radioactive
          temperature_unit
          temperature
        }
        product_name
        outer_package_qty
        outer_package_type
        gross_volume
        gross_weight
        weight_unit
        volume_unit
        commodity_id
        volumetric_weight
        commodity_type
        chargeable_weight
        invoice_number
        invoice_date
        batch_number
        custom_ref
        serial_number
        eway_bill_no
        eway_bill_validity
        commodity {
          id
          name
          commodity_classification_code
          commodity_coding_system
          dangerous_cargo
          dry_cargo
          live_reefer_cargo
          oog_cargo
        }
      }
      container_requests {
        id
        container_settings {
          is_active_reefer
          temperature
          temperature_unit
          ventilation_requested
          air_flow
          air_flow_unit
          humidity_control_requested
          relative_humidity_percent
          vent_setting
          additional_instructions
          genset_requested
          controlled_atmosphere_requested
          oxygen_level_percent
          nitrogen_level_percent
          carbon_dioxide_level_percent
          length
          width
          height
          lbh_unit
        }
        container_type
        container_type_code
        is_shipper_owned
        quantity
        quantity_fulfilled
        quantity_unfulfilled
        quantity_picked_up
        quantity_origin_port_gated_in
        quantity_loaded_on_vessel
        quantity_shutout
        quantity_cancelled
        quantity_offloaded
        weight_per_container
        weight_unit
      }
      customer_company {
        id
        registered_name
        status
        logo
        company_type
        company_group
        country_of_incorporation
      }
      customer_address {
        id
        name
        print_address
      }
      sales_person {
        ...salesPersonFields
      }
      collaborators {
        id
        first_name
        last_name
      }
      pricing_person {
        id
        first_name
        last_name
        email
        name
      }
      origin {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      destination {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      origin_custom_clearance_location {
        id
        type
        name
        unlocode
      }
      destination_custom_clearance_location {
        id
        name
        unlocode
      }
      created_by {
        id
        first_name
        last_name
      }
      incoterms
      description
      target_rate
      target_closing_date
      received_at
      cargo_ready_date
      target_delivery_date
      preferred_carriers {
        id
        carrier_type
        iata_awb_prefix
        iata_carrier_code
        name
        standard_alpha_carrier_code
        meta_information
        short_code
      }
      services
      tags
      next_actions
      business_vertical {
        name
        id
      }
      involved_branch {
        id
        name
      }

      freight_terms
      other_terms
      destination_port_free_days
      origin_carrier_free_days
      destination_carrier_free_days

      inquiry_options {
        ...inquiryOptionFields
      }
      billing_party {
        id
        registered_name
        status
        logo
        company_group
        country_of_incorporation
      }
      billing_party_address {
        id
        name
        print_address
      }
      place_of_carrier_receipt {
        id
        name
        type
        unlocode
      }
      place_of_carrier_delivery {
        id
        name
        type
        unlocode
      }
      additional_loss_remarks
      business_received_through
      shipments {
        id
        job_number
        shipment_booking_number
        gross_weight
        gross_volume
        status
        shipment_containers {
          id
          container_type_code
        }
      }
      color_code
      teams {
        id
        branch_accounts {
          id
          name
        }
        role
        user_contact {
          id
          first_name
          last_name
          name
        }
        sales_person {
          ...salesPersonFields
        }
        reference_id
        reference_type
      }
      port_of_loading {
        id
        type
        name
        unlocode
      }
      port_of_discharge {
        id
        type
        name
        unlocode
      }
      shipment_parties {
        id
        name
        party_address {
          id
          name
        }
        party_company {
          id
          registered_name
        }
      }
      service_type
      movement_mode
      routing_remarks
      container_stuffing_location {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      container_destuffing_location {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
    }
  }
  ${salesPersonFields}
  ${inquiryOptionFields}
  ${addressFields}
`;

export const GET_INQUIRY_OPTION_LINKED_RESOURCE = gql`
  query get_inquiry_option_linked_resources($ids: [ID!]!) {
    get_inquiry_option_linked_resources(ids: $ids) {
      id
      inquiry_option_id
      type
      quotation_number
      resource_number
    }
  }
`;

export const VALIDATE_CUSTOM_RULE = gql`
  mutation validate_custom_rule(
    $actor_id: ID!
    $trigger: String!
    $actor_type: String!
    $actor_context: String
  ) {
    validate_custom_rule(
      actor_id: $actor_id
      trigger: $trigger
      actor_type: $actor_type
      actor_context: $actor_context
    ) {
      rule {
        id
        title
        description
        trigger
        actor_type
        action_method
        conditions
      }
      message
    }
  }
`;
