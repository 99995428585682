import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Upload,
  Form,
  message,
  Input,
  FormInstance,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { InboxOutlined } from '@shipmnts/pixel-hub';
import { FETCH_SHIPMENT_CUSTOM_DETAILS } from 'operations/graphql/shipmentCustomDetail';

import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { ShipmentValue } from 'operations/models/Shipment';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const { Dragger } = Upload;

interface ReuploadUploadSbBeFile {
  form: FormInstance;
  api: string;
  shipment: ShipmentValue;
  customDetails?: ShipmentCustomDetailValue;
}

const CustomsFileUploader = (props: any) => {
  const handleChange = (info: any) => {
    if (info.fileList.length === 0) {
      return;
    }
    const file = info.fileList[info.fileList.length - 1];
    const reader = new FileReader();
    reader.onload = function () {
      props.onChange(reader.result);
    };
    reader.onerror = function () {
      message.error('unable to read the file');
    };
    reader.readAsText(file.originFileObj);
  };

  return (
    <Dragger
      maxCount={1}
      name="data"
      beforeUpload={() => false}
      onChange={handleChange}
      accept={props.fileType}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Upload the .sb or .be file here</p>
      <p className="ant-upload-hint">Click or drag file to this area to upload</p>
    </Dragger>
  );
};

const ReuploadUploadSbBeFileForm = (props: ReuploadUploadSbBeFile) => {
  const { form, shipment, api, customDetails } = props;
  const shipmentId = shipment.id;
  const [countCustomDetails, setCountCustomDetails] = useState<any>();
  const [fileType, setFileType] = useState<any>();

  useEffect(() => {
    if (api === 'create') {
      if (shipment.trade_type === 'export') {
        setFileType('.sb');
      } else if (shipment.trade_type === 'import') {
        setFileType('.be');
      }
    } else {
      if (customDetails?.trade_type === 'export') {
        setFileType('.sb');
      } else if (customDetails?.trade_type === 'import') {
        setFileType('.be');
      }
    }
  }, [api, customDetails?.trade_type, shipment.trade_type]);
  const [fetchCustomDetails, { data }] = useLazyQuery(FETCH_SHIPMENT_CUSTOM_DETAILS, {
    variables: { shipment_ids: [shipmentId] },
  });
  useEffect(() => {
    fetchCustomDetails();
    const _custom_details = get(data, 'fetch_shipment_custom_details', []);
    setCountCustomDetails(_custom_details.length);
  }, [data, fetchCustomDetails]);
  const customValidator = ({ otherKeyName, message, value, form, callback }: any) => {
    try {
      if (form.getFieldValue(otherKeyName) && !value) {
        throw new Error(message);
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="custom_document_number"
            label="Customs Entry Number"
            rules={[
              {
                validator: (rule, value, callback) => {
                  customValidator({
                    otherKeyName: 'custom_document_date',
                    message: 'Number Field Cannot be blank',
                    value,
                    form,
                    callback,
                  });
                },
              },
            ]}
          >
            <Input type={'text'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="custom_document_date"
            label="Customs Entry Date"
            rules={[
              {
                validator: (rule, value, callback) => {
                  customValidator({
                    otherKeyName: 'custom_document_number',
                    message: 'Date Field Cannot be blank',
                    value,
                    form,
                    callback,
                  });
                },
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      {shipment.shipment_type !== 'house' &&
        !shipment.shipment_booking_number &&
        (countCustomDetails === 0 || (countCustomDetails === 1 && api === 'update')) && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="port_of_loading" label="Port of Loading">
                  <GlobalSearch
                    doc_type="Global::Location"
                    searchProps={{ type: ['Seaport', 'Airport'] }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="port_of_discharge" label="Port of Discharge">
                  <GlobalSearch
                    doc_type="Global::Location"
                    searchProps={{ type: ['Seaport', 'Airport'] }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="cargo_description" label="Cargo Description">
                  <Input type={'text'} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Customs Entry File"
            name="data"
            required
            rules={[{ required: true, message: 'Please select a custom file to upload' }]}
          >
            <CustomsFileUploader fileType={fileType} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ReuploadUploadSbBeFileForm;
