import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { ShipmentValue } from 'operations/models/Shipment';
import { Column, ColumnGroup } from 'operations/models/Report';
import DeleteAction from './DeleteAction';
import { ErpNextCompanyConfigDataContext, erpnextApis, useErpNextConfig } from 'network';
import { addNewEstimateRow, getFinalUoms, quantityDisabled } from './helper';
import { CHARGE_TERMS } from 'operations/modules/shipment/constants';
import { FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG } from 'operations/constants';
import { CompanySearch } from 'common';
import { CHARGE_TYPE_ACCOUNT } from './constants';
import {
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';
import { GetEstimatesTableCardViewTitle, RenderExtraHeader } from './EstimatesTableCardViewTitle';
import EstimateActions from './EstimateActions';
import { useSession } from 'common';
import {
  PERMISSION_ESTIMATES_TOTAL,
  PERMISSION_FREIGHT_BUY,
  PERMISSION_FREIGHT_SELL,
  PERMISSION_REBATE_BUY,
  PERMISSION_REBATE_SELL,
  SHIPMENT_STATUS_BACK_TO_TOWN,
  SHIPMENT_STATUS_CANCELLED,
} from 'operations/modules/reports/constants';
import {
  Card,
  message,
  BaseTable,
  hasPermission,
  AccountsLinkRender,
  FloatEditor,
  EnumEditor,
  SearchDocTypeEditor,
  StringEditor,
} from '@shipmnts/pixel-hub';
import { ItemCellRenderer } from './ItemCellRenderer';
import { GridOptions } from '@ag-grid-community/core';
import { errorMessageHandlerGraphQLString } from 'common';
import { doc_status_map } from '../Accounts/components/renderers';
import { DocumentRenderer } from '../Accounts/components/renderers';
import { ActionRendererDetailReport } from 'operations';
import { getSequencedEstimates, handleEstimatesRowDrag } from 'common';
import { get, toLower } from 'lodash';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { ContractNumberRenderer } from './ContractNumberRenderer';

// interface ShipmentEstimateContextType {
//   configData: ERPNextCompanyConfigData
//   branches: BranchAccountWithCompany[]
// }
interface ShipmentEstimateLayoutWrapperType {
  shipment: ShipmentValue;
  displayMode?: string;
  showCurrencyTotals?: boolean;
}
export interface ERPNextCompanyConfigData {
  default_currency: string;

  currencies: {
    name: string;
  }[];
  unit_of_measurements: {
    name: string;
    must_be_whole_number: 0 | 1;
  }[];
  charges_settings: {
    reference_name: string;
    item: string;
  }[];
}

interface BranchAccountPartial {
  id: string;
  name: string;
  erp_cost_center_id?: string;
}

export interface BranchAccountWithCompany extends BranchAccountPartial {
  company?: string;
}

type FetchConfigDataType = () => Promise<{
  data?: ERPNextCompanyConfigData;
  error?: string;
}>;

type FetchCostCenterListType = () => Promise<{
  data?: BranchAccountWithCompany[];
  error?: string;
}>;

export interface ErpNextCompanyConfigDataContextType {
  getErpConfigData: FetchConfigDataType;
  getBranchList: FetchCostCenterListType;
}

interface ShipmentEstimateLayoutInternalType
  extends ShipmentEstimateLayoutWrapperType,
    ErpNextCompanyConfigDataContextType {}

interface ShipmentEstimateLayoutType extends ShipmentEstimateLayoutInternalType {
  estimates: ShipmentEstimateValue[];
}
interface CostCenter {
  name: string;
  company: string;
}

const mergeData = (
  branches: BranchAccountPartial[],
  cost_centers: CostCenter[]
): BranchAccountWithCompany[] => {
  return branches.map((branch) => {
    const matched = (cost_centers || []).find(
      (cost_center) => branch.erp_cost_center_id === cost_center.name
    );
    return matched ? { ...branch, company: matched.company } : branch;
  });
};

function ShipmentEstimateLayout(props: ShipmentEstimateLayoutType) {
  const { shipment, estimates, displayMode, showCurrencyTotals } = props;
  const gridRef = useRef<GridOptions>();
  const { configData, branches } = useShipmentEstimate();
  const { store } = useShipmentEstimateStore();
  const shipment_estimates = store.estimatesForShipment(shipment.id);
  const finalUoms = getFinalUoms(configData.unit_of_measurements, shipment, shipment_estimates);
  const sharedWithMultipleBranches = (shipment?.accessible_by_branches?.length || 0) > 1;
  const { permissions, company_account } = useSession();
  const erpnextContext = useErpNextConfig();
  const { erpnextConfigData } = erpnextContext;
  const isSpEnabled =
    erpnextConfigData?.create_interbranch_invoices_automatically ===
    'Interbranch sales invoice creation (SP)';
  const default_currency = company_account?.default_currency;
  const freightSellAllowed = hasPermission(permissions, {
    name: PERMISSION_FREIGHT_SELL,
    docType: 'NewAccounting::ShipmentEstimate',
  });
  const freightBuyAllowed = hasPermission(permissions, {
    name: PERMISSION_FREIGHT_BUY,
    docType: 'NewAccounting::ShipmentEstimate',
  });
  const estimateTotalsAllowed = hasPermission(permissions, {
    name: PERMISSION_ESTIMATES_TOTAL,
    docType: 'NewAccounting::ShipmentEstimate',
  });
  const rebateBuyAllowed = hasPermission(permissions, {
    name: PERMISSION_REBATE_BUY,
    docType: 'NewAccounting::ShipmentEstimate',
  });
  const rebateSellAllowed = hasPermission(permissions, {
    name: PERMISSION_REBATE_SELL,
    docType: 'NewAccounting::ShipmentEstimate',
  });

  const getExchangeRate = (
    currency: string,
    default_currency: string,
    type: 'sell' | 'buy',
    cb: (value: number) => void
  ) => {
    if (currency === default_currency) {
      cb(1);
    } else {
      const estimatesCount = store.estimateCountForCurrency(shipment.id, type, currency);
      // Fetch New exchange rate only if this currency is getting used for the first time... else it is already updated from cache
      if (estimatesCount === 1) {
        erpnextApis.fetchExchangeRate(currency, default_currency).then((r) => {
          if (r && r?.data?.message) {
            cb(r.data.message);
          }
        });
      }
    }
  };
  let defaultColumnDefs: (Column | ColumnGroup)[] = [
    {
      headerName: 'Item Name',
      field: 'item',
      width: 300,
      minWidth: 200,
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      suppressPaste: true,
      cellRenderer: 'ItemCellRenderer',
      cellRendererParams: {
        shipment: shipment,
        linkTarrifEditable:
          displayMode === 'edit' &&
          shipment.isMasterShipment() &&
          !shipment.isAccountingClosed() &&
          shipment.isAirShipment() &&
          shipment.isExportShipment() &&
          freightBuyAllowed &&
          freightSellAllowed &&
          rebateBuyAllowed &&
          rebateSellAllowed,
        showBillTags: true,
      },
      editable: (o) =>
        displayMode === 'edit' &&
        !o.node.isRowPinned() &&
        o.data.charge_type !== CHARGE_TYPE_ACCOUNT &&
        !o.data.item,
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: {
        CustomComponent: GlobalSearch,
        componentProps: {
          doc_type: 'OrderManagement::ProductOrderItem',
          extraProps: {
            freightType: shipment.freight_type || null,
            tradeType: shipment.trade_type || null,
            shouldExcludeFreightItems: !(freightSellAllowed || freightBuyAllowed),
            shouldExcludeRebateItems: !(rebateSellAllowed || rebateBuyAllowed),
          },
          showCreation: true,
        },
      },
      valueSetter: (params) => {
        if (!params.newValue) return false;
        const itemCurrency =
          params.newValue.default_currency !== null
            ? params.newValue.default_currency
            : params.data.sell_currency;
        params.data.updateItem({
          item: params.newValue.item_code,
          item_currency: itemCurrency,
          uom: params.newValue.stock_uom,
          quantity:
            finalUoms.find((finalUom) => finalUom.value === params.newValue.stock_uom)?.quantity ||
            1,
          tag: params.newValue.shipment_tags === '' ? null : params.newValue.shipment_tags,
          tax_percentage: params?.newValue?.tax_percentage,
          taxable: params?.newValue?.taxable,
          taxability: params?.newValue?.taxability,
          description: params.newValue.description,
          item_group: params.newValue.item_group,
        });
        params.data.updateSellCurrency(itemCurrency);
        return true;
      },
      onCellValueChanged: (params: any) => {
        getExchangeRate(params.data.sell_currency, default_currency, 'sell', (ex_rate: number) => {
          params.data.updateSellExchangeRate(ex_rate);
          params.data.recalculateSellTotal();
          params.api.refreshCells({
            force: true,
            columns: ['sell_exchange_rate', 'buy_exchange_rate'],
            rowNodes: [params.node],
          });
        });
      },
      lockVisible: true,
      rowDrag: true,
    },
  ];

  if (displayMode === 'view') {
    const viewColumnDefs: (Column | ColumnGroup)[] = [
      {
        headerName: 'Qty',
        field: 'quantity',
        columnType: 'Float',
        width: 80,
        aggFunc: undefined,
      },
      {
        headerName: 'Status',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_status',
        width: 130,
        columnType: 'String',
        cellRenderer: 'DocumentRenderer',
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => doc_status_map[params?.value].display_name,
        hide: true,
        lockVisible: true,
      },
      {
        headerName: 'Sell Rate (E)',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_rate',
        minWidth: 150,
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.sell_rate;
          return params.data.is_sell_view_allowed ? params.data.sell_rate : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 4,
                  currency_column: 'sell_currency',
                  showCurrency: true,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        aggFunc: undefined,
      },
      {
        headerName: 'Sell Total (E) (Cur)',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'total_sell_amount_in_currency',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 2,
                  currency: params.data.sell_currency,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        aggFunc: undefined,
        cellRenderer: undefined,
        width: 120,
        hide: !showCurrencyTotals,
      },
      {
        headerName: 'Sell Total (E)',
        field: 'total_sell_amount',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.total_sell_amount;
          return params.data.is_sell_view_allowed ? params.data.total_sell_amount : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Customer',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'customer_company',
        columnType: 'String',
        valueFormatter: (params) => params.value?.registered_name,
        minWidth: 100,
        maxWidth: 150,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params?.value?.registered_name,
        filterParams: {
          values: estimates.map((estimate) => estimate.customer_company?.registered_name),
          convertValuesToStrings: true,
        },
      },
      {
        headerName: 'Sell Voucher #',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_transaction_identifier',
        columnType: 'String',
        width: 110,
        cellRendererParams: {
          id_field: 'sell_transaction_identifier',
          doc_type_field: 'sell_transaction_doctype',
          doc_status_field: 'sell_status',
        },
        cellRenderer: 'AccountsLinkRender',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Sell Total (A)',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'total_sell_billed_amount',
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.total_sell_billed_amount;
          return params.data.is_sell_view_allowed ? params.data.total_sell_billed_amount : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Sell Remarks',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_remarks',
        columnType: 'String',
        width: 100,
        aggFunc: undefined,
      },
      {
        headerName: 'Ex. Rate',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_exchange_rate',
        columnType: 'Float',
        cellRendererParams: {
          precision: 5,
        },
        width: 95,
        minWidth: 95,
        aggFunc: undefined,
        hide: true,
      },
      {
        headerName: 'Terms',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_terms',
        columnType: 'String',
        width: 60,
        hide: true,
      },
      {
        headerName: 'Branch',
        field: 'sell_branch_id',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        columnType: 'String',
        valueGetter: (params) =>
          branches.find((branch: any) => branch.id === params.data?.sell_branch_id),
        valueFormatter: (params) => params.value?.name || '',
        width: 60,
        hide: true,
        lockVisible: false,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params.value?.name,
        filterParams: {
          convertValuesToStrings: true,
        },
      },
      {
        headerName: 'Sell Contract #',
        field: 'sell_contract_item_id',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        cellRenderer: 'ContractNumberRenderer',
        hide: estimates.every((estimate) => !estimate.sell_contract_item),
        valueGetter: (params) => params.data?.sell_contract_item?.contract?.contract_number,
        cellRendererParams: (params: any) => {
          return {
            contract: params.data?.sell_contract_item?.contract,
            freightType: shipment.freight_type,
          };
        },
      },
      {
        headerName: 'Supplier',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'supplier_company',
        columnType: 'String',
        valueFormatter: (params) => params.value?.registered_name,
        minWidth: 100,
        maxWidth: 150,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params?.value?.registered_name,
        filterParams: {
          convertValuesToStrings: true,
        },
      },
      {
        headerName: 'Buy Rate (E)',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_rate',
        columnType: 'Float',
        minWidth: 150,
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.buy_rate;
          return params.data.is_buy_view_allowed ? params.data.buy_rate : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 4,
                  currency_column: 'buy_currency',
                  showCurrency: true,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        aggFunc: undefined,
      },
      {
        headerName: 'Buy Total (E) (Cur)',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'total_buy_amount_in_currency',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 2,
                  currency: params.data.buy_currency,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        aggFunc: undefined,
        cellRenderer: undefined,
        width: 120,
        hide: true,
      },
      {
        headerName: 'Buy Total (E)',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'total_buy_amount',
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.total_buy_amount;
          return params.data.is_buy_view_allowed ? params.data.total_buy_amount : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Buy Voucher #',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_transaction_identifier',
        columnType: 'String',
        width: 110,
        cellRendererParams: {
          id_field: 'buy_transaction_identifier',
          doc_type_field: 'buy_transaction_doctype',
          doc_status_field: 'buy_status',
        },
        cellRenderer: 'AccountsLinkRender',
      },
      {
        headerName: 'Buy Total (A)',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'total_buy_billed_amount',
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.total_buy_billed_amount;
          return params.data.is_buy_view_allowed ? params.data.total_buy_billed_amount : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Ex. Rate',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_exchange_rate',
        columnType: 'Float',
        cellRendererParams: {
          precision: 5,
        },
        width: 95,
        minWidth: 95,
        hide: true,
      },
      {
        headerName: 'Terms',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_terms',
        columnType: 'String',
        width: 60,
        hide: true,
      },
      {
        headerName: 'Branch',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_branch_id',
        columnType: 'String',
        valueGetter: (params) =>
          branches.find((branch: any) => branch.id === params.data?.buy_branch_id),
        valueFormatter: (params) => params.value?.name || '',
        width: 100,
        hide: true,
        lockVisible: false,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params.value?.name,
        filterParams: {
          convertValuesToStrings: true,
        },
      },
      {
        headerName: 'Remarks',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'remarks',
        columnType: 'String',
        width: 100,
        aggFunc: undefined,
      },
      {
        headerName: 'Status',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_status',
        width: 130,
        cellRenderer: 'DocumentRenderer',
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => doc_status_map[params?.value].display_name,
        hide: true,
        lockVisible: true,
      },
      {
        headerName: 'Buy Contract #',
        field: 'buy_contract_item_id',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        cellRenderer: 'ContractNumberRenderer',
        hide: estimates.every((estimate) => !estimate?.buy_contract_item),
        valueGetter: (params) => params.data?.buy_contract_item?.contract?.contract_number,
        cellRendererParams: (params: any) => {
          return {
            contract: params.data?.buy_contract_item?.contract,
            freightType: shipment.freight_type,
          };
        },
      },

      {
        headerName: default_currency ? `Total Sell (${default_currency})` : 'Total Sell',
        field: 'sell_provisional_amount',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        hide: true,
        width: 90,
      },
      {
        headerName: default_currency ? `Total Buy (${default_currency})` : 'Total Buy',
        field: 'buy_provisional_amount',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        hide: true,
        width: 90,
      },
      {
        headerName: 'Prov. Margin (Cur)',
        field: 'provisional_margin_in_currency',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_sell_view_allowed && params.data.is_buy_view_allowed)
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                  currency:
                    params.data.sell_currency === params.data.buy_currency
                      ? params.data.sell_currency
                      : default_currency,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        aggFunc: undefined,
        hide: true,
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Actual Margin',
        field: 'actual_margin',
        hide: true,
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_sell_view_allowed && params.data.is_buy_view_allowed)
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Prov. Margin',
        field: 'provisional_margin',
        pinned: 'right',
        valueGetter: (params) =>
          (params.data.sell_provisional_amount || 0) - (params.data.buy_provisional_amount || 0),
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_sell_view_allowed && params.data.is_buy_view_allowed)
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
      },

      {
        headerName: 'Prov. Margin %',
        field: 'provisional_margin_percentage',
        pinned: 'right',
        valueGetter: (params) => {
          const marginValue =
            (params.data.sell_provisional_amount || 0) - (params.data.buy_provisional_amount || 0);
          if (params.data.sell_provisional_amount) {
            return (marginValue / params.data.sell_provisional_amount) * 100;
          }
          return 0;
        },
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_sell_view_allowed && params.data.is_buy_view_allowed)
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        hide: true,
        width: 70,
      },
    ];
    defaultColumnDefs = [...defaultColumnDefs, ...viewColumnDefs];
  }

  if (displayMode === 'edit') {
    const cols = [
      {
        headerName: 'Customer',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'customer_company',
        columnType: 'String',
        suppressPaste: true,
        valueFormatter: (params: any) => params.value?.registered_name,
        minWidth: 100,
        maxWidth: 150,
        editable: (o: any) => !o.node.isRowPinned() && o.data.is_sell_view_allowed,
        suppressKeyboardEvent: (params: any) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'SearchDocTypeEditor',
        cellEditorParams: {
          CustomComponent: CompanySearch,
          componentProps: {
            selectMode: 'single',
            searchProps: {
              is_customer: true,
            },
            showAddCompanyAction: true,
          },
          disabled: (params: any) => !params.data.is_sell_edit_allowed || shipment.id === 'new',
          hide: shipment.id === 'new',
        },
        valueSetter: (params: any) => {
          if (!params.newValue) return false;
          params.data.updateCustomer(params.newValue);
          return true;
        },
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params?.value?.registered_name,
        filterParams: {
          values: estimates.map((estimate) => estimate.customer_company?.registered_name),
          convertValuesToStrings: true,
        },
        lockVisible: true,
      },
    ];
    const editColumnDefs: (Column | ColumnGroup)[] = [
      {
        headerName: 'Basis',
        field: 'uom',
        columnType: 'String',
        width: 80,
        valueGetter: (params) =>
          params.data.equipment_name
            ? `${params.data.uom}.${params.data.equipment_name}.${params.data.equipment_type}`
            : params.data.uom,
        valueFormatter: (params) =>
          params.data.equipment_name
            ? `${params.data.uom} / ${params.data.equipment_name}`
            : params.data.uom,
        editable: (o) => !o.node.isRowPinned() && o.data.charge_type !== CHARGE_TYPE_ACCOUNT,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: finalUoms,
          disabled: (params: any) =>
            quantityDisabled(params) ||
            (!params.data.is_sell_edit_allowed && !params.data.is_buy_edit_allowed),
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          const validOption = finalUoms?.find(
            (uom) => toLower(uom.value) === toLower(params?.newValue)
          );
          if (!validOption) return false;
          const [uom, equipment_name, equipment_type] = validOption.value.split('.');
          return params.data.updateUom(
            uom,
            equipment_name,
            equipment_type,
            validOption?.quantity || 1
          );
        },
        lockVisible: true,
      },
      {
        headerName: 'Qty',
        field: 'quantity',
        columnType: 'Float',
        width: 80,
        aggFunc: undefined,
        editable: (o) => !o.node.isRowPinned() && o.data.charge_type !== CHARGE_TYPE_ACCOUNT,
        cellEditor: 'FloatEditor',
        cellEditorParams: {
          disabled: (params: any) =>
            quantityDisabled(params) ||
            (!params.data.is_sell_edit_allowed && !params.data.is_buy_edit_allowed),
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null || params.newValue === '')
            return false;
          return params.data.updateQuantity(params.newValue);
        },
        lockVisible: true,
      },

      {
        headerName: 'Status',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_status',
        colId: 'sell_status',
        width: 130,
        cellRenderer: 'DocumentRenderer',
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => doc_status_map[params?.value].display_name,
        hide: true,
        lockVisible: true,
      },
      {
        headerName: 'Cur.',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_currency',
        columnType: 'String',
        width: 60,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        editable: (o) => !o.node.isRowPinned() && o.data.is_sell_view_allowed,
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: configData.currencies.map((currency: any) => ({
            value: currency.name,
            label: currency.name,
          })),
          disabled: (params: any) => !params.data.is_sell_edit_allowed,
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null || params.newValue === '')
            return false;
          const validOption = configData?.currencies?.find(
            (curr: any) => toLower(curr.name) === toLower(params?.newValue)
          );
          if (!validOption) return false;
          return params.data.updateSellCurrency(validOption.name);
        },
        onCellValueChanged: (params: any) => {
          getExchangeRate(params.newValue, default_currency, 'sell', (ex_rate: number) => {
            params.data.updateSellExchangeRate(ex_rate);
            params.data.recalculateSellTotal();
            params.api.refreshCells({
              force: true,
              columns: [
                'sell_exchange_rate',
                'buy_exchange_rate',
                'sell_rate',
                'total_sell_amount',
                'total_buy_amount',
                'estimated_margin',
                'sell_provisional_amount',
                'buy_provisional_amount',
                'provisional_margin',
              ],
              rowNodes: [params.node],
            });
          });
        },
      },
      {
        headerName: 'Sell Rate (E)',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_rate',
        columnType: 'Float',
        minWidth: 150,
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.sell_rate;
          return params.data.is_sell_view_allowed ? params.data.sell_rate : 0;
        },
        aggFunc: undefined,
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 4,
                  currency: params.data.sell_currency,
                  showCurrency: true,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        editable: (o) => !o.node.isRowPinned() && o.data.is_sell_view_allowed,
        cellEditor: 'FloatEditor',
        cellEditorParams: {
          precision: 4,
          min: -Infinity,
          disabled: (params: any) => !params.data.is_sell_edit_allowed,
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null || params.newValue === '')
            return false;
          return params.data.updateSellRate(params.newValue);
        },
        lockVisible: true,
      },
      {
        headerName: 'Ex. Rate',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_exchange_rate',
        columnType: 'Float',
        cellRendererParams: {
          precision: 5,
        },
        width: 95,
        minWidth: 95,
        aggFunc: undefined,
        editable: (o) => !o.node.isRowPinned() && o.data.is_sell_view_allowed,
        cellEditor: 'FloatEditor',
        cellEditorParams: {
          precision: 5,
          min: 0.00001,
          max: 999,
          disabled: (params: any) => !params.data.is_sell_edit_allowed,
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null || params.newValue === '')
            return false;
          return params.data.updateSellExchangeRate(params.newValue);
        },
        lockVisible: true,
      },
      {
        headerName: 'Total Sell (E)',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'total_sell_amount',
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.total_sell_amount;
          return params.data.is_sell_view_allowed ? params.data.total_sell_amount : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 115,
      },
      {
        headerName: 'Terms',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_terms',
        columnType: 'String',
        width: 60,
        editable: (o) => !o.node.isRowPinned() && o.data.is_sell_view_allowed,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: CHARGE_TERMS.map((term) => ({ value: term.key, label: term.name })),
          disabled: (params: any) => !params.data.is_sell_edit_allowed,
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          const validOption = CHARGE_TERMS.find(
            (term) => toLower(term.key) === toLower(params?.newValue)
          );
          if (!validOption) return false;
          params.data.updateField('sell_terms', validOption.key);
          return true;
        },
      },
      ...((shipment.id === 'new' ? [] : cols) as any[]),
      {
        headerName: 'Branch',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_branch_id',
        columnType: 'String',
        suppressPaste: true,
        valueGetter: (params) =>
          branches.find((branch: any) => branch.id === params.data?.sell_branch_id),
        valueFormatter: (params) => params.value?.name || '',
        width: 60,
        editable: (o) => !o.node.isRowPinned() && o.data.is_sell_view_allowed,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'SearchDocTypeEditor',
        cellEditorParams: {
          CustomComponent: GlobalSearch,
          componentProps: {
            doc_type: 'Network::BranchAccount',
            extraProps: {
              options: shipment.accessible_by_branches,
            },
          },
          disabled: (params: any) =>
            !params.data.is_sell_edit_allowed || (!params.data.is_buy_edit_allowed && isSpEnabled),
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          const sell_branch = branches.find((branch: any) => branch.id === params.newValue.id);
          const buy_branch = branches.find(
            (branch: any) => branch.id === params.data.buy_branch_id
          );
          const companyMismatch =
            sell_branch && buy_branch && sell_branch.company !== buy_branch.company;
          if (companyMismatch) message.error('Buy and Sell Branch Can not be of Different Company');
          params.data.updateSellBranch(params.newValue.id, companyMismatch);
          if (isSpEnabled) params.data.updateBuyBranch(params.newValue.id, companyMismatch);
          return true;
        },
        hide: !sharedWithMultipleBranches,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params.value?.name,
        filterParams: {
          convertValuesToStrings: true,
        },
      },
      {
        headerName: 'Sell Voucher #',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_transaction_identifier',
        columnType: 'String',
        width: 110,
        cellRendererParams: {
          id_field: 'sell_transaction_identifier',
          doc_type_field: 'sell_transaction_doctype',
          doc_status_field: 'sell_status',
        },
        cellRenderer: 'AccountsLinkRender',
        hide: true,
      },
      {
        headerName: 'Sell Total (A)',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'total_sell_billed_amount',
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.total_sell_billed_amount;
          return params.data.is_sell_view_allowed ? params.data.total_sell_billed_amount : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
        hide: true,
      },
      {
        headerName: 'Sell Remarks',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_remarks',
        columnType: 'String',
        width: 100,
        aggFunc: undefined,
        editable: (o) => !o.node.isRowPinned() && o.data.is_sell_view_allowed,
        cellEditor: 'StringEditor',
        cellEditorParams: {
          disabled: (params: any) => !params.data.is_sell_edit_allowed,
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null) return false;
          params.data.updateField('sell_remarks', params.newValue.trim());
          return true;
        },
      },
      {
        headerName: default_currency ? `Total Sell (${default_currency})` : 'Total Sell',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        field: 'sell_provisional_amount',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_sell_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        hide: true,
        cellRenderer: undefined,
        width: 90,
      },
      {
        headerName: 'Sell Contract #',
        field: 'sell_contract_item_id',
        headerClass: 'sell-estimates',
        cellClass: 'sell-estimates',
        cellRenderer: 'ContractNumberRenderer',
        hide: estimates.every((estimate) => !estimate.sell_contract_item),
        valueGetter: (params) => params.data?.sell_contract_item?.contract?.contract_number,
        cellRendererParams: (params: any) => {
          return {
            isEdit: displayMode === 'edit' && !params.node.isRowPinned(),
            type: 'sell_contract_item',
            contract: params.data?.sell_contract_item?.contract,
            freightType: shipment.freight_type,
          };
        },
      },
      {
        headerName: 'Status',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_status',
        colId: 'buy_status',
        width: 130,
        cellRenderer: 'DocumentRenderer',
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => doc_status_map[params?.value].display_name,
        hide: true,
        lockVisible: true,
      },
      {
        headerName: 'Cur.',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_currency',
        columnType: 'String',
        width: 60,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        editable: (o) => !o.node.isRowPinned() && o.data.is_buy_view_allowed,
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: configData.currencies.map((currency: any) => ({
            value: currency.name,
            label: currency.name,
          })),
          disabled: (params: any) => !params.data.is_buy_edit_allowed,
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null || params.newValue === '')
            return false;
          const validOption = configData?.currencies?.find(
            (curr: any) => toLower(curr.name) === toLower(params?.newValue)
          );
          if (!validOption) return false;
          return params.data.updateBuyCurrency(validOption.name);
        },
        onCellValueChanged: (params: any) => {
          getExchangeRate(params.newValue, default_currency, 'buy', (ex_rate: number) => {
            params.data.updateBuyExchangeRate(ex_rate);
            params.data.recalculateBuyTotal();
            params.api.refreshCells({
              force: true,
              columns: [
                'sell_exchange_rate',
                'buy_exchange_rate',
                'buy_rate',
                'total_sell_amount',
                'total_buy_amount',
                'estimated_margin',
                'sell_provisional_amount',
                'buy_provisional_amount',
                'provisional_margin',
              ],
              rowNodes: [params.node],
            });
          });
        },
      },
      {
        headerName: 'Buy Rate (E)',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_rate',
        columnType: 'Float',
        minWidth: 150,
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.buy_rate;
          return params.data.is_buy_view_allowed ? params.data.buy_rate : 0;
        },
        aggFunc: undefined,
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 4,
                  currency: params.data.buy_currency,
                  showCurrency: true,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        editable: (o) => !o.node.isRowPinned() && o.data.is_buy_view_allowed,
        cellEditor: 'FloatEditor',
        cellEditorParams: {
          precision: 4,
          min: -Infinity,
          disabled: (params: any) => !params.data.is_buy_edit_allowed,
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null || params.newValue === '')
            return false;
          const newValue = parseFloat(params.newValue);
          if (Number.isNaN(newValue)) return false;
          return params.data.updateBuyRate(newValue);
        },
      },
      {
        headerName: 'Ex. Rate',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_exchange_rate',
        columnType: 'Float',
        cellRendererParams: {
          precision: 5,
        },
        width: 95,
        minWidth: 95,
        aggFunc: undefined,
        editable: (o) => !o.node.isRowPinned() && o.data.is_buy_view_allowed,
        cellEditor: 'FloatEditor',
        cellEditorParams: {
          precision: 5,
          min: 0.00001,
          max: 999,
          disabled: (params: any) => !params.data.is_buy_edit_allowed,
        },
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null || params.newValue === '')
            return false;
          return params.data.updateBuyExchangeRate(params.newValue);
        },
        lockVisible: true,
      },
      {
        headerName: 'Total Buy (E)',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'total_buy_amount',
        columnType: 'Float',
        valueGetter: (params) => {
          if (params.node?.isRowPinned()) return params.data.total_buy_amount;
          return params.data.is_buy_view_allowed ? params.data.total_buy_amount : 0;
        },
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 115,
      },
      {
        headerName: 'Terms',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_terms',
        columnType: 'String',
        width: 60,
        editable: (o) => !o.node.isRowPinned() && o.data.is_buy_view_allowed,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'EnumEditor',
        cellEditorParams: {
          options: CHARGE_TERMS.map((term) => ({ value: term.key, label: term.name })),
          disabled: (params: any) => !params.data.is_buy_edit_allowed,
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          const validOption = CHARGE_TERMS.find(
            (term) => toLower(term.key) === toLower(params?.newValue)
          );
          if (!validOption) return false;
          params.data.updateField('buy_terms', validOption.key);
          return true;
        },
      },
      {
        headerName: 'Branch',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_branch_id',
        columnType: 'String',
        suppressPaste: true,
        valueGetter: (params) =>
          branches.find((branch: any) => branch.id === params.data?.buy_branch_id),
        valueFormatter: (params) => params.value?.name || '',
        width: 100,
        hide: !sharedWithMultipleBranches,
        editable: (o) => !o.node.isRowPinned() && o.data.is_buy_view_allowed,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'SearchDocTypeEditor',
        cellEditorParams: {
          CustomComponent: GlobalSearch,
          componentProps: {
            doc_type: 'Network::BranchAccount',
            extraProps: {
              options: shipment.accessible_by_branches,
            },
          },
          disabled: (params: any) =>
            !params.data.is_buy_edit_allowed ||
            (!params.data.is_sell_edit_allowed && isSpEnabled) ||
            ([FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG].includes(params.data.tag) &&
              shipment.isStockAllocated() &&
              !isSpEnabled),
        },
        valueSetter: (params) => {
          if (!params.newValue) return false;
          const buy_branch = branches.find((branch: any) => branch.id === params.newValue.id);
          const sell_branch = branches.find(
            (branch: any) => branch.id === params.data.sell_branch_id
          );
          const companyMismatch =
            sell_branch && buy_branch && sell_branch.company !== buy_branch.company;
          if (companyMismatch) message.error('Buy and Sell Branch Can not be of Different Company');
          params.data.updateBuyBranch(params.newValue.id, companyMismatch);
          if (isSpEnabled) params.data.updateSellBranch(params.newValue.id, companyMismatch);
          return true;
        },
        lockVisible: false,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params.value?.name,
        filterParams: {
          convertValuesToStrings: true,
        },
      },
      {
        headerName: 'Supplier',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'supplier_company',
        columnType: 'String',
        suppressPaste: true,
        valueFormatter: (params) => params.value?.registered_name,
        minWidth: 100,
        maxWidth: 150,
        editable: (o) => !o.node.isRowPinned() && o.data.is_buy_view_allowed,
        suppressKeyboardEvent: (params) => {
          return params.event.key === 'Enter' && params.editing;
        },
        cellEditor: 'SearchDocTypeEditor',
        cellEditorParams: {
          CustomComponent: CompanySearch,
          componentProps: {
            selectMode: 'single',
            searchProps: {
              is_vendor: true,
            },
            showAddCompanyAction: true,
            style: {
              width: '100%',
            },
          },
          disabled: (params: any) =>
            !params.data.is_buy_edit_allowed ||
            ([FREIGHT_CHARGE_TAG, DUE_CARRIER_TAG].includes(params.data.tag) &&
              shipment.isStockAllocated() &&
              !isSpEnabled),
        },
        valueSetter: (params) => {
          // if (!params.newValue) return false;
          params.data.updateSupplier(params.newValue);
          return true;
        },
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params?.value?.registered_name,
        filterParams: {
          convertValuesToStrings: true,
        },
      },
      {
        headerName: 'Remarks',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'remarks',
        columnType: 'String',
        width: 100,
        aggFunc: undefined,
        editable: (o) => !o.node.isRowPinned() && o.data.is_buy_view_allowed,
        cellEditor: 'StringEditor',
        valueSetter: (params) => {
          if (params.newValue === undefined || params.newValue === null) return false;
          params.data.updateField('remarks', params.newValue);
          return true;
        },
      },
      {
        headerName: default_currency ? `Total Buy (${default_currency})` : 'Total Buy',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        field: 'buy_provisional_amount',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data.is_buy_view_allowed
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 90,
        hide: true,
      },
      {
        headerName: 'Buy Contract #',
        field: 'buy_contract_item_id',
        headerClass: 'buy-estimates',
        cellClass: 'buy-estimates',
        cellRenderer: 'ContractNumberRenderer',
        hide: estimates.every((estimate) => !estimate.buy_contract_item),
        valueGetter: (params) => params.data?.buy_contract_item?.contract?.contract_number,
        cellRendererParams: (params: any) => {
          return {
            isEdit: displayMode === 'edit' && !params.node.isRowPinned(),
            type: 'buy_contract_item',
            contract: params.data?.buy_contract_item?.contract,
            freightType: shipment.freight_type,
          };
        },
      },

      {
        headerName: 'Margin (Cur) (E)',
        field: 'estimated_margin_in_currency',
        columnType: 'Float',

        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_buy_view_allowed && params.data.is_sell_view_allowed)
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                  currency:
                    params.data.sell_currency === params.data.buy_currency
                      ? params.data.sell_currency
                      : default_currency,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        aggFunc: undefined,
        cellRenderer: undefined,
        width: 120,
        hide: true,
      },
      {
        headerName: 'Margin (E)',
        field: 'estimated_margin',
        pinned: 'right',
        valueGetter: (params) =>
          (params.getValue('total_sell_amount') || 0) - (params.getValue('total_buy_amount') || 0),
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_buy_view_allowed && params.data.is_sell_view_allowed)
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Prov. Margin (Cur)',
        field: 'provisional_margin_in_currency',
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_sell_view_allowed && params.data.is_buy_view_allowed)
            ? {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                  currency:
                    params.data.sell_currency === params.data.buy_currency
                      ? params.data.sell_currency
                      : default_currency,
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        aggFunc: undefined,
        hide: true,
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Prov. Margin',
        field: 'provisional_margin',
        pinned: 'right',
        valueGetter: (params) =>
          (params.data.sell_provisional_amount || 0) - (params.data.buy_provisional_amount || 0),
        columnType: 'Float',
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() ||
            (params.data.is_sell_view_allowed && params.data.is_buy_view_allowed)
            ? {
                component: 'NumberTypeRenderer',
                params: {
                  precision: 2,
                  negative_class: 'error-color',
                },
              }
            : {
                component: 'NotPermittedRenderer',
              };
        },
        hide: true,
        cellRenderer: undefined,
        width: 120,
      },
      {
        headerName: 'Prov. Margin %',
        field: 'provisional_margin_percentage',
        pinned: 'right',
        valueGetter: (params) => {
          const marginValue =
            (params.data.sell_provisional_amount || 0) - (params.data.buy_provisional_amount || 0);
          if (params.data.sell_provisional_amount) {
            return (marginValue / params.data.sell_provisional_amount) * 100;
          }
          return 0;
        },
        hide: true,
      },

      {
        headerName: '',
        field: 'actions',
        cellRendererParams: {
          disabled: (params: any) =>
            !params.data.is_sell_edit_allowed ||
            !params.data.is_buy_edit_allowed ||
            params.data.buy_transaction_identifier ||
            params.data.sell_transaction_identifier,
        },
        cellRenderer: 'DeleteAction',
        width: 50,
        pinned: 'right',
        lockVisible: true,
      },
    ];
    defaultColumnDefs = [...defaultColumnDefs, ...editColumnDefs];
  }

  const components = {
    FloatEditor,
    EnumEditor,
    SearchDocTypeEditor,
    StringEditor,
    DeleteAction,
    ItemCellRenderer,
    AccountsLinkRender,
    DocumentRenderer,
    ActionRendererDetailReport,
    ContractNumberRenderer,
  };
  return (
    <Card
      id={`table_${shipment.id}`}
      key={shipment.id}
      bordered={false}
      title={
        shipment.id === 'new'
          ? undefined
          : GetEstimatesTableCardViewTitle(shipment, () => {
              gridRef?.current?.api?.redrawRows();
            })
      }
      extra={
        shipment.id === 'new' ? undefined : (
          <RenderExtraHeader shipment={shipment} areEstimatesEmpty={estimates.length === 0} />
        )
      }
      style={{
        position: 'relative',
        marginBottom: '20px',
      }}
      styles={{
        body: {
          padding: '0 0 24px 0',
        },
        header: {
          background:
            shipment?.status === SHIPMENT_STATUS_CANCELLED ||
            shipment?.status === SHIPMENT_STATUS_BACK_TO_TOWN
              ? 'linear-gradient(transparent 25%, #ff979370 25%, #ff979370 75%, transparent 75%)'
              : '',
        },
      }}
    >
      <div>
        <BaseTable
          reportName={getReportName(shipment.id, displayMode)}
          skipCacheColumns={['sell_branch_id', 'buy_branch_id']}
          gridRef={gridRef}
          columns={defaultColumnDefs}
          key={displayMode}
          rowData={getSequencedEstimates(estimates)}
          height={shipment.id === 'new' ? '40vh' : '50vh'}
          hideTotals={!estimateTotalsAllowed}
          reportConfig={{
            components,
            enableCellChangeFlash: true,
            animateRows: true,
            singleClickEdit: true,
            stopEditingWhenCellsLoseFocus: true,
            onCellFocused: (event: any) => {
              if (!event?.column?.colId) return;
              Array.from(document.querySelectorAll('.col-bg-cell-focus')).forEach((el) =>
                el.classList.remove('col-bg-cell-focus')
              );
              Array.from(
                document.querySelectorAll(
                  `.ag-header-cell.ag-focus-managed[col-id="${event?.column?.colId}"]`
                )
              ).forEach((el) => el.classList.add('col-bg-cell-focus'));

              Array.from(
                document.querySelectorAll(
                  `.ag-cell-focus, .ag-theme-material .ant-select-focused .ant-select-selector, .ant-select-selector:focus, .ant-select-selector:active,.ant-select-open.ant-select-selector, .ant-select-selector-search .ant-select-selector-search:focus, .ag-has-focus .ag-cell-inline-editing input`
                )
              ).forEach((el) => el.classList.add('col-bg-cell-focus'));
            },
            tabToNextCell: (params: any) => {
              const col = gridRef.current?.columnApi?.getColumn('item');
              if (
                displayMode === 'edit' &&
                params.editing &&
                !params.backwards &&
                !params.nextCellPosition &&
                col
              ) {
                addNewEstimateRow(store, shipment, gridRef, company_account);
                return {
                  rowIndex: -1,
                  column: col,
                  rowPinned: null,
                };
              } else return params.nextCellPosition;
            },
            defaultColDef: {
              suppressMovable: true,
            },
            rowDragManaged: true,
            suppressRowDrag: displayMode !== 'edit',
            suppressMoveWhenRowDragging: true,
            onRowDragEnd: (event: any) => {
              handleEstimatesRowDrag(event, gridRef);
            },
          }}
        />
      </div>
      {displayMode === 'edit' && (
        <div>
          <EstimateActions gridRef={gridRef} shipment={shipment} />
        </div>
      )}
    </Card>
  );
}

const ShipmentEstimateContext = createContext<null | any>(null);

export function useShipmentEstimate() {
  const contextValue = useContext(ShipmentEstimateContext);
  if (contextValue === null) {
    throw new Error('contextValue cannot be null, please add a context provider');
  }
  return contextValue;
}

const ShipmentEstimateLayoutInternal = observer(function ShipmentEstimateLayoutInternal(
  props: ShipmentEstimateLayoutInternalType
) {
  const [loading, setLoading] = useState(true);
  const [configData, setConfigData] = useState<ERPNextCompanyConfigData | undefined>(undefined);
  const [branches, setBranches] = useState<BranchAccountWithCompany[] | undefined>(undefined);
  const { getErpConfigData, getBranchList, shipment, displayMode, showCurrencyTotals } = props;
  const { store } = useShipmentEstimateStore();

  useEffect(() => {
    async function fetchErpData() {
      const promises: Promise<any>[] = [getErpConfigData()];
      promises.push(getBranchList());
      const result = await Promise.all(promises);
      if (result[0].error) message.error(errorMessageHandlerGraphQLString(result[0].error));
      if (result[1].error) message.error(errorMessageHandlerGraphQLString(result[1].error));
      const cost_centers = get(result[0].data, 'cost_centers');
      setConfigData(result[0].data);
      setBranches(mergeData(result[1].data, cost_centers));
      setLoading(false);
    }
    fetchErpData();
  }, [getErpConfigData, getBranchList]);

  if (loading) return <Card loading={loading} />;

  if (!configData) return <div>Error while loading config data.</div>;
  if (!branches) return <div>Error while loading cost centers data.</div>;

  return (
    <ShipmentEstimateContext.Provider value={{ configData, branches }}>
      <ShipmentEstimateLayout
        shipment={shipment}
        getErpConfigData={getErpConfigData}
        displayMode={displayMode}
        showCurrencyTotals={showCurrencyTotals}
        getBranchList={getBranchList}
        estimates={store.estimates.filter((estimate) => estimate.shipment_id === shipment.id)}
      />
      {(shipment.house_shipments || []).map((house_shipment) => (
        <ShipmentEstimateLayout
          key={house_shipment.id}
          shipment={house_shipment}
          displayMode={displayMode}
          showCurrencyTotals={showCurrencyTotals}
          getErpConfigData={getErpConfigData}
          getBranchList={getBranchList}
          estimates={store.estimates.filter(
            (estimate) => estimate.shipment_id === house_shipment.id
          )}
        />
      ))}
    </ShipmentEstimateContext.Provider>
  );
});

export default function ShipmentEstimateLayoutWrapper(props: any) {
  return (
    <ErpNextCompanyConfigDataContext.Consumer>
      {({ getErpConfigData, getBranchList }) => {
        return (
          <ShipmentEstimateLayoutInternal
            {...props}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getErpConfigData={getErpConfigData}
            getBranchList={getBranchList}
          />
        );
      }}
    </ErpNextCompanyConfigDataContext.Consumer>
  );
}

function getReportName(id?: string, displayMode?: string) {
  if (id === 'new') return 'estimate_2_create';
  return displayMode === 'edit' ? 'estimate_2_edit' : 'estimate_2_view';
}
