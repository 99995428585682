import React, { useEffect, useRef } from 'react';
import { BaseTable, hasPermission } from '@shipmnts/pixel-hub';
import { GridOptions } from '@ag-grid-community/core';
import { ShipmentEstimatesTotal, useSession } from 'common';
import { PERMISSION_ESTIMATES_TOTAL } from 'sales_hub/utils/constants';
import { ItemCellRenderer } from 'sales_hub/components/InquiryOption/ItemCellRenderer';
import { Column, ColumnGroup } from 'sales_hub/models/Report';
import {
  ShipmentEstimateStore,
  ShipmentEstimateStoreContextProvider,
  ShipmentEstimateStoreValue,
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'sales_hub/models/ShipmentEstimate';

export function RateTable(props: {
  estimates?: ShipmentEstimateValue[];
  defaultCurrency?: string;
}) {
  const { estimates = [], defaultCurrency } = props;
  const { permissions, company_account } = useSession();

  const gridRef = useRef<GridOptions>();
  const store = useRef<ShipmentEstimateStoreValue>(
    ShipmentEstimateStore.create(
      { estimates, sync_state: 'saved' },
      {
        permissions,
      }
    )
  );
  const estimateTotalsAllowed = hasPermission(permissions, {
    name: PERMISSION_ESTIMATES_TOTAL,
    docType: 'NewAccounting::ShipmentEstimate',
  });

  const isSellViewAllowed = hasPermission(permissions, {
    name: 'sell',
    docType: 'NewAccounting::ShipmentEstimate',
  });

  const isBuyViewAllowed = hasPermission(permissions, {
    name: 'buy',
    docType: 'NewAccounting::ShipmentEstimate',
  });

  useEffect(() => {
    setTimeout(() => {
      gridRef?.current?.columnApi?.autoSizeAllColumns();
    }, 500);
  }, [gridRef]);

  const defaultColumnDefs: (Column | ColumnGroup)[] = [
    {
      headerName: 'Item Name',
      field: 'item',
      width: 300,
      minWidth: 200,
      pinned: 'left',
      cellRenderer: 'ItemCellRenderer',
    },
    {
      headerName: 'Quantity',
      children: [
        {
          headerName: 'Basis',
          field: 'uom',
          columnType: 'String',
          width: 80,
          valueGetter: (params) =>
            params.data.equipment_name
              ? `${params.data.uom}.${params.data.equipment_name}.${params.data.equipment_type}`
              : params.data.uom,
          valueFormatter: (params) =>
            params.data.equipment_name
              ? `${params.data.uom} / ${params.data.equipment_name}`
              : params.data.uom,
        },
        {
          headerName: 'Qty',
          field: 'quantity',
          columnType: 'Float',
          width: 80,
          aggFunc: undefined,
        },
      ],
    },
    {
      headerName: 'Buy Estimates',
      headerClass: 'buy-estimates',
      children: [
        {
          headerName: 'Cur.',
          headerClass: 'buy-estimates',
          cellClass: 'buy-estimates',
          field: 'buy_currency',
          columnType: 'String',
          width: 60,
          suppressKeyboardEvent: (params) => {
            return params.event.key === 'Enter' && params.editing;
          },
        },
        {
          headerName: 'Buy Rate',
          headerClass: 'buy-estimates',
          cellClass: 'buy-estimates',
          field: 'buy_rate',
          columnType: 'Float',
          minWidth: 150,
          valueGetter: (params) => {
            return isBuyViewAllowed ? params.data.buy_rate : 0;
          },
          aggFunc: undefined,
          cellRendererSelector: (params) => {
            if (isBuyViewAllowed) {
              return {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 4,
                  currency: params.data.buy_currency,
                  showCurrency: true,
                },
              };
            } else {
              return {
                component: 'NotPermittedRenderer',
              };
            }
          },
          cellRenderer: undefined,
        },
        {
          headerName: 'Buy Total',
          headerClass: 'buy-estimates',
          cellClass: 'buy-estimates',
          field: 'total_buy_amount_in_currency',
          columnType: 'Float',
          cellRendererSelector: (params) => {
            if (isBuyViewAllowed) {
              return {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 2,
                  currency: params.data.buy_currency,
                },
              };
            } else {
              return {
                component: 'NotPermittedRenderer',
              };
            }
          },
          aggFunc: undefined,
          cellRenderer: undefined,
          width: 120,
        },
        {
          headerName: 'Ex. Rate',
          headerClass: 'buy-estimates',
          cellClass: 'buy-estimates',
          field: 'buy_exchange_rate',
          columnType: 'Float',
          cellRendererParams: {
            precision: 5,
          },
          width: 95,
          minWidth: 95,
          aggFunc: undefined,
        },
        {
          headerName: 'Terms',
          headerClass: 'buy-estimates',
          cellClass: 'buy-estimates',
          field: 'buy_terms',
          columnType: 'String',
          width: 60,
        },
        {
          headerName: 'Supplier',
          headerClass: 'buy-estimates',
          cellClass: 'buy-estimates',
          field: 'supplier_company',
          columnType: 'String',
          suppressPaste: true,
          valueFormatter: (params) => params.value?.registered_name,
          minWidth: 100,
          width: 100,
          // maxWidth: 150,
          filter: 'agSetColumnFilter',
          keyCreator: (params: any) => params?.data?.supplier_company?.registered_name,
          filterParams: {
            convertValuesToStrings: true,
          },
        },
      ],
    },
    {
      headerName: 'Sell Estimates',
      headerClass: 'sell-estimates',
      children: [
        {
          headerName: 'Sell Cur.',
          headerClass: 'sell-estimates',
          cellClass: 'sell-estimates',
          field: 'sell_currency',
          columnType: 'String',
          width: 60,
        },
        {
          headerName: 'Sell Rate',
          headerClass: 'sell-estimates',
          cellClass: 'sell-estimates',
          field: 'sell_rate',
          columnType: 'Float',
          minWidth: 150,
          width: 100,
          valueGetter: (params) => {
            return isSellViewAllowed ? params.data.sell_rate : 0;
          },
          aggFunc: undefined,
          cellRendererSelector: (params) => {
            console.log('params', isSellViewAllowed);
            if (isSellViewAllowed) {
              return {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 4,
                  currency: params.data.sell_currency,
                  showCurrency: true,
                },
              };
            } else {
              return {
                component: 'NotPermittedRenderer',
              };
            }
          },
          cellRenderer: undefined,
        },
        {
          headerName: 'Sell Total',
          headerClass: 'sell-estimates',
          cellClass: 'sell-estimates',
          field: 'total_sell_amount_in_currency',
          columnType: 'Float',
          cellRendererSelector: (params) => {
            if (isSellViewAllowed) {
              return {
                component: 'CurrencyTypeRenderer',
                params: {
                  precision: 2,
                  currency: params.data.sell_currency,
                },
              };
            } else {
              return {
                component: 'NotPermittedRenderer',
              };
            }
          },
          aggFunc: undefined,
          cellRenderer: undefined,
          width: 120,
        },
        {
          headerName: 'Ex. Rate',
          headerClass: 'sell-estimates',
          cellClass: 'sell-estimates',
          field: 'sell_exchange_rate',
          columnType: 'Float',
          cellRendererParams: {
            precision: 5,
          },
          width: 95,
          minWidth: 95,
          aggFunc: undefined,
        },
        {
          headerName: 'Terms',
          headerClass: 'sell-estimates',
          cellClass: 'sell-estimates',
          field: 'sell_terms',
          columnType: 'String',
          width: 60,
        },
        {
          headerName: 'Remarks',
          headerClass: 'sell-estimates',
          cellClass: 'sell-estimates',
          field: 'remarks',
          columnType: 'String',
          width: 100,
          aggFunc: undefined,
        },
      ],
    },
    {
      headerName: 'Margin',
      children: [
        {
          headerName: 'Margin',
          field: 'estimated_margin_in_currency',
          columnType: 'Float',
          cellRendererSelector: (params) => {
            return isBuyViewAllowed && isSellViewAllowed
              ? {
                  component: 'CurrencyTypeRenderer',
                  params: {
                    precision: 2,
                    negative_class: 'error-color',
                    currency:
                      params.data.sell_currency === params.data.buy_currency
                        ? params.data.sell_currency
                        : defaultCurrency || company_account?.default_currency,
                  },
                }
              : {
                  component: 'NotPermittedRenderer',
                };
          },
          aggFunc: undefined,
          cellRenderer: undefined,
          width: 120,
          hide: !estimateTotalsAllowed,
        },
      ],
    },
  ];

  return (
    <ShipmentEstimateStoreContextProvider
      value={{
        store: store.current,
      }}
    >
      <div style={{ marginBottom: '1em' }}>
        <ShipmentEstimatesTotal useStore={useShipmentEstimateStore} />
      </div>
      <BaseTable
        reportName="charges_layout"
        gridRef={gridRef}
        columns={defaultColumnDefs}
        rowData={estimates}
        height={estimates.length * 34 + 120}
        hideTotals={!estimateTotalsAllowed}
        reportConfig={{
          components: { ItemCellRenderer },
          enableCellChangeFlash: true,
          animateRows: true,
          onCellFocused: (event: any) => {
            if (!event?.column?.colId) return;
            Array.from(document.querySelectorAll('.col-bg-cell-focus')).forEach((el) =>
              el.classList.remove('col-bg-cell-focus')
            );
            Array.from(
              document.querySelectorAll(
                `.ag-header-cell.ag-focus-managed[col-id="${event.column.colId}"]`
              )
            ).forEach((el) => el.classList.add('col-bg-cell-focus'));

            Array.from(
              document.querySelectorAll(
                '.ag-cell-focus, .ag-theme-material .ant-select-focused .ant-select-selector, .ant-select-selector:focus, .ant-select-selector:active,.ant-select-open.ant-select-selector, .ant-select-selector-search .ant-select-selector-search:focus, .ag-has-focus .ag-cell-inline-editing input'
              )
            ).forEach((el) => el.classList.add('col-bg-cell-focus'));
          },
          tabToNextCell: (params: any) => {
            return params.nextCellPosition;
          },
          defaultColDef: {
            suppressMovable: true,
          },
        }}
      />
    </ShipmentEstimateStoreContextProvider>
  );
}
