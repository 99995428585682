import React from 'react';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { get as _get } from 'lodash';
import { collapsibleCard } from '../../Common/common';
import { CompassOutlined } from '@shipmnts/pixel-hub';
import {
  MOVEMENT_MODE_AIRPORT_DELIVERY,
  MOVEMENT_MODE_AIRPORT_PICKUP,
  MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
  MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP,
} from 'operations/modules/shipment/constants';
import { utcStringToFormatedString } from '@shipmnts/pixel-hub';

export default function LinkShipmentDetails() {
  const { shipment } = useShipmentDetail();
  const linkShipmentDetails: { [key: string]: string | null | undefined } = {};
  const linked_shipment_json = _get(shipment, 'link_shipment_json');
  const movementMode = shipment?.movement_mode || '';
  const isAirMovementMode = [MOVEMENT_MODE_AIRPORT_DELIVERY, MOVEMENT_MODE_AIRPORT_PICKUP].includes(
    movementMode || ''
  );
  const isOceanMovementMode = [
    MOVEMENT_MODE_CONTAINER_DESTUFFING,
    MOVEMENT_MODE_CONTAINER_STUFFING,
  ].includes(movementMode || '');

  const isDoorMovementMode = [
    MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP,
    MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
  ].includes(movementMode);

  const carrierName = linked_shipment_json?.carrier?.name;
  linkShipmentDetails['Carrier'] = carrierName;
  if (
    [
      MOVEMENT_MODE_CONTAINER_DESTUFFING,
      MOVEMENT_MODE_CONTAINER_STUFFING,
      MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
    ].includes(movementMode)
  )
    linkShipmentDetails['Carrier Ref #'] = linked_shipment_json?.booking_number || '';

  if (isOceanMovementMode || isDoorMovementMode)
    linkShipmentDetails['Vessel'] = linked_shipment_json?.ocean_vessel?.name || '';

  linkShipmentDetails[isAirMovementMode ? 'Flight Number' : 'Voyage #'] =
    linked_shipment_json?.voyage_number;

  if (isOceanMovementMode)
    linkShipmentDetails['Carrier Validity Date'] = utcStringToFormatedString(
      linked_shipment_json?.valid_till_date
    );

  if ([MOVEMENT_MODE_CONTAINER_DESTUFFING].includes(movementMode)) {
    linkShipmentDetails['LFD Port'] = utcStringToFormatedString(linked_shipment_json?.lfd_at_pod);
    linkShipmentDetails['LFD Empty Return'] = utcStringToFormatedString(
      linked_shipment_json?.lfd_at_empty_return
    );
  }
  if (
    [
      MOVEMENT_MODE_CONTAINER_DESTUFFING,
      MOVEMENT_MODE_AIRPORT_PICKUP,
      MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
    ].includes(movementMode)
  )
    linkShipmentDetails['ETA at Port'] = utcStringToFormatedString(
      linked_shipment_json?.estimated_time_of_arrival
    );

  if (
    [
      MOVEMENT_MODE_CONTAINER_STUFFING,
      MOVEMENT_MODE_AIRPORT_DELIVERY,
      MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
    ].includes(movementMode)
  )
    linkShipmentDetails['ETD at Port'] = utcStringToFormatedString(
      linked_shipment_json?.estimated_time_of_departure
    );

  if ([MOVEMENT_MODE_CONTAINER_STUFFING].includes(movementMode))
    linkShipmentDetails['Gate Close Cutof'] = utcStringToFormatedString(
      linked_shipment_json?.gate_close_date
    );

  const updatedLinkShipmentDetails: { [key: string]: string } = {};
  Object.keys(linkShipmentDetails).forEach((label: string) => {
    let value = linkShipmentDetails[label];
    if (!value) value = '-';
    updatedLinkShipmentDetails[label] = value;
  });

  return (
    <div>
      {collapsibleCard({
        icon: <CompassOutlined />,
        header: 'Link Shipment Details',
        data: updatedLinkShipmentDetails,
      })}
    </div>
  );
}
