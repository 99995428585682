import React, { useMemo, useState } from 'react';
import {
  Row,
  Col,
  Radio,
  Select,
  Form,
  TextArea,
  DatePicker,
  AddressCompanySearch,
  Input,
} from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import {
  ROW_GUTTER,
  DocumentType,
  DOCUMENT_TYPE_HOUSE,
  FreightType,
  TradeType,
  BL_TYPE_ARRAY,
  BL_TYPE_EXPRESS,
  CHARGE_TERMS,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
  ShipmentType,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_HOUSE,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_IMPORT,
  BL_TYPE_E_BL,
  RELEASE_TYPE_ARRAY,
  BUSINESS_TYPE_SUBAGENT,
  DOCUMENT_TYPE_MASTER,
  TRADE_TYPE_EXPORT,
  getConsigneeOptionsBackToBackAndConsol,
  getConsigneeOptionsDirect,
  getShipperOptions,
  SHIPMENT_TYPE_CONSOL,
  getConsigneeOptionsConsol,
  getShipperOptionsForConsol,
} from 'operations/modules/shipment/constants';
import { LABEL_JSON } from 'operations/modules/shipment/helpers/labelKeyHelper';
import ShipmentNumber from './ShipmentNumber';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { CarrierValue } from 'operations/models/Carrier';
import {
  ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE,
  ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE_IMPORT,
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  document_status_to_display_name_mapping,
} from 'operations/modules/reports/constants';
import {
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
} from 'operations/modules/reports/constants';
// import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
interface ShipmentDocumentDetailsProps {
  form: FormInstance;
  shipmentType: ShipmentType;
  documentType: DocumentType;
  freightType: FreightType;
  tradeType: TradeType;
  carrier?: CarrierValue;
  disableShipmentNumber?: boolean;
  disableDocumentStatus?: boolean;
}

const getConsigneeOptionsWithName = (
  consignee_name: string,
  destination_agent_name: string,
  shipmentType: string,
  documentType: string,
  consolidation_type?: string
) => {
  let options;

  if (shipmentType === SHIPMENT_TYPE_DIRECT || documentType === DOCUMENT_TYPE_HOUSE) {
    options = getConsigneeOptionsDirect(consignee_name);
  } else if (shipmentType === SHIPMENT_TYPE_CONSOL && consolidation_type) {
    options = getConsigneeOptionsConsol(consignee_name, destination_agent_name, consolidation_type);
  } else {
    options = getConsigneeOptionsBackToBackAndConsol(consignee_name, destination_agent_name);
  }

  return options;
};

const getShipperOptionsWithName = (
  shipper_name: string,
  origin_agent_name: string,
  shipmentType: string,
  documentType: string,
  consolidation_type?: string
) => {
  if (
    shipmentType === SHIPMENT_TYPE_CONSOL &&
    consolidation_type &&
    documentType === DOCUMENT_TYPE_MASTER
  ) {
    return getShipperOptionsForConsol(shipper_name, origin_agent_name, consolidation_type);
  }
  return getShipperOptions(shipper_name, origin_agent_name);
};
// const isSameParty = (party1?: PartyValue | null, party2?: PartyValue | null) => {
//   if (party1 && party2) return party1?.party_company?.id === party2?.party_company?.id;
//   else return false;
// };

const ShipmentDocumentDetailsNew = React.memo(function ShipmentDocumentDetails(
  props: ShipmentDocumentDetailsProps
): JSX.Element {
  const {
    shipmentType,
    documentType,
    freightType,
    tradeType,
    form,
    disableShipmentNumber = false,
    disableDocumentStatus = false,
  } = props;
  const carrier = Form.useWatch('carrier');
  const consginee_to_order_keys = [CONSIGNEE_TYPE_TO_ORDER.key, CONSIGNEE_TYPE_TO_ORDER_BANK.key];
  const [documentStatus, setDocumentStatus] = useState('');
  const services = Form.useWatch('services');
  const originClearance = services?.origin?.clearance;
  const destinationClearance = services?.destination?.clearance;
  const freightForwarding = services?.freight_forwarding;
  const isClearanceByServices = !freightForwarding
    ? originClearance || destinationClearance
    : false;
  const isSubAgentBusiness = (form: FormInstance) =>
    [BUSINESS_TYPE_SUBAGENT].includes(form.getFieldValue('business_type'));
  const isConsigneeMandatory = useMemo(() => {
    return (
      (tradeType === TRADE_TYPE_IMPORT || documentType === DOCUMENT_TYPE_HOUSE) &&
      freightType !== FREIGHT_TYPE_OCEAN &&
      isSubAgentBusiness(form)
    );
  }, [documentType, form, freightType, tradeType]);

  return (
    <Col>
      {isClearanceByServices ? (
        <>
          <Row gutter={ROW_GUTTER}>
            <Col span={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues[documentType]?.shipment_document?.consignee_on_document !==
                    currentValues[documentType]?.shipment_document?.consignee_on_document ||
                  prevValues?.consignee_party_name !== currentValues?.consignee_party_name
                }
              >
                {({ getFieldValue }) => {
                  const consignee_on_document = getFieldValue([
                    documentType,
                    'shipment_document',
                    'consignee_on_document',
                  ]);
                  const consignee_party_name = getFieldValue('consignee_party_name');
                  const disabledKeys = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                  return (
                    <Form.Item
                      required
                      rules={[{ required: true }]}
                      label="BL Type"
                      name={[documentType, 'shipment_document', 'bl_type']}
                    >
                      <Radio.Group>
                        {BL_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                          <Radio
                            key={index}
                            value={option.key}
                            disabled={
                              (consginee_to_order_keys.includes(consignee_on_document) ||
                                (consginee_to_order_keys.includes(consignee_party_name) &&
                                  (documentType === DOCUMENT_TYPE_HOUSE ||
                                    shipmentType === SHIPMENT_TYPE_DIRECT))) &&
                              disabledKeys.includes(option.key)
                            }
                          >
                            {option.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            {!disableShipmentNumber && freightType === FREIGHT_TYPE_OCEAN && (
              <Col span={8}>
                <ShipmentNumber
                  form={form}
                  carrier={carrier}
                  label={LABEL_JSON.shipment_number[documentType][freightType]}
                  documentType={documentType}
                  freightType={freightType}
                />
              </Col>
            )}

            {!disableShipmentNumber && freightType === FREIGHT_TYPE_OCEAN && (
              <>
                <Col span={8}>
                  <Form.Item
                    name={[documentType, 'shipment_document', 'shipment_date']}
                    label={LABEL_JSON.document_date[documentType][freightType]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row>
            {shipmentType === SHIPMENT_TYPE_BACK_TO_BACK &&
              documentType === DOCUMENT_TYPE_MASTER && (
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const shipper = getFieldValue(['party', 'shipper']);
                      const origin_agent = getFieldValue(['party', 'origin_agent']);
                      return (
                        <Form.Item
                          label={LABEL_JSON.shipper_name[documentType][freightType]}
                          name={[documentType, 'shipment_document', 'shipper_on_document']}
                        >
                          <Radio.Group>
                            {getShipperOptionsWithName(
                              shipper?.party_company?.registered_name || '',
                              origin_agent?.party_company?.registered_name || '',
                              shipmentType,
                              documentType
                            ).map((option: RadioOptionProps, index: number) => (
                              <Radio key={index} value={option.key}>
                                {option.name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}
            {shipmentType === SHIPMENT_TYPE_BACK_TO_BACK &&
              documentType === DOCUMENT_TYPE_MASTER && (
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const consignee = getFieldValue(['party', 'consignee']);
                      const destination_agent = getFieldValue(['party', 'destination_agent']);
                      const bl_type = getFieldValue([documentType, 'shipment_document', 'bl_type']);
                      const restricted_bl_types = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                      return (
                        <Form.Item
                          label={LABEL_JSON.consignee_name[documentType][freightType]}
                          name={[documentType, 'shipment_document', 'consignee_on_document']}
                        >
                          <Radio.Group>
                            {getConsigneeOptionsWithName(
                              consignee?.party_company?.registered_name || '',
                              destination_agent?.party_company?.registered_name || '',
                              shipmentType,
                              documentType
                            ).map((option: RadioOptionProps, index: number) => (
                              <Radio
                                key={index}
                                value={option.key}
                                disabled={
                                  consginee_to_order_keys.includes(option.key) &&
                                  restricted_bl_types.includes(bl_type)
                                }
                              >
                                {option.name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}
            {(shipmentType === SHIPMENT_TYPE_DIRECT || documentType === DOCUMENT_TYPE_HOUSE) && (
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const consignee = getFieldValue(['party', 'consignee']);
                    const destination_agent = getFieldValue(['party', 'destination_agent']);
                    const bl_type = getFieldValue([documentType, 'shipment_document', 'bl_type']);
                    const restricted_bl_types = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                    return (
                      <Form.Item
                        label={LABEL_JSON.consignee_name[documentType][freightType]}
                        name="consignee_party_name"
                      >
                        <Radio.Group>
                          {getConsigneeOptionsWithName(
                            consignee?.party_company?.registered_name || '',
                            destination_agent?.party_company?.registered_name || '',
                            shipmentType,
                            documentType
                          ).map((option: RadioOptionProps, index: number) => (
                            <Radio
                              key={index}
                              value={option.key}
                              disabled={
                                consginee_to_order_keys.includes(option.key) &&
                                restricted_bl_types.includes(bl_type)
                              }
                            >
                              {option.name}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues?.consignee_party_name !== currentValues?.consignee_party_name
                }
              >
                {({ getFieldValue }) => {
                  const consignee_party_name = getFieldValue('consignee_party_name');
                  const shouldToOrderPartyRender =
                    consginee_to_order_keys.includes(consignee_party_name);
                  if (!shouldToOrderPartyRender) return null;
                  return (
                    <Form.Item
                      required={isConsigneeMandatory}
                      rules={[{ required: isConsigneeMandatory }]}
                      name={['party', consignee_party_name, 'party_details']}
                      label={LABEL_JSON[consignee_party_name][documentType][freightType]}
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const consignee_party_name = getFieldValue('consignee_party_name');
                let showBRO =
                  tradeType === TRADE_TYPE_IMPORT &&
                  [CONSIGNEE_TYPE_TO_ORDER_BANK.key, CONSIGNEE_TYPE_TO_ORDER.key].includes(
                    consignee_party_name
                  );
                if (documentType === 'house') {
                  if (![SHIPMENT_TYPE_BACK_TO_BACK, SHIPMENT_TYPE_HOUSE].includes(shipmentType)) {
                    showBRO = false;
                  }
                }
                if (documentType === 'master') {
                  if (shipmentType !== SHIPMENT_TYPE_DIRECT) showBRO = false;
                }

                if (showBRO)
                  return (
                    <>
                      <Col span={8}>
                        <Form.Item
                          name={['shipment_events', 'bank_release_order', 'event_number']}
                          label="Bank Release Order Number"
                        >
                          <Input
                            placeholder={'Bank Release Order Number'}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={['shipment_events', 'bank_release_order', 'event_date']}
                          label="Bank Release Order Date"
                        >
                          <DatePicker
                            placeholder={'Bank Release Order Date'}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  );
                else return <></>;
              }}
            </Form.Item>
          </Row>
        </>
      ) : (
        <>
          <Row gutter={ROW_GUTTER}>
            <Col span={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues[documentType]?.shipment_document?.consignee_on_document !==
                    currentValues[documentType]?.shipment_document?.consignee_on_document ||
                  prevValues?.consignee_party_name !== currentValues?.consignee_party_name
                }
              >
                {({ getFieldValue }) => {
                  const consignee_on_document = getFieldValue([
                    documentType,
                    'shipment_document',
                    'consignee_on_document',
                  ]);
                  const consignee_party_name = getFieldValue('consignee_party_name');
                  const disabledKeys = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                  return (
                    <Form.Item
                      required
                      rules={[{ required: true }]}
                      label="BL Type"
                      name={[documentType, 'shipment_document', 'bl_type']}
                    >
                      <Radio.Group>
                        {BL_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                          <Radio
                            key={index}
                            value={option.key}
                            disabled={
                              (consginee_to_order_keys.includes(consignee_on_document) ||
                                (consginee_to_order_keys.includes(consignee_party_name) &&
                                  (documentType === DOCUMENT_TYPE_HOUSE ||
                                    shipmentType === SHIPMENT_TYPE_DIRECT))) &&
                              disabledKeys.includes(option.key)
                            }
                          >
                            {option.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Release Type"
                name={[documentType, 'shipment_document', 'release_type']}
              >
                <Radio.Group>
                  {RELEASE_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                    <Radio key={index} value={option.key}>
                      {option.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            {!disableShipmentNumber && freightType === FREIGHT_TYPE_OCEAN && (
              <Col span={8}>
                <ShipmentNumber
                  form={form}
                  carrier={carrier}
                  label={LABEL_JSON.shipment_number[documentType][freightType]}
                  documentType={documentType}
                  freightType={freightType}
                />
              </Col>
            )}

            {!disableShipmentNumber && freightType === FREIGHT_TYPE_OCEAN && (
              <>
                <Col span={8}>
                  <Form.Item
                    name={[documentType, 'shipment_document', 'shipment_date']}
                    label={LABEL_JSON.document_date[documentType][freightType]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                {!disableDocumentStatus && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        label={LABEL_JSON.document_status[documentType][freightType]}
                        name={[documentType, 'shipment_document', 'document_status']}
                      >
                        <Select
                          style={{ width: '100%' }}
                          onChange={setDocumentStatus}
                          disabled={tradeType === TRADE_TYPE_EXPORT ? true : false}
                        >
                          {(tradeType === TRADE_TYPE_IMPORT
                            ? ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE_IMPORT[freightType]
                            : ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE[freightType]
                          )
                            .filter((val) =>
                              documentType === 'house'
                                ? val !== DOCUMENT_STATUS_GIVEN_TO_CUSTOMER
                                : val
                            )
                            .map((option: string, index: number) => (
                              <Select.Option key={index} value={option}>
                                {document_status_to_display_name_mapping[option]}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {[
                      DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
                      DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
                    ].includes(documentStatus) && (
                      <Col span={8}>
                        <Form.Item
                          required
                          rules={[{ required: true }]}
                          name={[documentType, 'shipment_document', 'document_status_event_date']}
                          label={
                            documentStatus === DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN
                              ? 'Surrendered At Origin Date'
                              : 'Received At Destination Date'
                          }
                        >
                          <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}
              </>
            )}
          </Row>

          <Row gutter={ROW_GUTTER}>
            {freightForwarding && (
              <Col span={8} style={{ paddingBottom: '10px' }}>
                <Form.Item
                  label="Notify Party"
                  name={[
                    'party',
                    documentType === DOCUMENT_TYPE_HOUSE
                      ? 'notify_party_1_house'
                      : 'notify_party_1',
                  ]}
                  style={{ marginBottom: '10px' }}
                >
                  <AddressCompanySearch
                    addressSearchProps={{
                      showAddBranchAction: true,
                    }}
                  />
                </Form.Item>
                {/* <Form.Item
                  noStyle
                  valuePropName="checked"
                  name={
                    documentType === DOCUMENT_TYPE_HOUSE
                      ? 'notify_party_house_same_as_consignee'
                      : 'notify_party_same_as_consignee'
                  }
                >
                  <Checkbox
                    onChange={(e) => {
                      const notify_party = form?.getFieldValue([
                        'party',
                        documentType === DOCUMENT_TYPE_HOUSE
                          ? 'notify_party_1_house'
                          : 'notify_party_1',
                      ]);
                      const consignee_on_document = form?.getFieldValue('consignee_on_document');
                      const consignee =
                        consignee_on_document === 'consignee'
                          ? form?.getFieldValue(['party', 'consignee'])
                          : form?.getFieldValue(['party', 'destination_agent']);
                      if (!e.target.checked) {
                        if (isSameParty(notify_party, consignee))
                          form?.setFieldValue(
                            [
                              'party',
                              documentType === DOCUMENT_TYPE_HOUSE
                                ? 'notify_party_1_house'
                                : 'notify_party_1',
                            ],
                            null
                          );
                      } else {
                        form?.setFieldValue(
                          [
                            'party',
                            documentType === DOCUMENT_TYPE_HOUSE
                              ? 'notify_party_1_house'
                              : 'notify_party_1',
                          ],
                          {
                            ...consignee,
                            id: notify_party?.id,
                            name:
                              documentType === DOCUMENT_TYPE_HOUSE
                                ? 'notify_party_1_house'
                                : 'notify_party_1',
                          }
                        );
                      }
                    }}
                  >
                    Same As Consignee
                  </Checkbox>
                </Form.Item> */}
              </Col>
            )}
            <Col span={8}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Freight Charge Terms"
                name={[documentType, 'shipment_document', 'charge_terms', 'freight_terms']}
              >
                <Radio.Group>
                  {CHARGE_TERMS.map((option: RadioOptionProps, index: number) => (
                    <Radio key={index} value={option.key}>
                      {option.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Other Charge Terms"
                name={[documentType, 'shipment_document', 'charge_terms', 'other_charges_terms']}
              >
                <Radio.Group>
                  {CHARGE_TERMS.map((option: RadioOptionProps, index: number) => (
                    <Radio key={index} value={option.key}>
                      {option.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {(shipmentType === SHIPMENT_TYPE_BACK_TO_BACK ||
              shipmentType === SHIPMENT_TYPE_CONSOL) &&
              documentType === DOCUMENT_TYPE_MASTER &&
              freightForwarding && (
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const shipper = getFieldValue(['party', 'shipper']);
                      const origin_agent = getFieldValue(['party', 'origin_agent']);
                      const consolidation_type = getFieldValue('consol_type');
                      return (
                        <Form.Item
                          label={LABEL_JSON.shipper_name[documentType][freightType]}
                          name={[documentType, 'shipment_document', 'shipper_on_document']}
                        >
                          <Radio.Group>
                            {getShipperOptionsWithName(
                              shipper?.party_company?.registered_name || '',
                              origin_agent?.party_company?.registered_name || '',
                              shipmentType,
                              documentType,
                              consolidation_type
                            ).map((option: RadioOptionProps, index: number) => (
                              <Radio key={index} value={option.key}>
                                {option.name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}
            {(shipmentType === SHIPMENT_TYPE_BACK_TO_BACK ||
              shipmentType === SHIPMENT_TYPE_CONSOL) &&
              documentType === DOCUMENT_TYPE_MASTER &&
              freightForwarding && (
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const consignee = getFieldValue(['party', 'consignee']);
                      const destination_agent = getFieldValue(['party', 'destination_agent']);
                      const bl_type = getFieldValue([documentType, 'shipment_document', 'bl_type']);
                      const consolidation_type = getFieldValue('consol_type');
                      const restricted_bl_types = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                      return (
                        <Form.Item
                          label={LABEL_JSON.consignee_name[documentType][freightType]}
                          name={[documentType, 'shipment_document', 'consignee_on_document']}
                        >
                          <Radio.Group>
                            {getConsigneeOptionsWithName(
                              consignee?.party_company?.registered_name || '',
                              destination_agent?.party_company?.registered_name || '',
                              shipmentType,
                              documentType,
                              consolidation_type
                            ).map((option: RadioOptionProps, index: number) => (
                              <Radio
                                key={index}
                                value={option.key}
                                disabled={
                                  consginee_to_order_keys.includes(option.key) &&
                                  restricted_bl_types.includes(bl_type)
                                }
                              >
                                {option.name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}
            {(shipmentType === SHIPMENT_TYPE_DIRECT || documentType === DOCUMENT_TYPE_HOUSE) &&
              freightForwarding && (
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const consignee = getFieldValue(['party', 'consignee']);
                      const destination_agent = getFieldValue(['party', 'destination_agent']);
                      const bl_type = getFieldValue([documentType, 'shipment_document', 'bl_type']);
                      const restricted_bl_types = [BL_TYPE_EXPRESS, BL_TYPE_E_BL];
                      return (
                        <Form.Item
                          label={LABEL_JSON.consignee_name[documentType][freightType]}
                          name="consignee_party_name"
                        >
                          <Radio.Group>
                            {getConsigneeOptionsWithName(
                              consignee?.party_company?.registered_name || '',
                              destination_agent?.party_company?.registered_name || '',
                              shipmentType,
                              documentType
                            ).map((option: RadioOptionProps, index: number) => (
                              <Radio
                                key={index}
                                value={option.key}
                                disabled={
                                  consginee_to_order_keys.includes(option.key) &&
                                  restricted_bl_types.includes(bl_type)
                                }
                              >
                                {option.name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}
            <Col span={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues?.consignee_party_name !== currentValues?.consignee_party_name
                }
              >
                {({ getFieldValue }) => {
                  const consignee_party_name = getFieldValue('consignee_party_name');
                  const shouldToOrderPartyRender =
                    consginee_to_order_keys.includes(consignee_party_name);
                  if (!shouldToOrderPartyRender) return null;
                  return (
                    <Form.Item
                      required={isConsigneeMandatory}
                      rules={[{ required: isConsigneeMandatory }]}
                      name={['party', consignee_party_name, 'party_details']}
                      label={LABEL_JSON[consignee_party_name][documentType][freightType]}
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const consignee_party_name = getFieldValue('consignee_party_name');
                let showBRO =
                  tradeType === TRADE_TYPE_IMPORT &&
                  [CONSIGNEE_TYPE_TO_ORDER_BANK.key, CONSIGNEE_TYPE_TO_ORDER.key].includes(
                    consignee_party_name
                  );
                if (documentType === 'house') {
                  if (![SHIPMENT_TYPE_BACK_TO_BACK, SHIPMENT_TYPE_HOUSE].includes(shipmentType)) {
                    showBRO = false;
                  }
                }
                if (documentType === 'master') {
                  if (shipmentType !== SHIPMENT_TYPE_DIRECT) showBRO = false;
                }

                if (showBRO)
                  return (
                    <>
                      <Col span={8}>
                        <Form.Item
                          name={['shipment_events', 'bank_release_order', 'event_number']}
                          label="Bank Release Order Number"
                        >
                          <Input
                            placeholder={'Bank Release Order Number'}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={['shipment_events', 'bank_release_order', 'event_date']}
                          label="Bank Release Order Date"
                        >
                          <DatePicker
                            placeholder={'Bank Release Order Date'}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  );
                else return <></>;
              }}
            </Form.Item>
          </Row>
        </>
      )}
    </Col>
  );
});

export default ShipmentDocumentDetailsNew;
