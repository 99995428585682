import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { message, Popconfirm } from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQLString } from 'common';
import { DELETE_BR_CONTAINER, DELETE_CONTAINER } from './graphql';
import { FETCH_CONTAINER_FOR_EDIT } from 'operations/modules/booking/graphql/shipmentContainer';
import { ContainerForEditType } from './EditContainer/EditContainerForm';
import { TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';
import { getIsCustomClearanceByServices } from '../../NewShipmentForm/helpers';
import { STATUS_PICKUP_PENDING } from 'operations/modules/reports/constants';

type Props = {
  container_id: string;
  onClose: () => void;
  onSuccess: () => void;
};

const DeleteContainer = (props: Props) => {
  const { container_id, onClose, onSuccess } = props;

  const { loading, error, data } = useQuery(FETCH_CONTAINER_FOR_EDIT, {
    variables: { id: container_id },
    fetchPolicy: 'no-cache',
  });

  const [deleteContainer, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_CONTAINER);

  const [
    deleteBrContainer,
    { data: deleteBrData, loading: deleteBrLoading, error: deleteBrError },
  ] = useMutation(DELETE_BR_CONTAINER);

  const messageKey = 'container_delete_message';
  useEffect(() => {
    if (error)
      message.error({
        content: 'Error occurred while fetching container data',
        key: messageKey,
        duration: 2,
      });
  }, [loading, error, data]);

  useEffect(() => {
    if (deleteError) {
      console.error(deleteError);
      message.error(errorMessageHandlerGraphQLString(deleteError));
    }
    if (deleteData && deleteData.delete_shipment_container) {
      message.success('Container deleted successfully');
      onSuccess();
    }
  }, [deleteError, deleteData, onSuccess]);

  useEffect(() => {
    if (deleteBrError) {
      console.error(deleteBrError);
      message.error(errorMessageHandlerGraphQLString(deleteBrError));
    }
    if (deleteBrData && deleteBrData.delete_br_container) {
      message.success('Container deleted successfully');
      onSuccess();
    }
  }, [deleteBrError, deleteBrData, onSuccess]);

  if (!data) return <></>;
  const container: ContainerForEditType = data.fetch_basic_container_details_by_id;

  const handleContainerDelete = () => {
    const shipment = container.master_shipment || container.container_cargo_details[0]?.shipment;
    if ((container.ocean_transport_orders || []).length > 0) {
      // handling for import and custom clearance
      if (
        container.status === STATUS_PICKUP_PENDING &&
        (shipment?.trade_type === TRADE_TYPE_IMPORT ||
          getIsCustomClearanceByServices(shipment?.services))
      ) {
        deleteContainer({
          variables: {
            id: container_id,
          },
        });
        return;
      }
      if (!container.container_number || container.container_number === '') {
        message.error('Can not Delete container as it is assigned to booking');
        return;
      }
      deleteBrContainer({
        variables: {
          id: container.id,
        },
      });
    } else {
      deleteContainer({
        variables: {
          id: container_id,
        },
      });
    }
  };

  return (
    <Popconfirm
      key="delete_container"
      title="Are you sure you want to delete this container?"
      onConfirm={handleContainerDelete}
      onCancel={onClose}
      okText="Yes"
      cancelText="No"
      // onVisibleChange={onClose}
      open={true}
      destroyTooltipOnHide
      placement="topRight"
      okButtonProps={{ loading: deleteBrLoading || deleteLoading }}
    />
  );
  // }
  // return <></>;
};

export default DeleteContainer;
