import TeamAutoFillWrapper from 'common/components/Team/TeamAutoFillWrapper';
import React, { useEffect, useState } from 'react';
import { useInquiryForm } from './InquiryForm';
import {
  TEAM_ROLE_CUSTOMER_SERVICE,
  TEAM_ROLE_PRICING,
  TEAM_ROLE_SALES,
} from 'network/baseConstants';
import { TeamFormTable } from 'common';

const InquiryTeamDetails = () => {
  const { form, inquiry, customer, branchAccount, disable } = useInquiryForm();

  const [preFillTeam, setPreFillTeam] = useState<
    | {
        reference_id: string;
        branch_id?: string;
        reference_type: string;
      }
    | undefined
  >();

  useEffect(() => {
    const customerId = customer?.party_company?.id;
    if (!customerId) return;

    // if inquiry is present than only run, if customer is changed, otherwise skip this
    // done this to skip initial render of this effect
    if (inquiry?.customer_company?.id !== customerId) {
      setPreFillTeam({
        reference_id: customerId,
        branch_id: branchAccount?.id,
        reference_type: 'Network::Company',
      });
    }
  }, [customer, branchAccount, inquiry]);

  return (
    <>
      <h3 className="inq-form-section-title">{'Team Details'}</h3>
      <div style={{ display: 'block' }}>
        <TeamAutoFillWrapper
          form={form}
          preFillFields={
            inquiry && !preFillTeam
              ? undefined
              : {
                  ...preFillTeam,
                  roles: [TEAM_ROLE_CUSTOMER_SERVICE, TEAM_ROLE_PRICING, TEAM_ROLE_SALES],
                }
          }
        >
          <TeamFormTable form={form} reference_type="SalesHub::Inquiry" editable={!disable} />
        </TeamAutoFillWrapper>
      </div>
    </>
  );
};

export default InquiryTeamDetails;
