import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@apollo/client';
import { Drawer, Form } from '@shipmnts/pixel-hub';
import { VesselValue } from 'operations/models/Vessel';
import { ShipmentValue } from 'operations/models/Shipment';
import { getAllocationPayloadNew } from '../../helpers/allocationHelper';
import { ALLOCATE_BOOKING } from '../../graphql/shipmentReport';
import { DrawerFooter, Layout, message } from '@shipmnts/pixel-hub';
import BookingReport from './BookingReport';
import { FieldDisableReason } from 'operations/commonTypeDefs';
import { getWhyCarrierIsDisabled, getWhyVesselIsDisabled } from 'operations/modules/helpers';

export type PartialVesselValue = Omit<VesselValue, 'is_frequent'>;

const mergeContainersToQty = (containers: any[]) => {
  const containerMap = new Map();

  containers.forEach((container) => {
    const key = `${container.container_type_code}-${JSON.stringify(container.container_settings)}`;
    if (!containerMap.has(key)) {
      containerMap.set(key, {
        container_type: container.container_type,
        container_type_code: container.container_type_code,
        container_settings: container.container_settings,
        is_shipper_owned: container.is_shipper_owned,
        weight_per_container: container.weight_per_container,
        weight_unit: container.weight_unit,
        quantity: 0,
      });
    }

    containerMap.get(key).quantity += 1;
  });

  return Array.from(containerMap.values());
};

const AllocateOTOReport = observer(function AllocateOTOReport(props: {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess?: () => void;
}): JSX.Element {
  const { onClose, shipment, onSuccess } = props;
  const [form] = Form.useForm();
  const shipmentCarrier = shipment?.carrier ||
    shipment?.inquiry_option?.carrier || [...(shipment?.preferred_carriers || [])];
  const shipmentContainerQty = mergeContainersToQty(shipment?.shipment_containers as any);
  const [allocateBooking, { data, loading, error }] = useMutation(ALLOCATE_BOOKING);

  const disabledFilters: Record<string, FieldDisableReason> = {
    ...getWhyCarrierIsDisabled(shipment),
    ...getWhyVesselIsDisabled(shipment),
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data?.allocate_booking) {
      message.success('Booking Order(s) Allocated Successfully!');
      onClose();
      onSuccess && onSuccess();
    }
  }, [loading, error, data, onClose, onSuccess]);
  return (
    <Layout style={{ height: '100%' }}>
      <Drawer
        title={`Confirm Customer Order By Allocating Booking`}
        width={'60%'}
        open={true}
        onClose={onClose}
        footer={<DrawerFooter onSave={() => form.submit()} saveText="Confirm" onClose={onClose} />}
      >
        <Form
          form={form}
          onFinish={(values): any => {
            let allocate_ocean_transport_orders: any = undefined;
            allocate_ocean_transport_orders = getAllocationPayloadNew(
              values.allocation_ocean_transport_order
            );
            const hasAllocatedAtLeastOneCr = allocate_ocean_transport_orders?.some((item: any) =>
              item?.container_requests?.some((req: any) => req?.count >= 1)
            );
            if (!hasAllocatedAtLeastOneCr) {
              message.error('Please allocate at least one container');
              return;
            }
            const variables = {
              allocate_ocean_transport_orders: allocate_ocean_transport_orders,
              shipment_id: shipment?.id,
            };
            allocateBooking({ variables });
          }}
        >
          <BookingReport
            filters={{
              port_of_loading_id: shipment?.port_of_loading?.id,
              port_of_discharge_id: shipment?.port_of_discharge?.id,
              carrier: shipmentCarrier,
              shipmentContainerQty: shipmentContainerQty,
              ocean_vessel: shipment?.ocean_vessel,
            }}
            disabledFilters={disabledFilters}
          />
        </Form>
      </Drawer>
    </Layout>
  );
});

export default AllocateOTOReport;
