import React, { useState } from 'react';
import TeamDetailsFormModal from 'network/components/TeamDetailsForm';
import { TeamValue } from 'common/models/Team';

interface UpdateTeamActionRendererProps {
  initialValues: {
    teams: Array<TeamValue>;
  };
  reference_type: string;
  reference_id: string;
  onSuccess?: () => void;
  onClose: () => void;
}

const UpdateTeamActionRenderer = React.memo(function UpdateTeamDrawer(
  props: UpdateTeamActionRendererProps
): JSX.Element {
  const { reference_id, reference_type, initialValues, onSuccess, onClose } = props;
  const [open, setIsOpen] = useState(true);

  return (
    <TeamDetailsFormModal
      initialValues={initialValues}
      open={open}
      setIsOpen={setIsOpen}
      reference_id={reference_id}
      reference_type={reference_type}
      onSuccess={onSuccess}
      onClose={onClose}
      style={{ maxWidth: '900px' }}
    />
  );
});

export default UpdateTeamActionRenderer;
