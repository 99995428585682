import React, { useEffect, useState } from 'react';
import Dashboard, { DashboardObj } from 'src/components/Dashboard';
import { GET_DASHBOARDS } from 'src/graphQL/query';
import { useQuery } from '@apollo/client';
import { CUSTOMER_DASHBOARD } from 'network/constants';
import { Result } from '@shipmnts/pixel-hub';
import Spinner from 'src/components/Spinner';

export const DashboardAnalyticsTab = (props: any) => {
  const { company } = props;
  const { loading, error, data } = useQuery(GET_DASHBOARDS, {
    variables: {
      dashboard_type: CUSTOMER_DASHBOARD,
      dashboard_sub_type: company.company_type,
    },
  });
  const [dashboard, setDashboard] = useState<DashboardObj>();
  const customURLParams = { company_condition: `customer.id = ${company?.id}` };

  useEffect(() => {
    if (data?.get_dashboards && data?.get_dashboards[0]) {
      setDashboard(data?.get_dashboards[0]);
    }
  }, [data, company]);
  if (loading) return <Spinner />;
  if (error || !dashboard)
    return (
      <Result
        status="500"
        title="Unable to fetch your information."
        subTitle="Please check dashboard permissions or contact support."
      />
    );
  return (
    <div>
      <Dashboard id={dashboard?.id} customURLParams={customURLParams} />
    </div>
  );
};
