import React from 'react';
import { Checkbox, Input, InputNumber, Select, Row, Form } from '@shipmnts/pixel-hub';
import {
  TEMPERATURE_UNIT_CELSIUS,
  TEMPERATURE_UNIT_FAHRENHEIT,
  AIRFLOW_UNITS,
  DIMENSION_CMS,
} from 'operations/baseConstants';

import { ContainerSettingValue } from 'operations/models/ContainerRequest';

const { Option } = Select;
const lbh_dimensions = [{ key: DIMENSION_CMS, name: DIMENSION_CMS }];
const renderInputWithUnit = (
  key: string,
  unit_key: string,
  label: string,
  options: Array<{ key: string; name: string }>,
  required?: boolean
) => {
  return (
    <Input.Group compact style={{ width: '100%' }}>
      <Form.Item
        required={required}
        rules={[{ required: required }]}
        name={key}
        style={{ width: '70%' }}
      >
        <InputNumber
          className="no-border-right-radius"
          style={{ width: '100%' }}
          placeholder={label}
        />
      </Form.Item>
      <Form.Item name={unit_key} style={{ width: '30%' }}>
        <Select className="no-border-left-radius">
          {options.map((unit) => (
            <Option key={unit.key} value={unit.key}>
              {unit.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Input.Group>
  );
};

const renderPercentField = (key: string, label: string, addonAfter: string, required?: boolean) => {
  return (
    <Input.Group style={{ width: '100%' }} compact>
      <Form.Item
        style={{ width: '70%' }}
        name={key}
        required={required}
        rules={[{ required: required }]}
      >
        <InputNumber
          className="no-border-right-radius"
          style={{ width: '100%' }}
          min={0}
          max={100}
          placeholder={label}
        />
      </Form.Item>
      <Select value={addonAfter} disabled style={{ width: '30%' }}>
        <Option key={addonAfter} value={addonAfter}>
          {addonAfter}
        </Option>
      </Select>
    </Input.Group>
  );
};

const ContainerSettingContent = ({
  is_reefer,
  is_out_of_dimension,
}: {
  is_reefer: boolean;
  is_out_of_dimension: boolean;
  onChange?: (value: ContainerSettingValue) => void;
  value?: ContainerSettingValue;
}) => {
  return (
    <>
      {is_reefer && (
        <>
          <Form.Item name="is_active_reefer" valuePropName="checked">
            <Checkbox>Operating Reefer Container</Checkbox>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                getFieldValue('is_active_reefer') && (
                  <>
                    <Row>
                      {renderInputWithUnit(
                        'temperature',
                        'temperature_unit',
                        'Temperature',
                        [
                          { key: TEMPERATURE_UNIT_CELSIUS, name: TEMPERATURE_UNIT_CELSIUS },
                          {
                            key: TEMPERATURE_UNIT_FAHRENHEIT,
                            name: TEMPERATURE_UNIT_FAHRENHEIT,
                          },
                        ],
                        true
                      )}
                    </Row>
                    <Row>
                      <Form.Item name="ventilation_requested" valuePropName="checked">
                        <Checkbox>Ventilation Requested</Checkbox>
                      </Form.Item>
                      {getFieldValue('ventilation_requested') &&
                        renderInputWithUnit(
                          'air_flow',
                          'air_flow_unit',
                          'Air flow',
                          AIRFLOW_UNITS,
                          true
                        )}
                    </Row>
                    <Row>
                      <Form.Item name="humidity_control_requested" valuePropName="checked">
                        <Checkbox>Humidity Control Requested</Checkbox>
                      </Form.Item>
                      {getFieldValue('humidity_control_requested') &&
                        renderPercentField(
                          'relative_humidity_percent',
                          'Humidity percent',
                          '%',
                          true
                        )}
                    </Row>
                    <Row>
                      <Form.Item name="controlled_atmosphere_requested" valuePropName="checked">
                        <Checkbox>Controlled Atmosphere Requested</Checkbox>
                      </Form.Item>
                      {getFieldValue('controlled_atmosphere_requested') && (
                        <>
                          {renderPercentField('oxygen_level_percent', 'Oxygen percent', 'O2 %')}
                          {renderPercentField('nitrogen_level_percent', 'Nitrogen percent', 'N %')}
                          {renderPercentField(
                            'carbon_dioxide_level_percent',
                            'Carbon Dioxide percent',
                            'CO2 %'
                          )}
                        </>
                      )}
                    </Row>
                    <Row>
                      <Form.Item name="genset_requested" valuePropName="checked">
                        <Checkbox>Genset Requested</Checkbox>
                      </Form.Item>
                    </Row>
                  </>
                )
              );
            }}
          </Form.Item>
        </>
      )}
      {is_out_of_dimension && (
        <>
          {renderInputWithUnit('length', 'lbh_unit', 'Length', lbh_dimensions)}
          {renderInputWithUnit('width', 'lbh_unit', 'Width', lbh_dimensions)}
          {renderInputWithUnit('height', 'lbh_unit', 'Height', lbh_dimensions)}
        </>
      )}
    </>
  );
};

export default ContainerSettingContent;
