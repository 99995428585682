import React, { useMemo, lazy, Suspense, useRef, useState, useCallback } from 'react';
import { capitalize as _capitalize, startCase as _startCase } from 'lodash';
import { useParams, useLocation } from 'wouter';

import {
  Badge,
  Button,
  dayjs,
  FallbackSkeleton,
  hasPermission,
  message,
  Modal,
  NoPermissionFallback,
  PageHeader,
  Result,
} from '@shipmnts/pixel-hub';
import { ActionRenderer } from 'operations';
import {
  CONTRACT_DOCTYPE_MAP,
  CONTRACT_TYPE_COMPONENT_MAP,
  DRAFT_CONTRACT_STATUS,
  EXPIRE_CONTRACT_STATUS,
  PERMISSION_CREATE_CONTRACT,
  PERMISSION_EDIT_CONTRACT,
  PERMISSION_VIEW_CONTRACT,
  STATUS_CLASS_MAP,
  STATUS_COLOR_MAP,
  SUBMIT_CONTRACT_STATUS,
} from '../constants';
import { useSession } from 'common';
import { useMutation } from '@apollo/client';
import { UPSERT_CONTRACT } from '../graphql/mutations';

import '../styles.css';
import { getContractType } from '../helper';

const getContractForm = (contractType?: string) => {
  const component =
    CONTRACT_TYPE_COMPONENT_MAP[contractType as keyof typeof CONTRACT_TYPE_COMPONENT_MAP];
  if (!component) message.error('Invalid Contract Type Passed to BaseContract');
  return !!component
    ? {
        ContractForm: lazy(() => import(`${component.componentPath}`)),
        ...component.contractProps,
        title: component.title,
      }
    : {};
};

const BaseContract = (props: {
  contract?: any;
  refetchContract?: any;
  isDuplicate?: boolean;
  isAmend?: boolean;
}) => {
  const { contract, refetchContract, isDuplicate, isAmend } = props;
  const { 1: navigate } = useLocation();
  const session = useSession();

  // params
  const params = useParams<{ id: string; type: string }>();
  const id = contract?.id || params.id;
  const contractType = params.type;

  // mutations & queries
  const [upsertContract] = useMutation(UPSERT_CONTRACT);

  // constants
  const isCreate = id === 'new';

  const { ContractForm, freightType, rateType, title } = useMemo(
    () => getContractForm(contractType),
    [contractType]
  );

  const doc_type = CONTRACT_DOCTYPE_MAP[freightType || ''];
  const contractStatus = useMemo(() => contract?.status, [contract]);

  // refs
  const ContractFormRef = useRef<{
    handleOnCreate: (status?: string) => void;
  }>();

  const DocumentUploadRef = useRef<{
    updateDocsToParent: (id: string) => Promise<void>;
  }>();

  // states
  const [valuesChanged, setValuesChanged] = useState(isCreate);

  // callbacks
  const handleUpsertContract = useCallback(
    (payload: any) => {
      upsertContract({
        variables: {
          contract: payload,
          contract_id: !isCreate ? id : null,
        },
      })
        .then((res) => {
          if (res?.data && !res?.errors) {
            DocumentUploadRef.current
              ?.updateDocsToParent(res.data?.upsert_contract?.contract?.id)
              .then(() => {
                message.success(`Contract ${isCreate ? 'Created' : 'Updated'} Successfully`);
                if (!isCreate) refetchContract();
                else {
                  navigate(
                    `~/form/contract/${contractType}/${res?.data?.upsert_contract?.contract.id}`
                  );
                }
              })
              .catch((err) => {
                message.error(`Error in uploading documents: ${err.message}`);
              });
          }
        })
        .catch((err) => {
          message.error(`Error in creating contract: ${err.message}`);
        });
    },
    [contractType, id, isCreate, navigate, refetchContract, upsertContract]
  );

  const submitForm = useCallback(
    (payload: any) => {
      if (payload.status === SUBMIT_CONTRACT_STATUS) {
        const is_expired = dayjs(payload.valid_till).unix() < dayjs().unix();
        Modal.confirm({
          title: `${
            is_expired
              ? 'Are you sure you want to Submit this Contract as Expired?'
              : 'Are you sure you want to Submit this Contract?'
          }`,
          content: `${
            is_expired
              ? 'Validity of contract has Expired. Do you want to continue?'
              : 'This action cannot be undone.'
          }`,
          okText: 'Yes',
          cancelText: 'Cancel',
          onOk: async () => {
            if (is_expired) {
              handleUpsertContract({ ...payload, status: EXPIRE_CONTRACT_STATUS });
            } else handleUpsertContract(payload);
          },
        });
      } else {
        handleUpsertContract(payload);
      }
    },
    [handleUpsertContract]
  );

  const handleSaveOrSubmit = useCallback(() => {
    if (ContractFormRef.current?.handleOnCreate) {
      ContractFormRef.current.handleOnCreate(
        valuesChanged ? DRAFT_CONTRACT_STATUS : SUBMIT_CONTRACT_STATUS
      );
    }
  }, [valuesChanged]);

  // Permission Check
  const hasViewPermission =
    !isCreate &&
    hasPermission(session.permissions, {
      name: PERMISSION_VIEW_CONTRACT,
      docType: doc_type,
    });

  const hasCreatePermission =
    isCreate &&
    hasPermission(session.permissions, {
      name: PERMISSION_CREATE_CONTRACT,
      docType: doc_type,
    });

  const hasEditPermission =
    !isCreate &&
    hasViewPermission &&
    hasPermission(session.permissions, {
      name: PERMISSION_EDIT_CONTRACT,
      docType: doc_type,
    });

  if (!hasCreatePermission && !hasViewPermission) {
    return (
      <Suspense fallback={<FallbackSkeleton />}>
        <NoPermissionFallback action={isCreate ? 'Create' : 'View this'} resource="contract" />
      </Suspense>
    );
  }

  if (
    (!isCreate || isDuplicate || isAmend) &&
    contractType !== getContractType(contract?.type, contract?.rate_type)
  ) {
    return (
      <Result
        status="404"
        title="Unable to fetch contract information"
        subTitle="Sorry, something went wrong while fetching contract details. Either contract doesn't exist or there is some problem with your internet."
        extra={[
          <Button key="retry" type="primary" onClick={() => window.history.back()}>
            Return to Previous Page
          </Button>,
        ]}
      />
    );
  }

  const getTitle = () => {
    if (isCreate || !contract?.id) {
      return `Create ${
        !!title ? title : `${_capitalize(freightType)} ${_capitalize(rateType).replace('_', ' ')}`
      } Contract`;
    } else if (contract?.contract_number) {
      return contract?.contract_number;
    }

    return `Edit ${contract?.contract_number}`;
  };

  return (
    <>
      <PageHeader
        style={{
          backgroundColor: '#FFF',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          top: '42px',
        }}
        title={
          <div style={{ display: 'flex' }}>
            <span style={{ fontSize: '16px' }}>{getTitle()}</span>
            {contractStatus && !isCreate && (
              <span
                className={STATUS_CLASS_MAP[contractStatus as keyof typeof STATUS_CLASS_MAP]}
                style={{ fontSize: '14px', marginLeft: '14px', display: 'flex' }}
              >
                <Badge color={STATUS_COLOR_MAP[contractStatus as keyof typeof STATUS_COLOR_MAP]} />
                <span style={{ marginLeft: '6px' }}>{_startCase(contractStatus)}</span>
              </span>
            )}
          </div>
        }
        onBack={() => window.history.back()}
        extra={[
          !isCreate && (
            <ActionRenderer
              id={contract?.id}
              key={'actions'}
              refetchData={refetchContract}
              data={contract}
              isDetailScreen={true}
              doc_type_id={'RateManagement::Contract'}
            />
          ),
          (contract?.status === DRAFT_CONTRACT_STATUS || isCreate) &&
            (hasEditPermission || hasCreatePermission) && (
              <Button
                type="primary"
                key={'save_n_submit_button'}
                size="small"
                onClick={handleSaveOrSubmit}
              >
                {valuesChanged ? (isCreate ? 'Create Contract' : 'Save') : 'Submit'}
              </Button>
            ),
        ]}
      />
      <div
        style={{
          margin: '20px 30px',
          paddingTop: '40px',
          borderRadius: '4px',
        }}
      >
        <Suspense fallback={<FallbackSkeleton />}>
          <ContractForm
            ref={ContractFormRef}
            docRef={DocumentUploadRef}
            data={contract}
            contractType={contractType}
            onValuesChange={setValuesChanged}
            submitForm={submitForm}
            isCreate={isCreate}
          />
        </Suspense>
      </div>
    </>
  );
};

export default BaseContract;
