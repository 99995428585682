import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from 'react';
import { Table, Button, Row, Typography, Tooltip, EditableCell } from '@shipmnts/pixel-hub';
import { uniqueId as _uniqueId, pick as _pick } from 'lodash';
import {
  containerTypesArray,
  containerTypesHash,
  containerSettingsMap,
  WEIGHT_UNIT_KGS,
  RENDER_INPUT_NUMBER,
  RENDER_SELECT,
  RENDER_CHECKBOX,
} from 'operations/baseConstants';
import ContainerSettings from './ContainerSettings';
import _map from 'lodash/map';
import { ContainerRequestValue, ContainerSettingValue } from 'operations/models/ContainerRequest';
const { Text } = Typography;

export interface ContainerRequestDetailsProps {
  value?: Array<ContainerRequestValue>;
  onChange?: (value: Array<ContainerRequestValue>) => void;
  required_fields?: Array<string>;
  disableFields?: string[];
  disableAdd?: boolean;
  disableDelete?: boolean;
  isLiveReefer?: boolean;
  showcolumns?: string[];
  required?: boolean;
  disabled?: boolean;
  allowZeroQty?: boolean;
  showRowDelete?: boolean;
}

const getColumns: any = (is_live_reefer?: boolean, allowZeroQty?: boolean) => [
  {
    title: (
      <Tooltip title="Select the type of Container being used for the shipment ">
        Container Type
      </Tooltip>
    ),
    dataIndex: 'container_type_code',
    width: '25%',
    editable: true,
    renderComponent: RENDER_SELECT,
    componentProps: {
      showGroup: true,
      options: is_live_reefer ? { Reefer: containerTypesArray['Reefer'] } : containerTypesArray,
    },
    showComponentValueWhenDisabled: true,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    editable: true,
    width: '10%',
    renderComponent: RENDER_INPUT_NUMBER,
    componentProps: {
      min: allowZeroQty ? 0 : 1,
      max: 500,
      precision: 0,
    },
  },
  {
    title: 'Gross Weight / Container',
    dataIndex: 'weight_per_container',
    editable: true,
    renderComponent: RENDER_INPUT_NUMBER,
    componentProps: {
      precision: 2,
      min: 0,
      style: {
        width: '100%',
      },
    },
  },
  {
    title: 'Weight unit',
    dataIndex: 'weight_unit',
  },
  {
    title: 'Max Cargo Weight (Kgs)',
    dataIndex: 'maximum_cargo_weight',
    editable: true,
    renderComponent: RENDER_INPUT_NUMBER,
    componentProps: {
      precision: 2,
      min: 0,
      style: {
        width: '100%',
      },
    },
  },
  {
    title: 'Max Cargo Volume (cbm)',
    dataIndex: 'maximum_cargo_volume',
    editable: true,
    renderComponent: RENDER_INPUT_NUMBER,
    componentProps: {
      precision: 2,
      min: 0,
      style: {
        width: '100%',
      },
    },
  },
  {
    title: 'Shipper Owned',
    dataIndex: 'is_shipper_owned',
    editable: true,
    renderComponent: RENDER_CHECKBOX,
  },
  {
    title: 'Settings',
    dataIndex: 'container_settings',
    // editable: true,
    // renderComponent: RENDER_INPUT,
    render: function render(text: string, record: ContainerRequestValue, index: number) {
      return <span />;
    },
  },
];

const components: any = {
  body: {
    cell: EditableCell,
  },
};

function renderContainerSettings(
  text: string,
  record: ContainerRequestValue,
  index: number,
  onFieldChange: (key: string, value: ContainerSettingValue, index: number) => void,
  disabled?: boolean
) {
  return (
    <ContainerSettings
      value={record.container_settings}
      onChange={(value) => onFieldChange('container_settings', value, index)}
      container_type_code={record.container_type_code}
      disabled={disabled}
    />
  );
}

export const new_cr = {
  container_type: undefined,
  quantity: 1,
  weight_per_container: undefined,
  weight_unit: WEIGHT_UNIT_KGS,
  maximum_cargo_weight: undefined,
  maximum_cargo_volume: undefined,
  is_shipper_owned: false,
  container_settings: undefined,
};

const ContainerDetails = forwardRef(function ContainerDetails(
  props: ContainerRequestDetailsProps,
  ref
) {
  const {
    value,
    onChange,
    required_fields = [],
    disableFields = [],
    disableAdd,
    disabled,
    disableDelete,
    isLiveReefer,
    showcolumns = [
      'container_type_code',
      'quantity',
      'weight_per_container',
      'weight_unit',
      'is_shipper_owned',
      'container_settings',
    ],
    required = true,
    allowZeroQty,
  } = props;

  const initialContainerRequestValue = useMemo(
    () => [
      {
        _id: 'cr_0',
        ..._pick(new_cr, showcolumns),
      } as ContainerRequestValue,
    ],
    [showcolumns]
  );

  const container_requests: Array<ContainerRequestValue> = value || initialContainerRequestValue;

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.ReactText>>([]);
  const [errorsPresent, setErrorsPresent] = useState(false);

  const validateContainerRequests = useCallback(
    (allowZeroQty?: boolean) => {
      let foundError = false;

      _map<ContainerRequestValue>(value, (cr: ContainerRequestValue) => {
        _map(required_fields, (field: keyof ContainerRequestValue) => {
          if (!cr[field] && !(field === 'quantity' && allowZeroQty)) {
            setErrorsPresent(true);
            foundError = true;
          }
        });
      });
      if (!foundError) {
        setErrorsPresent(false);
      }
      return foundError;
    },
    [required_fields, value]
  );

  useImperativeHandle(ref, () => ({
    runValidation() {
      let foundError = false;
      if (!allowZeroQty && (!value || value.length === 0) && required) {
        setErrorsPresent(true);
        foundError = true;
      } else {
        foundError = validateContainerRequests(allowZeroQty);
      }
      return foundError;
    },
  }));

  useEffect(() => {
    validateContainerRequests();
  }, [validateContainerRequests]);

  useEffect(() => {
    if (isLiveReefer) {
      const new_container_requests = container_requests.map((cr) => {
        const new_cr = cr;
        if (
          cr.container_type_code &&
          !Object.keys(containerSettingsMap.reefer).includes(cr.container_type_code)
        ) {
          new_cr['container_type_code'] = undefined;
          new_cr['container_type'] = undefined;
          new_cr['container_settings'] = undefined;
        }
        return new_cr;
      });
      if (onChange) onChange([...new_container_requests]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveReefer]);

  const onContainerFieldChange = useCallback(
    (key: string, value: any, index: number) => {
      const new_container_requests = container_requests;
      new_container_requests[index] = { ...new_container_requests[index], [key]: value };
      if (key === 'container_type_code') {
        new_container_requests[index]['container_type'] = value && containerTypesHash[value];
      }
      if (onChange) onChange([...new_container_requests]);
    },
    [container_requests, onChange]
  );

  const addContainerRequest = () => {
    const new_container_requests = [
      ...container_requests,
      {
        _id: _uniqueId('cr_'),
        ..._pick(new_cr, showcolumns),
      } as ContainerRequestValue,
    ];
    if (onChange) onChange(new_container_requests);
  };

  const removeContainerRequest = () => {
    const new_container_requests = container_requests.filter(
      (cr: ContainerRequestValue) =>
        !selectedRowKeys.includes(cr.id || '') && !selectedRowKeys.includes(cr._id || '')
    );
    setSelectedRowKeys([]);
    if (onChange) onChange(new_container_requests);
  };

  const getComponentProps = (col: any, record: ContainerRequestValue) => {
    if (col.dataIndex === 'quantity') {
      return {
        ...col.componentProps,
        ...(record.quantity_unfulfilled ? { min: record.quantity_fulfilled } : {}),
        disabled: disableFields.includes(col.dataIndex),
      };
    } else if (col.dataIndex === 'container_type_code') {
      return {
        ...col.componentProps,
        disabled:
          (record?.quantity_fulfilled || 0) > 0 || disableFields.includes('container_type_code'),
      };
    } else {
      return {
        ...col.componentProps,
        disabled: disableFields.includes(col.dataIndex),
      };
    }
  };

  const columns = getColumns(isLiveReefer, allowZeroQty).filter((column: any) =>
    showcolumns.includes(column?.dataIndex)
  );

  const containerColumns = columns.map((col: any) => {
    if (col.dataIndex === 'container_settings') {
      col.render = (title: string, record: ContainerRequestValue, index: number) =>
        renderContainerSettings(
          title,
          record,
          index,
          onContainerFieldChange,
          disableFields.includes(col.dataIndex)
        );
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any, rowIndex: number) => {
        return {
          record,
          rowIndex,
          editable:
            col.dataIndex === 'container_type_code'
              ? !record.disable_container_type && !disabled && col.editable
              : !disabled && col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          renderComponent: col.renderComponent,
          componentProps: getComponentProps(col, record),
          onFieldChange: onContainerFieldChange,
          required: required_fields.includes(col.dataIndex),
          errorsPresent: errorsPresent,
          value: value,
          showComponentValueWhenDisabled: col.showComponentValueWhenDisabled,
        };
      },
    };
  });

  const rowSelectionProps = !disableDelete
    ? {
        rowSelection: {
          selectedRowKeys,
          onChange: (selectedRowKeys: any) => setSelectedRowKeys(selectedRowKeys),
          getCheckboxProps: (record: ContainerRequestValue) => ({
            disabled: (record?.quantity_fulfilled || 0) > 0,
          }),
        },
      }
    : {};

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        {...rowSelectionProps}
        components={components}
        dataSource={container_requests}
        columns={containerColumns}
        pagination={false}
        rowKey={(record) => record.id || record._id || ''}
        size="small"
      />
      {errorsPresent && <Text type="danger">Please fill required fields</Text>}
      <Row style={{ margin: '10px' }} gutter={16}>
        <Button
          size="small"
          style={{
            marginRight: 16,
          }}
          onClick={addContainerRequest}
          disabled={disableAdd}
        >
          Add row
        </Button>
        <Button
          danger
          size="small"
          style={{
            marginRight: 16,
          }}
          disabled={selectedRowKeys.length === 0}
          onClick={removeContainerRequest}
        >
          Delete row(s)
        </Button>
      </Row>
    </div>
  );
});

export default ContainerDetails;
