/**
 * Conponent to be rendered in Container Tab in shipment view, booking order view
 * uses AgGrid to render containers
 */

import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'operations/models/Report';
import ActionRendererReport from 'operations/modules/actionHelper/ActionRenderer';
import { compact as _compact, get as _get } from 'lodash';
import { GET_SUBSCRIPTIONS } from './graphql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import EventRenderer from './EventRenderer';
import ContainerTypeAndSettingsRenderer from './ContainerTypeAndSettingsRenderer';
import LinkedHousesRenderer from './LinkedHousesRenderer';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import { fetchDocumentsCount } from '../../../helpers/shipmentHelper';
import {
  Layout,
  message,
  BaseTable,
  ActivityRenderer,
  FloatEditor,
  TrackerContextProvider,
  EnumEditor,
} from '@shipmnts/pixel-hub';
import DocTypeNumberRenderer from '../Common/DocTypeNumberRenderer';
import {
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  FREIGHT_TYPE_ROAD,
} from 'operations/modules/shipment/constants';
import { TrackingEventValue } from 'operations/models/TrackingEvent';
import { useSession } from 'common';
import { makeColumnForTab } from 'common';
import { UPDATE_SHIPMENT_CONTAINER, UPDATE_CONTAINER_CARGO_DETAIL } from './graphql';
import { FETCH_CONTAINER_FOR_EDIT } from 'operations/modules/booking/graphql/shipmentContainer';
import { getIsContainerDisabled } from './EditContainer/EditContainerForm';
import { GridOptions, SelectionChangedEvent } from '@ag-grid-community/core';
import BulkActionDrawer from 'operations/utils/BulkActionDrawer';
import { ActionRendererDetailReport } from 'operations';
// import { getLastEvent } from '../../Tracking/components/applications/EventsAndMilestones/helpers'
import { gql } from '@apollo/client';
import { FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';
import { oceanPackageTypesFlatArray } from 'operations/baseConstants';
const GET_CUSTOM_FIELDS_FROM_DOC_TYPE = gql`
  query get_custom_fields_from_doc_type($doc_type_id: String!) {
    get_custom_fields_from_doc_type(doc_type_id: $doc_type_id) {
      id
      db_column_name
      filterable
      width
      field_type
      label
      filter_options
      cell_renderer
      cell_editor
      type
    }
  }
`;
type Props = {
  parentType: 'shipment'; // prop for determining parent
  parentId: string; // id of shipment/br
  parentData: any; //parent_data will be shipment or booking request object
  containers: ShipmentContainerValue[];
  refetch: () => any;
  externalLink?: boolean;
};
export function getLastEvent(tracking_events: TrackingEventValue[], last_action_status?: string) {
  const last: any = tracking_events.find((e) => e.name === last_action_status);
  const event = last
    ? { ...last, current_location: last?.location?.name }
    : { name: last_action_status };
  return event;
}

const CARGO_DETAILS_EDIT_ALLOWED_SHIPMENTS = [
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_HOUSE,
];

const ContainerDetails = ({
  containers,
  refetch,
  parentType,
  parentId,
  parentData,
  externalLink = false,
}: Props) => {
  // Queries/Mutations
  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useQuery(GET_SUBSCRIPTIONS, {});
  const [documentsCount, setDocumentsCount] = useState<any>(null);

  const [isContainerEditDisabled, setIsContainerEditDisabled] = useState<boolean>();
  const [fetchContainerData, { data: containerData }] = useLazyQuery(FETCH_CONTAINER_FOR_EDIT, {
    fetchPolicy: 'no-cache',
  });
  const [getFieldsFromDocType, { data }] = useLazyQuery(GET_CUSTOM_FIELDS_FROM_DOC_TYPE);

  const [isBulkActionVisible, setIsBulkActionVisible] = useState<boolean>(false);
  const [selectedNodeCounts, setSelectedNodeCounts] = useState<number>(0);
  const gridRef = useRef<GridOptions>();
  const sessionData = useSession();

  const [updateShipmentContainer, { error, data: updateContainerData }] =
    useMutation(UPDATE_SHIPMENT_CONTAINER);
  const [updateCargoDetail, { error: updateCargoError, data: updateCargoData }] = useMutation(
    UPDATE_CONTAINER_CARGO_DETAIL
  );

  const updateShipmentContainerHandler = (id: number, values: any) => {
    updateShipmentContainer({
      variables: {
        id: id,
        shipment_container: values,
      },
    });
  };

  const updateCargoDetailHandler = (id: number, values: any) => {
    updateCargoDetail({
      variables: {
        id: id,
        container_cargo_detail: values,
      },
    });
  };

  const onSelectionChange = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedNodes();
    setIsBulkActionVisible(selectedRows.length > 0);
    setSelectedNodeCounts(selectedRows.length);
  };

  useEffect(() => {
    if ((containers || []).length > 0) {
      const ids = containers.map((c: any) => c.id);
      fetchDocumentsCount(
        'shipment_container',
        ids,
        sessionData.id,
        sessionData.company_account.id
      ).then((res) => {
        const count = _get(res, 'data.count');
        setDocumentsCount(count);
      });

      const cnt_id: number = parseInt(containers[0].id);
      fetchContainerData({
        variables: { id: cnt_id },
      });
    }
  }, [containers, fetchContainerData, sessionData.company_account.id, sessionData.id]);

  useEffect(() => {
    if (containerData?.fetch_basic_container_details_by_id) {
      setIsContainerEditDisabled(
        getIsContainerDisabled(containerData.fetch_basic_container_details_by_id)
      );
    }
  }, [containerData]);

  const [allColumnsDefs, setAllColumnsDefs] = useState<Column[]>([]);

  useEffect(() => {
    getFieldsFromDocType({ variables: { doc_type_id: 'Shipment::ShipmentContainer' } });
  }, [getFieldsFromDocType]);

  useEffect(() => {
    if (data) {
      const resultArray = data.get_custom_fields_from_doc_type.map((field: any) => {
        return makeColumnForTab(field);
      });
      setAllColumnsDefs(resultArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) message.error(error.message);
    if (updateCargoError) message.error(updateCargoError.message);
  }, [error, updateCargoError]);

  useEffect(() => {
    if (updateContainerData || updateCargoData) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContainerData, updateCargoData]);

  //Handle loading/Error
  if (subscriptionLoading) {
    return <div>loading...</div>;
  }
  if (subscriptionError) {
    return <div>error occured</div>;
  }

  let showOriginTracking = false; //flag to show LDB tracking
  const subscriptions = _get(subscriptionData, 'get_subscriptions', null);

  // columns
  const columnDefs: Column[] = _compact([
    {
      headerName: 'Container#',
      field: 'container_number',
      colId: 'container_number',
      filter: 'agTextColumnFilter',
      cellRenderer: 'render_tracker_drawer',
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 180,
    },
    {
      headerName: 'Container Type & Setting',
      field: 'container_type',
      colId: 'container_type',
      filter: 'agTextColumnFilter',
      width: 250,
      cellRenderer: 'container_type_and_setting_renderer',
    },
    parentType === 'shipment' &&
      parentData.freight_type === FREIGHT_TYPE_OCEAN && {
        headerName: 'Booking Number',
        field: 'booking_number',
        colId: 'booking_number',
        filter: 'agTextColumnFilter',
        pinned: 'left',
        width: 250,
      },
    parentType === 'shipment' &&
      parentData.shipment_type === SHIPMENT_TYPE_CONSOL && {
        headerName: '# Linked Houses',
        field: 'container_cargo_details',
        colId: 'container_cargo_details',
        width: 250,
        cellRendererSelector: (params) => {
          return params.node.isRowPinned() || params.data?.container_number
            ? {
                component: 'linked_houses_renderer',
                params: {
                  refetch: refetch,
                },
              }
            : { component: 'EmptyRenderer' };
        },
      },
    {
      headerName: 'Shipping Line Seal #',
      field: 'carrier_seal_number',
      colId: 'carrier_seal_number',
      width: 150,
    },
    {
      headerName: 'Shipper Seal #',
      field: 'shipper_seal_number',
      colId: 'shipper_seal_number',
      width: 150,
      hide: true,
    },
    {
      headerName: 'Customs Seal #',
      field: 'customs_seal_number',
      colId: 'customs_seal_number',
      width: 150,
      hide: true,
    },
    {
      headerName: 'No of Packages',
      field: 'total_number_of_packages',
      colId: 'total_number_of_packages',
      columnType: 'Float',
      editable: (o) => {
        const shipment_type = _get(o, 'data.parent_data.shipment_type');
        return (
          !o.node.isRowPinned() &&
          !isContainerEditDisabled &&
          CARGO_DETAILS_EDIT_ALLOWED_SHIPMENTS.includes(shipment_type)
        );
      },
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateCargoDetailHandler(params?.data?.container_cargo_details?.[0].id, {
          total_number_of_packages: parseInt(params.newValue),
        });
        return true;
      },
      width: 150,
    },
    {
      headerName: 'Type of Package',
      colId: 'type_of_package',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: oceanPackageTypesFlatArray,
      },
      editable: (o) => {
        const shipment_type = _get(o, 'data.parent_data.shipment_type');
        return (
          !o.node.isRowPinned() &&
          !isContainerEditDisabled &&
          CARGO_DETAILS_EDIT_ALLOWED_SHIPMENTS.includes(shipment_type)
        );
      },
      cellStyle: (params) => {
        const standardValue = oceanPackageTypesFlatArray.some(
          (item) => item.value === params.value
        );
        if (params.value && !standardValue) {
          return { background: '#FFCCCB' };
        }
        return null;
      },
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateCargoDetailHandler(params?.data?.container_cargo_details?.[0].id, {
          type_of_package: params.newValue,
        });
        return true;
      },
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      valueGetter: (params) => {
        return params?.data?.container_cargo_details?.[0]?.type_of_package;
      },
      width: 150,
    },
    {
      headerName: 'Gross Weight',
      field: 'found_cargo_gross_weight',
      colId: 'found_cargo_gross_weight',
      columnType: 'Float',
      editable: (o) => {
        const shipment_type = _get(o, 'data.parent_data.shipment_type');
        return (
          !o.node.isRowPinned() &&
          !isContainerEditDisabled &&
          CARGO_DETAILS_EDIT_ALLOWED_SHIPMENTS.includes(shipment_type)
        );
      },
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateCargoDetailHandler(params?.data?.container_cargo_details?.[0]?.id, {
          gross_weight: parseFloat(parseFloat(params.newValue).toFixed(3)),
        });
        return true;
      },
      width: 180,
    },
    {
      headerName: 'Gross Volume',
      field: 'found_cargo_gross_volume',
      colId: 'found_cargo_gross_volume',
      columnType: 'Float',
      editable: (o) => {
        const shipment_type = _get(o, 'data.parent_data.shipment_type');
        return (
          !o.node.isRowPinned() &&
          !isContainerEditDisabled &&
          CARGO_DETAILS_EDIT_ALLOWED_SHIPMENTS.includes(shipment_type)
        );
      },
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateCargoDetailHandler(params?.data?.container_cargo_details?.[0]?.id, {
          gross_volume: parseFloat(parseFloat(params.newValue).toFixed(3)),
        });
        return true;
      },
      width: 180,
    },
    {
      headerName: 'Net Weight',
      field: 'found_cargo_net_weight',
      colId: 'found_cargo_net_weight',
      columnType: 'Float',
      editable: (o) => {
        return (
          !o.node.isRowPinned() &&
          !isContainerEditDisabled &&
          CARGO_DETAILS_EDIT_ALLOWED_SHIPMENTS.includes(o?.data?.parent_data?.shipment_type)
        );
      },
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateCargoDetailHandler(params?.data?.container_cargo_details?.[0]?.id, {
          net_weight: parseFloat(parseFloat(params.newValue).toFixed(3)),
        });
        return true;
      },
      width: 180,
    },
    {
      headerName: 'VGM Weight',
      field: 'verified_gross_mass',
      colId: 'verified_gross_mass',
      columnType: 'Float',
      editable: (o) => !o.node.isRowPinned() && !isContainerEditDisabled,
      cellEditor: 'FloatEditor',
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateShipmentContainerHandler(params.data.id, {
          verified_gross_mass: parseFloat(params.newValue),
        });
        return true;
      },
      width: 180,
    },
    {
      headerName: 'Commercial Invoice #',
      field: 'commercial_invoice_number',
      colId: 'commercial_invoice_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned() && !isContainerEditDisabled,
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateShipmentContainerHandler(params.data.id, {
          commercial_invoice_number: params.newValue,
        });
        return true;
      },
      width: 180,
    },
    {
      headerName: 'Purchase Order #',
      field: 'purchase_order_number',
      colId: 'purchase_order_number',
      width: 180,
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned() && !isContainerEditDisabled,
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateShipmentContainerHandler(params.data.id, { purchase_order_number: params.newValue });
        return true;
      },
    },
    {
      headerName: 'Remarks',
      field: 'remarks',
      colId: 'remarks',
      width: 180,
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned() && !isContainerEditDisabled,
      valueSetter: (params) => {
        if (params.newValue === undefined || params.newValue === null) return false;
        updateShipmentContainerHandler(params.data.id, { remarks: params.newValue });
        return true;
      },
    },
    {
      headerName: 'Current Status',
      field: 'last_event',
      colId: 'last_event',
      rowGroup: true,
      hide: true,
      valueGetter: (params: any) => {
        if (params.data?.last_event?.name === 'Pickup Pending')
          return params.data?.last_event?.name;
      },
    },
    {
      headerName: 'Current Status',
      field: 'last_event',
      colId: 'last_event',
      minWidth: 200,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRendererSelector: (params) => {
        return params.node.isRowPinned() || params.data?.container_number
          ? {
              component: 'render_event',
              params: {
                doc_type_id: 'Shipment::ShipmentContainer',
                refetchData: refetch,
              },
            }
          : { component: 'EmptyValueRenderer' };
      },
    },
    {
      headerName: 'Comment',
      field: 'comment',
      colId: 'comment',
      width: 200,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'Comment',
        doc_type_id: 'Shipment::ShipmentContainer',
        refetchData: refetch,
        showDrawer: externalLink,
      },
    },
    {
      headerName: 'Status Update',
      field: 'status_update',
      colId: 'status_update',
      width: 200,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'StatusUpdate',
        doc_type_id: 'Shipment::ShipmentContainer',
        refetchData: refetch,
        showDrawer: externalLink,
      },
      lockVisible: true,
    },
    {
      headerName: 'Actions',
      colId: 'actions',
      field: 'actions',
      valueGetter: 'data',
      minWidth: 100,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      headerComponent: 'StaticHeaderComponent',
      cellRenderer: 'ActionRendererReport',
      cellRendererParams: {
        doc_type_id: 'Shipment::ShipmentContainer',
        refetchData: refetch,
      },
      lockVisible: true,
    },
  ]);

  // if subscription show app based tracker
  if (subscriptions) {
    showOriginTracking = subscriptions.some(
      (i: any) => i.app.name === 'LdbTracker' && i.plan_type === 'paid' //ToDo: add this in constants.ts
    );
    if (showOriginTracking)
      columnDefs.push({
        headerName: 'LDB Tracking',
        field: 'origin_tracking_event',
        colId: 'origin_tracking_event',
        cellRenderer: 'render_event',
        width: 258,
        cellRendererParams: { refetch: refetch },
      });
  }

  if (parentData?.freight_type === FREIGHT_TYPE_ROAD)
    columnDefs.push(
      {
        headerName: 'E-way #',
        field: 'eway_bill_no',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'E-way Validity',
        field: 'eway_bill_validity',
        colId: 'eway_bill_validity',
        columnType: 'Date',
        cellEditor: 'DateEditor',
        editable: false,
        valueSetter: (params) => {
          const newDate = params.newValue;
          if (typeof newDate !== 'number') {
            params.data.eway_bill_validity = params.newValue?.unix?.();
          } else {
            params.data.eway_bill_validity = params.newValue;
          }
          return true;
        },
        valueGetter: (params) => {
          return params.data.eway_bill_validity;
        },
      }
    );

  columnDefs.push(...allColumnsDefs);

  // adding aditional dettails in container
  const _containers = containers.map((container: ShipmentContainerValue) => {
    const el: any = { ...container };

    el.doc_count =
      documentsCount?.find((doc: any) => doc.parent_id && doc.parent_id === container.id)?.count ||
      0;

    //adding tracking event
    if (el.tracking_events) {
      el.last_event = getLastEvent(el.tracking_events, el.last_action_status);
    }
    //adding details from LDB tracker
    if (showOriginTracking) {
      const last_event = {
        ...container.tracking_statuses?.ldb_tracker?.last_event,
        last_tracked_at: container.tracking_statuses?.ldb_tracker?.last_tracked_at,
      };
      el.origin_tracking_event = last_event;
    }
    //adding details from cargo
    if (container.container_cargo_details) {
      if (
        parentType === 'shipment' &&
        (!parentData.shipment_type ||
          parentData.shipment_type === SHIPMENT_TYPE_HOUSE ||
          (parentData.split_from_order_id && parentData.freight_type === FREIGHT_TYPE_ROAD))
      ) {
        const cargo = container?.container_cargo_details.find(
          (i: any) => i.shipment_id === parentData.id
        );
        el.found_cargo_gross_weight = cargo?.gross_weight || 0;
        el.found_cargo_net_weight = cargo?.net_weight || 0;
        el.found_cargo_gross_volume = cargo?.gross_volume || 0;
        el.update_weight_on = 'cargo'; // to determine where to update gross_weight/net_weight
        el.total_number_of_packages = cargo?.total_number_of_packages || 0;
      } else {
        el.found_cargo_gross_weight = 0;
        el.found_cargo_gross_volume = 0;
        el.found_cargo_net_weight = 0;
        el.total_number_of_packages = 0;
        (container?.container_cargo_details || []).forEach((cargo_detail) => {
          if (
            cargo_detail.shipment_id !== parentData.id ||
            (parentType === 'shipment' &&
              [SHIPMENT_TYPE_DIRECT, SHIPMENT_TYPE_BACK_TO_BACK].includes(
                parentData?.shipment_type
              ))
          ) {
            el.total_number_of_packages += cargo_detail.total_number_of_packages || 0;
            el.found_cargo_gross_weight += cargo_detail.gross_weight || 0;
            el.found_cargo_net_weight += cargo_detail.net_weight || 0;
            el.found_cargo_gross_volume += cargo_detail.gross_volume || 0;
            if (!el.verified_gross_mass) el.verified_gross_mass = 0;
          }
        });
      }
    }
    //adding parent data
    el.parent_type = parentType;
    el.parent_id = parentId;
    el.parent_data = parentData;

    //adding comment and status_update
    el.status_update = container?.last_status_update;
    el.comment = container.last_comment;
    return el;
  });

  // framework components to be rendered
  const components = {
    container_type_and_setting_renderer: ContainerTypeAndSettingsRenderer,
    render_event: EventRenderer,
    render_tracker_drawer: DocTypeNumberRenderer,
    ActionRendererReport,
    linked_houses_renderer: LinkedHousesRenderer,
    render_activity: ActivityRenderer,
    FloatEditor,
    ActionRendererDetailReport,
    EnumEditor,
  };

  const renderCheckboxFor = (node: any) => {
    return true;
  };

  return (
    <div className="container-tab">
      <BulkActionDrawer
        isBulkActionVisible={isBulkActionVisible}
        setIsBulkActionVisible={setIsBulkActionVisible}
        gridRef={gridRef}
        doc_type_id={'Shipment::ShipmentContainer'}
        selectedNodeCounts={selectedNodeCounts}
        refetch={refetch}
      />
      <TrackerContextProvider referenceType="Shipment::ShipmentContainer">
        <Layout style={{ height: '100%' }}>
          <BaseTable
            reportName={'container_details'}
            columns={columnDefs}
            rowData={_containers}
            reportConfig={{
              groupDefaultExpanded: 0,
              components: components,
              rowHeight: 40,
              stopEditingWhenCellsLoseFocus: true,
              enableCellChangeFlash: true,
              groupDisplayType: 'groupRows',
            }}
            gridRef={gridRef}
            onSelectionChanged={onSelectionChange}
            rowSelection={renderCheckboxFor}
            checkbox_always_visible={false}
            allMapping={true}
          />
        </Layout>
      </TrackerContextProvider>
    </div>
  );
};

export default ContainerDetails;
