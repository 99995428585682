import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  message,
  BaseTable,
  SearchDocTypeEditor,
  Form,
  Button,
  Col,
  Row,
  FormInstance,
} from '@shipmnts/pixel-hub';
import { FETCH_AWB_DUE_CHARGES } from 'operations/graphql/shipmentEstimate';
import { ShipmentValue } from 'operations/models/Shipment';
import { useLazyQuery } from '@apollo/client';
import { errorMessageHandlerGraphQL } from 'common';
import { BASIS_TYPES } from 'operations/modules/reports/constants';
import { get as _get, startCase as _startCase } from 'lodash';
import { getDefaultShipmentValues } from './helper';
import { Column } from 'operations/models/Report';
import { FirstDataRenderedEvent, GridOptions } from '@ag-grid-community/core';
import { ShipmentEstimateValue } from 'operations/models/ShipmentEstimate';
import { ActionRendererDetailReport } from 'operations';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { useErpNextConfig } from 'network/utils/ErpNextConfigDataWrapper';
import { SP_FLOW } from 'network/constants';
import { useSession } from 'common/utils/SessionContext';
import { toJS } from 'mobx';

interface AwbChargeDataType {
  item: string;
  printable_item_name: string;
  rate: number;
  terms: string;
  uom: string;
  tag?: string;
}

interface AwbChargeDataExtendedType extends AwbChargeDataType {
  quantity: number;
  amount: number;
  id: number;
}

interface AwbChargesModalType {
  shipment: ShipmentValue;
  onClose: () => void;
  addItemsToEstimates: (estimates: Partial<ShipmentEstimateValue>[]) => void;
}

interface AwbChargesModalInternalType extends AwbChargesModalType {
  data: AwbChargeDataExtendedType[];
}

interface AccessibleBranch {
  id?: string | null;
  name?: string | null;
  erp_cost_center_id?: string | null;
}

interface BranchAccount {
  id?: string | null;
}

const getUOMMappedValue = (uom: string) => {
  const basisObj = BASIS_TYPES.find((basis) => basis.key === uom);
  return basisObj ? basisObj.name : uom;
};

const transformDataRows = (dataSource: AwbChargeDataExtendedType[], form?: FormInstance) => {
  return dataSource.map((row) => {
    return {
      buy_terms: row.terms,
      sell_terms: row.terms,
      tag: row.tag === '' ? null : row.tag,
      buy_exchange_rate: 1.0,
      sell_exchange_rate: 1.0,
      buy_rate: row.rate,
      sell_rate: row.rate,
      uom: row.uom || 'Nos',
      item: row.item,
      printable_item_name: row.printable_item_name,
      quantity: row?.quantity || 1.0,
      sell_branch_id: form?.getFieldValue('branch')?.id,
      buy_branch_id: form?.getFieldValue('branch')?.id,
      supplier_company_id: form?.getFieldValue('supplier')?.id,
      customer_company_id: form?.getFieldValue('customer')?.id,
    };
  });
};

const findIntersectionById = (
  shared_branches: AccessibleBranch[],
  user_branches: BranchAccount[]
): AccessibleBranch[] => {
  const intersection = shared_branches.filter((item1) =>
    user_branches.some((item2) => item1?.id === item2?.id)
  );
  return intersection;
};

export default function AwbChargesModal(props: AwbChargesModalType) {
  const { shipment, onClose, addItemsToEstimates } = props;
  const document_id = shipment.getShipmentDocument(undefined)?.document_id;
  const carrier_id = shipment.carrier?.id;

  const [fetchAWBDueCharges, { data, loading, error }] = useLazyQuery(FETCH_AWB_DUE_CHARGES);

  useEffect(() => {
    if (document_id && carrier_id) {
      fetchAWBDueCharges({
        variables: {
          document_id,
          carrier_id,
        },
      });
    }
  }, [carrier_id, document_id, fetchAWBDueCharges]);

  useEffect(() => {
    if (loading) message.loading('Fetching AWB Charges', 1);
  }, [loading]);

  if (!document_id || !carrier_id) {
    message.error('Please make sure AWB is created and carrier is filled in shipment');
    return <></>;
  }

  if (loading) {
    return <></>;
  }

  if (error) {
    return (
      <Modal
        title={'Select Items from AWB'}
        open
        key="awb_charge_items"
        okText={'Add Items to Estimates'}
        cancelText={'Close'}
        onCancel={onClose}
        width="80vw"
        style={{ top: 50 }}
      >
        {errorMessageHandlerGraphQL(error)}
      </Modal>
    );
  }

  if (!data) return <></>;

  const dataSource = _get(data, 'fetch_awb_due_charges', []).map(
    (row: AwbChargeDataExtendedType, i: number) => {
      const uom = getUOMMappedValue(row.uom) || '';
      const quantity = getDefaultShipmentValues(shipment)[uom] || 1;
      const amount = quantity * _get(row, 'rate', 0);
      return { ...row, id: i, uom, quantity, amount };
    }
  );

  return (
    <AwbChargesModalInternal
      onClose={onClose}
      shipment={shipment}
      data={dataSource}
      addItemsToEstimates={addItemsToEstimates}
    />
  );
}

function AwbChargesModalInternal(props: AwbChargesModalInternalType) {
  const { onClose, data, addItemsToEstimates, shipment } = props;
  const [loading, setLoading] = useState(false);
  const gridRef = useRef<GridOptions>();
  const session = useSession();
  const erpnextContext = useErpNextConfig();
  const { erpnextConfigData } = erpnextContext;
  const [form] = Form.useForm();

  const branchOptions = findIntersectionById(
    toJS(shipment?.accessible_by_branches) || [],
    toJS(session?.branch_accounts) || []
  );

  const isSpEnabled = erpnextConfigData?.create_interbranch_invoices_automatically === SP_FLOW;

  const [isButtonDisabled, setIsButtonDisabled] = useState(isSpEnabled);
  const [isItemSelected, setIsItemSelected] = useState<boolean>(false);

  const handleValuesChange = () => {
    if (isSpEnabled) {
      const branch = form.getFieldValue('branch')?.id;
      const supplier = form.getFieldValue('supplier')?.id;
      const customer = form.getFieldValue('customer')?.id;
      setIsButtonDisabled(!branch || !supplier || !customer);
    }
    onSelectionChanged();
  };

  const onSelectionChanged = () => {
    const selectedNodes = gridRef.current?.api?.getSelectedNodes();
    // Disable button if no rows are selected (length === 0), enable otherwise
    setIsItemSelected(selectedNodes?.length === 0);
  };

  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      gridApi.addEventListener('selectionChanged', onSelectionChanged);
    }
    return () => {
      if (gridApi) {
        gridApi.removeEventListener('selectionChanged', onSelectionChanged);
      }
    };
  }, [isItemSelected, isButtonDisabled]);

  const columnDefs: Column[] = [
    {
      headerName: 'Charge in AWB',
      field: 'printable_item_name',
      columnType: 'String',
      minWidth: 100,
      lockVisible: true,
    },
    {
      headerName: 'Charge in Accounts',
      field: 'item',
      columnType: 'String',
      minWidth: 150,
      editable: (o) => !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: {
        CustomComponent: GlobalSearch,
        componentProps: {
          doc_type: 'OrderManagement::ProductOrderItem',
          showCreation: true,
        },
      },
      valueSetter: (params) => {
        if (!params.newValue) {
          params.data.item = undefined;
          return true;
        }
        params.data.item = params.newValue.item_code;
        params.data.uom = params.newValue.stock_uom;
        params.data.tag = params.newValue.shipment_tags;
        return true;
      },
      lockVisible: true,
    },
    {
      headerName: 'Basis',
      field: 'uom',
      columnType: 'String',
      minWidth: 150,
      lockVisible: true,
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      aggFunc: undefined,
      columnType: 'Float',
      minWidth: 100,
      lockVisible: true,
    },
    {
      headerName: 'Rate',
      field: 'rate',
      columnType: 'Float',
      minWidth: 150,
      lockVisible: true,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      columnType: 'Float',
      minWidth: 150,
      lockVisible: true,
    },
    {
      headerName: 'Type',
      field: 'tag',
      columnType: 'String',
      valueFormatter: (params) => _startCase(params.value),
      minWidth: 150,
      lockVisible: true,
      keyCreator: (params) => _startCase(params?.value),
      filter: 'agSetColumnFilter',
      filterParams: {
        convertValuesToStrings: true,
      },
    },
  ];

  const startGridEdit = (event: FirstDataRenderedEvent) => {
    gridRef.current?.api?.startEditingCell({ rowIndex: 0, colKey: 'item' });
  };

  const createEstimates = async () => {
    if (!gridRef.current) {
      message.error('something is not right. please reopen the modal and try again');
      return;
    }

    const rows: AwbChargeDataExtendedType[] = [];
    gridRef.current?.api?.forEachNodeAfterFilter((node: any) => {
      if (node.selected) {
        rows.push(node.data);
      }
    });

    const item_missing_rows = rows.some((row: any) => !row.item);
    if (item_missing_rows) {
      message.error('Please enter Item in the selected rows');
      return;
    }
    setLoading(true);
    await addItemsToEstimates(transformDataRows(rows, form));
    onClose();
    setLoading(false);
  };

  return (
    <Modal
      title={'Select Items from AWB'}
      open
      key="awb_charge_items"
      confirmLoading={loading}
      onCancel={onClose}
      width="80vw"
      style={{ top: 50 }}
      footer={[
        <Button key="close" onClick={onClose}>
          {'Close'}
        </Button>,
        <Button
          key="Create"
          disabled={isButtonDisabled || isItemSelected}
          loading={loading}
          onClick={createEstimates}
          type="primary"
        >
          Add Items to Estimates
        </Button>,
      ]}
    >
      <BaseTable
        reportName={'awb_charges'}
        gridRef={gridRef}
        columns={columnDefs}
        rowData={data}
        rowSelection="multiple"
        reportConfig={{
          onFirstDataRendered: startGridEdit,
          components: {
            SearchDocTypeEditor,
            ActionRendererDetailReport,
          },
        }}
        showCheckBoxOnHeader
      />
      <br></br>
      {isSpEnabled ? (
        <Form form={form} onValuesChange={handleValuesChange}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item label="Branch" required rules={[{ required: true }]} name="branch">
                <GlobalSearch
                  doc_type="Network::BranchAccount"
                  disabled={false}
                  extraProps={{
                    options: branchOptions,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Customer" required rules={[{ required: true }]} name="customer">
                <GlobalSearch
                  doc_type="Network::Company"
                  searchProps={{
                    is_customer: true,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Supplier" required rules={[{ required: true }]} name="supplier">
                <GlobalSearch
                  doc_type="Network::Company"
                  searchProps={{
                    is_vendor: true,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <></>
      )}
    </Modal>
  );
}
