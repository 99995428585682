import { Form, Input } from '@shipmnts/pixel-hub';
import React from 'react';

export const Remarks = () => {
  return (
    <>
      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={2} />
      </Form.Item>
    </>
  );
};
