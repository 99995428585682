import React, { lazy, useState } from 'react';

import {
  PageHeader,
  Tag,
  Tooltip,
  CustomIcon,
  RenderCollaborators,
  getDateFromUnix,
  Button,
  StopOutlined,
  SelectPipelineModal,
  SelectStateInput,
  ArrowLeftOutlined,
  ColorPickerTool,
  getLighterColour,
} from '@shipmnts/pixel-hub';

import { useLocation } from 'wouter';

import { BreadCrumbsMapRender, countries, useSession } from 'common';

import {
  getInitials,
  getColorsForAvatar,
  COMPANY_STATUS_TAG_COLORS,
  COMPANY_STATUS_ARRAY,
  keyLabelMapping,
  CREDIT_STATUS,
  NEGATIVE_STATUS,
} from './helpers';
import { useCompanyView } from './CompanyView';
import { startCase as _startCase } from 'lodash';
import DisableCompany from '../DisableCompany';
import SendInvite from '../companyAccount/SendInvite';

const ActionRenderer = lazy(() => import('operations/modules/actionHelper/ActionRendererExport'));

const CompanySummary = ({ details }: any) => {
  return (
    <div className="company-summary">
      {details.map((detail: any, index: any) => {
        return (
          <div className="company-summary-child" key={index}>
            <div className="company-summary-child-title">{detail.title}</div>
            <div className="company-summary-child-value">{detail.value || '-'}</div>
          </div>
        );
      })}
    </div>
  );
};

export const CompanyHeader = ({
  showFooter = true,
  externalLink,
  internal,
}: {
  showFooter?: boolean;
  externalLink?: boolean;
  internal?: boolean;
}) => {
  const { company, onClose, refetch, setCompany, loading, fetchError } = useCompanyView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { 1: navigate } = useLocation();
  const session = useSession();

  const { company_type, company_stage, company_group, registered_name, logo, is_disabled, id } =
    company || {};

  const [colorCode, setColorCode] = useState(company?.color_code || '#FFFFFF');

  const showInvite = session
    .getSubscriptions()
    .some((i: any) => i.app?.name === 'Digital Booking Platform');
  return (
    <>
      {!onClose && !externalLink && !internal && (
        <div style={{ backgroundColor: getLighterColour(colorCode) }}>
          <div style={{ marginTop: '10px', marginLeft: '24px' }}>
            <BreadCrumbsMapRender />
          </div>
        </div>
      )}
      <div>
        <PageHeader
          className="company-view-header"
          style={{
            backgroundColor: getLighterColour(colorCode),
          }}
          onBack={() => {
            if (onClose) onClose();
            else if (company) {
              navigate(`~/workspace?doc_type=Network::Company&resource_id=${company.id}`);
            }
          }}
          backIcon={!!!externalLink || !!!internal ? <ArrowLeftOutlined /> : false}
          avatar={{
            src: logo || '',
            icon: !logo ? getInitials(registered_name) : 'India',
            shape: 'square',
            // size: 40,
            alt: registered_name,
            srcSet: 'wed3',
            style: {
              ...getColorsForAvatar(id),
              fontWeight: 600,
              borderRadius: '4px',
              fontSize: '18px',
              textAlign: 'center',
            },
          }}
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ColorPickerTool
                onChange={setColorCode}
                value={colorCode}
                docType={'Network::Company'}
                docId={company?.id}
              />
              <h4 style={{ color: 'var(--color-primary)', margin: 'auto' }}>{registered_name} </h4>
              {externalLink && (
                <span style={{ marginLeft: '10px' }}>
                  <Tooltip title="Open Detail Screen">
                    <a href={`/view/company/${id}`} target="_blank" rel="noreferrer">
                      <CustomIcon icon="ExternalLinkIcon" />
                    </a>
                  </Tooltip>
                </span>
              )}
            </div>
          }
          tags={[
            !!company && company?.status && CREDIT_STATUS.includes(company?.status) ? (
              <Tooltip title="Credit Status" showArrow={false}>
                <Tag
                  style={{ marginLeft: '8px', height: 'fit-content' }}
                  color={COMPANY_STATUS_TAG_COLORS[company?.status]}
                  key="status"
                >
                  {COMPANY_STATUS_ARRAY.includes(company?.status)
                    ? keyLabelMapping[company?.status]
                    : _startCase(company?.status)}
                </Tag>
              </Tooltip>
            ) : (
              <></>
            ),
            company_type && company_group !== 'Self' ? (
              <Tooltip title="Company Type" showArrow={false}>
                <Tag key={''} color="cyan" style={{ fontWeight: 600 }}>
                  {company_type}
                </Tag>
              </Tooltip>
            ) : (
              <></>
            ),
            company_stage &&
            company_type &&
            company_stage.toUpperCase() !== company_type.toUpperCase() &&
            !['Contact'].includes(company_type) ? (
              <Tooltip title="Company Stage" showArrow={false}>
                <Tag
                  key={''}
                  color={
                    NEGATIVE_STATUS.some((st) => company_stage?.toUpperCase().includes(st))
                      ? 'red'
                      : 'blue'
                  }
                  style={{ fontWeight: 600 }}
                >
                  {_startCase(company_stage.replace('_', ' '))}
                </Tag>
              </Tooltip>
            ) : (
              <></>
            ),
            is_disabled ? (
              <Tag key={''} color="red" style={{ fontWeight: 600 }}>
                {'Disabled'}
              </Tag>
            ) : (
              <></>
            ),
          ]}
          extra={[
            showInvite &&
            company &&
            company.connection_request?.status !== 'accepted' &&
            company.company_group !== 'Self' ? (
              <SendInvite key="send_invite" company={company} />
            ) : null,
            setCompany && (
              <DisableCompany
                icon={<StopOutlined />}
                disabled={loading || !!fetchError}
                key="disable"
                company={company}
                flag={!company?.is_disabled}
                setCompany={setCompany}
              />
            ),
            company?.company_group !== 'Self' && (
              <RenderCollaborators
                referenceId={id}
                referenceType={'Network::Company'}
                key=""
                id={registered_name}
              />
            ),
            company && (
              <ActionRenderer
                id={company.id}
                refetchData={refetch}
                data={company}
                isDetailScreen={true}
                doc_type_id="Network::Company"
              />
            ),
          ]}
        />
        {showFooter && (
          <CompanySummary
            details={[
              {
                title: 'Company Identification #',
                value: company?.company_identification_number,
              },
              { title: 'Company Category', value: company?.company_group },
              { title: 'Company Type', value: company?.entity_type },
              {
                title: 'Country of Inc.',
                value: countries.find(
                  (country) => country.code === company?.country_of_incorporation
                )?.name,
              },
              { title: 'Tax ID', value: company?.tax_registration_number },
              {
                title: 'Created By',
                value: [company?.created_by?.first_name, company?.created_by?.last_name].join(' '),
              },
              {
                title: 'Created At',
                value: company?.created_at ? getDateFromUnix(company?.created_at) : null,
              },
              {
                title: 'Pipeline',
                value: company?.pipeline?.id ? (
                  company?.company_group === 'Self' ? (
                    <Tag color="default">{company?.pipeline?.name || ''}</Tag>
                  ) : (
                    <SelectPipelineModal
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      pipeline_id={company?.pipeline?.id}
                      pipeline_name={company?.pipeline?.name}
                      doctype={'Network::Company'}
                      resource_id={company.id}
                      current_state_id={company.current_state?.id || ''}
                      onSuccess={refetch}
                    />
                  )
                ) : company?.company_group === 'Self' ? (
                  <></>
                ) : (
                  <>
                    <Button
                      type="primary"
                      ghost
                      size="small"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      Assign Pipeline
                    </Button>
                    {company && (
                      <SelectPipelineModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        pipeline_id={''}
                        pipeline_name={''}
                        doctype={'Network::Company'}
                        resource_id={company.id}
                        current_state_id={company.current_state?.id || ''}
                        onSuccess={refetch}
                      />
                    )}
                  </>
                ),
              },
              {
                title: 'Lead Stage',
                value:
                  company_stage &&
                  company_type &&
                  company_stage.toUpperCase() !== company_type.toUpperCase() &&
                  !['Contact'].includes(company_type) ? (
                    <span
                      style={
                        NEGATIVE_STATUS.some((st) => company_stage?.toUpperCase().includes(st))
                          ? { color: 'red' }
                          : {}
                      }
                    >
                      {_startCase(company_stage.replace('_', ' '))}
                    </span>
                  ) : (
                    '-'
                  ),
              },
              {
                title: 'Pipeline Status',
                value: company?.pipeline?.id ? (
                  company?.company_group === 'Self' ? (
                    company?.current_state?.name
                  ) : (
                    <SelectStateInput
                      pipeline_id={company.pipeline.id}
                      current_state_id={company.current_state?.id || ''}
                      resource_id={company.id}
                      onSuccess={refetch}
                      doctype={'Network::Company'}
                    />
                  )
                ) : null,
              },
            ]}
          />
        )}
      </div>
    </>
  );
};
