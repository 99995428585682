import React, { useEffect } from 'react';
import { Card, Button, Row, Col, Input, message, Form } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { BookingOrderOverviewProps } from './BookingOrderOverview';
import { errorMessageHandlerGraphQL, useSession } from 'common';
import { observer } from 'mobx-react-lite';
import { BOOKING_TYPE_SELF } from 'common/baseConstants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface BookingOrderOverviewCardProps extends BookingOrderOverviewProps {
  setOtoOverviewEdit: (value: boolean) => void;
}

const BookingOrderOverviewCard = observer(function BookingOrderOverview(
  props: BookingOrderOverviewCardProps
): JSX.Element {
  const { oceanTransportOrder, onUpdate, setOtoOverviewEdit } = props;
  const session = useSession();
  const [updateOceanTransportOrder, { data, loading, error }] = useMutation(
    UPDATE_OCEAN_TRANSPORT_ORDER
  );

  useEffect(() => {
    if (data?.update_ocean_transport_order?.id && !error) {
      message.success('Booking Order Details Updated!');
      if (onUpdate) onUpdate(data?.update_ocean_transport_order);
      setOtoOverviewEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, oceanTransportOrder, setOtoOverviewEdit]);

  return (
    <Form
      layout="vertical"
      initialValues={{
        booking_number: oceanTransportOrder.booking_number,
        sales_person: oceanTransportOrder.sales_person,
        customer: {
          party_address: oceanTransportOrder.customer_address,
        },
      }}
      onFinish={(values: any) => {
        const payload = {
          ocean_transport_order: {
            id: oceanTransportOrder?.id,
            booking_number: values.booking_number,
            sales_person_id: values?.sales_person?.id,
            customer_address_id: values?.customer?.party_address?.id,
          },
        };
        updateOceanTransportOrder({ variables: payload });
      }}
    >
      <Card
        title="Basic Details"
        extra={[
          <Button
            style={{ marginRight: '5px' }}
            key="cancel"
            onClick={() => setOtoOverviewEdit(false)}
            disabled={loading}
          >
            Cancel
          </Button>,
          <Button key="save" htmlType="submit" type="primary" loading={loading}>
            Save
          </Button>,
        ]}
      >
        {error && errorMessageHandlerGraphQL(error)}
        <Row gutter={16}>
          {
            // Booking Number Feild Disabled for Self Type
            oceanTransportOrder.booking_type !== BOOKING_TYPE_SELF ? (
              <Col span={5}>
                <Form.Item
                  required={true}
                  rules={[{ required: true }]}
                  name="booking_number"
                  label="Booking number"
                >
                  <Input placeholder="Booking Number" />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )
          }

          <Col span={5}>
            <Form.Item required rules={[{ required: true }]} name="sales_person" label="Order By">
              <GlobalSearch doc_type="Network::SalesPerson" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name={['customer', 'party_address']}
              label="Booked By Branch"
            >
              <GlobalSearch
                doc_type="Network::Address"
                searchProps={{
                  company_id: session.company_account.default_company.id,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
});

export default BookingOrderOverviewCard;
