import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { AddressCompanySearch, useSession } from 'common';
import { hasPermission } from '@shipmnts/pixel-hub';
import { CREDIT_CONTROL_FEATURE } from 'operations/baseConstants';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { ShipmentValue } from 'operations/models/Shipment';
import { PERMISSION_CREDIT_CONTROL_WRITE } from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
import React from 'react';
import {
  BOOKING_THROUGH_TYPES,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  BUSINESS_TYPE_SUBAGENT,
  ROW_GUTTER,
  MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
  MOVEMENT_MODE_AIRPORT_DELIVERY,
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
  MOVEMENT_MODE_AIRPORT_PICKUP,
} from '../../constants';
import { FREIGHT_TYPES, ROAD_LOAD_TYPES, TRADE_TYPES } from './constants';
import { MOVEMENT_MODES } from './constants';
import { PartyValue } from 'common/components/CustomInputs/AddressCompanySearch/AddressCompanySearch';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { prefilFormWithRouteValue } from './helpers';

interface BasicDetailsProps {
  shipment?: ShipmentValue;
  disableHash?: any;
  form?: FormInstance;
  hide?: boolean;
  showRouteSelection?: boolean;
}
export default function BasicDetails(props: BasicDetailsProps) {
  const { form, shipment, hide, disableHash, showRouteSelection = false } = props;
  const sessionData = useSession();
  const { permissions } = sessionData;
  const loadType = Form.useWatch('load_type', form);
  const tradeType = Form.useWatch('trade_type', form);
  const freightType = Form.useWatch('freight_type', form);
  const businessType = Form.useWatch('business_type', form);

  const id = Form.useWatch('id', form);

  const isCustomerDisabled =
    sessionData.isFeatureEnabled(CREDIT_CONTROL_FEATURE) &&
    !hasPermission(permissions, {
      name: PERMISSION_CREDIT_CONTROL_WRITE,
      docType: 'Network::Company',
    });

  const isSameParty = (party1?: PartyValue | null, party2?: PartyValue | null) => {
    if (party1 && party2) return party1?.party_company?.id === party2?.party_company?.id;
    else return false;
  };

  const getUpdatedPartyValue = (party?: PartyValue | null, value?: PartyValue | null) => {
    return {
      ...(party || null),
      party_company: value?.party_company,
      party_address: value?.party_address,
    };
  };

  return (
    <Row gutter={ROW_GUTTER}>
      <Form.Item noStyle hidden={hide}>
        <Form.Item name={'id'} noStyle></Form.Item>
        <Form.Item name={'consignee_party_name'} noStyle></Form.Item>
        {showRouteSelection && (
          <Col span={6}>
            <Form.Item required={false} label="Routing" name={'routing'}>
              <GlobalSearch
                doc_type="Shipment::Route"
                onChange={(routeValue) => {
                  prefilFormWithRouteValue(routeValue, form);
                }}
              />
            </Form.Item>
          </Col>
        )}

        <Col span={6}>
          <Form.Item rules={[{ required: !hide }]} name="freight_type" label="Freight Type">
            <Radio.Group disabled={true}>
              {FREIGHT_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item rules={[{ required: !hide }]} name="trade_type" label="Trade Type">
            <Radio.Group disabled={disableHash?.trade_type || (!!shipment && !!id)}>
              {TRADE_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={showRouteSelection ? 6 : 10}>
          <Form.Item rules={[{ required: !hide }]} name="load_type" label="Load Type">
            <Radio.Group disabled={!!shipment && !!id}>
              {ROAD_LOAD_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item rules={[{ required: !hide }]} name={'involved_branch_id'} label="Branch">
            <Select
              showSearch
              placeholder="Booked By Branch"
              filterOption={(input, option) =>
                `${option?.children || ''}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={isCustomerDisabled && !!shipment && !!id}
            >
              {(sessionData?.branch_accounts || []).map((branch: { id: string; name: string }) => (
                <Select.Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="job_date" rules={[{ required: !hide }]} label="Job Date">
            <DatePicker style={{ width: '100%' }} disabled={!!id && !!shipment?.job_date} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true }]}
            label="Business Booked Through"
            name="business_type"
          >
            <Radio.Group>
              {BOOKING_THROUGH_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="sales_agent" label="Sales Person">
            <GlobalSearch doc_type="Network::SalesPerson" />
          </Form.Item>
          {[BUSINESS_TYPE_SUBAGENT, BOOKING_THROUGH_OVERSEAS_AGENT].includes(businessType) && (
            <Form.Item
              rules={[{ required: !hide }]}
              name={[
                'party',
                `${businessType === BUSINESS_TYPE_SUBAGENT ? 'subagent' : 'overseas_agent'}`,
              ]}
              label={`${businessType === BUSINESS_TYPE_SUBAGENT ? 'Subagent' : 'Overseas Agent'}`}
            >
              <AddressCompanySearch />
            </Form.Item>
          )}
        </Col>

        <Col span={8}>
          <Form.Item name="customer" required rules={[{ required: true }]} label="Customer">
            <AddressCompanySearch
              customerSearchProps={{
                trigger: 'create_shipment',
              }}
              companySearchProps={{ disabled: isCustomerDisabled && !!shipment && !!id }}
              addressSearchProps={{
                searchProps: { entity_type: 'billing' },
              }}
              onChange={(value: any) => {
                if (
                  value?.party_company?.sales_partner?.country_of_incorporation &&
                  sessionData?.company_account?.country_of_incorporation
                ) {
                  if (
                    value.party_company.sales_partner.country_of_incorporation ===
                    sessionData.company_account.country_of_incorporation
                  ) {
                    form?.setFieldValue('business_type', 'subagent');
                  } else {
                    form?.setFieldValue('business_type', 'agent_nomination');
                  }
                  form?.setFieldValue(
                    [
                      'party',
                      `${businessType === BUSINESS_TYPE_SUBAGENT ? 'subagent' : 'overseas_agent'}`,
                    ],
                    {
                      party_company: value.party_company.sales_partner,
                    }
                  );
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item noStyle dependencies={['customer', 'shipper_same_as_customer']}>
            {({ getFieldValue }) => {
              const customer = getFieldValue('customer');
              const shipperSameAsCustomer = getFieldValue('shipper_same_as_customer');
              const shipper = getFieldValue(['party', 'shipper']);
              if (shipperSameAsCustomer) {
                if (customer && !shipment?.id)
                  form?.setFieldValue(
                    ['party', 'shipper'],
                    getUpdatedPartyValue(shipper, customer)
                  );
              } else if (isSameParty(shipper, customer)) {
                form?.setFieldValue(['party', 'shipper'], getUpdatedPartyValue(shipper, null));
              }

              return (
                <Form.Item name={['party', 'shipper']} label="Shipper">
                  <AddressCompanySearch
                    onChange={(shipper) => {
                      const customer = form?.getFieldValue('customer');
                      if (isSameParty(shipper, customer))
                        form?.setFieldValue('shipper_same_as_customer', true);
                      else {
                        form?.setFieldValue('shipper_same_as_customer', false);
                      }
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['movement_mode']}>
            {({ getFieldValue }) => {
              if (!shipment?.id) {
                const movementMode = getFieldValue('movement_mode');

                if (
                  [
                    MOVEMENT_MODE_CARGO_PICKUP_AND_DROP,
                    MOVEMENT_MODE_CONTAINER_STUFFING,
                    MOVEMENT_MODE_AIRPORT_DELIVERY,
                  ].includes(movementMode)
                ) {
                  form?.setFieldValue('shipper_same_as_customer', true);
                } else form?.setFieldValue('shipper_same_as_customer', false);
              }
              return (
                <Form.Item name={'shipper_same_as_customer'} noStyle valuePropName="checked">
                  <Checkbox
                    onChange={(e) => {
                      const shipper = form?.getFieldValue(['party', 'shipper']);
                      const customer = form?.getFieldValue(['customer']);
                      if (!e.target.checked) {
                        if (isSameParty(shipper, customer))
                          form?.setFieldValue(
                            ['party', 'shipper'],
                            getUpdatedPartyValue(shipper, null)
                          );
                      } else {
                        form?.setFieldValue(
                          ['party', 'shipper'],
                          getUpdatedPartyValue(shipper, customer)
                        );
                      }
                      form?.setFieldValue('shipper_same_as_customer', e.target.checked);
                    }}
                  >
                    Same As Customer
                  </Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item noStyle dependencies={['customer', 'consignee_same_as_customer']}>
            {({ getFieldValue }) => {
              const customer = getFieldValue('customer');
              const consigneeSameAsCustomer = getFieldValue('consignee_same_as_customer');
              const consignee = getFieldValue(['party', 'consignee']);
              if (consigneeSameAsCustomer) {
                if (customer && !shipment?.id)
                  form?.setFieldValue(
                    ['party', 'consignee'],
                    getUpdatedPartyValue(consignee, customer)
                  );
              } else if (isSameParty(consignee, customer)) {
                form?.setFieldValue(['party', 'consignee'], getUpdatedPartyValue(consignee, null));
              }

              return (
                <Form.Item name={['party', 'consignee']} label="Consignee">
                  <AddressCompanySearch
                    onChange={(consignee) => {
                      const customer = form?.getFieldValue('customer');
                      if (isSameParty(consignee, customer))
                        form?.setFieldValue('consignee_same_as_customer', true);
                      else {
                        form?.setFieldValue('consignee_same_as_customer', false);
                      }
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item noStyle dependencies={['movement_mode']}>
            {({ getFieldValue }) => {
              if (!shipment?.id) {
                const movementMode = getFieldValue('movement_mode');
                if (
                  [MOVEMENT_MODE_CONTAINER_DESTUFFING, MOVEMENT_MODE_AIRPORT_PICKUP].includes(
                    movementMode
                  )
                )
                  form?.setFieldValue('consignee_same_as_customer', true);
                else form?.setFieldValue('consignee_same_as_customer', false);
              }
              return (
                <Form.Item name={'consignee_same_as_customer'} noStyle valuePropName="checked">
                  <Checkbox
                    onChange={(e) => {
                      const consignee = form?.getFieldValue(['party', 'consignee']);
                      const customer = form?.getFieldValue(['customer']);
                      if (!e.target.checked) {
                        if (isSameParty(consignee, customer))
                          form?.setFieldValue(
                            ['party', 'consignee'],
                            getUpdatedPartyValue(consignee, null)
                          );
                      } else {
                        form?.setFieldValue(
                          ['party', 'consignee'],
                          getUpdatedPartyValue(consignee, customer)
                        );
                      }

                      form?.setFieldValue('consignee_same_as_customer', e.target.checked);
                    }}
                  >
                    Same As Customer
                  </Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            noStyle
            dependencies={[['is_external_quotation_number'], ['customer'], ['involved_branch_id']]}
          >
            {({ getFieldValue }) => {
              const is_external_quotation_number = getFieldValue('is_external_quotation_number');
              const customer = getFieldValue('customer');
              const branch_id = getFieldValue('involved_branch_id');
              if (is_external_quotation_number)
                return (
                  <Form.Item name="quotation_number" label="Quotation Ref">
                    <Input placeholder="Enter quotation number" />
                  </Form.Item>
                );
              return (
                <Form.Item name="inquiry_option" label="Quotation Ref">
                  <GlobalSearch
                    doc_type="SalesHub::InquiryOption"
                    // Passing key, because we want to reset select options on customer change
                    key={customer?.party_company?.id}
                    disabled={!customer || !branch_id}
                    searchProps={{
                      customer_company_id: customer?.party_company?.id,
                      involved_branch_id: branch_id,
                      freight_type: freightType,
                      trade_type: tradeType,
                      load_type: loadType,
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item name="is_external_quotation_number" valuePropName="checked">
            <Checkbox>External Quotation #</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchase_order_number" label="Customer's PO #">
            <Input placeholder="Enter Customer's PO #" />
          </Form.Item>
        </Col>
      </Form.Item>
      <Col span={8}>
        <Form.Item rules={[{ required: !hide }]} name={'movement_mode'} label="Movement Mode">
          <Select disabled={!!shipment && !!id} placeholder="Movement Mode">
            {MOVEMENT_MODES.map((movementMode) => (
              <Select.Option key={movementMode.key} value={movementMode.key}>
                {movementMode.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
