import React, { useEffect, useMemo } from 'react';
import {
  Form,
  Button,
  DeleteOutlined,
  Popconfirm,
  Select,
  Table,
  Tag,
  FormInstance,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common/utils/SessionContext';
import { pick, startCase, uniq } from 'lodash';
import {
  TEAM_ROLE_CREDIT_CONTROLLER,
  TEAM_ROLE_DOCUMENTATION,
  TEAM_ROLE_OPTIONS,
  TEAM_ROLE_SALES,
} from 'network/baseConstants';
import { TeamValue } from 'network/commonTypeDefs';
import { SALES_PERSON_DISABLED_FEATURE } from 'operations/baseConstants';
interface TableFromProps {
  form?: FormInstance;
  editable?: boolean;
  value?: Array<TeamValue>;
  onChange?: (value: Array<TeamValue>) => void;
  reference_type: string;
  reference_id?: string;
}

const REFERENCES_TYPE_WITH_MULTIPLE_TEAM_ROLE = ['Network::Company'];
const SALES_PERSON_DISABLED_DOCTYPES = ['Shipment::Shipment', 'SalesHub::Inquiry'];

const mapValueToTableData = (value: Array<TeamValue>, reference_type: string): Array<TeamValue> => {
  return value?.map((value) => {
    let recordDisabled = false;
    if (value.role === 'sales' && reference_type === 'Shipment::Shipment') recordDisabled = true;
    if (value.preFilled) recordDisabled = false;
    return {
      ...value,
      isViewOnly: recordDisabled,
    };
  });
};

export const getRoleOptions = (reference_type: string, editable = true) => {
  let options = TEAM_ROLE_OPTIONS;
  if (editable) {
    if (reference_type === 'SalesHub::Inquiry') {
      options = options.filter(
        (role) => ![TEAM_ROLE_CREDIT_CONTROLLER, TEAM_ROLE_DOCUMENTATION].includes(role.value)
      );
    } else if (reference_type === 'Shipment::Shipment') {
      options = options.filter((role) => ![TEAM_ROLE_SALES].includes(role.value));
    }
  }
  return options;
};

const TeamFormTable = (props: TableFromProps) => {
  const { form, editable = true, value: teamValue, reference_type } = props;
  const session = useSession();
  const teamValues = Form.useWatch('teams', form);

  const isSalesPersonDisabled = useMemo(
    () => session?.isFeatureEnabled(SALES_PERSON_DISABLED_FEATURE),
    [session]
  );

  const { company_account } = session;

  useEffect(() => {
    if (editable) {
      const value = form?.getFieldValue('teams');
      const newTableData = value ?? [];
      const presentRoles = uniq(value?.map((data: any) => data.role));
      getRoleOptions(reference_type, editable).forEach((option) => {
        !presentRoles.includes(option.value) && newTableData.push({ role: option.value });
      });
      form?.setFieldValue('teams', mapValueToTableData(newTableData, reference_type));
    }
  }, [editable, form, reference_type, teamValues]);

  const getColumns = (fields: any) => {
    const columnDefs = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        hidden: !editable,
        width: '5%',
        render(value: any, record: any, index: any) {
          return index + 1;
        },
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: '20%',
        render(value: any, record: any, index: any) {
          if (!editable || record.isViewOnly) return startCase(value);
          return (
            <Form.Item
              name={[record.field_name, 'role']}
              key={record.key}
              rules={[{ required: true, message: 'Role is required' }]}
            >
              <Select
                options={getRoleOptions(reference_type, editable)}
                value={value}
                onChange={(value) => form?.setFieldValue([record.field_name, 'role'], value)}
                style={{ width: '100%' }}
                popupMatchSelectWidth={150}
              />
            </Form.Item>
          );
        },
      },
      {
        title: 'Team Member',
        dataIndex: 'team_member',
        key: 'team_member',
        width: '25%',
        render(value: any, record: any, index: any) {
          if (record.role === TEAM_ROLE_SALES) {
            if (!editable || record.isViewOnly)
              return <span>{record.sales_person?.name ?? '-'}</span>;
            return (
              <Form.Item name={[record.field_name, 'sales_person']} key={record.key}>
                <GlobalSearch
                  doc_type="Network::SalesPerson"
                  value={record.sales_person}
                  disabled={
                    !record.role ||
                    (SALES_PERSON_DISABLED_DOCTYPES.includes(reference_type) &&
                      isSalesPersonDisabled)
                  }
                />
              </Form.Item>
            );
          }
          if (!editable || record.isViewOnly) return <span>{record.user_contact?.name ?? ''}</span>;
          return (
            <Form.Item name={[record.field_name, 'user_contact']} key={record.key}>
              <GlobalSearch
                doc_type="Network::UserContact"
                value={record.user_contact}
                disabled={!record.role}
                showCreation={false}
                extraProps={{ company: company_account.default_company }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: 'Handling Branch',
        dataIndex: 'branch_accounts',
        key: 'branch_accounts',
        width: '45%',
        render(value: any, record: any, index: any) {
          if (!editable || record.isViewOnly) {
            return (
              <div>
                {value?.map((v: any) => (
                  <Tag key={v.id}>{v.name}</Tag>
                ))}
              </div>
            );
          }
          return (
            <Form.Item name={[record.field_name, 'branch_accounts']} key={record.key}>
              <GlobalSearch
                doc_type="Network::BranchAccount"
                value={value}
                onChange={(value) => {
                  form?.setFieldValue(
                    [record.field_name, 'branch_accounts'],
                    (value as any).map((v: any) => pick(v, ['id', 'name']))
                  );
                }}
                selectMode="multiple"
                disabled={!editable}
              />
            </Form.Item>
          );
        },
        hidden: reference_type === 'Shipment::Shipment' || reference_type === 'SalesHub::Inquiry',
      },
      {
        hidden:
          !editable ||
          !(
            REFERENCES_TYPE_WITH_MULTIPLE_TEAM_ROLE.includes(reference_type) ||
            getRoleOptions(reference_type, editable).length > fields.length
          ),
        width: '5%',
        render(value: any, record: any, index: any) {
          if (record.isViewOnly) return <></>;
          return (
            <Form.Item key={record.key}>
              {record.role ? (
                <Popconfirm
                  key={index}
                  title={`Are you sure?`}
                  onConfirm={() => record.handleRemove(record.field_name)}
                >
                  <Button key={index} danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              ) : (
                <Button key={index} danger onClick={() => record.handleRemove(record.field_name)}>
                  <DeleteOutlined />
                </Button>
              )}
            </Form.Item>
          );
        },
      },
    ];

    return columnDefs;
  };

  return (
    <Form.List name="teams">
      {(fields, { add, remove }) => {
        const dataSource = fields.map((field, index) => {
          return {
            ...form?.getFieldValue(['teams', field.name ?? index]),
            key: field.key ?? index,
            field_name: field.name ?? index,
            handleRemove: remove,
          };
        });
        return (
          <>
            <Table
              columns={getColumns(fields)}
              dataSource={teamValue ?? dataSource}
              pagination={false}
              scroll={editable ? { x: 'max-content', y: 300 } : {}}
              style={{ width: '100%' }}
              className="team-table"
            />
            {editable &&
              (REFERENCES_TYPE_WITH_MULTIPLE_TEAM_ROLE.includes(reference_type) ||
                getRoleOptions(reference_type, editable).length > fields.length) && (
                <Form.Item>
                  <Button
                    style={{ marginTop: '10px' }}
                    onClick={() => add({ role: '', team_member: '', branch_accounts: [] })}
                  >
                    Add Row
                  </Button>
                </Form.Item>
              )}
          </>
        );
      }}
    </Form.List>
  );
};

export default TeamFormTable;
