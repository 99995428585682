import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import Carrier from './Carrier';
import Vessel from './Vessel';

const LinkedCarrier = Carrier.named('LinkedCarrier').props({
  id: types.maybe(types.maybeNull(types.number)),
});

const LinkedVessel = Vessel.named('LinkedVessel').props({
  imo: types.maybe(types.maybeNull(types.number)),
});

const LinkShipmentJson = types.model({
  carrier_id: types.maybe(types.maybeNull(types.string)),
  carrier: types.maybe(types.maybeNull(types.late((): IAnyModelType => LinkedCarrier))),
  lfd_at_empty_return: types.maybe(types.maybeNull(types.string)),
  lfd_at_pod: types.maybe(types.maybeNull(types.string)),
  ocean_vessel_id: types.maybe(types.maybeNull(types.string)),
  ocean_vessel: types.maybe(types.maybeNull(LinkedVessel)),
  voyage_number: types.maybe(types.maybeNull(types.string)),
  estimated_time_of_arrival: types.maybe(types.maybeNull(types.string)),
  estimated_time_of_departure: types.maybe(types.maybeNull(types.string)),
  gate_close_date: types.maybe(types.maybeNull(types.string)),
  valid_till_date: types.maybe(types.maybeNull(types.string)),
  booking_number: types.maybe(types.maybeNull(types.string)),
});

export type LinkShipmentJsonValue = Instance<typeof LinkShipmentJson>;
export default LinkShipmentJson;
