import {
  BL_TYPE_ARRAY,
  RELEASE_TYPE_ARRAY,
  CHARGE_TERMS,
  CONSIGNEE_TYPE_ACTUAL_CONSIGNEE,
  TradeType,
} from 'operations/modules/shipment/constants';
import { Dayjs } from '@shipmnts/pixel-hub';
import { LocationValue } from 'operations/models/Location';
import { CompanyValue } from 'operations/models/Company';
import { AddressValue } from 'operations/models/Address';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { InquiryOptionValue } from 'operations/models/InquiryOption';

export interface PartyValue {
  id?: string;
  name?: string;
  party_company?: CompanyValue;
  party_address?: AddressValue;
  print_address?: string | null;
}
export interface CreateShipmentFormBRValue {
  id: string;
  consignee_company?: CompanyValue | null;
  consignee_address?: AddressValue | null;
  quotation_number?: string | null;
  inquiry_option?: InquiryOptionValue | null;
  is_external_quotation_number?: boolean | null;
  incoterm?: string | null;
  remarks?: string | null;
  load_type?: string | null;
  port_of_loading: LocationValue;
  port_of_discharge: LocationValue;
  place_of_carrier_receipt: LocationValue;
  place_of_carrier_delivery: LocationValue;
  trade_type: TradeType;
}

export interface DocumentDetailFormValue {
  shipment_document?: {
    bl_type?: string;
    release_type?: string;
    charge_terms?: {
      freight_terms: 'prepaid' | 'collect' | undefined;
      other_charges_terms: 'prepaid' | 'collect' | undefined;
    };
    origin_agent_same_as_shipper?: boolean;
    destination_agent_same_as_consignee?: boolean;
    shipment_number?: string;
    document_status?: string;
    shipment_date?: Dayjs;
    document_status_event_date?: Dayjs;
  };
  consignee_party_name?: string;
  party?: {
    [key: string]: ShipmentPartyValue | undefined;
  };
}

export const shipment_document_initial_value = {
  shipment_document: {
    bl_type: BL_TYPE_ARRAY[0].key,
    release_type: RELEASE_TYPE_ARRAY[0].key,
    charge_terms: {
      freight_terms: CHARGE_TERMS[0].key,
      other_charges_terms: CHARGE_TERMS[0].key,
    },
  },
  consignee_party_name: CONSIGNEE_TYPE_ACTUAL_CONSIGNEE.key,
};
