import React, { useEffect } from 'react';
import ChangeLoadTypeContent from './ChangeLoadTypeContent';
import { Button, Form, InfoCircleOutlined, Modal, Tooltip } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { LOAD_TYPE_OPTIONS } from 'operations/utils/constants';
import { CHANGE_LOAD_TYPE } from 'operations/graphql/shipment';
import { useMutation } from '@apollo/client';
import { omit as _omit } from 'lodash';

function ChangeLoadTypeDrawer(props: {
  open: boolean;
  shipment?: ShipmentValue;
  onClose?: () => void;
  onSucess?: () => void;
}) {
  const { open, onClose, shipment, onSucess } = props;
  const [form] = Form.useForm();
  const LoadTypeOptions = LOAD_TYPE_OPTIONS[shipment?.freight_type || 'air'].filter(
    (option: any) => option.value !== shipment?.load_type
  );
  const [changeLoadType, { data, error, loading }] = useMutation(CHANGE_LOAD_TYPE);

  useEffect(() => {
    if (data && !error && onSucess) onSucess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal
      open={open}
      title={
        <>
          <div>
            <Tooltip title="Changing load type will lead to change in container quantity in booking.">
              <InfoCircleOutlined />
            </Tooltip>{' '}
            &nbsp; Change Load Type
          </div>
        </>
      }
      width={'60%'}
      onCancel={() => {
        if (onClose) onClose();
      }}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          changeLoadType({
            variables: {
              shipment_id: shipment?.id,
              load_type: values?.load_type,
              container_requests: values?.container_requests?.map((cr: any) => _omit(cr, ['_id'])),
            },
          });
        }}
        initialValues={{ load_type: LoadTypeOptions?.[0]?.value }}
      >
        <ChangeLoadTypeContent shipment={shipment} />
      </Form>
    </Modal>
  );
}

export default ChangeLoadTypeDrawer;
