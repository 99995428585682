import { CarrierValue } from 'operations/models/Carrier';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  getCargoPayloadWithExistingCargo,
  getContainerFromContainerQty,
  getRoutingLegsPayload,
  getShipmentInvoicePayload,
  shipmentCustomDetailPayload,
} from '../ShipmentForm/helpers';
import { pick as _pick, omit as _omit } from 'lodash';
import { Dayjs } from '@shipmnts/pixel-hub';
import { SHIPMENT_TYPE_CONSOL } from '../../constants';
import {
  getAllocationPayloadNew,
  getNewOtoPayload,
} from 'operations/modules/reports/helpers/allocationHelper';
import { convertDateToUnix } from 'operations/modules/reports/components/ShipmentReports/ShipmentEditFieldAction';
import { transformPartyDetails } from 'operations/models/ShipmentParty';
import { FREIGHT_TYPE_AIR } from 'operations/utils/constants';
import { convertToStringFormat } from '@shipmnts/pixel-hub';

export const getShipmentRoutingDetails = (values: any, shipment: any) => {
  if (shipment && shipment?.id !== 'new') {
    const routing_legs = shipment.shipment_routing_details.sort(
      (a: any, b: any) => a.route_order - b.route_order
    );
    const first_leg = routing_legs[0];
    const last_leg = routing_legs[shipment?.shipment_routing_details?.length - 1];
    let updated_first_leg = null;
    let updated_last_leg = null;
    if (first_leg) {
      updated_first_leg = {
        id: first_leg?.id,
        location_id: values?.port_of_loading?.id,
      };
    }
    if (last_leg) {
      updated_last_leg = {
        id: last_leg?.id,
        location_id: values?.port_of_discharge?.id,
        voyage_number: values?.voyage_number,
        actual_time_of_arrival: values?.actual_time_of_arrival
          ? values?.actual_time_of_arrival.unix()
          : null,
      };
    }
    return [updated_first_leg, updated_last_leg];
  } else {
    return [
      {
        location_id: values?.port_of_loading?.id,
        location_tags: ['port_of_loading'],
        route_order: 1,
      },
      {
        location_id: values?.port_of_discharge?.id,
        location_tags: ['port_of_discharge'],
        route_order: 100,
        voyage_number: values?.voyage_number,
        actual_time_of_arrival: values?.actual_time_of_arrival
          ? values?.actual_time_of_arrival.unix()
          : null,
      },
    ];
  }
};

export const transformShipmentEventDetails = (
  events: { [key: string]: any },
  shipment: ShipmentValue | null | undefined,
  session: any
) => {
  if (!events) return [];
  return Object.keys(events).map((partyKey) => {
    const event = events[partyKey];
    const shpEvent = shipment?.shipment_events?.find((e) => e.name === partyKey);
    return {
      ...event,
      id: shpEvent?.id,
      event_by_id: shpEvent?.event_by?.id || session?.id,
      name: partyKey,
    };
  });
};

export const getGenericPayloadValue = (
  values: any,
  shipment?: ShipmentValue | undefined | null,
  clearanceShipment?: boolean,
  masterShipmentId?: string | null
) => {
  const oto: any = getNewOtoPayload(values, clearanceShipment, masterShipmentId);
  const party = values?.party;

  // If the party is not present in the form, then we need to get the party from the shipment to delete the party
  Object.keys(party).forEach((key) => {
    if (!party[key] || !party[key].id) {
      party[key] = {
        ...(party[key] ?? {}),
        id: shipment?.shipment_parties?.find((p) => p.name === key)?.id,
      };
    }
  });

  if (
    values?.consignee_party_name === 'to_order' ||
    values?.consignee_party_name === 'to_order_bank'
  ) {
    party[values?.consignee_party_name] = {
      ...party[values?.consignee_party_name],
      id: shipment?.shipment_parties?.find((p: any) => p.name === values?.consignee_party_name)?.id,
    };
  }
  const defaultServiceValues = {
    origin: {
      clearance: false,
      transport: false,
      fumigation: false,
      palletization: false,
    },
    destination: {
      clearance: false,
      transport: false,
    },
    cargo_insurance: false,
    freight_forwarding: false,
  };
  const services = {
    ...defaultServiceValues,
    origin: {
      ...defaultServiceValues.origin,
      ...Object.fromEntries(
        Object.entries(values.services?.origin || {}).map(([key, value]) => [key, value ?? false])
      ),
    },
    destination: {
      ...defaultServiceValues.destination,
      ...Object.fromEntries(
        Object.entries(values.services?.destination || {}).map(([key, value]) => [
          key,
          value ?? false,
        ])
      ),
    },
    freight_forwarding:
      values.services?.freight_forwarding || defaultServiceValues.freight_forwarding,
    cargo_insurance: values.services?.cargo_insurance || defaultServiceValues.cargo_insurance,
  };
  const payload = {
    ...values,
    services: services,
    sales_agent_id: values?.sales_agent?.id || null,
    ocean_transport_order: oto,
    allocate_ocean_transport_orders: getAllocationPayloadNew(
      values.allocation_ocean_transport_order
    ),
    involved_branch_id: values?.involved_branch?.id,
    final_place_of_delivery_id: values?.final_place_of_delivery?.id,
    preferred_carrier_ids: (values?.preferred_carriers || []).map((pc: CarrierValue) => pc.id),
    split_from_id: values?.split_from_id,
    consignee_party_name: values?.consignee_party_name || 'consignee',
    customer_company_id: values?.customer?.party_company?.id || null,
    customer_address_id: values?.customer?.party_address?.id || null,
    inquiry_option_id: values?.inquiry_option?.id,
    carrier_id: values.carrier?.id || values?.ocean_transport_order?.carrier?.id,
    ocean_vessel_id: values.ocean_vessel?.imo,
    cargos: getCargoPayloadWithExistingCargo(values, shipment),
    routing_legs: getRoutingLegsPayload(values, shipment),
    routing_nodes: Object.values(values.routing_details?.routing_nodes || {}).map((rn: any) => {
      const { location, address, company, terminal, id, _id, tags } = rn;
      return {
        id: id || null,
        _id: _id || null,
        location_id: location?.id || null,
        terminal_id: terminal?.id || null,
        address_id: address?.id || null,
        company_id: company?.id || null,
        tags: tags || null,
      };
    }),
    shipment_parties: transformPartyDetails(Object.assign({}, party)),
    shipment_invoices: values?.shipment_invoices
      ? getShipmentInvoicePayload(values, shipment)
      : undefined,
    shipment_containers: values.shipment_containers
      ? (values.shipment_containers || [])
          .filter((c: { container_number: string; pickup_date: Dayjs }) => c.container_number)
          .map((c: { container_number: string; pickup_date: Dayjs }) => ({
            ..._pick(c, [
              'id',
              'is_non_iso_container',
              'container_number',
              'carrier_seal_number',
              'cargo_gross_weight',
              'verified_gross_mass',
              'cargo_net_weight',
              'commercial_invoice_number',
              'purchase_order_number',
              'container_type',
              'container_type_code',
              'remarks',
              'is_shipper_owned',
              'container_settings',
              'total_number_of_packages',
              'type_of_package',
            ]),
          }))
      : undefined,
    shipment_custom_details: shipmentCustomDetailPayload(values, shipment),
    linked_shipment_id: values?.linked_to_shipment?.id,
    shipment_type: values.shipment_type,
    lfd_at_pod: values.lfd_at_pod?.unix()?.toString(),
    lfd_at_pocd: values.lfd_at_pocd?.unix()?.toString(),
    lfd_at_empty_return: values.lfd_at_empty_return?.unix()?.toString(),
    lfd_at_carrier: values.lfd_at_carrier?.unix()?.toString(),
    shipment_product_ratings:
      values?.freight_type === FREIGHT_TYPE_AIR
        ? [
            {
              ...values?.shipment_product_ratings?.[0],
              cargo_type: values?.load_type,
              id: shipment?.shipment_product_ratings?.[0]?.id,
            },
          ]
        : undefined,
    shipment_events: transformShipmentEventDetails(
      values?.shipment_events,
      shipment,
      values?.sessionData
    ),
    surveyor_company_id: values?.surveyor?.party_company?.id,
    surveyor_address_id: values?.surveyor?.party_address?.id,
    empty_return_location_id: values?.empty_return_location?.id,
    carrier_validity_date: values?.carrier_validity_date?.unix(),
    job_date: convertToStringFormat(values.job_date),
  };

  if (values?.shipment_estimates?.length > 0)
    payload.shipment_estimates = getShipmentEstimatePay(values?.shipment_estimates, values);
  if (values?.shipment_container_quantity && values?.shipment_container_quantity.length > 0) {
    payload.shipment_containers = getContainerFromContainerQty(values);
  }
  if (values?.freight_type === FREIGHT_TYPE_AIR) {
    payload.shipment_routing_details = getShipmentRoutingDetails(values, shipment);
  }
  const omitFields = [
    'routing_details',
    'ocean_vessel',
    'carrier',
    'party',
    'customer',
    'pickup_containers',
    'import_custom_details',
    'shipping_bill_details',
    'linked_to_shipment',
    'inquiry_option',
    'shipment_container_quantity',
    'master',
    'house',
    'id',
    'preferred_carriers',
    'sales_agent',
    'allocation_ocean_transport_order',
    'booking_number',
    'booking_date',
    'contract_number',
    'booking_type',
    'vendor',
    'surveyor',
    'voyage_schedule_id',
    'sales_person',
    'booking_party',
    'si_cutoff_date',
    'empty_pickup_location',
    'empty_return_location',
    'gate_open_date',
    'gate_close_date',
    'valid_till_date',
    'doc_cutoff_date',
    'origin_demurrage_free_days',
    'origin_detention_free_days',
    'destination_demurrage_free_days',
    'destination_detention_free_days',
    'confirmed_booking',
    'terms_and_condition',
    'terms_and_condition_description',
    'stuffing_location',
    'destuffing_location',
    'pickup_location',
    'delivery_location',
    'stuffing_type',
    'destuffing_type',
    'involved_branch',
    'seller_same_as_shipper',
    'buyer_same_as_consignee',
    'consignee_same_as_customer',
    'notify_party_house_same_as_consignee',
    'notify_party_same_as_consignee',
    'shipper_same_as_customer',
    'notify_party_same_as_consignee',
    'service_type',
    'movement_location',
    'pol_buffer_location',
    'pod_buffer_location',
    'type',
    'final_place_of_delivery',
    'sessionData',
    'form13_cutoff_date',
    'vgm_cutoff_date',
    'port_of_discharge',
    'port_of_discharge_terminal',
    'port_of_loading',
    'cargo_properties',
  ];
  if (payload.shipment_type !== SHIPMENT_TYPE_CONSOL) {
    omitFields.push('consol_type');
  }
  return _omit(payload, omitFields);
};

const getShipmentEstimatePay = (estimatePayloads: any[], values: any) => {
  return (estimatePayloads || []).map((item) => {
    return {
      item: item.item || null,
      buy_branch_id: item.buy_branch_id || null,
      supplier_company_id: item.supplier_company_id || null,
      buy_rate: item.buy_rate || 0,
      buy_exchange_rate: item.buy_exchange_rate || 1,
      buy_terms: item.buy_terms || null,
      buy_currency: item.buy_currency || null,
      uom: item.uom || null,
      equipment_type: item.equipment_type || null,
      equipment_name: item.equipment_name || null,
      quantity: item.quantity || 0,
      sell_rate: item.sell_rate || 0,
      sell_exchange_rate: item.sell_exchange_rate || 1,
      sell_terms: item.sell_terms || null,
      sell_currency: item.sell_currency || null,
      remarks: item.remarks || null,
      printable_item_name: item.item || null, // Assuming this is the item name for printing
      tag: item.tag || null, // Convert the tag if it's part of an enum
    };
  });
};

export const getMasterDocumentPayload = (values: any) => {
  if (values.master?.shipment_document) {
    let master_shipment_number = values?.master?.shipment_document?.shipment_number;
    if (
      values?.freight_type === FREIGHT_TYPE_AIR &&
      values?.carrier?.iata_awb_prefix &&
      master_shipment_number
    ) {
      master_shipment_number = `${values?.carrier?.iata_awb_prefix}${master_shipment_number}`;
    }
    return {
      ..._omit(values.master?.shipment_document, ['shipment_date', 'document_status_event_date']),
      shipment_number: master_shipment_number,
      shipment_date: convertDateToUnix(values.master?.shipment_document?.shipment_date),
      document_status_event_date: values.master?.shipment_document?.document_status_event_date
        ? convertDateToUnix(values.master?.shipment_document?.document_status_event_date)
        : undefined,
    };
  }
  return undefined;
};

export const getHouseDocumentPayload = (values: any) => {
  if (values.house?.shipment_document) {
    return {
      ..._omit(values.house?.shipment_document, ['shipment_date', 'document_status_event_date']),
      shipment_date: convertDateToUnix(values.house?.shipment_document?.shipment_date),
      document_status_event_date: values.house?.shipment_document?.document_status_event_date
        ? convertDateToUnix(values.house?.shipment_document?.document_status_event_date)
        : undefined,
    };
  }
  return undefined;
};
