export const FUEL_TYPE = [
  { value: 'Petrol', name: 'petrol' },
  { value: 'Diesel', name: 'diesel' },
  { value: 'Natural Gas', name: 'natural_gas' },
  {
    value: 'Electric',
    name: 'electric',
  },
];

export const VEHICLE_CATEGORY = [
  { name: 'car_carrier_trailer', value: 'Car Carrier Trailer' },
  { name: 'car_carrier_truck', value: 'Car Carrier Truck' },
  { name: 'chassis_on_trailer', value: 'Chassis On Trailer (COT)' },
  { name: 'crane', value: 'Crane' },
  { name: 'excavator', value: 'Excavator' },
  { name: 'flat_bed_trailer', value: 'Flat Bed Trailer' },
  { name: 'forklift', value: 'Forklift' },
  { name: 'heavy_commercial_vehicle', value: 'Heavy Commercial Vehicle (HCV)' },
  { name: 'intermediate_commercial_vehicle', value: 'Intermediate Commercial Vehicle (ICV)' },
  { name: 'light_commercial_vehicle', value: 'Light Commercial Vehicle (LCV)' },
  { name: 'loader', value: 'Loader' },
  { name: 'low_bed_trailer', value: 'Low Bed Trailer' },
  { name: 'medium_commercial_vehicle', value: 'Medium Commercial Vehicle (MCV)' },
  { name: 'semi_low_bed_trailer', value: 'Semi Low Bed Trailer' },
  { name: 'small_commercial_vehicle', value: 'Small Commercial Vehicle (SCV)' },
  { name: 'tractor', value: 'Tractor' },
  { name: 'chiller_1_ton', value: '1 Ton Chiller' },
  { name: 'freezer_1_ton', value: '1 Ton Freezer' },
  { name: 'freezer_1_and_half_ton', value: '1.5 Ton Freezer' },
  { name: 'chiller_with_tail_gate_3_ton', value: '3 Ton Chiller With Tail Gate' },
  { name: 'open_truck_3_ton', value: '3 Ton Open Truck' },
  { name: 'chiller_with_tail_gate_4_ton', value: '4 Ton Chiller With Tail Gate' },
  { name: 'dry_box_with_tail_gate_4_ton', value: '4 Ton Dry Box With Tail Gate' },
  { name: 'freezer_with_tail_gate_4_ton', value: '4 Ton Freezer With Tail Gate' },
  { name: 'dry_box_with_tail_gate_5_ton', value: '5 Ton Dry Box With Tail Gate' },
  { name: 'open_pickup_7_ton', value: '7 Ton Open Pickup' },
  { name: 'car', value: 'Car' },
  { name: 'hilux', value: 'Hilux' },
];

export const ASSET_TYPE_OWN = 'own';
export const ASSET_TYPE_HIRE = 'hire';

export const ASSET_TYPES = [
  {
    key: ASSET_TYPE_OWN,
    name: 'Own',
  },
  {
    key: ASSET_TYPE_HIRE,
    name: 'Hire',
  },
];
