/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import { CargoProperty } from './Cargo';
import WarehouseTransaction from './WarehouseTransaction';
import WarehouseUnit from './WarehouseUnit';

const WarehouseTransactionProduct = types.model({
  id: types.identifier,
  parent_id: types.string,
  warehouse_transaction_type: types.maybe(types.maybeNull(types.string)),
  linked_to_id: types.string,
  batch_number: types.maybe(types.maybeNull(types.string)),
  container_number: types.maybe(types.maybeNull(types.string)),
  invoice_number: types.maybe(types.maybeNull(types.string)),
  customs_ref_code: types.maybe(types.maybeNull(types.string)),
  dimension_unit: types.maybe(types.maybeNull(types.string)),
  from_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))),
  to_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))),
  damage_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))),
  lot_number: types.maybe(types.maybeNull(types.string)),
  serial_number: types.maybe(types.maybeNull(types.string)),
  packing_type: types.maybe(types.maybeNull(types.string)),
  product_code: types.maybe(types.maybeNull(types.string)),
  product_id: types.maybe(types.maybeNull(types.string)),
  product_name: types.maybe(types.maybeNull(types.string)),
  length: types.maybe(types.maybeNull(types.number)),
  breadth: types.maybe(types.maybeNull(types.number)),
  height: types.maybe(types.maybeNull(types.number)),
  weight_unit: types.maybe(types.maybeNull(types.string)),
  gross_weight: types.maybe(types.maybeNull(types.number)),
  per_pack_weight: types.maybe(types.maybeNull(types.number)),
  gross_volume: types.maybe(types.maybeNull(types.number)),
  volumetric_weight: types.maybe(types.maybeNull(types.number)),
  chargeable_weight: types.maybe(types.maybeNull(types.number)),
  invoice_date: types.maybe(types.maybeNull(types.number)),
  qty: types.maybe(types.maybeNull(types.number)),
  damage_qty: types.maybe(types.maybeNull(types.number)),
  inner_by_outer_qty: types.maybe(types.maybeNull(types.number)),
  outer_packing_type: types.maybe(types.maybeNull(types.string)),
  container_type: types.maybe(types.maybeNull(types.string)),
  out_container_number: types.maybe(types.maybeNull(types.string)),
  seal_number: types.maybe(types.maybeNull(types.string)),
  issued_qty: types.maybe(types.maybeNull(types.number)),
  available_qty: types.maybe(types.maybeNull(types.number)),
  product_property: types.maybe(types.maybeNull(CargoProperty)),
  country_of_origin: types.maybe(types.maybeNull(types.string)),
  prod_date: types.maybe(types.maybeNull(types.number)),
  exp_date: types.maybe(types.maybeNull(types.number)),
  warehouse_transaction: types.maybe(
    types.maybeNull(types.late((): IAnyModelType => WarehouseTransaction))
  ),
  linked_to: types.maybe(
    types.maybeNull(types.late((): IAnyModelType => WarehouseTransactionProduct))
  ),
  linked_products: types.maybeNull(
    types.maybe(types.array(types.late((): IAnyModelType => WarehouseTransactionProduct)))
  ),
  remarks: types.maybe(types.maybeNull(types.string)),
  pallet_no: types.maybe(types.maybeNull(types.string)),
  customer_ref_code: types.maybe(types.maybeNull(types.string)),
  receipt_number: types.maybe(types.maybeNull(types.string)),
  receipt_date: types.maybe(types.maybeNull(types.number)),
  shipment_document_number: types.maybe(types.maybeNull(types.string)),
});

export interface WarehouseTransactionProductValue
  extends Instance<typeof WarehouseTransactionProduct> {}
export interface WarehouseTransactionProductIn
  extends SnapshotIn<typeof WarehouseTransactionProduct> {}
export interface WarehouseTransactionProductOut
  extends SnapshotOut<typeof WarehouseTransactionProduct> {}

export default WarehouseTransactionProduct;
