import React, { useCallback, useEffect, useState } from 'react';

import {
  Badge,
  Button,
  ClearOutlined,
  Col,
  CustomIcon,
  Drawer,
  Form,
  Icon,
  Input,
  Popover,
  Row,
  SearchOutlined,
  SortForm,
  Space,
} from '@shipmnts/pixel-hub';
import {
  getQuickFilters,
  rateExplorerFiltersMapping,
  MORE_FILTERS,
  SORT_FIELDS,
  getAllQuickFilters,
} from 'sales_hub/utils/RateExplorerFiltersMapping';
import { FormInstance } from '@shipmnts/pixel-hub';
import { RateExplorerMoreFilter, RateExplorerResource } from './RateExplorer.type';
import { debounce as _debounce } from 'lodash';

interface RateExplorerFilterProp {
  resource: RateExplorerResource;
  fetchData: (variables: any) => void;
  form: FormInstance;
  defaultFilter?: Record<string, any>;
  initialFormValue?: Record<string, any>;
}

function RateExplorerFilter(props: RateExplorerFilterProp) {
  const { resource, fetchData, form, defaultFilter = {}, initialFormValue = {} } = props;
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const is_custom = resource.is_custom;
  const filtersMap: Record<string, React.JSX.Element> = rateExplorerFiltersMapping(resource, form);
  const freightType = is_custom ? 'custom' : resource.freight_type;
  const currentMoreFilters: RateExplorerMoreFilter[] = MORE_FILTERS?.[freightType || ''] || [];
  const moreFilters = currentMoreFilters.map((filter) => (
    <Col key={filter.field} span={24}>
      {filtersMap[filter.field]}
    </Col>
  ));

  const getFilterConditionObject = (filters: Record<string, any>) => {
    const quickFilter = filters['quick_filters'] || {};
    const all_filters = { ...filters['filters'], ...quickFilter };
    const allQuickFilters = getAllQuickFilters(resource);

    const filterMap: any = {};

    const new_filter: any = {
      filters: { ...defaultFilter },
    };
    currentMoreFilters.forEach((filter) => (filterMap[filter.field] = filter));
    Object.keys(quickFilter).forEach(
      (key) =>
        (filterMap[key] = Object.values(allQuickFilters).find(
          (ele: any) => ele.field_type.toLowerCase() === key || ele.form_name?.toLowerCase() === key
        ))
    );

    (Object.keys(all_filters) || []).forEach((field_name) => {
      const fieldMetaData = filterMap[field_name];
      const field_value = all_filters[field_name];
      if (!fieldMetaData) {
        new_filter['filters'][field_name] = field_value;
      } else if (field_value !== undefined) {
        const getValue = fieldMetaData?.getValue;
        if (getValue) {
          const values = getValue(field_value, resource);
          (Object.keys(values) || []).forEach((value) => {
            new_filter['filters'][value] = values[value];
          });
        } else new_filter['filters'][field_name] = field_value;
      }
    });

    return new_filter;
  };

  const getRates = _debounce(
    useCallback(() => {
      const feilds = form.getFieldsValue();
      const filterPayload = getFilterConditionObject(feilds);
      const sort = feilds?.sort?.filter((ele: any) => ele.field_id && ele.direction);
      fetchData({
        filters: filterPayload.filters,
        sort: sort,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, fetchData]),
    1000
  );

  useEffect(() => {
    initialFormValue && form.setFieldsValue(initialFormValue);
    getRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValue, form]);

  const moreFiltersCount =
    Object.values(Form.useWatch('filters', form) || []).filter(
      (value) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0)
    ).length || 0;

  const sortCount =
    (Form.useWatch('sort', form) || []).filter((value: { field_id: any }) => !!value.field_id)
      .length || 0;

  return (
    <Form
      form={form}
      layout="vertical"
      labelCol={{ span: 24 }}
      initialValues={initialFormValue}
      onFieldsChange={getRates}
      onFinish={getRates}
    >
      <div style={{ gap: '8px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Form.Item
          name={['quick_filters', 'reference_id']}
          style={{ marginBottom: 0, minWidth: 180 }}
        >
          <Input
            size="small"
            placeholder="Search #Ref ID"
            prefix={
              <SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            }
          />
        </Form.Item>

        <Form.Item name="sort" noStyle>
          <Popover
            overlayClassName="report-sort-button"
            open={showSort}
            title="Sort by"
            trigger="click"
            destroyTooltipOnHide
            onOpenChange={setShowSort}
            overlayInnerStyle={{ height: '400px' }}
            content={
              <SortFormContent
                setShowSort={setShowSort}
                form={form}
                freightType={freightType || 'custom'}
              />
            }
          >
            <Button
              className={`view-action-button ${sortCount > 0 && 'view-sort-applied'}`}
              size="small"
              icon={<Icon component={() => <CustomIcon icon="SortIcon" />} />}
            >
              {sortCount > 0 ? (
                <>
                  <span style={{ fontWeight: 500 }}>Sorted By </span>
                  {sortCount > 1 ? `${sortCount} fields` : `${sortCount} field`}
                </>
              ) : (
                'Sort'
              )}
            </Button>
          </Popover>
        </Form.Item>

        {getQuickFilters(resource)}

        <Badge count={moreFiltersCount} className="view-filters-applied">
          <Button
            className={`view-action-button ${moreFiltersCount > 0 && 'view-filters-applied'}`}
            size="small"
            icon={<Icon component={() => <CustomIcon icon="NewFilterIcon" />} />}
            onClick={() => setShowMoreFilter(true)}
          >
            More Filters
          </Button>
        </Badge>

        <Button
          className={`view-action-button`}
          size="small"
          style={{ marginLeft: '1rem' }}
          icon={<Icon component={() => <ClearOutlined />} />}
          onClick={() => {
            form.setFieldsValue({
              quick_filters: undefined,
              filters: undefined,
            });
            form.setFieldsValue({
              filters: {
                rate_type: initialFormValue?.filters?.rate_type,
              },
            });
            form.submit();
          }}
        >
          Clear
        </Button>

        {/* Keeping the more filters form items rendered, but hiding them visually */}
        <div style={{ display: 'none' }}>{moreFilters}</div>

        <Drawer
          title="More Filters"
          placement="right"
          closable={true}
          open={showMoreFilter}
          onClose={() => {
            setShowMoreFilter(false);
          }}
          width={'25%'}
        >
          <Row gutter={32}>{moreFilters}</Row>
        </Drawer>
      </div>
    </Form>
  );
}

export default RateExplorerFilter;

function SortFormContent({
  setShowSort,
  form,
  freightType,
}: {
  setShowSort: (value: boolean) => void;
  form: FormInstance;
  freightType: string;
}) {
  return (
    <div
      className="no-margin-form-item"
      style={{ minWidth: 'min(450px, 90vw)', overflow: 'auto', maxWidth: '90vw' }}
    >
      <SortForm
        fields={{
          'Sort By': SORT_FIELDS[freightType],
        }}
        onClose={() => setShowSort(false)}
        withoutView
      />
      <div className="flex-row report-popover-footer">
        <Space
          style={{
            marginLeft: 'auto',
          }}
        >
          <Button onClick={() => form.setFieldValue('sort', [{ direction: 'desc' }])}>
            Clear All
          </Button>
          <Button
            type="primary"
            onClick={() => setShowSort(false)}
            style={{
              marginLeft: '10px',
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    </div>
  );
}
