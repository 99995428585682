import React from 'react';
import { Descriptions, Form, EditModeCard } from '@shipmnts/pixel-hub';
import { CompanyValue } from 'network/models/Company';
import { isCustomer, isVendor, useSession } from 'common';
import CompanyFormBasicDetails from '../CompanyFormBasicDetails';
import { pick as _pick } from 'lodash';
import SectionEditFormWrapper from './SectionEditFormWrapper';
import { CompanyViewSectionProps } from '../CompanyView';
import { COMPANY_TYPE_MAP } from 'common/baseConstants';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { UpsertCompanyFormValues } from '../CompanyForm';
import { getPrimarySalesPersonFromTeams } from 'common/helpers/helper';

export const getFormInitialValues = (company?: CompanyValue) => {
  if (!company) return;
  const sales_person = getPrimarySalesPersonFromTeams(company?.teams || []);

  let formPayload = {
    ..._pick(company, [
      'id',
      'registered_name',
      'company_group',
      'country_of_incorporation',
      'company_identification_number',
      'tax_deduction_id',
      'tax_registration_number',
      'entity_type',
      'global_company_account',
      'sales_partner',
      'company_type',
      'domain',
    ]),
    sales_person,
  };

  formPayload = {
    ...formPayload,
    ...(company.company_type ? COMPANY_TYPE_MAP[company.company_type] : {}),
  };

  if (formPayload.sales_partner) {
    formPayload.sales_partner = _pick(formPayload.sales_partner, ['id', 'registered_name']);
  }
  return formPayload;
};

const CompanyOverviewDetails = React.memo(function CompanyOverviewDetails(
  props: CompanyViewSectionProps
): JSX.Element {
  const { company, onCompanyUpdate } = props;
  const session = useSession();
  const tenant_country_code = session.company_account?.default_company?.country_of_incorporation;
  const [form] = Form.useForm();
  let editPermission = undefined;
  if (isCustomer(company?.company_type) || isVendor(company?.company_type))
    editPermission = {
      OR: [
        { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
      ],
    };

  const initialValues = getFormInitialValues(company);
  return (
    <EditModeCard
      onEdit={() => form.setFieldsValue(initialValues)}
      editDisabled={company.is_disabled}
      onSave={form.submit}
      cardKey="overview"
      title="Overview"
      editPermission={editPermission}
    >
      {({
        editMode,
        setEditMode,
        setLoading,
      }: {
        editMode: any;
        setEditMode: any;
        setLoading: any;
      }) => {
        if (editMode) {
          return (
            <SectionEditFormWrapper
              setEditMode={setEditMode}
              form={form}
              setLoading={setLoading}
              onSuccess={onCompanyUpdate}
              initialValues={initialValues as Partial<UpsertCompanyFormValues>}
            >
              <CompanyFormBasicDetails form={form} company_id={company.id} />
            </SectionEditFormWrapper>
          );
        }
        return (
          <Descriptions
            className="no-padding-description"
            column={{ xs: 2, sm: 4, md: 4, lg: 4 }}
            size="small"
            layout="vertical"
            colon={false}
          >
            <Descriptions.Item label="Company Group">{company?.company_group}</Descriptions.Item>
            <Descriptions.Item
              label={`Company Identification # ${
                tenant_country_code === 'IN' ? '(CIN)' : ''
              } / LLP`}
            >
              {company?.company_identification_number || '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={`Tax Deduction ID ${tenant_country_code === 'IN' ? '(TAN)' : ''}`}
            >
              {company?.tax_deduction_id || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={`Tax ID ${tenant_country_code === 'IN' ? '(PAN)' : ''}`}>
              {company?.tax_registration_number || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Company Type">
              {company?.entity_type || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Global Company">
              {company?.global_company_account?.registered_name || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Sales Partner">
              {company?.sales_partner?.registered_name || '-'}
            </Descriptions.Item>
          </Descriptions>
        );
      }}
    </EditModeCard>
  );
});

export default CompanyOverviewDetails;
