import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button, Space, message } from '@shipmnts/pixel-hub';
import { FormOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { UPSERT_COMPANY } from 'network/graphql/company';
import { UpsertCompanyFormValues } from 'network/components/CompanyForm';
import LeadForm from '../LeadForm';
import '../lead.less';
import LeadLostForm from '../LeadLostForm';
import CompanyConvertForm from '../CompanyConvertForm';
import {
  CUSTOMER,
  SUPPLIER,
  AGENT,
  ACTIVE_CUSTOMER,
  LEAD,
  CONTACT,
  LOST_LEAD,
  CHURNED_CUSTOMER,
  LEAD_STAGE,
  WON_CUSTOMER,
  WON_AGENT,
  WON_SUPPLIER,
} from 'sales_hub/constants';
import { getPrimarySalesPersonFromTeams, getUpsertTeamPayload } from 'common/helpers/helper';
import { TeamValue } from 'network/models/Team';

interface PayloadType extends Partial<UpsertCompanyFormValues> {
  contacts?: Array<any>;
  sales_partner_id?: string | null;
  entity_type?: string | null;
  company_stage?: string;
  remarks?: string;
  pipeline_id?: string;
  current_state_id?: string;
  addresses?: any;
  teams?: any; //need to use any type, as id is required in TeamValue, and TeamPayloadType
}

type Props = {
  initialValue?: any;
  title?: string;
  action?: string;
  type?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
};

//Actions that are not allowed for leads
const CONVERT_COMPANY_ACTIONS = [CUSTOMER, SUPPLIER, AGENT, LEAD];
const FORM_ACTIONS = ['edit', 'won', LOST_LEAD, ...CONVERT_COMPANY_ACTIONS];
const DISABLE_ACTIONS = ['enable', 'disable'];
const COMPANY_STAGE_ACTIONS = [ACTIVE_CUSTOMER, CHURNED_CUSTOMER, LEAD_STAGE];
const COMPANY_TYPE_ACTIONS = [CONTACT];
const OTHER_ACTIONS = [...DISABLE_ACTIONS, ...COMPANY_STAGE_ACTIONS, ...COMPANY_TYPE_ACTIONS];

const CompanyStageMap = [
  { to: CUSTOMER, from: LEAD, stage: ACTIVE_CUSTOMER },
  { to: CUSTOMER, from: CONTACT, stage: ACTIVE_CUSTOMER },
  { to: SUPPLIER, from: LEAD, stage: null },
  { to: SUPPLIER, from: CONTACT, stage: null },
  { to: AGENT, from: SUPPLIER, stage: null },
  { to: LEAD, from: CONTACT, stage: LEAD_STAGE },
  { to: CONTACT, from: LEAD, stage: null },
  { to: AGENT, from: CUSTOMER, stage: undefined },
  { to: AGENT, from: CUSTOMER, stage: ACTIVE_CUSTOMER },
  { to: AGENT, from: LEAD, stage: ACTIVE_CUSTOMER },
];

const UpdateLeadAction = (props: Props) => {
  const { initialValue, title = 'Edit Lead', action = 'edit', type } = props;
  const [visible, setVisible] = useState(true);
  const [upsertCompany, { data, loading }] = useMutation(UPSERT_COMPANY);
  const [form] = Form.useForm();
  const onClose = () => {
    form.resetFields();
    setVisible && setVisible(false);
    props.onClose && props.onClose();
  };

  const onSuccessfulConversion = () => {
    if (CONVERT_COMPANY_ACTIONS.includes(action) || COMPANY_TYPE_ACTIONS.includes(action)) {
      message.success(`Successfully Converted to ${action}`);
    } else if (action === 'won') {
      message.success('Lead marked as Won Successfully');
    } else if (action === LOST_LEAD) {
      message.success('Lead marked as Lost Successfully');
    } else if (action === ACTIVE_CUSTOMER) {
      message.success('Company marked as Active Successfully');
    } else if (action === CHURNED_CUSTOMER) {
      message.success('Company marked as Churned Successfully');
    } else {
      message.success('Updated Successfully');
    }
    form.resetFields();
    setVisible && setVisible(false);
    props.onSuccess && props.onSuccess();
  };

  useEffect(() => {
    if (data?.upsert_company?.company) {
      onSuccessfulConversion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, data, form, props]);

  //useEffect for OTHER_ACTIONS
  useEffect(() => {
    if (!initialValue || !OTHER_ACTIONS.includes(action)) return;

    const payload: {
      variables: {
        company: {
          id: any;
          is_disabled?: boolean;
          company_stage?: string | null;
          company_type?: string;
        };
      };
    } = {
      variables: {
        company: {
          id: initialValue.id,
        },
      },
    };
    if (type === 'is_disabled' && DISABLE_ACTIONS.includes(action)) {
      payload.variables.company.is_disabled = action === 'disable';
    } else if (type === 'company_stage' && COMPANY_STAGE_ACTIONS.includes(action)) {
      payload.variables.company.company_stage = action;
    } else if (type === 'company_type' && COMPANY_TYPE_ACTIONS.includes(action)) {
      payload.variables.company.company_type = action;
      payload.variables.company.company_stage = CompanyStageMap.find(
        (cs) => cs.to === action && cs.from === initialValue.company_type
      )?.stage;
    }
    upsertCompany(payload);
  }, [action, initialValue, type, upsertCompany]);

  function getUpsertCompanyPayload(formValues: any) {
    if (!initialValue?.id || !formValues) return {};

    const { sales_partner, sales_person, address, pipeline_status, pipeline, ...rest } = formValues;
    if (CONVERT_COMPANY_ACTIONS.includes(action)) {
      const payload = {
        id: initialValue?.id,
        company_type: action,
        company_stage: CompanyStageMap.find(
          (cs) => cs.to === action && cs.from === initialValue.company_type
        )?.stage,
        ...rest,
        teams: !!formValues?.teams
          ? getUpsertTeamPayload(
              formValues?.teams,
              'Network::Company',
              initialValue?.id,
              initialValue?.teams
            )?.teams
          : !!sales_person?.id
          ? [
              {
                role: 'sales',
                sales_person_id: formValues.sales_person.id,
                reference_type: 'Network::Company',
              },
            ]
          : [],
        pipeline_id: formValues.pipeline || 'is_deleted',
        current_state_id: pipeline_status,
      };
      if (!!address && !!address.print_address) {
        payload.addresses = getAddress(formValues);
      }
      return payload;
    }

    if (['edit', 'won'].includes(action)) {
      const payload: PayloadType = {
        id: initialValue?.id,
        registered_name: formValues.registered_name,
        company_stage: initialValue?.company_stage,
        country_of_incorporation: formValues.country_of_incorporation,
        company_group: formValues.company_group,
        domain: formValues.domain,
        entity_type: formValues.entity_type,
        sales_partner_id: formValues.sales_partner?.id,
        teams: formValues.sales_person?.id
          ? [
              {
                id: initialValue?.teams?.filter((team: TeamValue) => team.role === 'sales')?.[0]
                  ?.id,
                role: 'sales',
                sales_person_id: formValues.sales_person.id,
                reference_type: 'Network::Company',
              },
            ]
          : [],
        remarks: formValues.remarks,
        pipeline_id: formValues.pipeline || 'is_deleted',
        current_state_id: formValues.pipeline_status,
      };
      if (!!formValues?.address && formValues?.address.print_address) {
        payload.addresses = getAddress(formValues);
      }
      if (action === 'won' || type === 'won') {
        payload['company_stage'] = formValues.company_stage;
      }
      return payload;
    }

    if (action === LOST_LEAD)
      return { id: initialValue?.id, company_stage: LOST_LEAD, remarks: formValues.remarks };
    return {};
  }

  const handleFormSubmit = (values: any) => {
    const payload = getUpsertCompanyPayload(values);
    if (Object.keys(payload).length === 0) return;
    upsertCompany({ variables: { company: payload } });
  };

  const getAddress = (formValues: any) => {
    const address_entity_type = formValues?.address?.is_billing ? 'billing' : 'shipping';
    const city_id = formValues?.address?.city?.id;
    delete formValues?.address?.city;
    delete formValues?.address?.is_billing;
    return [
      {
        ...formValues?.address,
        entity_type: address_entity_type,
        city_id,
        id: initialValue?.addresses?.[0]?.id,
      },
    ];
  };

  const getInitialValues = () => {
    if (initialValue && ['edit', 'won', LEAD].includes(action)) {
      return {
        sales_partner: initialValue?.sales_partner,
        sales_person: getPrimarySalesPersonFromTeams(initialValue?.teams),
        registered_name: initialValue.registered_name,
        country_of_incorporation: initialValue.country_of_incorporation,
        company_group: initialValue.company_group,
        domain: initialValue.domain,
        entity_type: initialValue.entity_type,
        remarks: initialValue.remarks,
        company_stage: [WON_CUSTOMER, WON_SUPPLIER, WON_AGENT].includes(initialValue.company_stage)
          ? initialValue.company_stage
          : WON_CUSTOMER,
        pipeline: initialValue.pipeline?.id,
        pipeline_status: initialValue.current_state?.id,
        teams: initialValue?.teams,
        address: initialValue.addresses?.[0],
      };
    }
    return {};
  };
  if (FORM_ACTIONS.includes(action)) {
    return (
      <Drawer
        title={
          <>
            <FormOutlined /> {title}
          </>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        bodyStyle={{
          padding: '8px',
          backgroundColor: '#fafafa',
        }}
        open={visible}
        width={720}
        footer={
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={getInitialValues()}
          onFinish={handleFormSubmit}
        >
          {['edit', 'won', LEAD].includes(action) && (
            <LeadForm
              form={form}
              showContactCard={false}
              isWonForm={action === 'won' || type === 'won'}
              company_id={initialValue?.id}
              isEdit={true}
            />
          )}
          {CONVERT_COMPANY_ACTIONS.includes(action) && (
            <CompanyConvertForm form={form} action={action} initialValue={initialValue} />
          )}
          {action === LOST_LEAD && <LeadLostForm />}
        </Form>
      </Drawer>
    );
  } else {
    return <></>;
  }
};

export default UpdateLeadAction;
