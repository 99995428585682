import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType, cast } from 'mobx-state-tree';
import Carrier from 'operations/models/Carrier';
import Location from 'operations/models/Location';
import Vessel from 'operations/models/Vessel';
import Address from 'operations/models/Address';
import ShipmentContainer, { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import UserAccount from 'operations/models/UserAccount';
import ShipmentDocument, { ShipmentDocumentValue } from './ShipmentDocument';
import ShipmentParty, { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import ChangeEvent from 'operations/models/ChangeEvent';
import ShipmentCustomDetail from './ShipmentCustomDetail';
import Activity from './Activity';

import {
  ACCOUNTING_STATUS_PENDING,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  SERVICE_TYPE_DESTINATION,
  SERVICE_TYPE_FREIGHT,
  SERVICE_TYPE_ORIGIN,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_HOUSE,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  ACCOUNTING_STATUS_BILLING_CLOSED,
  ACCOUNTING_STATUS_CLOSED,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_CROSS_TRADE,
  CARGO_TYPE_CONTAINER,
  CARGO_TYPE_BREAK_BULK,
  CARGO_TYPE_LIQUID,
  CARGO_TYPE_BULK,
  FREIGHT_TYPE_ROAD,
} from 'operations/modules/shipment/constants';
import {
  CARGO_TYPE_AIR_LOOSE,
  CARGO_TYPE_AIR_ULD,
  DOCUMENT_STATUS_DRAFT_APPROVED,
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
  DOCUMENT_STATUS_POD_RECEIVED,
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
  EVENT_BANK_RELEASE_ORDER,
  EVENT_CLEARANCE,
  LOCATION_TAG_PCD,
  LOCATION_TAG_PCR,
  LOCATION_TAG_POD,
  LOCATION_TAG_POL,
  SERVICE_CARGO_INSURANCE,
  SERVICE_CLEARANCE,
  SERVICE_EMPTY_CONTAINER_INSURANCE,
  SERVICE_FUMIGATION,
  SERVICE_PALLETIZATION,
  SERVICE_TRANSPORT,
  SHIPMENT_STATUS_BACK_TO_TOWN,
  SHIPMENT_STATUS_CANCELLED,
  SHIPMENT_STATUS_EXECUTED,
  SHIPMENT_STATUS_PLANNED,
  SHIPMENT_TYPE_GENERAL,
} from 'operations/modules/reports/constants';
import {
  camelCase as _camelCase,
  countBy as _countBy,
  get as _get,
  pickBy as _pickBy,
  startCase as _startCase,
} from 'lodash';
import {
  LOAD_TYPE_FCL,
  LOAD_TYPE_FTL_BREAK_BULK,
  LOAD_TYPE_LCL,
  STUFFING_LOCATION_TYPE_CFS,
  STUFFING_LOCATION_TYPE_FACTORY,
  STUFFING_LOCATION_TYPE_ICD,
} from 'operations/baseConstants';
import { isJobClosed } from 'operations/modules/shipment/helpers/containerHelper';
import { SessionDataValue } from 'operations/models/SessionData';
import BranchAccount from 'operations/models/BranchAccount';
import Company, { CompanyValue } from 'operations/models/Company';
import TrackingEvent from './TrackingEvent';
import ShipmentProductRating from './ShipmentProductRating';
import InquiryOption from './InquiryOption';
import RoutingLeg from './RoutingLeg';
import ContainerRequest from './ContainerRequest';
import Driver from './Driver';
import Vehicle from './Vehicle';
import ShipmentManifest from './ShipmentManifest';
import ProductOrderItem from './ProductOrderItem';
import UserContact from 'common/models/UserContact';
import SalesPerson, { SalesPersonValue } from 'common/models/SalesPerson';
import OceanTransportOrder from './OceanTransportOrder';
import { Ticket } from './Ticket';
import Team from 'common/models/Team';
import LinkShipmentJson from './LinkShipmentJson';
// import OceanTransportOrder from './OceanTransportOrder';

const PARTY_KEYS = [
  'shipper',
  'consignee',
  'notify_party_1',
  'notify_party_2',
  'notify_party_3',
  'notify_party_4',
  'notify_party_1_house',
  'notify_party_2_house',
  'notify_party_3_house',
  'notify_party_4_house',
  'origin_agent',
  'destination_agent',
  'delivery_agent',
  'overseas_agent',
  'subagent',
  'to_order',
  'to_order_bank',
  'master_issuing_agent',
  'house_issuing_agent',
  'destination_clearance_agent',
  'destination_transport_agent',
  'delivery_order_in_favour_of',
  'delivery_order_addressed_to',
  'buyer',
  'seller',
  'consolidator',
  'ship_to',
  'manufacturer',
  'importer',
];

export const ShipmentProperties = types
  .model({
    is_battery: types.maybe(types.maybeNull(types.boolean)),
    is_general: types.maybe(types.maybeNull(types.boolean)),
    is_hazardous: types.maybe(types.maybeNull(types.boolean)),
    is_perishable: types.maybe(types.maybeNull(types.boolean)),
    is_temp_controlled: types.maybe(types.maybeNull(types.boolean)),
    hazardous_details: types.maybeNull(
      types.model({
        un_number: types.maybe(types.maybeNull(types.string)),
        un_imo_class: types.maybe(types.maybeNull(types.string)),
        proper_shipping_name: types.maybe(types.maybeNull(types.string)),
        packaging_group_type: types.maybe(types.maybeNull(types.string)),
        air_craft_type: types.maybe(types.maybeNull(types.string)),
        is_radioactive: types.maybe(types.maybeNull(types.boolean)),
      })
    ),
    temp_controlled_details: types.maybeNull(
      types.model({
        temperature_range: types.maybe(types.maybeNull(types.string)),
        cooling_type: types.maybe(types.maybeNull(types.string)),
      })
    ),
    battery_details: types.maybeNull(
      types.model({
        battery_type: types.maybe(types.maybeNull(types.string)),
      })
    ),
  })
  .views((self) => ({
    get activeProperties() {
      return _pickBy(self, (value) => !!value);
    },
  }));

export const PortObject = types.model({
  id: types.identifier,
  type: types.maybe(types.maybeNull(types.string)),
  name: types.maybe(types.maybeNull(types.string)),
  unlocode: types.maybe(types.maybeNull(types.string)),
  latlong: types.maybe(types.maybeNull(types.string)),
  city: types.maybe(
    types.maybeNull(
      types.model({
        name: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
  country: types.maybe(
    types.maybeNull(
      types.model({
        code: types.maybe(types.maybeNull(types.string)),
        name: types.maybe(types.maybeNull(types.string)),
      })
    )
  ),
  city_code: types.maybe(types.maybeNull(types.string)),
  state: types.maybe(types.maybeNull(types.string)),
  state_code: types.maybe(types.maybeNull(types.string)),
  country_code: types.maybe(types.maybeNull(types.string)),
  iata_code: types.maybe(types.maybeNull(types.string)),
  address: types.maybe(types.maybeNull(types.string)),
  is_customs_location: types.maybe(types.maybeNull(types.boolean)),
  customs_code: types.maybe(types.maybeNull(types.string)),
  city_id: types.maybe(types.maybeNull(types.string)),
  parent_id: types.maybe(types.maybeNull(types.string)),
});

export const RoutingDetails = types.model({
  id: types.maybe(types.maybeNull(types.string)),
  actual_time_of_arrival: types.maybe(types.maybeNull(types.number)),
  estimated_time_of_arrival: types.maybe(types.maybeNull(types.number)),
  estimated_time_of_departure: types.maybe(types.maybeNull(types.number)),
  location: types.maybe(types.maybeNull(PortObject)),
  final_place_of_delivery: types.maybe(types.maybeNull(Location)),
  location_tags: types.maybe(types.maybeNull(types.array(types.string))),
  mode_of_transport: types.maybe(types.maybeNull(types.string)),
  vessel: types.maybe(types.maybeNull(types.array(types.string))),
  voyage_date: types.maybe(types.maybeNull(types.number)),
  voyage_number: types.maybe(types.maybeNull(types.string)),
  routing_type: types.maybe(types.maybeNull(types.string)),
  route_order: types.maybe(types.maybeNull(types.number)),
  carrier: types.maybe(types.maybeNull(Carrier)),
});

const ShipmentService = types.model({
  [SERVICE_TYPE_FREIGHT]: types.maybe(types.maybeNull(types.boolean)),
  [SERVICE_CARGO_INSURANCE]: types.maybe(types.maybeNull(types.boolean)),
  [SERVICE_EMPTY_CONTAINER_INSURANCE]: types.maybe(types.maybeNull(types.boolean)),
  [SERVICE_TYPE_ORIGIN]: types.maybe(
    types.maybeNull(
      types.model({
        [SERVICE_CLEARANCE]: types.maybe(types.maybeNull(types.boolean)),
        [SERVICE_TRANSPORT]: types.maybe(types.maybeNull(types.boolean)),
        [SERVICE_FUMIGATION]: types.maybe(types.maybeNull(types.boolean)),
        [SERVICE_PALLETIZATION]: types.maybe(types.maybeNull(types.boolean)),
      })
    )
  ),
  [SERVICE_TYPE_DESTINATION]: types.maybe(
    types.maybeNull(
      types.model({
        [SERVICE_CLEARANCE]: types.maybe(types.maybeNull(types.boolean)),
        [SERVICE_TRANSPORT]: types.maybe(types.maybeNull(types.boolean)),
      })
    )
  ),
});

const Shipment = types
  .model({
    id: types.identifier,
    created_at: types.maybe(types.maybeNull(types.number)),
    load_type: types.maybeNull(types.string),
    ownership_type: types.maybeNull(types.string),
    next_actions: types.maybe(types.maybeNull(types.array(types.string))),
    last_action_status: types.maybe(types.maybeNull(types.string)),
    booking_type: types.maybe(types.maybeNull(types.string)),
    transporter_name: types.maybe(types.maybeNull(types.string)),
    last_comment: types.maybe(types.maybeNull(Activity)),
    last_status_update: types.maybe(types.maybeNull(Activity)),
    carrier: types.maybe(types.maybeNull(Carrier)),
    commodity_description: types.maybe(types.maybeNull(types.string)),
    customer: types.maybe(types.maybeNull(types.string)),
    net_weight: types.maybe(types.maybeNull(types.number)),
    estimated_time_of_departure: types.maybe(types.maybeNull(types.number)),
    estimated_time_of_arrival: types.maybe(types.maybeNull(types.number)),
    eta_tsp1: types.maybe(types.maybeNull(types.number)),
    eta_tsp2: types.maybe(types.maybeNull(types.number)),
    etd_tsp1: types.maybe(types.maybeNull(types.number)),
    etd_tsp2: types.maybe(types.maybeNull(types.number)),
    eta_status: types.maybe(types.maybeNull(types.string)),
    atd_pol_date: types.maybe(types.maybeNull(types.number)),
    actual_time_of_arrival: types.maybe(types.maybeNull(types.number)),
    actual_time_of_arrival_pocd: types.maybe(types.maybeNull(types.number)),
    movement_type: types.maybe(
      types.maybeNull(types.enumeration(['dpd', 'cfs', 'dpd-cfs', 'icd']))
    ),
    movement_location: types.maybe(
      types.maybeNull(
        types.model({
          port: types.maybe(types.maybeNull(PortObject)),
          company_address: types.maybe(types.maybeNull(Address)),
          company: types.maybe(types.maybeNull(Company)),
        })
      )
    ),
    shipping_line_service_type: types.maybe(types.maybeNull(types.string)),
    linked_shipment_id: types.maybe(types.maybeNull(types.string)),
    linked_shipment: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
    child_linked_shipments: types.maybe(types.array(types.late((): IAnyModelType => Shipment))),
    final_place_of_delivery: types.maybe(types.maybeNull(Location)),
    job_number: types.maybe(types.maybeNull(types.string)),
    master_shipment_id: types.maybe(types.maybeNull(types.string)),
    master_shipment: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
    linked_to_shipment: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
    ocean_vessel: types.maybe(types.maybeNull(Vessel)),
    current_vessel: types.maybe(types.maybeNull(Vessel)),
    place_of_carrier_receipt: types.maybe(types.maybeNull(PortObject)),
    port_of_loading: types.maybe(types.maybeNull(PortObject)),
    tsp_1: types.maybe(types.maybeNull(PortObject)),
    tsp_2: types.maybe(types.maybeNull(PortObject)),
    port_of_discharge: types.maybe(types.maybeNull(PortObject)),
    place_of_carrier_delivery: types.maybe(types.maybeNull(PortObject)),
    place_of_carrier_delivery_terminal: types.maybe(types.maybeNull(PortObject)),
    port_of_discharge_terminal: types.maybe(types.maybeNull(PortObject)),
    port_of_loading_terminal: types.maybe(types.maybeNull(PortObject)),
    preferred_carriers: types.maybe(types.array(Carrier)),
    sales_agent: types.maybe(types.maybeNull(SalesPerson)),
    shipment_containers: types.maybe(types.array(ShipmentContainer)),
    shipment_documents: types.maybe(types.array(ShipmentDocument)),
    house_shipments: types.maybe(types.array(types.late((): IAnyModelType => Shipment))),
    trips: types.maybe(types.array(types.late((): IAnyModelType => Shipment))),
    trip_customer_order: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
    created_by: types.maybe(types.maybeNull(UserAccount)),
    tickets: types.maybe(types.maybeNull(types.array(Ticket))),
    total_number_of_packages: types.maybe(types.maybeNull(types.number)),
    chargeable_weight: types.maybe(types.maybeNull(types.number)),
    gross_weight: types.maybe(types.maybeNull(types.number)),
    weight_unit: types.maybe(types.maybeNull(types.string)),
    final_place_of_delivery_print: types.maybe(types.maybeNull(types.string)),
    gross_volume: types.maybe(types.maybeNull(types.number)),
    volume_unit: types.maybe(types.maybeNull(types.string)),
    involved_branch_id: types.maybe(types.maybeNull(types.string)),
    incoterms: types.maybe(types.maybeNull(types.string)),
    letter_of_credit_number: types.maybe(types.maybeNull(types.string)),
    letter_of_credit_date: types.maybe(types.maybeNull(types.number)),
    accounting_status: types.maybe(types.maybeNull(types.string)),
    shipment_product_ratings: types.maybe(types.maybeNull(types.array(ShipmentProductRating))),
    inquiry_option: types.maybe(types.maybeNull(types.late((): IAnyModelType => InquiryOption))),
    routing_legs: types.array(types.late((): IAnyModelType => RoutingLeg)),
    collaborators: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => UserContact)))
    ),
    // driver: types.maybe(types.maybeNull(DriverDetails)),
    accessible_by_branches: types.maybe(
      types.maybeNull(
        types.array(
          types.model({
            id: types.maybe(types.maybeNull(types.string)),
            name: types.maybe(types.maybeNull(types.string)),
            erp_cost_center_id: types.maybe(types.maybeNull(types.string)),
          })
        )
      )
    ),
    shipment_properties: types.maybe(types.maybeNull(ShipmentProperties)),
    credit_status: types.maybe(types.maybeNull(types.string)),
    delivery_order_number: types.maybe(types.maybeNull(types.string)),
    booking_status: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    consol_type: types.maybe(types.maybeNull(types.string)),
    is_from_consol_helper: types.maybe(types.maybeNull(types.boolean)),
    is_linked_with_booking: types.maybe(types.maybeNull(types.boolean)),
    direct_port_delivery: types.maybe(types.maybeNull(types.boolean)),
    ops_pre_alert_sent_date: types.maybe(types.maybeNull(types.number)),
    shipment_parties: types.maybe(types.maybeNull(types.array(ShipmentParty))),
    shipment_custom_details: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => ShipmentCustomDetail)))
    ),
    is_external_quotation_number: types.maybe(types.maybeNull(types.boolean)),
    quotation_number: types.maybe(types.maybeNull(types.string)),
    shipment_events: types.maybe(types.maybeNull(types.array(ChangeEvent))),
    shipment_routing_details: types.maybe(types.maybeNull(types.array(RoutingDetails))),
    freight_type: types.maybe(types.maybeNull(types.enumeration(['air', 'ocean', 'road']))),
    trade_type: types.maybe(
      types.maybeNull(
        types.enumeration([
          TRADE_TYPE_EXPORT,
          TRADE_TYPE_IMPORT,
          TRADE_TYPE_DOMESTIC,
          TRADE_TYPE_CROSS_TRADE,
        ])
      )
    ),
    cargo_type: types.maybe(
      types.maybeNull(
        types.enumeration([
          CARGO_TYPE_CONTAINER,
          CARGO_TYPE_BREAK_BULK,
          CARGO_TYPE_LIQUID,
          CARGO_TYPE_BULK,
        ])
      )
    ),
    driver: types.maybe(types.maybeNull(Driver)),
    vehicle: types.maybe(types.maybeNull(Vehicle)),
    vehicle_category: types.maybe(types.maybeNull(types.string)),
    vehicle_license_plate_number: types.maybe(types.maybeNull(types.string)),
    trip_distance: types.maybe(types.maybeNull(types.number)),
    driver_name: types.maybe(types.maybeNull(types.string)),
    driver_contact_number: types.maybe(types.maybeNull(types.string)),
    priority: types.maybe(types.maybeNull(types.string)),
    color_code: types.maybe(types.maybeNull(types.string)),
    ordered_quantity: types.maybeNull(types.number),
    allocated_quantity: types.maybeNull(types.number),
    allocation_pending_quantity: types.maybeNull(types.number),
    shipment_type: types.maybe(
      types.maybeNull(types.enumeration(['direct', 'back_to_back', 'consol', 'house', 'general']))
    ),
    movement_mode: types.maybe(
      types.maybeNull(
        types.enumeration([
          'container_stuffing',
          'container_destuffing',
          'airport_delivery',
          'airport_pickup',
          'cargo_pickup_and_drop',
          'container_pickup_and_drop',
        ])
      )
    ),
    container_requests: types.array(ContainerRequest),
    involved_branch: types.maybe(types.maybeNull(BranchAccount)),
    document_dispatch_date: types.maybe(types.maybeNull(types.number)),
    ocean_transport_order_id: types.maybe(types.maybeNull(types.string)),
    ocean_transport_order: types.maybe(
      types.maybeNull(
        types.late(
          (): IAnyModelType =>
            types.model({
              id: types.identifier,
              empty_pickup_location: types.maybe(
                types.maybeNull(types.late((): IAnyModelType => Location))
              ),
            })
        )
      )
    ),
    ocean_transport_orders: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => OceanTransportOrder)))
    ),
    any_house_linked_with_lcl_booking: types.maybe(types.maybeNull(types.boolean)),
    customer_company: types.maybe(types.maybeNull(Company)),
    customer_address: types.maybe(types.maybeNull(Address)),
    shipment_users: types.maybe(
      types.maybeNull(
        types.array(
          types.model({
            id: types.identifier,
            role: types.maybe(types.maybeNull(types.string)),
            user: types.maybe(types.maybeNull(UserAccount)),
          })
        )
      )
    ),
    shipment_invoices: types.maybe(
      types.maybeNull(
        types.array(
          types.model({
            id: types.identifier,
            invoice_date: types.maybe(types.maybeNull(types.number)),
            invoice_number: types.maybe(types.maybeNull(types.string)),
            invoice_currency: types.maybe(types.maybeNull(types.string)),
            invoice_amount: types.maybe(types.maybeNull(types.number)),
            shipment_id: types.maybe(types.maybeNull(types.string)),
          })
        )
      )
    ),
    shipment_packages: types.maybe(
      types.maybeNull(
        types.array(
          types.model({
            id: types.identifier,
            shipment_id: types.maybe(types.maybeNull(types.string)),
            master_shipment_id: types.maybe(types.maybeNull(types.string)),
            gross_volume: types.maybe(types.maybeNull(types.number)),
            height: types.maybe(types.maybeNull(types.number)),
            length: types.maybe(types.maybeNull(types.number)),
            total_number_of_packages: types.maybe(types.maybeNull(types.number)),
            per_piece_weight: types.maybe(types.maybeNull(types.number)),
            type_of_package: types.maybe(types.maybeNull(types.string)),
            width: types.maybe(types.maybeNull(types.number)),
            dimension_unit: types.maybe(types.maybeNull(types.string)),
          })
        )
      )
    ),
    remarks: types.maybe(types.maybeNull(types.string)),
    services: types.maybe(types.maybeNull(ShipmentService)),
    shipped_on_board_date: types.maybe(types.maybeNull(types.number)),
    received_for_shipment_by_forwarder_date: types.maybe(types.maybeNull(types.number)),
    received_for_shipment_by_shipping_line_date: types.maybe(types.maybeNull(types.number)),
    consignee_party_name: types.maybe(types.maybeNull(types.string)),
    tracking_events: types.maybe(types.maybeNull(types.array(TrackingEvent))),
    job_date: types.maybe(types.maybeNull(types.string)),
    lfd_at_carrier: types.maybe(types.maybeNull(types.number)),
    lfd_at_empty_return: types.maybe(types.maybeNull(types.number)),
    lfd_at_pod: types.maybe(types.maybeNull(types.number)),
    lfd_at_pocd: types.maybe(types.maybeNull(types.number)),
    lfd_at_cfs: types.maybe(types.maybeNull(types.number)),
    job_execution_date: types.maybe(types.maybeNull(types.number)),
    business_type: types.maybe(types.maybeNull(types.string)),
    vessel_name: types.maybe(types.maybeNull(types.string)),
    voyage_number: types.maybe(types.maybeNull(types.string)),
    current_voyage_number: types.maybe(types.maybeNull(types.string)),
    forwarding_invoice_status: types.maybe(types.maybeNull(types.string)),
    stuffing_location: types.maybe(
      types.maybeNull(
        types.model({
          port: types.maybe(types.maybeNull(PortObject)),
          company_address: types.maybe(types.maybeNull(Address)),
          company: types.maybe(types.maybeNull(Company)),
        })
      )
    ),
    stuffing_type: types.maybe(
      types.maybeNull(
        types.union(
          types.literal(STUFFING_LOCATION_TYPE_CFS),
          types.literal(STUFFING_LOCATION_TYPE_FACTORY),
          types.literal(STUFFING_LOCATION_TYPE_ICD)
        )
      )
    ),
    destuffing_location: types.maybe(
      types.maybeNull(
        types.model({
          port: types.maybe(types.maybeNull(PortObject)),
          company_address: types.maybe(types.maybeNull(Address)),
          company: types.maybe(types.maybeNull(Company)),
        })
      )
    ),
    destuffing_type: types.maybe(
      types.maybeNull(
        types.union(
          types.literal(STUFFING_LOCATION_TYPE_CFS),
          types.literal(STUFFING_LOCATION_TYPE_FACTORY),
          types.literal(STUFFING_LOCATION_TYPE_ICD)
        )
      )
    ),
    shipment_booking_number: types.maybe(types.maybeNull(types.string)),
    atd_pcr_date: types.maybe(types.maybeNull(types.number)),
    estimated_time_of_arrival_at_fpod: types.maybe(types.maybeNull(types.number)),
    container_tracking_events_summary: types.maybe(
      types.maybeNull(
        types.model({
          picked_up_containers: types.maybe(types.maybeNull(types.integer)),
          pickup_pending_containers: types.maybe(types.maybeNull(types.integer)),
          loaded_containers: types.maybe(types.maybeNull(types.integer)),
          gated_in_containers: types.maybe(types.maybeNull(types.integer)),
          late_gated_in_containers: types.maybe(types.maybeNull(types.integer)),
          gated_out_containers: types.maybe(types.maybeNull(types.integer)),
          empty_returned_containers: types.maybe(types.maybeNull(types.integer)),
          demurrage_containers: types.maybe(types.maybeNull(types.integer)),
          detention_containers: types.maybe(types.maybeNull(types.integer)),
        })
      )
    ),
    other_job_number: types.maybe(types.maybeNull(types.string)),
    inquiry_number: types.maybe(types.maybeNull(types.string)),
    purchase_order_number: types.maybe(types.maybeNull(types.string)),
    sales_order_number: types.maybe(types.maybeNull(types.string)),
    cargo_ready_date: types.maybe(types.maybeNull(types.number)),
    target_shipping_date: types.maybe(types.maybeNull(types.number)),
    target_delivery_date: types.maybe(types.maybeNull(types.number)),
    is_cargo_ready_date_appliable: types.maybe(types.maybeNull(types.boolean)),
    is_purchase_order_number_appliable: types.maybe(types.maybeNull(types.boolean)),
    is_sales_order_number_appliable: types.maybe(types.maybeNull(types.boolean)),
    carrier_product: types.maybe(types.maybeNull(types.string)),
    volumetric_weight: types.maybe(types.maybeNull(types.number)),
    // cargos: types.array(Cargo),
    shipment_manifests: types.maybe(
      types.maybeNull(types.array(types.late((): IAnyModelType => ShipmentManifest)))
    ),
    destination_last_inward_date: types.maybe(types.maybeNull(types.number)),
    cargos: types.array(types.late((): IAnyModelType => ProductOrderItem)),
    destination_carrier_free_days: types.maybe(types.maybeNull(types.number)),
    destination_port_free_days: types.maybe(types.maybeNull(types.number)),
    origin_carrier_free_days: types.maybe(types.maybeNull(types.number)),
    isf_filed_by: types.maybe(types.maybeNull(types.string)),
    pocd_last_inward_date: types.maybe(types.maybeNull(types.number)),
    pickup_address_print: types.maybe(types.maybeNull(types.string)),
    merged_by_user: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
    split_by_user: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
    merged_at: types.maybe(types.maybeNull(types.number)),
    split_at: types.maybe(types.maybeNull(types.number)),
    teams: types.maybe(types.maybeNull(types.array(Team))),
    split_from_order_id: types.maybe(types.maybeNull(types.string)),
    link_shipment_json: types.maybe(types.maybeNull(LinkShipmentJson)),
    primary_ocean_transport_order: types.maybe(
      types.maybeNull(
        types.late(
          (): IAnyModelType =>
            types.model({
              service_type: types.maybe(types.maybeNull(types.string)),
              booking_date: types.maybe(types.maybeNull(types.number)),
              booking_number: types.maybe(types.maybeNull(types.string)),
              id: types.identifier,
            })
        )
      )
    ),
    carrier_do_number: types.maybe(types.maybeNull(types.string)),
    carrier_validity_date: types.maybe(types.maybeNull(types.number)),
    empty_return_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => Location))),
    surveyor_company: types.maybe(types.maybeNull(Company)),
    surveyor_address: types.maybe(types.maybeNull(Address)),
  })
  .views((self) => {
    function buildGetters(keys: string[], getterFunction: string): { [key: string]: () => any } {
      return keys.reduce((getters: any, field: any) => {
        return {
          ...getters,
          [_camelCase(`get_${field}`)]: function () {
            return this[getterFunction](field);
          },
        };
      }, {});
    }
    function getCustomDetailsGetters() {
      return buildGetters(['shipping_bill', 'bill_of_entry'], 'getShipmentCustomDetailsByType');
    }
    function getPartyGetters() {
      return buildGetters(PARTY_KEYS, 'getShipmentPartyByName');
    }
    function getPortGetters() {
      const PORT_KEYS = [
        LOCATION_TAG_PCR.key,
        LOCATION_TAG_POL.key,
        LOCATION_TAG_POD.key,
        LOCATION_TAG_PCD.key,
      ];
      return buildGetters(PORT_KEYS, 'getShipmentPortByType');
    }
    function getEventGetters() {
      const EVENT_KEYS = [EVENT_BANK_RELEASE_ORDER, EVENT_CLEARANCE];
      return buildGetters(EVENT_KEYS, 'getEventByName');
    }

    return {
      isBackToBackShipment: function () {
        return self.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK;
      },
      isConsolShipment: function () {
        return self.shipment_type === SHIPMENT_TYPE_CONSOL;
      },
      isRoadShipment: function () {
        return self.freight_type === FREIGHT_TYPE_ROAD;
      },
      isRoadSplitShipment: function () {
        return self.freight_type === FREIGHT_TYPE_ROAD && !!self.split_from_order_id;
      },
      isRoadCustomerOrder: function () {
        return !self.job_number && self.freight_type === FREIGHT_TYPE_ROAD;
      },
      isRoadConsolShipment: function () {
        return (
          self.shipment_type === SHIPMENT_TYPE_CONSOL && self.freight_type === FREIGHT_TYPE_ROAD
        );
      },
      isRoadBackToBackShipment: function () {
        return (
          self.shipment_type === SHIPMENT_TYPE_BACK_TO_BACK &&
          self.freight_type === FREIGHT_TYPE_ROAD
        );
      },
      isRoadHouseShipment: function () {
        return (
          self.shipment_type === SHIPMENT_TYPE_HOUSE && self.freight_type === FREIGHT_TYPE_ROAD
        );
      },
      isDirectShipment: function () {
        return self.shipment_type === SHIPMENT_TYPE_DIRECT;
      },
      isHouseShipment: function () {
        return self.shipment_type === SHIPMENT_TYPE_HOUSE;
      },
      isMasterShipment: function () {
        return self.shipment_type !== SHIPMENT_TYPE_HOUSE;
      },
      isConsolHouse: function () {
        return self.shipment_type === SHIPMENT_TYPE_HOUSE && Boolean(self.master_shipment_id);
      },
      get isHazardous() {
        return self.cargos.some((cargo: any) => {
          return cargo?.cargo_properties?.is_hazardous;
        });
      },
      get isFullyUnallocated() {
        return (
          self.ocean_transport_orders &&
          self.ocean_transport_orders.length === 1 &&
          !self.ocean_transport_orders[0].is_independent
        );
      },
      get carriageWiseRoutingLegs() {
        return (
          self.ocean_transport_orders && self.ocean_transport_orders[0]?.carriageWiseRoutingLegs
        );
      },
      isBreakBulk: function () {
        return self.load_type === LOAD_TYPE_FTL_BREAK_BULK;
      },
      getShipmentPartyByName: function (name: string) {
        return self.shipment_parties
          ? self.shipment_parties.find((shipment_party) => shipment_party.name === name)
          : undefined;
      },
      getShipmentPortByType: function (type: string) {
        return _get(
          self.shipment_routing_details?.find(
            (routing_detail) =>
              routing_detail.location_tags && routing_detail.location_tags.includes(type)
          ),
          'location'
        );
      },
      getFirstLegRoutingDetail: function () {
        return self.shipment_routing_details?.find(
          (item) => item?.location_tags && item?.location_tags.includes(LOCATION_TAG_POL.key)
        );
      },
      getLastLegRoutingDetail: function () {
        return self.shipment_routing_details?.find(
          (item) => item?.location_tags && item?.location_tags.includes(LOCATION_TAG_POD.key)
        );
      },
      getVesselAndVoyage: function () {
        if (self.freight_type === FREIGHT_TYPE_AIR) return;
        let routing_detail;
        if (self.trade_type === TRADE_TYPE_EXPORT) {
          routing_detail = this.getFirstLegRoutingDetail();
        } else {
          routing_detail = this.getLastLegRoutingDetail();
        }
        return {
          vessel: _get(routing_detail, 'vessel'),
          voyage_number: _get(routing_detail, 'voyage_number'),
        };
      },
      getVessel: function () {
        return _get(this.getVesselAndVoyage(), 'vessel');
      },
      getVoyageNumber: function () {
        return _get(this.getVesselAndVoyage(), 'voyage_number');
      },
      getContainerSummary: function (groupBy = 'container_type_code') {
        if (!self.shipment_containers) return '';
        const containersGroupedBy = _countBy(self.shipment_containers, groupBy);
        return Object.keys(containersGroupedBy)
          .map((groupKey) => {
            return `${containersGroupedBy[groupKey]} X ${groupKey}`;
          })
          .join(', ');
      },
      getEventByName: function (name: string) {
        return self.shipment_events?.find((shipment_event) => shipment_event.name === name);
      },
      getDisplayName: function () {
        if (!self.shipment_type && self.freight_type === FREIGHT_TYPE_ROAD) {
          return 'Customer Order';
        }
        return 'Shipment';
      },
      getShipmentType: function () {
        if (self.shipment_type === SHIPMENT_TYPE_HOUSE && self.freight_type === FREIGHT_TYPE_ROAD) {
          if (!!self.split_from_order_id) return 'House';
          return 'Direct';
        }
        return self.shipment_type || 'Customer Order';
      },
      getUserByName: function (name: string) {
        return self.shipment_users?.find((shipment_user) => shipment_user.role === name);
      },
      getShipmentCustomDetailsByType(trade_type: any) {
        return self.shipment_custom_details?.filter(
          (custom_detail) => custom_detail.trade_type === trade_type
        );
      },
      getShipmentDocumentType: function () {
        return this.isMasterShipment() ? 'master' : 'house';
      },
      getShipmentDocument: function (documentType: any): ShipmentDocumentValue {
        /*explicitly send documentType if you want. I'll handle case of back_to_back house */
        const shipmentDocumentType = documentType || this.getShipmentDocumentType();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (
          _get(this, 'shipment_documents', []).find(
            (document: any) => document.document_type === shipmentDocumentType
          ) || {}
        );
      },
      getMasterShipmentNumber: function () {
        const master_shipment_number = this.getMasterDocumentNumber();
        if (self.freight_type === FREIGHT_TYPE_AIR && master_shipment_number) {
          return `${master_shipment_number.substr(0, 3)}-${master_shipment_number.substr(3)}`;
        }
        return master_shipment_number;
      },
      getMasterDocumentNumber: function () {
        return this.getShipmentDocument('master')?.shipment_number;
      },
      getHouseDocumentNumber: function () {
        return this.getShipmentDocument('house')?.shipment_number;
      },
      isOceanShipment: function () {
        return self.freight_type === FREIGHT_TYPE_OCEAN;
      },
      canEdit: function () {
        if (
          self.freight_type === FREIGHT_TYPE_ROAD &&
          self.shipment_type === SHIPMENT_TYPE_CONSOL
        ) {
          if (self.allocation_pending_quantity && self.allocation_pending_quantity <= 0)
            return false;
        }
        if (self.accounting_status === ACCOUNTING_STATUS_CLOSED) return false;
        if (self.freight_type !== FREIGHT_TYPE_ROAD) {
          if (this.isExportMasterExecuted()) return false;
          if (this.isExportHouseExecuted()) {
            return false;
          }
        }
        if (
          self.status === SHIPMENT_STATUS_CANCELLED ||
          self.status === SHIPMENT_STATUS_BACK_TO_TOWN
        )
          return false;
        if (this.isRoadSplitShipmentExecuted()) return false;
        return true;
      },
      isAirShipment: function () {
        return self.freight_type === FREIGHT_TYPE_AIR;
      },
      isExportShipment: function () {
        return self.trade_type === TRADE_TYPE_EXPORT;
      },
      isGeneralShipment: function () {
        return self.shipment_type === SHIPMENT_TYPE_GENERAL;
      },
      isImportShipment: function () {
        return self.trade_type === TRADE_TYPE_IMPORT;
      },
      isForwardingShipment: function () {
        return _get(self.services, SERVICE_TYPE_FREIGHT);
      },
      isAccountingOpen: function () {
        return self.accounting_status === ACCOUNTING_STATUS_PENDING;
      },
      isAccountingBillingClosed: function () {
        return self.accounting_status === ACCOUNTING_STATUS_BILLING_CLOSED;
      },
      isAccountingClosed: function () {
        return self.accounting_status === ACCOUNTING_STATUS_CLOSED;
      },
      isLcl: function () {
        return self.load_type === LOAD_TYPE_LCL;
      },
      isFcl: function () {
        return self.load_type === LOAD_TYPE_FCL;
      },
      getServices: function () {
        let services_offered: string[] = [];
        if (_get(self.services, SERVICE_TYPE_FREIGHT)) services_offered.push(SERVICE_TYPE_FREIGHT);
        const origin_services = _get(self.services, SERVICE_TYPE_ORIGIN);
        if (origin_services)
          services_offered = services_offered.concat(
            Object.entries(origin_services)
              .filter(([_service, value]) => !!value)
              .map(([service, _value]) => `origin_${service}`)
          );
        const destination_services = _get(self.services, SERVICE_TYPE_DESTINATION);
        if (destination_services)
          services_offered = services_offered.concat(
            Object.entries(destination_services)
              .filter(([_service, value]) => !!value)
              .map(([service, _value]) => `destination_${service}`)
          );
        if (_get(self.services, SERVICE_CARGO_INSURANCE))
          services_offered.push(SERVICE_CARGO_INSURANCE);
        if (_get(self.services, SERVICE_EMPTY_CONTAINER_INSURANCE))
          services_offered.push(SERVICE_EMPTY_CONTAINER_INSURANCE);
        return services_offered;
      },
      getInvoiceNumbers: function () {
        const invoices = self.shipment_invoices || [];
        return invoices
          .map((invoice) => invoice.invoice_number)
          .filter((inv) => !!inv)
          .join(', ');
      },
      isMasterDocumentHandoverSetPrinted: function () {
        const shipment_document = self.shipment_documents?.find(
          (sd) => sd.document_type === 'master'
        );
        return _get(shipment_document, 'document_status') === DOCUMENT_STATUS_HANDOVER_SET_PRINTED;
      },
      isMasterDocumentExecuted: function () {
        const shipment_document = self.shipment_documents?.find(
          (sd) => sd.document_type === 'master'
        );
        return _get(shipment_document, 'document_status') === SHIPMENT_STATUS_EXECUTED;
      },
      isHouseDocumentExecuted: function () {
        const shipment_document = self.shipment_documents?.find(
          (sd) => sd.document_type === 'house'
        );
        return _get(shipment_document, 'document_status') === SHIPMENT_STATUS_EXECUTED;
      },
      isExportMasterExecuted: function () {
        return this.isExportShipment() && this.isDocumentFinalised('master');
      },
      isExportHouseExecuted: function () {
        return this.isExportShipment() && this.isDocumentFinalised('house');
      },
      isRoadSplitShipmentExecuted: function () {
        return this?.isRoadSplitShipment() && this.isDocumentFinalised(SHIPMENT_TYPE_HOUSE);
      },
      isDocumentFinalised: function (documentType: string) {
        const shipment_document = this.getShipmentDocument(documentType);
        const final_statuses = [
          SHIPMENT_STATUS_EXECUTED,
          DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
          DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
          DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
        ];
        if (documentType === 'house')
          final_statuses.push(DOCUMENT_STATUS_DRAFT_APPROVED, DOCUMENT_STATUS_POD_RECEIVED);
        return final_statuses.includes(_get(shipment_document, 'document_status') || '');
      },
      isPlanned: function () {
        return self.status === SHIPMENT_STATUS_PLANNED;
      },
      isShipmentCancelled: function () {
        return (
          self.status === SHIPMENT_STATUS_CANCELLED || self.status === SHIPMENT_STATUS_BACK_TO_TOWN
        );
      },
      isStockAllocated: function () {
        const shipment_document = self.shipment_documents?.find(
          (sd) => sd.document_type === 'master'
        );
        return !!_get(shipment_document, 'stock_order_item.id');
      },
      isLooseCargo: function () {
        return self.load_type === CARGO_TYPE_AIR_LOOSE.key;
      },
      isUldCargo: function () {
        return self.load_type === CARGO_TYPE_AIR_ULD.key;
      },
      isStockUsed: function () {
        return this.isExportMasterExecuted() || this.isStockAllocated();
      },
      isLinkedWithMaster: function () {
        return !!self.master_shipment_id;
      },
      isLclLinkedHouse: function () {
        return (
          this.isLcl() &&
          this.isHouseShipment() &&
          this.isLinkedWithMaster() &&
          self.is_linked_with_booking
        );
      },
      isContainerAddDisabled: function () {
        return isJobClosed(self) || this.isShipmentCancelled() || this.isExportMasterExecuted();
      },
      isContainerEditDisabled: function () {
        return this.isShipmentCancelled() ||
          isJobClosed(self) ||
          _get(self, 'shipment_type') === SHIPMENT_TYPE_HOUSE
          ? this.isExportHouseExecuted()
          : this.isExportMasterExecuted();
      },
      isContainerDeleteDisabled: function () {
        return (
          isJobClosed(self) ||
          this.isExportMasterExecuted() ||
          (self.load_type === LOAD_TYPE_FCL && self?.is_linked_with_booking) ||
          this.isShipmentCancelled()
        );
      },
      getAllPartiesRolesMapping: function (user: SessionDataValue) {
        const all_companies: any[] = [];
        if (self.customer_company) {
          all_companies.push({
            role: 'Customer',
            id: self.customer_company?.id,
            address_id: self.customer_address?.id,
            registered_name: self.customer_company?.registered_name,
          });
        }
        const default_company = user?.company_account?.default_company;
        // let isDefaultCompanyConsidered = false;
        PARTY_KEYS.forEach((party: string) => {
          const shipment_party = self.shipment_parties?.find(
            (p: ShipmentPartyValue) => p.name === party
          );
          if (shipment_party && shipment_party?.party_company) {
            all_companies.push({
              role: _startCase(party),
              id: shipment_party?.party_company?.id,
              registered_name: shipment_party?.party_company?.registered_name,
              address_id: shipment_party?.party_address?.id,
            });
            // TODO: isko badme karenge, once we bring in branch wise Your Company
            // isDefaultCompanyConsidered ||= shipment_party.party_company.id === default_company.id;
          }
        });
        if (default_company && default_company?.id) {
          all_companies.push({
            role: 'Your Company',
            id: default_company?.id,
            registered_name: default_company?.registered_name,
            address_id: self.involved_branch?.default_address?.id,
          });
        }
        return all_companies;
      },
      getPickedupContainers: function () {
        return self.shipment_containers?.filter((c) => c.status !== 'pickup_pending');
      },
      getPortManifest: function (trade_type = 'import') {
        const shipment = self;
        const shipment_manifest = shipment.shipment_manifests?.find((sm) => {
          return sm.trade_type === trade_type && sm.location_type === 'port';
        });
        return shipment_manifest;
      },
      getIcdManifest: function (trade_type = 'import') {
        const shipment = self;
        const shipment_manifest = shipment.shipment_manifests?.find((sm) => {
          return sm.trade_type === trade_type && sm.location_type === 'icd';
        });
        return shipment_manifest;
      },
      getImportGeneralManifest: function () {
        const sm = this.getPortManifest();
        const event = {
          event_number: sm?.manifest_number,
          event_date: sm.manifest_date,
          event_data: {
            line_number: sm.line_number,
            subline_number: sm.subline_number,
          },
        };
        return event;
      },
      getIcdImportGeneralManifest: function () {
        const sm = this.getIcdManifest();
        const event = {
          event_number: sm?.manifest_number,
          event_date: sm?.manifest_date,
        };
        return event;
      },
      getOtoBookingFromShipment: function () {
        const oto = self.ocean_transport_orders?.filter(
          (oto) => oto.booking_type === 'shipping_line'
        );
        if (!!oto) return oto[0];
        else if (!!self.ocean_transport_orders) return self.ocean_transport_orders[0];
        return [];
      },
      ...getEventGetters(),
      ...getPortGetters(),
      ...getPartyGetters(),
      ...getCustomDetailsGetters(),
      // ...getOtoBookingFromShipment(),
    };
  })
  .actions((shipment) => ({
    handleDeleteGeneratedDocument: function (docIds: string[]) {
      const matchingDocuments = (shipment.shipment_documents || []).filter((document) =>
        docIds.includes(document.document_id || '')
      );
      shipment.house_shipments?.forEach((house_shipment: Instance<typeof Shipment>) => {
        const docs = (house_shipment.shipment_documents || []).filter((document) =>
          docIds.includes(document.document_id || '')
        );
        matchingDocuments.concat(docs);
      });
      matchingDocuments.forEach((document) => document.handleDeleteGeneratedDocument());
    },
    replaceContainers: function (containers: ShipmentContainerValue[]) {
      shipment.shipment_containers = cast(containers);
    },
    updateCustomerAndSalesAgent(values: {
      customer_company_id?: CompanyValue;
      sales_agent_id?: SalesPersonValue;
    }) {
      const { customer_company_id, sales_agent_id } = values;
      if (customer_company_id) shipment.customer_company = customer_company_id;
      if (sales_agent_id) shipment.sales_agent = sales_agent_id;
    },
    hasMinimumOneContainerAllocated() {
      return shipment.shipment_containers?.some((container) => container.container_number) === true;
    },
    isShipment() {
      return shipment.job_number;
    },
  }));

export type ShipmentValue = Instance<typeof Shipment>;
export type ShipmentIn = SnapshotIn<typeof Shipment>;
export type ShipmentOut = SnapshotOut<typeof Shipment>;
export type ShipmentPropertiesValue = Instance<typeof ShipmentProperties>;
export type PortValue = Instance<typeof PortObject>;
export type ShipmentServiceValue = Instance<typeof ShipmentService>;

export default Shipment;
