import { gql } from '@apollo/client';
import { trackingEventFields } from './trackingEvent';
import { shipmentPartyFields } from './shipment';

const shipmentCustomsFields = gql`
  fragment shipmentCustomsFields on ShipmentCustomDetailObject {
    id
    shipment_id
    shipment {
      id
      shipment_type
      trade_type
      freight_type
      load_type
      status
      accounting_status
      shipment_parties {
        ...shipmentPartyFields
      }
      port_of_discharge {
        id
        type
        name
        country_code
      }
      port_of_loading {
        id
        type
        name
        country_code
      }
      place_of_carrier_receipt {
        id
        type
        name
        country_code
      }
      place_of_carrier_delivery {
        id
        type
        name
        country_code
      }
    }
    custom_document_date
    custom_document_number
    trade_type
    egm_no
    filing_type
    duty_free
    duty_payment_by
    custom_clearance_location {
      id
      name
      type
      country_code
      state_code
      is_customs_location
    }
    cargo_value
    insurance_value
    freight_value
    cargo_gross_weight
    cargo_net_weight
    cargo_volume
    duty_amount
    exchange_rate
    currency
    due_date_to_pay_duty
    filing_valid_till
    bank_realisation_cutoff_date
    scroll_date
    reward_scheme
    duty_benefits
    reward_amount
    duty_paid_amount
    drawback_bank_account
    drawback_scroll_number
    challan_no
    forex_bank_account
    last_action_status
    next_actions
    icd_egm_no
    assessable_value
    fob_value
    cif_value
    invoice_value
    country
    last_comment
    last_status_update
    bill_of_entry_type
    shipping_bill_type
    transhipment_permission_no
    transhipment_permission_date
    rewarehousing_certificate_no
    rewarehousing_certificate_date
    warehouse_location {
      id
      name
      type
      country_code
      state_code
      is_customs_location
    }
    warehouse_be_no
    current_queue
    warehouse_be_date
    import_duty_free_no
    import_duty_free_date
    stamp_duty
    shipment_invoices {
      id
      shipment_custom_detail_id
      invoice_number
      invoice_currency
      fob_invoice_amount
      cif_invoice_amount
      invoice_amount
      exchange_rate
    }
    custom_field_values
  }
  ${shipmentPartyFields}
`;

export const SHIPMENT_CUSTOM_DETAIL = gql`
  query shipment_custom_detail($id: ID!) {
    shipment_custom_detail(id: $id) {
      ...shipmentCustomsFields
      tracking_events {
        ...trackingEventFields
      }
    }
  }
  ${shipmentCustomsFields}
  ${trackingEventFields}
`;

export const FETCH_SHIPMENT_CUSTOM_DETAILS = gql`
  query fetch_shipment_custom_details($shipment_ids: [ID!]!) {
    fetch_shipment_custom_details(shipment_ids: $shipment_ids) {
      ...shipmentCustomsFields
      tracking_events {
        ...trackingEventFields
      }
    }
  }
  ${shipmentCustomsFields}
  ${trackingEventFields}
`;

export const DELETE_SHIPMENT_CUSTOM_DETAIL = gql`
  mutation deleteShipmentCustomDetail($id: ID!) {
    delete_shipment_custom_detail(id: $id) {
      message
    }
  }
`;

export const CREATE_SHIPMENT_CUSTOM_DETAIL = gql`
  mutation createShipmentCustomDetail(
    $shipment_id: ID!
    $shipment_custom_detail: ShipmentCustomDetailInput!
  ) {
    create_shipment_custom_detail(
      shipment_id: $shipment_id
      shipment_custom_detail: $shipment_custom_detail
    ) {
      ...shipmentCustomsFields
    }
  }
  ${shipmentCustomsFields}
`;

export const UPDATE_SHIPMENT_CUSTOM_DETAIL = gql`
  mutation updateShipmentCustomDetail(
    $id: ID!
    $shipment_custom_detail: ShipmentCustomDetailInput!
  ) {
    update_shipment_custom_detail(id: $id, shipment_custom_detail: $shipment_custom_detail) {
      ...shipmentCustomsFields
    }
  }
  ${shipmentCustomsFields}
`;

export const CUSTOM_FROM_SB_BE = gql`
  mutation upsertCustomDetailFromSbBe(
    $id: ID
    $shipment_id: ID!
    $custom_document_date: String
    $custom_document_number: String
    $port_of_loading_id: ID
    $port_of_discharge_id: ID
    $data: String!
    $cargo_description: String
  ) {
    upsert_custom_detail_from_sb_be(
      id: $id
      shipment_id: $shipment_id
      custom_document_date: $custom_document_date
      custom_document_number: $custom_document_number
      port_of_loading_id: $port_of_loading_id
      port_of_discharge_id: $port_of_discharge_id
      data: $data
      cargo_description: $cargo_description
    ) {
      message
    }
  }
`;
