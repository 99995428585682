import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Tag } from '@shipmnts/pixel-hub';
import { ENUM_BUY_SELL_STATUS } from 'operations/modules/shipment/constants';

const STATUS_INDICATOR_COLOR: { [key: string]: string } = {
  default: 'darkgrey',
  Return: 'darkgrey',
  Overdue: 'red',
  Draft: 'red',
  Cancelled: 'red',
  Paid: 'green',
  Unpaid: 'orange',
  Submitted: 'blue',
  Hold: 'orange',
  Dispute: 'red',
};

export const doc_status_map: {
  [key: string]: {
    display_name: string;
    color: string;
    backgroundColor?: string;
    borderColor?: string;
  };
} = {
  Submit: { display_name: 'Submitted', color: 'var(--color-primary)' },
  Draft: { display_name: 'Draft', color: '#9d9fa3' },
  Cancelled: { display_name: 'Cancelled', color: '#d91f11' },
  Dispute: { display_name: 'Dispute', color: '#d91f11' },
  [ENUM_BUY_SELL_STATUS.DRAFT]: {
    display_name: 'Draft',
    color: 'rgba(10, 79, 202, 1)',
    backgroundColor: 'rgba(10, 79, 202, 0.1)',
    borderColor: 'currentColor',
  },
  [ENUM_BUY_SELL_STATUS.BILLED]: {
    display_name: 'Submitted',
    color: 'rgba(2, 126, 70, 1)',
    backgroundColor: 'rgba(2, 126, 70, 0.1)',
    borderColor: 'currentColor',
  },
  Paid: {
    display_name: 'Paid',
    color: 'rgba(2, 126, 70, 1)',
    backgroundColor: 'rgba(2, 126, 70, 0.1)',
    borderColor: 'currentColor',
  },
  [ENUM_BUY_SELL_STATUS.CANCELLED]: { display_name: 'Cancelled', color: '#d91f11' },
  [ENUM_BUY_SELL_STATUS.UNBILLED]: {
    display_name: 'Unbilled',
    color: '#996E2F',
    backgroundColor: 'rgba(255, 184, 78, 0.1)',
    borderColor: 'rgba(255, 184, 78, 1)',
  },
  Unpaid: {
    display_name: 'Unpaid',
    color: '#996E2F',
    backgroundColor: 'rgba(255, 184, 78, 0.1)',
    borderColor: 'rgba(255, 184, 78, 1)',
  },
  [ENUM_BUY_SELL_STATUS.IGNORED]: {
    display_name: 'Ignored',
    color: 'rgba(217, 31, 17, 1)',
    backgroundColor: 'rgba(217, 31, 17, 0.1)',
    borderColor: 'currentColor',
  },
  Hold: {
    display_name: 'Hold',
    color: '#d46b08',
  },
};

export const PaymentStatusRenderer = (props: ICellRendererParams) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Tag color={STATUS_INDICATOR_COLOR[props.value]}>{props.value}</Tag>
    </div>
  );
};

export const DocumentRenderer = (props: ICellRendererParams) => {
  const val = doc_status_map[props.value] || {};
  return (
    <div
      style={{
        color: val.color || '#232a36',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
      }}
    >
      {val.display_name || ''}
    </div>
  );
};
