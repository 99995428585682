import React, { useEffect } from 'react';
import { Form, message, Modal, Typography } from '@shipmnts/pixel-hub';
import { TeamFormTable, getUpsertTeamPayload, isSalesPersonPresentInTeam } from 'common';
import { TeamValue } from 'network/models/Team';
import { useMutation } from '@apollo/client';
import { UPSERT_COMPANY, UPSERT_TEAM } from 'network/graphql/company';

interface TeamDetailsFormProps {
  initialValues: {
    teams: Array<TeamValue>;
  };
  open: boolean;
  setIsOpen: (open: boolean) => void;
  reference_id: string;
  reference_type: string;
  onSuccess?: () => void;
  onClose?: () => void;
  style?: React.CSSProperties;
}

const { Text } = Typography;

const TeamDetailsFormModal = React.memo(function TeamDetailsForm(
  props: TeamDetailsFormProps
): JSX.Element {
  const {
    initialValues,
    open,
    setIsOpen,
    reference_type,
    reference_id,
    onSuccess,
    style,
    onClose,
  } = props;
  const [form] = Form.useForm();
  const [upsertTeam, { data, loading }] = useMutation(
    reference_type === 'Network::Company' ? UPSERT_COMPANY : UPSERT_TEAM
  );

  useEffect(() => {
    if (data?.upsert_team || data?.upsert_company) {
      message.success('Team member detail(s) updated successfully!');
      setIsOpen(false);
      onSuccess && onSuccess();
      onClose && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="team-modal">
      <Modal
        title="Update Team Details"
        open={open}
        style={style}
        onCancel={() => {
          onClose && onClose();
          setIsOpen(false);
        }}
        okText={'Save'}
        okButtonProps={{
          onClick: () => {
            form.submit();
          },
          loading: loading,
        }}
      >
        <div style={{ padding: '0 15px' }}>
          {reference_type === 'Network::Company' && (
            <Text type="secondary">
              Handling branch specifies the branch of your company for which a sales person is
              handling this customer. If kept empty then it means that sales person is same for all
              branches.
            </Text>
          )}
          <Form
            initialValues={initialValues}
            name="teams"
            form={form}
            onFinish={(values: any) => {
              const payload = getUpsertTeamPayload(
                values.teams as any,
                reference_type,
                reference_id,
                initialValues?.teams as any
              );
              if (reference_type === 'SalesHub::Inquiry') {
                if (!isSalesPersonPresentInTeam(payload.teams)) {
                  message.error('Please enter sales person details');
                  return;
                }
              }
              if (!payload.teams.length) {
                message.error('Please enter team details');
                return;
              }
              if (reference_type === 'Network::Company') {
                upsertTeam({
                  variables: {
                    company: {
                      id: reference_id,
                      teams: payload.teams,
                    },
                  },
                });
              } else {
                upsertTeam({ variables: payload });
              }
            }}
          >
            <TeamFormTable form={form} reference_type={reference_type} />
          </Form>
        </div>
      </Modal>
    </div>
  );
});

export default TeamDetailsFormModal;
