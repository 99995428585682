import React from 'react';

import { Col, Row, Typography, UploadedDocuments } from '@shipmnts/pixel-hub';
import { useSession } from 'common';

import { useInquiryDetail } from './InquiryDetailLayout';

const DocumentTab = () => {
  const { inquiry } = useInquiryDetail();
  const session = useSession();
  if (!inquiry) return <></>;
  return (
    <div>
      <Typography.Title level={4} style={{ color: 'var(--color-primary)' }}>
        Uploaded Documents
      </Typography.Title>
      <Row>
        <Col sm={24} md={12}>
          <UploadedDocuments
            sessionData={session}
            parent_id={inquiry?.id}
            parent_type="inquiry"
            docgen_url={`${process.env.DOCGEN_URL}`}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DocumentTab;
