import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Tooltip, message, Form } from '@shipmnts/pixel-hub';
import CargosView from './CargosView';
import { pick } from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { observer } from 'mobx-react-lite';
import { BookingType } from 'operations/modules/booking/constants';
import { errorMessageHandlerGraphQL } from 'common';
import CargoNewComponent, {
  getInitialValueForCargoProperties,
} from 'operations/modules/shipment/components/DetailLayout/Cargo/CargoNewComponent';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

interface CargoDetailsCardProps {
  cargos: ProductOrderItemValue[];
  parent_id: string;
  parent_type: BookingType;
  onUpdate?: (value: ProductOrderItemValue[]) => void;
  load_type?: string;
  disabled?: boolean;
  disableReason?: string;
  disableCargoAddRemove?: boolean;
}

const CargoDetailsCard = observer(function CargoDetailsCard(
  props: CargoDetailsCardProps
): JSX.Element {
  const {
    cargos,
    parent_id,
    parent_type,
    onUpdate,
    load_type,
    disabled,
    disableReason,
    disableCargoAddRemove,
  } = props;
  const [form] = Form.useForm();
  const [cargoEdit, setCargoEdit] = useState<{
    edit: boolean;
    beforeUpdate?: Array<ProductOrderItemValue>;
  }>({ edit: false });
  const cargoRef = useRef<{
    runValidation: () => boolean;
    getPayload: () => ProductOrderItemValue[];
  }>();
  const mutation = UPDATE_OCEAN_TRANSPORT_ORDER;

  const [updateParent, { data, loading, error }] = useMutation(mutation);

  useEffect(() => {
    if (!error && data && data[`update_${parent_type}`]) {
      const { cargos: updatedCargos } = data[`update_${parent_type}`];
      message.success('Cargo Details Updated!');
      form.resetFields();
      if (onUpdate) onUpdate(updatedCargos);
      setCargoEdit({ edit: false, beforeUpdate: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    form.setFieldValue('cargos', cargoEdit.beforeUpdate);
  }, [cargoEdit, form]);

  if (cargoEdit.edit) {
    return (
      <Form
        form={form}
        scrollToFirstError
        onFinish={(values: { cargos?: ProductOrderItemValue[] }) => {
          const error = Boolean(cargoRef?.current?.runValidation());
          if (error) return;
          const previousValues: any = cargoEdit.beforeUpdate?.slice() || [];
          const pickCargoElement = [
            'id',
            'linked_to_id',
            'cargo_properties',
            'product_name',
            'outer_package_type',
            'outer_package_qty',
            'gross_weight',
            'gross_volume',
            'volume_unit',
            'weight_unit',
            // 'shipment_packages',
          ];
          const updateValues = (cargoRef?.current?.getPayload() || []).map((cargo: any) => ({
            ...pick(cargo, pickCargoElement),
            _destroy: cargo._destroy,
            commodity_id: cargo.commodity?.id,
            // booking_request_id: cargo.booking_request?.id,
            // ocean_transport_order_id: cargo.ocean_transport_order?.id,
            // road_transport_order_id: cargo.road_transport_order?.id,
            // shipment_id: cargo.shipment?.id,
            parent_id: cargo.parent_id,
            parent_type: cargo.parent_type,
            commodity: undefined,
            allocation_pending_quantity: undefined,
          }));
          previousValues.forEach((cargo: any) => {
            if (!updateValues.find((c: any) => c.id === cargo.id))
              updateValues.push({
                ...pick(cargo, pickCargoElement),
                commodity_id: cargo.commodity?.id,
                parent_id: cargo.parent_id,
                parent_type: cargo.parent_type,
                commodity: undefined,
                _destroy: true,
                allocation_pending_quantity: undefined,
              });
          });
          updateParent({
            variables: {
              [parent_type]: {
                id: parent_id,
                cargos: updateValues.map((cr: any) => {
                  const { commodity, ...restCargos } = cr;
                  return {
                    ...restCargos,
                    _id: undefined,
                    allocation_pending_quantity: undefined,
                    commodity_id: commodity?.id,
                  };
                }),
              },
            },
          });
        }}
        initialValues={{
          cargos: cargoEdit.beforeUpdate,
          cargo_properties: getInitialValueForCargoProperties(cargoEdit?.beforeUpdate),
        }}
        layout="vertical"
      >
        <Card
          id="cargos"
          title="Cargo Details"
          extra={[
            <Button
              style={{ marginRight: '5px' }}
              key="cancel"
              disabled={loading}
              onClick={() => {
                setCargoEdit({ edit: false });
                form.resetFields();
              }}
            >
              Cancel
            </Button>,
            <Button loading={loading} key="save" htmlType="submit" type="primary">
              Save
            </Button>,
          ]}
        >
          {error && errorMessageHandlerGraphQL(error)}
          <CargoNewComponent
            form={form}
            ref={cargoRef}
            showAddDeleteButton={!disableCargoAddRemove}
          />
        </Card>
      </Form>
    );
  }

  return (
    <Card
      id="cargos"
      title="Cargo Details"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              setCargoEdit({
                edit: true,
                beforeUpdate: cargos || [],
              });
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <CargosView cargos={cargos} load_type={load_type} />
    </Card>
  );
});

export default CargoDetailsCard;
