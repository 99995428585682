import { uuid4 } from '@sentry/utils';
import { roundToTwoDecimals } from 'common';
import { hasPermission } from '@shipmnts/pixel-hub';
import Company, { CompanyValue } from 'operations/models/Company';
import {
  types,
  Instance,
  SnapshotIn,
  SnapshotOut,
  detach,
  getEnv,
  getParent,
  getSnapshot,
  IAnyModelType,
  applySnapshot,
} from 'mobx-state-tree';
import {
  PERMISSION_FREIGHT_BUY,
  PERMISSION_FREIGHT_SELL,
  PERMISSION_BUY_ESTIMATE,
  PERMISSION_SELL_ESTIMATE,
  PERMISSION_REBATE_BUY,
  PERMISSION_REBATE_SELL,
} from 'operations/modules/reports/constants';
import {
  ACCOUNTING_STATUS_PENDING,
  ACCOUNTING_STATUS_CLOSED,
} from 'operations/modules/shipment/constants';
import {
  ENUM_BUY_SELL_STATUS,
  FREIGHT_CHARGE_TAG,
  REBATE_CHARGE_TAG,
} from 'operations/modules/shipment/constants';
import { createContext, useContext } from 'react';
import { BranchAccountValue } from 'operations/models/BranchAccount';
import { Contract } from 'src/packages/rate_management';
import { SHOW_ONLY_PERMITTED_BRANCH_EST } from 'network/permissions';

const ContractLineItem = types.model({
  id: types.maybe(types.maybeNull(types.string)),
  contract: types.maybe(types.late((): IAnyModelType => Contract)),
});

const ShipmentEstimateInternal = types
  .model({
    uuid: types.string,
    id: types.maybe(types.string),
    item: types.maybe(types.maybeNull(types.string)),
    inquiry_option_id: types.maybe(types.maybeNull(types.string)),
    shipment_id: types.maybe(types.maybeNull(types.string)),
    buy_branch_id: types.maybe(types.maybeNull(types.string)),
    sell_branch_id: types.maybe(types.maybeNull(types.string)),
    supplier_company_id: types.maybe(types.maybeNull(types.string)),
    supplier_company: types.maybe(types.maybeNull(Company)),
    customer_company_id: types.maybe(types.maybeNull(types.string)),
    customer_company: types.maybe(types.maybeNull(Company)),
    uom: types.maybe(types.maybeNull(types.string)),
    quantity: types.maybe(types.maybeNull(types.number)),
    buy_rate: types.maybe(types.maybeNull(types.number)),
    buy_exchange_rate: types.maybe(types.maybeNull(types.number)),
    buy_terms: types.maybe(types.maybeNull(types.string)),
    buy_currency: types.maybe(types.maybeNull(types.string)),
    total_buy_amount: types.maybe(types.maybeNull(types.number)),
    total_buy_billed_amount: types.maybe(types.maybeNull(types.number)),
    sell_rate: types.maybe(types.maybeNull(types.number)),
    sell_exchange_rate: types.maybe(types.maybeNull(types.number)),
    sell_terms: types.maybe(types.maybeNull(types.string)),
    sell_currency: types.maybe(types.maybeNull(types.string)),
    total_sell_amount: types.maybe(types.maybeNull(types.number)),
    total_sell_billed_amount: types.maybe(types.maybeNull(types.number)),
    sell_remarks: types.maybe(types.maybeNull(types.string)),
    remarks: types.maybe(types.maybeNull(types.string)),
    buy_status: types.maybe(types.maybeNull(types.string)),
    sell_status: types.maybe(types.maybeNull(types.string)),
    buy_transaction_identifier: types.maybe(types.maybeNull(types.string)),
    buy_transaction_doctype: types.maybe(types.maybeNull(types.string)),
    sell_transaction_identifier: types.maybe(types.maybeNull(types.string)),
    equipment_type: types.maybe(types.maybeNull(types.string)),
    equipment_name: types.maybe(types.maybeNull(types.string)),
    freight_contract_item_id: types.maybe(types.maybeNull(types.string)),
    tag: types.maybe(types.maybeNull(types.string)),
    tax_percentage: types.maybe(types.maybeNull(types.number)),
    taxable: types.maybe(types.maybeNull(types.number)),
    taxability: types.maybe(types.maybeNull(types.string)),
    product_rating: types.maybe(
      types.maybeNull(
        types.model({
          id: types.maybe(types.maybeNull(types.string)),
        })
      )
    ),
    sequence: types.maybe(types.maybeNull(types.number)),
    item_group: types.maybe(types.maybeNull(types.string)),
    item_description: types.maybe(types.maybeNull(types.string)),
    sell_contract_item: types.maybe(types.maybeNull(ContractLineItem)),
    buy_contract_item: types.maybe(types.maybeNull(ContractLineItem)),
    markup_type: types.maybe(types.maybeNull(types.string)),
    markup_value: types.maybe(types.maybeNull(types.number)),
  })
  .volatile((self) => ({
    sell_transaction_doctype: 'Sales Invoice', // adding this for links to work with renderers
    buy_permission: { view: true, edit: true },
    sell_permission: { view: true, edit: true },
    charge_type: 'Item',
    mismatch: false,
  }))
  .views((self) => ({
    get sell_provisional_amount() {
      if (!this.is_sell_view_allowed) return 0;
      return self.sell_status === ENUM_BUY_SELL_STATUS.BILLED
        ? self.total_sell_billed_amount
        : self.total_sell_amount;
    },
    get buy_provisional_amount() {
      if (!this.is_buy_view_allowed) return 0;
      return self.buy_status === ENUM_BUY_SELL_STATUS.BILLED
        ? self.total_buy_billed_amount
        : self.total_buy_amount;
    },
    get uom_string() {
      return self.equipment_name
        ? `${self.uom}.${self.equipment_name}.${self.equipment_type}`
        : self.uom;
    },
    get is_sell_edit_allowed() {
      return self.sell_permission.edit && self.sell_status === ENUM_BUY_SELL_STATUS.UNBILLED;
    },
    get is_buy_edit_allowed() {
      if (!self.buy_permission.edit) return false;
      if (self.buy_status !== ENUM_BUY_SELL_STATUS.UNBILLED) return false;
      if (
        self.buy_transaction_identifier &&
        ['Purchase Transaction', 'CSR Transaction'].includes(self.buy_transaction_doctype || '')
      )
        return false;
      return true;
    },
    get is_sell_view_allowed() {
      return self.sell_permission.view;
    },
    get is_buy_view_allowed() {
      return self.buy_permission.view;
    },
    get provisional_margin_in_currency() {
      if (self.sell_currency === self.buy_currency) {
        if (self.sell_exchange_rate === 0 || self.buy_exchange_rate === 0) return 0;
        return (
          (self.total_sell_billed_amount || self.total_sell_amount || 0) /
            (self.sell_exchange_rate || 1) -
          (self.total_buy_billed_amount || self.total_buy_amount || 0) /
            (self.buy_exchange_rate || 1)
        );
      } else
        return (
          (self.total_sell_billed_amount || self.total_sell_amount || 0) -
          (self.total_buy_billed_amount || self.total_buy_amount || 0)
        );
    },
    get estimated_margin_in_currency() {
      if (self.sell_currency === self.buy_currency) {
        return (
          (self.quantity || 0) * (self.sell_rate || 0) - (self.quantity || 0) * (self.buy_rate || 0)
        );
      } else {
        return (self.total_sell_amount || 0) - (self.total_buy_amount || 0);
      }
    },
    get total_sell_amount_in_currency() {
      return (self.sell_rate || 0) * (self.quantity || 0);
    },
    get total_buy_amount_in_currency() {
      return (self.buy_rate || 0) * (self.quantity || 0);
    },
    get actual_margin() {
      return (self.total_sell_billed_amount || 0) - (self.total_buy_billed_amount || 0);
    },
  }))
  .actions((self) => {
    return {
      setChargeType(charge_type: string) {
        self.charge_type = charge_type;
      },
      updatePermissions() {
        const permissions = getEnv(getParent(self, 2)).permissions;
        const session = getEnv(getParent(self, 2)).session;

        let buyBranchAllowed = false;
        let sellBranchAllowed = false;

        const isShowOnlyPermittedBranchEstEnabled = session.isFeatureEnabled(
          SHOW_ONLY_PERMITTED_BRANCH_EST
        );
        session.branch_accounts?.forEach((ba: BranchAccountValue) => {
          if (self.buy_branch_id === ba?.id) {
            buyBranchAllowed = true;
          }
          // Check sell branch permission based on the feature flag
          if (self.sell_branch_id === ba?.id) {
            if (isShowOnlyPermittedBranchEstEnabled) {
              sellBranchAllowed = true;
            } else {
              buyBranchAllowed = true;
              sellBranchAllowed = true;
            }
          }
        });
        if (!sellBranchAllowed && !isShowOnlyPermittedBranchEstEnabled && buyBranchAllowed) {
          sellBranchAllowed = true;
        }

        const accounting_status = getEnv(getParent(self, 2)).accounting_status;
        const showIgnored = getEnv(getParent(self, 2)).showIgnored;
        const permissionsConfig = [
          { key: 'buyAllowed', permission: PERMISSION_BUY_ESTIMATE },
          { key: 'sellAllowed', permission: PERMISSION_SELL_ESTIMATE },
          { key: 'freightBuyAllowed', permission: PERMISSION_FREIGHT_BUY },
          { key: 'freightSellAllowed', permission: PERMISSION_FREIGHT_SELL },
          { key: 'rebateBuyAllowed', permission: PERMISSION_REBATE_BUY },
          { key: 'rebateSellAllowed', permission: PERMISSION_REBATE_SELL },
        ];

        const permissionStatus = permissionsConfig.reduce((acc, { key, permission }) => {
          acc[key] = hasPermission(permissions, {
            name: permission,
            docType: 'NewAccounting::ShipmentEstimate',
          });
          return acc;
        }, {} as Record<string, boolean>);

        const sell_open = accounting_status === ACCOUNTING_STATUS_PENDING;
        const buy_open = accounting_status !== ACCOUNTING_STATUS_CLOSED;

        let sell_perm = { view: sell_open, edit: sell_open };
        let buy_perm = { view: buy_open, edit: buy_open };

        if (self.buy_branch_id !== null) {
          buy_perm.view = buy_perm.edit = buyBranchAllowed;
        }
        if (self.sell_branch_id !== null) {
          sell_perm.view = sell_perm.edit = sellBranchAllowed;
        }

        if (!permissionStatus.buyAllowed) {
          buy_perm.view = buy_perm.edit = false;
        }
        if (!permissionStatus.sellAllowed) {
          sell_perm.view = sell_perm.edit = false;
        }

        const tagPermissionMap = {
          [FREIGHT_CHARGE_TAG]: ['freightBuyAllowed', 'freightSellAllowed'],
          [REBATE_CHARGE_TAG]: ['rebateBuyAllowed', 'rebateSellAllowed'],
        };

        const tagPermissions = tagPermissionMap[self.tag as keyof typeof tagPermissionMap];

        if (tagPermissions) {
          const [buyTagPerm, sellTagPerm] = tagPermissions;
          if (!permissionStatus[buyTagPerm]) {
            buy_perm.view = buy_perm.edit = false;
          }
          if (!permissionStatus[sellTagPerm]) {
            sell_perm.view = sell_perm.edit = false;
          }
        }
        if (self.charge_type === 'Account') {
          sell_perm = { view: true, edit: false };
          buy_perm = { view: true, edit: false };
        }
        if (!showIgnored) {
          sell_perm.view = sell_perm.view && self.sell_status !== ENUM_BUY_SELL_STATUS.IGNORED;
          buy_perm.view = buy_perm.view && self.buy_status !== ENUM_BUY_SELL_STATUS.IGNORED;
        }
        this.setBuyPermission(buy_perm);
        this.setSellPermission(sell_perm);
      },
      setBuyPermission(permission: { view: boolean; edit: boolean }) {
        self.buy_permission = permission;
      },
      setSellPermission(permission: { view: boolean; edit: boolean }) {
        self.sell_permission = permission;
      },
      updateDetailsOnCreate(id: string, buy_branch_id: string, supplier: CompanyValue) {
        self.id = id;
        self.uuid = id;
        self.buy_branch_id = buy_branch_id;
        self.supplier_company = supplier ? supplier : undefined;
        self.supplier_company_id = supplier ? supplier.id : null;
      },
      updateItem(itemData: {
        item: string;
        item_currency: string;
        uom: string;
        quantity: number;
        tag?: string;
        description?: string;
        item_group?: string;
        tax_percentage?: number;
        taxable?: number;
        taxability?: string;
      }) {
        if (!itemData) return;
        const {
          item,
          item_currency,
          uom,
          quantity,
          tag,
          description,
          item_group,
          tax_percentage,
          taxable,
          taxability,
        } = itemData;
        self.sell_currency = item_currency;
        self.item = item;
        self.tag = tag;
        self.tax_percentage = tax_percentage;
        self.taxable = taxable;
        self.taxability = taxability;
        self.item_description = description;
        self.item_group = item_group;
        this.updatePermissions();
        const [newUom, equipmentName, equipmentType] = uom.split('.');
        this.updateUom(newUom, equipmentName, equipmentType, quantity);
      },
      updateUom(uom: string, equipment_name: string, equipment_type: string, quantity: number) {
        if (this.updateQuantity(quantity)) {
          self.uom = uom;
          self.equipment_name = equipment_name;
          self.equipment_type = equipment_type;
          return true;
        } else return false;
      },
      updateQuantity(quantity: number) {
        if (!self.is_sell_edit_allowed && !self.is_buy_edit_allowed) return false;
        if (
          self.sell_status !== ENUM_BUY_SELL_STATUS.UNBILLED ||
          self.buy_status !== ENUM_BUY_SELL_STATUS.UNBILLED
        )
          return false;
        const newValue = parseFloat(quantity.toString());
        if (Number.isNaN(newValue)) return false;
        self.quantity = newValue;
        this.recalculateSellTotal();
        this.recalculateBuyTotal();
        return true;
      },
      updateSellExchangeRate(exchange_rate: number) {
        if (!self.is_sell_edit_allowed) return false;
        const newValue = parseFloat(exchange_rate.toString());
        if (Number.isNaN(newValue)) return false;
        self.sell_exchange_rate = newValue;
        if (self.is_buy_edit_allowed) this.updateBuyExchangeRate(newValue);
        this.recalculateSellTotal();
        return true;
      },
      updateSellCurrency(currency: string) {
        if (!self.is_sell_edit_allowed) return false;
        if (self.is_buy_edit_allowed) self.buy_currency = currency;
        const exchange_rate = getParent<ShipmentEstimateStoreValue>(self, 2).getCachedExchangeRate(
          currency,
          'sell'
        );
        self.sell_currency = currency;
        if (exchange_rate) this.updateSellExchangeRate(exchange_rate);
        return true;
      },
      updateSellRate(sell_rate: number) {
        if (!self.is_sell_edit_allowed) return false;
        const newValue = parseFloat(sell_rate.toString());
        if (Number.isNaN(newValue)) return false;
        self.sell_rate = newValue;
        this.recalculateSellTotal();
        return true;
      },
      updateSellBranch(branch_id: string, mismatch: boolean) {
        self.sell_branch_id = branch_id;
        self.mismatch = mismatch;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        if (!mismatch) getParent<ShipmentEstimateStoreValue>(self, 2).checkEstimateError();
      },
      updateCustomer(customer: CompanyValue) {
        self.customer_company = customer;
        self.customer_company_id = customer.id;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      updateBuyExchangeRate(exchange_rate: number) {
        if (!self.is_buy_edit_allowed) return false;
        const newValue = parseFloat(exchange_rate.toString());
        if (Number.isNaN(newValue)) return false;
        self.buy_exchange_rate = newValue;
        this.recalculateBuyTotal();
        return true;
      },
      updateBuyCurrency(currency: string) {
        if (!self.is_buy_edit_allowed) return false;
        const exchange_rate = getParent<ShipmentEstimateStoreValue>(self, 2).getCachedExchangeRate(
          currency,
          'buy'
        );
        self.buy_currency = currency;
        if (exchange_rate) this.updateBuyExchangeRate(exchange_rate);
        return true;
      },
      updateBuyRate(buy_rate: number) {
        if (!self.is_buy_edit_allowed) return false;
        const newValue = parseFloat(buy_rate.toString());
        if (Number.isNaN(newValue)) return false;
        self.buy_rate = newValue;
        this.recalculateBuyTotal();
        return true;
      },
      updateBuyBranch(branch_id: string, mismatch: boolean) {
        self.buy_branch_id = branch_id;
        self.mismatch = mismatch;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        if (!mismatch) getParent<ShipmentEstimateStoreValue>(self, 2).checkEstimateError();
      },
      updateSupplier(supplier?: CompanyValue) {
        self.supplier_company = supplier ? supplier : undefined;
        self.supplier_company_id = supplier ? supplier.id : null;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      updateField(field_name: 'sell_terms' | 'buy_terms' | 'remarks' | 'sequence', value: any) {
        self[field_name] = value;
        if (self.charge_type === 'Item') {
          getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        }
      },
      recalculateSellTotal() {
        self.total_sell_amount = roundToTwoDecimals(
          (self.quantity || 1) * (self.sell_exchange_rate || 1) * (self.sell_rate || 0)
        );
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      recalculateBuyTotal() {
        self.total_buy_amount = roundToTwoDecimals(
          (self.quantity || 1) * (self.buy_exchange_rate || 1) * (self.buy_rate || 0)
        );
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
      },
      updateProductRating(id?: string) {
        if (!self.is_buy_edit_allowed) return false;
        self.product_rating = id ? { id } : undefined;
        getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        return true;
      },
      delete() {
        const parent = getParent<ShipmentEstimateStoreValue>(self, 2);
        parent.removeChild(self);
        if (parent.estimates.length === 0) {
          parent.clearEstimates();
        }
      },
      delinkSellContractItem() {
        self.sell_contract_item = null;
        if (self.charge_type === 'Item') {
          getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        }
      },
      delinkBuyContractItem() {
        self.buy_contract_item = null;
        if (self.charge_type === 'Item') {
          getParent<ShipmentEstimateStoreValue>(self, 2).addToBeSynced([self.uuid]);
        }
      },
    };
  });

export type ShipmentEstimateValue = Instance<typeof ShipmentEstimateInternal>;
export type shipmentEstimateIn = SnapshotIn<typeof ShipmentEstimateInternal>;
export type shipmentEstimateOut = SnapshotOut<typeof ShipmentEstimateInternal>;

const ShipmentEstimate = types.snapshotProcessor(ShipmentEstimateInternal, {
  // from snapshot to instance
  preProcessor(sn: shipmentEstimateIn) {
    return {
      ...sn,
      uuid: sn.id || uuid4(),
    };
  },
});

export default ShipmentEstimate;

export const ShipmentEstimateStore = types
  .model('ShipmentEstimateStore', {
    estimates: types.array(ShipmentEstimate),
    to_be_synced: types.array(types.string),
    sync_state: types.enumeration(['unsaved', 'saved', 'saving', 'error']),
  })
  .views((self) => ({
    estimateCountForCurrency(shipment_id: string, type: 'buy' | 'sell', currency: string) {
      return self.estimates.filter(
        (est) => est.shipment_id === shipment_id && est[`${type}_currency`] === currency
      ).length;
    },
    estimatesForSalesInvoice(shipment_id: string) {
      return self.estimates.filter(
        (est) =>
          est.is_sell_edit_allowed && est.charge_type === 'Item' && est.shipment_id === shipment_id
      );
    },
    estimatesForPurchaseInvoice(shipment_id: string) {
      return self.estimates.filter(
        (est) =>
          est.is_buy_edit_allowed && est.charge_type === 'Item' && est.shipment_id === shipment_id
      );
    },
    estimatesForShipment(shipment_id: string) {
      return self.estimates.filter((est) => est.shipment_id === shipment_id);
    },
    get totalProvisionalSellAmount() {
      return self.estimates.reduce((sum, est) => {
        sum += est.sell_provisional_amount || 0;
        return sum;
      }, 0);
    },
    get totalProvisionalBuyAmount() {
      return self.estimates.reduce((sum, est) => {
        sum += est.buy_provisional_amount || 0;
        return sum;
      }, 0);
    },
    get cachedSellExchangeRate() {
      return self.estimates.reduce((cache: { [key: string]: number }, est) => {
        if (est.sell_exchange_rate && !cache[est.sell_currency || ''])
          cache[est.sell_currency || ''] = est.sell_exchange_rate;
        return cache;
      }, {});
    },
    get cachedBuyExchangeRate() {
      return self.estimates.reduce((cache: { [key: string]: number }, est) => {
        if (est.buy_exchange_rate && !cache[est.buy_currency || ''])
          cache[est.buy_currency || ''] = est.buy_exchange_rate;
        return cache;
      }, {});
    },
    getCachedExchangeRate(currency: string, type: string) {
      if (type === 'sell') return this.cachedSellExchangeRate[currency];
      return this.cachedBuyExchangeRate[currency] || 1;
    },
  }))
  .actions((self) => ({
    updateEstimatePermissions() {
      self.estimates.forEach((est) => est.updatePermissions());
    },
    addToBeSynced(ids: string[]) {
      ids.forEach((id) => self.to_be_synced.push(id));
      if (self.sync_state === 'saved') self.sync_state = 'unsaved';
    },
    startSyncing() {
      const toBeSynced = getSnapshot(self.to_be_synced);
      self.to_be_synced.clear();
      self.sync_state = 'saving';
      return toBeSynced;
    },
    updateSyncSuccess() {
      self.sync_state = self.to_be_synced.length > 0 ? 'unsaved' : 'saved';
    },
    updateSyncError(ids: string[]) {
      this.addToBeSynced(ids);
      self.sync_state = 'error';
    },
    checkEstimateError() {
      if (self.estimates.find((est) => est.mismatch)) this.updateSyncError([]);
      else this.updateSyncSuccess();
    },
    replaceEstimates(estimates: ShipmentEstimateValue[], jv_estimates: ShipmentEstimateValue[]) {
      const filtered_estimates = [...estimates, ...jv_estimates];
      applySnapshot(self.estimates, filtered_estimates);
      if (jv_estimates.length > 0) {
        for (let i = 0; i < jv_estimates.length; i++) {
          self.estimates[estimates.length + i]?.setChargeType('Account');
        }
      }
      this.updateEstimatePermissions();
    },
    clearEstimates() {
      self.estimates.map((est) => est.delete());
    },
    addEstimates(estimates: ShipmentEstimateValue[]) {
      estimates.forEach((est) => self.estimates.push(est));
      this.updateEstimatePermissions();
    },
    addJVEstimates(estimates: ShipmentEstimateValue[]) {
      estimates.forEach((est) => {
        self.estimates.push(est);
        self.estimates[self.estimates.length - 1].setChargeType('Account');
      });
    },
    removeChild(estimate: Instance<IAnyModelType>) {
      detach(estimate);
    },
  }));

export type ShipmentEstimateStoreValue = Instance<typeof ShipmentEstimateStore>;
interface ShipmentEstimateStoreContextType {
  store: ShipmentEstimateStoreValue;
  fetchEstimateData?: () => Promise<void>;
  loading?: boolean;
}

const ShipmentEstimateStoreContext = createContext<null | ShipmentEstimateStoreContextType>(null);

export const ShipmentEstimateStoreContextProvider = ShipmentEstimateStoreContext.Provider;
export function useShipmentEstimateStore() {
  const contextValue = useContext(ShipmentEstimateStoreContext);
  if (contextValue === null) {
    throw new Error('contextValue cannot be null, please add a context provider');
  }
  return contextValue;
}
