import React, { lazy, useState } from 'react';
import { LinkRender, Modal, PageHeader, Table, Tag, Tooltip } from '@shipmnts/pixel-hub';
import { useLocation } from 'wouter';
import { CustomIcon, getDateFromUnix, ArrowLeftOutlined } from '@shipmnts/pixel-hub';
import { useContactView } from './ContactView';
import { startCase as _startCase } from 'lodash';
import { BreadCrumbsMapRender } from 'common';
import { CompanyValue } from 'network/models/Company';

const ActionRenderer = lazy(() => import('operations/modules/actionHelper/ActionRendererExport'));

const ContactSummary = ({ details }: any) => {
  if (!details) return null;
  return (
    <div
      style={{
        display: 'grid',
        gridAutoFlow: 'column',
        paddingBottom: '2em',
      }}
    >
      {details.map((detail: any, index: any) => {
        return (
          <div
            key={index}
            style={{ marginRight: '15px', maxWidth: '20em', wordBreak: 'break-word' }}
          >
            <span style={{ display: 'block', opacity: 0.7 }}>{detail.title}</span>
            {!detail.cellRenderer ? (
              <>
                {Array.isArray(detail.value) ? (
                  detail.value.map((detail: any, index: any) => {
                    return <Tag key={index}>{detail}</Tag>;
                  })
                ) : (
                  <span>{detail.value || '-'}</span>
                )}
              </>
            ) : (
              <>{detail.cellRenderer(detail.value)}</>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const getInitials = (name?: string | null) => {
  if (!name) return '';
  const words = name.trim().split('');
  let initials = '';

  for (let i = 0; i < Math.min(words.length, 2); i++) {
    initials += words[i][0].toUpperCase();
  }

  return initials;
};

export const Header = ({
  externalLink,
  internal,
}: {
  externalLink?: boolean;
  internal?: boolean;
}) => {
  const { contact, onClose, refetch } = useContactView();
  const { 1: navigate } = useLocation();

  const {
    id,
    department,
    email,
    first_name,
    last_name,
    mobile_number,
    dial_code,
    title,
    is_disabled,
    companies,
  } = contact || {};
  const contact_name = _startCase([first_name, last_name].join(' ').trim());

  return (
    <div style={{ marginTop: '-10px' }}>
      {!onClose && !externalLink && !internal && (
        <div style={{ backgroundColor: 'white' }}>
          <div style={{ marginTop: '10px', marginLeft: '24px' }}>
            <BreadCrumbsMapRender />
          </div>
        </div>
      )}
      <PageHeader
        className="contact-view-header"
        style={{ backgroundColor: 'white', paddingTop: externalLink || internal ? '1em' : 0 }}
        onBack={() => {
          if (externalLink && onClose) onClose();
          else navigate(`~/workspace?doc_type=Network::UserContact&resource_id=${id}`);
        }}
        backIcon={<ArrowLeftOutlined style={{ fontSize: '16px' }} />}
        avatar={{
          src: '',
          icon: getInitials(contact_name),
          shape: 'square',
          // size: 40,
          srcSet: 'wed3',
          style: {
            color: '#AF0ACA',
            backgroundColor: '#AF0ACA4D',
            borderColor: '#AF0ACA',
            fontWeight: 600,
            borderRadius: '4px',
            fontSize: '18px',
            textAlign: 'center',
          },
        }}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ color: 'var(--color-primary)', margin: 'auto' }}>{contact_name} </h4>
            {externalLink && (
              <span style={{ marginLeft: '10px' }}>
                <Tooltip title="Open Detail Screen">
                  <a href={`/view/contact/${id}`} target="_blank" rel="noreferrer">
                    <CustomIcon icon="ExternalLinkIcon" />
                  </a>
                </Tooltip>
              </span>
            )}
          </div>
        }
        tags={[
          is_disabled ? (
            <Tag key={''} color="red" style={{ fontWeight: 600 }}>
              {'Disabled'}
            </Tag>
          ) : (
            <></>
          ),
        ]}
        extra={[
          contact && (
            <div style={{ marginRight: '10px' }}>
              <ActionRenderer
                id={contact.id}
                refetchData={refetch}
                data={contact}
                isDetailScreen={true}
                doc_type_id="Network::UserContact"
              />
            </div>
          ),
        ]}
        footer={
          <ContactSummary
            details={[
              {
                title: 'EMAIL',
                value: email,
              },
              {
                title: 'CONTACT NO.',
                value: mobile_number ? [dial_code, mobile_number].join(' ').trim() : '',
              },
              { title: 'DEPARTMENT', value: department },
              {
                title: 'DESIGNATION',
                value: title,
              },
              {
                title: 'CREATED AT',
                value: contact?.created_at ? getDateFromUnix(contact.created_at) : '',
              },
              (companies?.length ?? 0) > 0 && {
                title: 'LINKED ON',
                value: companies ?? [],
                cellRenderer: (value: CompanyValue[]) => {
                  if (!value || value.length === 0) return '-';
                  if (value.length === 1) {
                    return (
                      <div
                        className="company-link-name"
                        style={{ cursor: 'pointer', maxWidth: '150px', maxHeight: '16px' }}
                      >
                        {
                          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          <LinkRender
                            value={value[0]}
                            data={value[0]}
                            value_field={'registered_name'}
                            id_field={'id'}
                            doc_type_id={'Network::Company'}
                          />
                        }
                      </div>
                    );
                  }
                  const resources = value.map((res: CompanyValue) => {
                    const label = res?.registered_name || '';
                    const link = '/view/company/' + res?.id;
                    return { label, link };
                  });
                  return <ShowAssociateResources resources={resources} />;
                },
              },
            ]}
          />
        }
      ></PageHeader>
    </div>
  );
};

const ShowAssociateResources = ({
  resources,
}: {
  resources: { label: string; link: string }[];
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div
        style={{ color: 'var(--color-primary)', cursor: 'pointer' }}
        onClick={() => setShowModal(true)}
      >{`${resources?.length} Linked Companies`}</div>
      <Modal
        title={`${resources?.length} Linked Companies`}
        open={showModal}
        footer={null}
        centered
        onCancel={() => setShowModal(false)}
      >
        <Table
          className="association-fields"
          columns={[
            {
              title: 'Links',
              dataIndex: 'label',
              width: '30%',
              render: (value: any, record: { label: string; link: string }) => (
                <a href={record.link} target="_blank" rel="noopener noreferrer">
                  {value}
                </a>
              ),
            },
          ]}
          dataSource={resources}
          pagination={false}
          bordered
        />
      </Modal>
    </div>
  );
};
