import React, { useMemo, lazy, useEffect } from 'react';
import { Row, Col, Card, Layout, LinkedParentType, UploadedDocuments } from '@shipmnts/pixel-hub';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { useSession } from 'common';
import {
  SHIPMENT_TYPE_GENERAL,
  PERMISSION_DELETE_DOCUMENT,
} from 'operations/modules/reports/constants';
import { hasPermission } from '@shipmnts/pixel-hub';
import { ACCOUNTING_STATUS_CLOSED } from '../../../constants';
import { masterDocumentFinalizedOrHandOver } from 'operations/modules/actionHelper/ShipmentActions/shipmentActionHelper';
import { getNoPermissionTags } from 'operations/modules/helpers';

const CreatedDocuments = lazy(() => import('./CreatedDocuments'));

const DocumentTab = () => {
  const { shipment, refetchShipments } = useShipmentDetail();
  const sessionData = useSession();
  useEffect(() => {
    if (refetchShipments) {
      refetchShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadDocumentsProps = useMemo(() => {
    if (!shipment) return {};
    const linked_shipments_options: any[] = [];
    const linked_parents: LinkedParentType[] = [];
    if (shipment.master_shipment_id)
      linked_shipments_options.push({
        label: shipment.job_number,
        value: shipment.master_shipment_id,
      });

    if (shipment.house_shipments) {
      shipment.house_shipments.forEach((shipment) => {
        linked_shipments_options.push({
          label: shipment.job_number,
          value: shipment.id,
        });
        linked_parents.push({
          parent_ids: [shipment.id],
          parent_type: 'shipment',
        });
      });
    }

    if (shipment.ocean_transport_orders) {
      shipment.ocean_transport_orders.forEach((oto) => {
        linked_parents.push({
          parent_ids: [oto.id],
          parent_type: 'ocean_transport_order',
        });
      });
    }

    // if shipment is created from inquiry,
    // documents of inquiry should be linked
    if (shipment?.inquiry_option?.inquiry) {
      linked_parents.push({
        parent_ids: [shipment.inquiry_option.inquiry.id],
        parent_type: 'inquiry',
      });
    }

    const upload_document_props = {
      linkedParentsForUploadOptions: linked_shipments_options,
      ...(linked_parents.length > 0 ? { linked_parents } : {}),
    };
    return upload_document_props;
  }, [shipment]);

  if (!shipment) return null;

  const tagsNoPermission: string[] = getNoPermissionTags(sessionData.permissions);
  const master_release = masterDocumentFinalizedOrHandOver(shipment);
  // ToDo: finalise the doctype
  const disableDelete = !hasPermission(sessionData.permissions, {
    name: PERMISSION_DELETE_DOCUMENT,
    docType: 'External::Docgen::Document',
  });
  return (
    <Layout style={{ height: '100%' }}>
      <Row style={{ paddingTop: '12px' }}>
        {shipment.shipment_type !== SHIPMENT_TYPE_GENERAL && (
          <Col span={14} style={{ padding: '15px' }}>
            <CreatedDocuments />
          </Col>
        )}
        <Col span={10} style={{ padding: '15px' }}>
          <Card title="Uploaded">
            <UploadedDocuments
              sessionData={sessionData}
              parent_id={shipment.id}
              parent_type="shipment"
              deleteDisabled={
                shipment.accounting_status === ACCOUNTING_STATUS_CLOSED || disableDelete
              }
              deleteDisabledTags={master_release ? ['Master bill of lading'] : []}
              docgen_url={process.env.DOCGEN_URL || ''}
              tagsNoPermission={tagsNoPermission}
              {...uploadDocumentsProps}
            />
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default DocumentTab;
