import { useLazyQuery } from '@apollo/client';
import { FormInstance } from '@shipmnts/pixel-hub';
import { TeamValue } from 'common/models/Team';
import { GET_REFERENCE_TEAM } from 'network/graphql/company';
import React, { ReactNode, useEffect } from 'react';

interface TeamAutoFillWrapperProps {
  form: FormInstance;
  preFillFields?: {
    reference_id?: string | undefined;
    branch_id?: string | undefined;
    reference_type?: string | undefined;
    roles?: string[];
  };
  children: ReactNode;
}

const TeamAutoFillWrapper = (props: TeamAutoFillWrapperProps) => {
  const { preFillFields, children, form } = props;
  const [fetchTeams, { data: fetchedTeams }] = useLazyQuery(GET_REFERENCE_TEAM);

  useEffect(() => {
    if (preFillFields?.reference_type && preFillFields.reference_id) {
      fetchTeams({
        variables: {
          reference_id: preFillFields.reference_id,
          reference_type: preFillFields.reference_type,
          branch_account_id: preFillFields.branch_id,
          roles: preFillFields.roles,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFillFields]);

  useEffect(() => {
    if (fetchedTeams?.get_reference_team) {
      const fetchedTeam = fetchedTeams.get_reference_team ?? [];
      if (fetchedTeam.length === 0) {
        return;
      }
      const fetchedItems = fetchedTeam.map((team: TeamValue) => ({
        ...team,
        preFilled: true,
      }));
      form.setFieldValue('teams', fetchedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTeams]);

  return <div>{children}</div>;
};

export default TeamAutoFillWrapper;
