import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_LOCATIONS, REMOVE_WAREHOUSE_UNIT } from './graphql/warehouseUnit';
import CreateWarehouseUnit from './CreateWarehouseUnit';
import {
  Col,
  Modal,
  Row,
  Space,
  TreeDataNode,
  TreeProps,
  message,
  Button,
  DownOutlined,
  UpOutlined,
  Skeleton,
} from '@shipmnts/pixel-hub';
import { WarehouseUnitValue } from 'operations/models/WarehouseUnit';
import { useSession } from 'common';
import {
  PERMISSION_WAREHOUSE_CREATE,
  PERMISSION_WAREHOUSE_DELETE,
  PERMISSION_WAREHOUSE_EDIT,
} from 'operations/permissions';
import { WithPermission } from '@shipmnts/pixel-hub';

const WarehouseUnitTree = () => {
  const session = useSession();
  const { data, loading, refetch } = useQuery(FETCH_LOCATIONS, {
    variables: {
      branch_id: (session?.branch_accounts || []).map((obj: any) => obj.id),
    },
  });
  const [removeUnit, { data: removeUnitData }] = useMutation(REMOVE_WAREHOUSE_UNIT);
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  const [locationData, setLocationData] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);
  const [parentData, setParentData] = useState<WarehouseUnitValue>();
  const [formType, setFormType] = useState<'new' | 'edit' | 'add'>('new');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [key, setKey] = useState<number>(0);
  useEffect(() => {
    if (data?.get_locations) {
      setLocationData(data.get_locations);
    }
  }, [data]);

  const getTreeNodeWithId = (id: string, expandedNodes: string[]) => {
    const node = locationData.find((e: WarehouseUnitValue) => e.id === id);
    if (!node) return;
    expandedNodes.push(node.id);
    const childUnits: TreeDataNode[] = [];
    node.child_unit_ids.forEach((e: any) => {
      const childNode = getTreeNodeWithId(e, expandedNodes);
      if (childNode) childUnits.push(childNode);
    });

    return {
      title: (
        <span className="warehouse-name">
          {' '}
          {node.node_name}
          <span id={node.id} className="warehouse-actions">
            {getActionButtons(node)}
          </span>
        </span>
      ),
      key: `${node.id}`,
      isLeaf: !node?.child_unit_ids?.length,
      children: childUnits,
    };
  };

  const getExpandedTree = () => {
    const initialTree: TreeDataNode[] = [];
    const expandedNodes: string[] = [];
    (locationData || []).forEach((element: WarehouseUnitValue) => {
      if (element.node_type === 'warehouse') {
        const node = getTreeNodeWithId(element.id, expandedNodes);
        if (node) initialTree.push(node);
      }
    });
    setTreeData(initialTree);
    setExpandedKeys(expandedNodes);
  };

  useEffect(() => {
    getExpandedTree();
    setKey((key) => 1 - key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  useEffect(() => {
    if (removeUnitData?.remove_warehouse_unit) {
      message.success('Node Deleted Successfully');
      refetch();
    }
  }, [removeUnitData, refetch]);

  const handleDeleteNode = (parent: WarehouseUnitValue) => {
    removeUnit({
      variables: {
        id: parent?.id,
      },
    });
  };

  const getActionButtons = (parent: any) => (
    <Space style={{ marginLeft: '15px' }}>
      <div style={{ display: 'flex' }}>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_CREATE,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          <button
            className="node-action-btn"
            onClick={() => {
              setFormType('add');
              setParentData(parent);
              setVisible(true);
            }}
          >
            Add Child
          </button>
        </WithPermission>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_EDIT,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          <button
            className="node-action-btn"
            onClick={() => {
              setFormType('edit');
              setParentData(parent);
              setVisible(true);
            }}
          >
            Edit
          </button>
        </WithPermission>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_DELETE,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          {!parent?.child_unit_ids?.length && (
            <button
              className="node-action-btn"
              onClick={() => {
                Modal.confirm({
                  title: `Are you sure you want to delete this node?`,
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk: () => handleDeleteNode(parent),
                });
              }}
              style={{ color: 'red' }}
            >
              Delete
            </button>
          )}
        </WithPermission>
      </div>
    </Space>
  );

  const [selectedNode, setSelectedNode] = useState<any>();
  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    if (selectedNode) {
      const targetedAction = document.getElementById(selectedNode + '');
      if (targetedAction) targetedAction.style.display = 'none';
    }
    const selectedKey = selectedKeys[0];
    setSelectedNode(selectedKey);
    const targetedAction = document.getElementById(selectedKey + '');
    if (targetedAction) targetedAction.style.display = 'inline';
  };
  const createWarehouse = () => {
    setFormType('new');
    setParentData(undefined);
    setVisible(true);
  };
  if (loading) {
    return <Skeleton />;
  }
  return (
    <>
      {visible && (
        <CreateWarehouseUnit
          visible={visible}
          setVisible={setVisible}
          parentData={parentData}
          formType={formType}
          type={parentData?.node_type || 'warehouse'}
          refetch={refetch}
        />
      )}
      <div className="tree-general-action-button">
        <Row>
          <Col span={6}>
            <Space>
              <Button
                size="small"
                onClick={() => {
                  getExpandedTree();
                  setKey((key) => 1 - key);
                }}
              >
                <DownOutlined /> Expand All
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setExpandedKeys([]);
                  setKey((key) => 1 - key);
                }}
              >
                <UpOutlined /> Collapse All
              </Button>
            </Space>
          </Col>
        </Row>
        <WithPermission
          permission={{
            name: PERMISSION_WAREHOUSE_CREATE,
            docType: 'Wms::WarehouseUnit',
          }}
        >
          <Button type="primary" htmlType="submit" onClick={() => createWarehouse()}>
            Create Warehouse
          </Button>
        </WithPermission>
      </div>

      <div className="main-tree">
        <Tree
          key={key}
          onSelect={onSelect}
          treeData={treeData}
          defaultExpandedKeys={expandedKeys}
          showLine={true}
        />
      </div>
    </>
  );
};

export default WarehouseUnitTree;
