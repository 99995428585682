/**
  PURPOSE: Defining a model for Ocean Contract Line Items.
  
  CONFIRM: We won't access contract from line item? (at least in frontend)
*/

import Carrier, { CarrierValue } from 'common/models/Carrier';
import Location, { LocationValue } from 'common/models/Location';
import { Instance, getSnapshot, types } from 'mobx-state-tree';
import { ContractCharge, ContractChargeValue } from './ContractCharge';

export const OceanContractLineItem = types
  .model({
    id: types.identifier,
    port_of_loading: types.maybeNull(Location),
    port_of_discharge: types.maybeNull(Location),
    transit_days: types.maybeNull(types.string),
    route: types.maybeNull(types.string),
    contract_ref: types.maybeNull(types.string),
    charge_terms: types.maybeNull(types.string),
    origin_free_days: types.maybeNull(types.integer),
    destination_free_days: types.maybeNull(types.integer),
    currency: types.maybeNull(types.string),
    carrier: types.maybeNull(Carrier),
    remarks: types.maybeNull(types.string),
    contract_charges: types.optional(types.array(ContractCharge), []),
    _destroy: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setCurrency: (value: string) => (self.currency = value),
    setValue: (
      key: 'transit_days' | 'route' | 'contract_ref' | 'charge_terms' | 'currency' | 'remarks',
      value: string
    ) => (self[key] = value),
    setFreeDays: (type: 'origin' | 'destination', value: number) => {
      self[`${type}_free_days`] = value;
    },
    setPortOfDischarge: (value: LocationValue) => {
      if (value) {
        self.port_of_discharge = { ...value, id: String(value.id) };
      } else self.port_of_discharge = null;
    },
    setCarrier: (value: CarrierValue | null) => {
      self.carrier = value;
    },
    addContractCharge: (charge: ContractChargeValue) => {
      self.contract_charges?.push(charge);
    },
    setChargeByContainerType: (containerType: string, chargeAmount: number) => {
      const charge = self.contract_charges?.find(
        (charge) => charge.container_type === containerType && !charge.isDestroyed()
      );
      if (charge) {
        charge.setChargeAmount(chargeAmount);
      } else {
        const charge = ContractCharge.create({
          id: 'new',
          container_type: containerType,
          charge_amount: chargeAmount,
          end_weight: null,
        });
        self.contract_charges?.push(charge);
      }
    },
    setDestroy: (value: boolean) => {
      self._destroy = value;
    },
    destroyAllCharges: () => {
      if (self.contract_charges)
        self.contract_charges?.forEach((charge) => charge.setDestroy(true));
      return true;
    },
  }))
  .views((self) => ({
    getChargeByContainerType: (containerType: string) => {
      const charge = self.contract_charges?.find(
        (charge) => charge.container_type === containerType && !charge.isDestroyed()
      );

      if (!charge) {
        const newCharge = ContractCharge.create({
          id: 'new',
          container_type: containerType,
          charge_amount: null,
          start_weight: 0,
          end_weight: null,
        });
        self.addContractCharge(newCharge);
        return newCharge;
      }

      return charge;
    },
    getPayload: (pol: LocationValue, removeIds = false) => {
      const lineItem = getSnapshot(self);
      const { port_of_discharge, port_of_loading, carrier, ...rest } = lineItem;
      return {
        ...rest,
        port_of_discharge_id: port_of_discharge?.id,
        port_of_loading_id: pol.id,
        carrier_id: carrier?.id,
        id: lineItem.id === 'new' || removeIds ? null : lineItem.id,
        contract_charges: self.contract_charges?.map((charge) => charge.getPayload(removeIds)),
        _destroy: lineItem._destroy ? true : undefined,
      };
    },
    getValue: (
      key:
        | 'transit_days'
        | 'route'
        | 'contract_ref'
        | 'charge_terms'
        | 'currency'
        | 'remarks'
        | 'port_of_discharge'
        | 'carrier'
        | 'origin_free_days'
        | 'destination_free_days'
    ) => {
      return self[key];
    },
    getPODString: () => {
      const pod = self.port_of_discharge;
      if (!pod) return '';
      return `${pod.name}, ${
        (typeof pod.country === 'string' ? pod.country : pod.country?.name) || ''
      } ${pod.unlocode ? `(${pod.unlocode})` : ''}`;
    },
    isDestroy: () => self._destroy,
  }));

export type OceanContractLineItemValue = Instance<typeof OceanContractLineItem>;

export default OceanContractLineItem;
