// TODO: form validations for win

import React, { useEffect, useState, lazy } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  dayjs,
  Drawer,
  Dropdown,
  Form,
  message,
  Modal,
  Space,
  Steps,
  TrackerContextProvider,
  Typography,
} from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import { MenuProps } from '@shipmnts/pixel-hub';
import { isCustomer, useSession } from 'common';
import { CustomIcon } from '@shipmnts/pixel-hub';

import { createInquiryParamsFromForm } from 'sales_hub/components/Inquiry/common';
import InquiryForm from 'sales_hub/components/Inquiry/InquiryForm/InquiryForm';
import { convertDateToUnix } from 'sales_hub/components/Inquiry/InquiryLostLayout';
import {
  GET_INQUIRY,
  UPDATE_INQUIRY,
  VALIDATE_CUSTOM_RULE,
  WIN_INQUIRY,
} from 'sales_hub/graphql/inquiry';
import Inquiry, { InquiryValue } from 'sales_hub/models/inquiry';
import { showConvertLeadErrorModal } from 'sales_hub/utils/utils';
import {
  ACTOR_TYPE_QUOTE,
  CONDITION_COMPUTE_FREIGHT_MARGIN,
  CONDITION_COMPUTE_MARGIN,
  INQUIRY_OPTION_APPROVED_STATUS,
  RENDER_INQUIRY_FOR_WON,
  TRIGGER_AFTER_QUOTE_WON,
} from 'sales_hub/utils/constants';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
} from 'operations/utils/constants';
import { SessionDataValue } from 'common/models/SessionData';
import { TRADE_TYPE_EXPORT, TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';

const InquiryOptionTable = lazy(() => import('../InquiryOption/InquiryOptionTable'));
type Props = {
  onSuccess?: () => void;
  id?: string;
  inquiry: InquiryValue;
};

const { useForm } = Form;
export const getCreateResourceUrl = (
  resourceType: string,
  inquiryOptionID?: string | null,
  inquiry?: InquiryValue,
  session?: SessionDataValue,
  shipmentType = 'general'
) => {
  if (inquiry?.customer_company && !isCustomer(inquiry?.customer_company?.company_type)) {
    showConvertLeadErrorModal(session, resourceType, inquiry);
    return null;
  }
  let isClearanceShipment = false;
  if (inquiry && inquiry?.services) {
    if (inquiry?.trade_type === TRADE_TYPE_EXPORT) {
      isClearanceShipment =
        inquiry?.services?.includes('origin_custom_clearance') &&
        !inquiry?.services?.includes('destination_custom_clearance') &&
        !inquiry?.services?.includes('freight_forwarding');
    } else if (inquiry.trade_type === TRADE_TYPE_IMPORT) {
      isClearanceShipment =
        inquiry?.services?.includes('destination_custom_clearance') &&
        !inquiry?.services?.includes('origin_custom_clearance') &&
        !inquiry?.services?.includes('freight_forwarding');
    }
  }
  if (!inquiryOptionID || !inquiry) return;
  const { freight_type: freightType, trade_type: tradeType } = inquiry;
  let url = '';
  if (resourceType === 'order' && freightType === FREIGHT_TYPE_OCEAN) {
    url = `${process.env.OPERATIONS_URL}/form/new_shipment/new?inquiry_option_id=${inquiryOptionID}`;
  } else if (resourceType === 'order' && freightType === FREIGHT_TYPE_AIR) {
    url = `${process.env.SHIPMNTS_WEB_URL}/shipment/copy_from_inquiry_option/customer_order/${inquiryOptionID}`;
  } else if (freightType === FREIGHT_TYPE_ROAD) {
    url = `${process.env.OPERATIONS_URL}/form/shipment/new?inquiry_option_id=${inquiryOptionID}&shipment_type=${shipmentType}&freight_type=${freightType}&trade_type=${tradeType}`;
  } else {
    if (freightType === FREIGHT_TYPE_AIR && !isClearanceShipment) {
      url = `${process.env.SHIPMNTS_WEB_URL}/shipment/copy_from_inquiry_option/${shipmentType}/${inquiryOptionID}`;
    } else if (!isClearanceShipment) {
      url = `${process.env.OPERATIONS_URL}/form/new_shipment/new?inquiry_option_id=${inquiryOptionID}&shipment_type=${shipmentType}&freight_type=${freightType}`;
    } else {
      url = `${process.env.OPERATIONS_URL}/form/new_shipment/new?inquiry_option_id=${inquiryOptionID}&shipment_type=${shipmentType}&freight_type=${freightType}&clearance_shipment=true`;
    }
  }

  return url;
};

const WinInquiryLayout = ({ onSuccess, id, inquiry }: Props) => {
  // States
  const [isVisible, setIsVisible] = useState(true);
  const [current, setCurrent] = useState(0);
  const [inquiryOptionID, setInquiryOptionID] = useState<any>();
  const [url, setUrl] = useState<string | null>();
  const [isApprovalModalVisible, setApprovalModalVisible] = useState(false);
  const [ruleResponse, setRuleResponse] = useState<Record<string, any> | null>(null);
  const [inquiryOptionStatus, setInquiryOptionStatus] = useState<string>('');

  //   Context
  const [form] = useForm();
  const [winForm] = useForm();

  const sessionData = useSession();
  const {
    data: inquiryDetails,
    loading,
    error,
    refetch,
  } = useQuery(GET_INQUIRY, { variables: { id: id }, fetchPolicy: 'network-only' });
  const [winInquiry, { data: winInquiryData, error: winInquiryError, loading: loadingWinInquiry }] =
    useMutation(WIN_INQUIRY);
  const [validateCustomRule] = useMutation(VALIDATE_CUSTOM_RULE);
  const [
    updateInquiry,
    { data: updateInquiryData, error: updateInquiryError, loading: loadingUpdateInquiry },
  ] = useMutation(UPDATE_INQUIRY);

  // Effects
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // On update inquiry successfull
  useEffect(() => {
    if (updateInquiryData && !updateInquiryError) {
      message.success('Inquiry updated successfully');
      next();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInquiryData]);

  // On win inquiry
  useEffect(() => {
    if (winInquiryData && !winInquiryError) {
      if (onSuccess) onSuccess();
      if (url) window.open(url);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winInquiryData]);

  // On error
  useEffect(() => {
    if (updateInquiryError) message.error(updateInquiryError?.message);
  }, [updateInquiryError]);

  // On error
  useEffect(() => {
    if (winInquiryError) message.error(winInquiryError?.message);
  }, [winInquiryError]);

  // Handling loading/error
  if (loading) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>erorr occured</div>;
  }

  // Methods and Constants
  const { freight_type: freightType } = inquiry;
  const isValidUrl = (url: string | null | undefined) => {
    if (!url) {
      if (onClose) onClose();
      return false;
    }
    return true;
  };
  const steps = [
    {
      title: 'Inquiry Details',
      content: <WinInquiryForm inquiry={inquiry} winForm={winForm} inquiryForm={form} />,
    },
    {
      title: 'Select Quotation',
      content: (
        <TrackerContextProvider referenceType="SalesHub::InquiryOption">
          <InquiryOptionTable
            inquiry={Inquiry.create(inquiryDetails?.get_inquiry)}
            refetchInquiry={refetch}
            rowSelection="single"
            disableResource={false}
            rowSelectionChange={(ele) => {
              const id = ele.api?.getSelectedRows()?.[0]?.id;
              const status = ele.api?.getSelectedRows()?.[0]?.status;
              setInquiryOptionID(id);
              setInquiryOptionStatus(status);
            }}
          />
        </TrackerContextProvider>
      ),
    },
  ];

  const stepItems = steps.map((item) => ({ key: item.title, title: item.title }));

  const onClose = () => {
    if (!loadingUpdateInquiry || !loadingWinInquiry) {
      setIsVisible(false);
      if (onSuccess) onSuccess();
      form.resetFields();
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const contentStyle: React.CSSProperties = {
    marginTop: 16,
    width: '100%',
  };

  const wonInquiryAction = async () => {
    const winDate = winForm.getFieldValue('actualDate');
    if (!winDate) {
      message.error('Please select Inquire Win Date.');
      return;
    }
    if (!inquiryOptionID) {
      message.error('Please select Quotation.');
      return;
    }

    winInquiry({
      variables: {
        inquiry_option_id: inquiryOptionID,
        inquiry_id: id,
        input: {
          actual_date: convertDateToUnix(winDate),
        },
      },
    });
  };

  const handleApprovalRequest = async () => {
    wonInquiryAction();
    if (onSuccess) onSuccess();
    onClose();
    message.success('Approval request sent successfully');
    setApprovalModalVisible(false);
  };

  const runMarginRule = async () => {
    let validationRule = null,
      ruleMessage = null,
      title = null,
      conditions = null,
      margin_value = null;
    if (inquiryOptionStatus !== INQUIRY_OPTION_APPROVED_STATUS && inquiryOptionID) {
      const validationResult = await validateCustomRule({
        variables: {
          actor_id: inquiryOptionID,
          trigger: TRIGGER_AFTER_QUOTE_WON,
          actor_type: ACTOR_TYPE_QUOTE,
          actor_context: JSON.stringify({ skip_hook: true }),
        },
      });

      validationRule = validationResult.data?.validate_custom_rule?.rule;
      ruleMessage = validationResult.data?.validate_custom_rule?.message;
      title = validationResult.data?.validate_custom_rule?.rule?.title;
      conditions = validationResult.data?.validate_custom_rule?.rule?.conditions;
    }
    if (validationRule) {
      setApprovalModalVisible(true);
      try {
        const conditionsString = conditions.replace(/=\u003e/g, ':');
        const parsedConditions = JSON.parse(conditionsString);
        const computeMarginCondition = parsedConditions?.find(
          (condition: any) =>
            condition.name === CONDITION_COMPUTE_MARGIN ||
            condition.name === CONDITION_COMPUTE_FREIGHT_MARGIN
        );
        if (computeMarginCondition) {
          margin_value = computeMarginCondition.value;
        }
      } catch (error) {
        console.error('Failed to parse conditions:', error);
      }
      setRuleResponse({
        validationRule,
        ruleMessage: ruleMessage.replace('<x>', margin_value),
        title,
      });
      return true;
    }

    return false;
  };

  const items: MenuProps['items'] = [
    {
      key: 'won',
      label: 'Won',
      onClick: async () => {
        if (await runMarginRule()) return;
        setUrl(null);
        wonInquiryAction();
      },
    },
    {
      key: 'won_order',
      label: 'Won & Create Order',
      onClick: async () => {
        if (await runMarginRule()) return;
        const url = getCreateResourceUrl('order', inquiryOptionID, inquiry, sessionData);
        setUrl(url);
        wonInquiryAction();
      },
    },
    {
      key: 'won_shipment_land',
      label: 'Won & Create Shipment',
      onClick: async () => {
        if (await runMarginRule()) return;
        const url = getCreateResourceUrl('shipment', inquiryOptionID, inquiry, sessionData);
        if (isValidUrl(url)) {
          setUrl(url);
          wonInquiryAction();
        }
      },
    },
    {
      key: 'won_shipment',
      label: 'Won & Create Shipment',
      children: [
        {
          key: 'direct',
          label: 'Direct',
          onClick: async (e: any) => {
            if (await runMarginRule()) return;
            const url = getCreateResourceUrl(
              'shipment',
              inquiryOptionID,
              inquiry,
              sessionData,
              e?.key
            );
            if (isValidUrl(url)) {
              setUrl(url);
              wonInquiryAction();
            }
          },
        },
        {
          key: 'back_to_back',
          label: 'Back TO Back',
          onClick: async (e: any) => {
            if (await runMarginRule()) return;
            const url = getCreateResourceUrl(
              'shipment',
              inquiryOptionID,
              inquiry,
              sessionData,
              e?.key
            );
            if (isValidUrl(url)) {
              setUrl(url);
              wonInquiryAction();
            }
          },
        },
      ],
    },
  ].filter((ele) => {
    if (freightType === 'road' && (ele.key === 'won_order' || ele.key === 'won_shipment'))
      return false;
    if (freightType !== 'road' && ele.key === 'won_shipment_land') return false;
    return true;
  });
  return (
    <>
      <Drawer
        title={
          <div style={{ display: 'flex' }}>
            <CustomIcon icon="CompassIcon" height={24} width={24} style={{ paddingRight: '5px' }} />
            <div>Mark Inquiry As Won - #{inquiry.inquiry_number}</div>
          </div>
        }
        width={'80%'}
        onClose={onClose}
        open={isVisible}
        styles={{ body: { paddingBottom: 80 } }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            {current < steps.length - 1 && (
              <Button
                type="primary"
                loading={loadingUpdateInquiry || loadingWinInquiry || loadingUpdateInquiry}
                onClick={async () => {
                  await Promise.all([form.validateFields(), winForm.validateFields()]);
                  updateInquiry({
                    variables: {
                      id: id,
                      inquiry: createInquiryParamsFromForm(
                        { ...form.getFieldsValue(), cargo_properties: undefined },
                        inquiry
                      ),
                    },
                  });
                }}
              >
                Update & Continue
              </Button>
            )}
            {current === steps.length - 1 && (
              <Dropdown menu={{ items }} placement="bottom">
                <Button
                  loading={loading || loadingWinInquiry || loadingUpdateInquiry}
                  disabled={loading || loadingWinInquiry || loadingUpdateInquiry}
                  type="primary"
                >
                  {' '}
                  Mark Inquiry As Won
                </Button>
              </Dropdown>
            )}
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </Space>
        }
        destroyOnClose={true}
      >
        <Steps current={current} items={stepItems} />
        <div style={contentStyle}>{steps[current].content}</div>
      </Drawer>
      <Modal
        title={ruleResponse?.title}
        visible={isApprovalModalVisible}
        onCancel={() => setApprovalModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setApprovalModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleApprovalRequest}>
            Request for Approval
          </Button>,
        ]}
      >
        <Typography.Text>{ruleResponse?.ruleMessage}</Typography.Text>
      </Modal>
    </>
  );
};

interface WinInquiryFormProps {
  winForm: FormInstance;
  inquiryForm: FormInstance;
  inquiry: InquiryValue;
}

const WinInquiryForm = (props: WinInquiryFormProps) => {
  const { winForm, inquiryForm, inquiry } = props;
  const today = dayjs();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card title="Win Details">
        <Form form={winForm} requiredMark={true} scrollToFirstError={true} layout="vertical">
          <Form.Item
            initialValue={dayjs(new Date())}
            label="Inquiry won on"
            name={'actualDate'}
            rules={[{ required: true }]}
          >
            <DatePicker
              disabledDate={(currentDate) => currentDate.isAfter(today)}
              showTime={true}
            />
          </Form.Item>
        </Form>
      </Card>
      <Card title="Inquiry Details">
        <InquiryForm
          form={inquiryForm}
          renderForm={RENDER_INQUIRY_FOR_WON}
          inquiry={inquiry}
          noTabs
        />
      </Card>
    </Space>
  );
};

export default WinInquiryLayout;
