import { useLazyQuery, useMutation } from '@apollo/client';
import {
  addDocumentParents,
  Col,
  CompanySearch,
  deleteUploadedDocument,
  DocumentsList,
  documentsStore,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  uploadDocument,
  UploadDocumentModal,
  UploadDocumentPayload,
  UploadedDocuments,
  UploadedDocumentType,
  dayjs,
  DatePicker,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import React, { useCallback, useEffect, useReducer } from 'react';
import { FUEL_TYPE, VEHICLE_CATEGORY } from './constants';
import { CREATE_VEHICLE, UPDATE_VEHICLE, GET_VEHICLE } from './graphql';
import { DistinctColSearch } from 'common';
import { VehicleValue } from 'operations/models/Vehicle';
import { ASSET_TYPES } from './constants';
import { omit } from 'lodash';
import { ASSET_TYPE_OWN } from './constants';

interface VehicleFormProps {
  form: FormInstance;
  onClose: () => void;
  onSuccess: (value: any) => void;
  id?: string;
}

export default function VehicleForm(props: VehicleFormProps) {
  const { form, onClose, onSuccess, id } = props;
  const sessionData = useSession();
  const [createVehicle, { data, loading, error }] = useMutation(CREATE_VEHICLE);
  const [updateVehicle, { data: updateData, loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_VEHICLE);

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const [fetchVehicle, { data: fetchedData, error: fetchedError }] = useLazyQuery(GET_VEHICLE);
  const getDateValue = (date?: any) => {
    if (!date) return null;
    if (dayjs.isDayjs(date)) return date;

    return dayjs.unix(new Date(date).getTime() / 1000);
  };

  const getInitValues = (vehicle: VehicleValue) => {
    return {
      ...vehicle,
      acquisition_date: getDateValue(vehicle?.acquisition_date),
      vehicle_registration_date: getDateValue(vehicle?.vehicle_registration_date),
      vehicle_registration_expiry_date: getDateValue(vehicle?.vehicle_registration_expiry_date),
      vehicle_insurance_date: getDateValue(vehicle?.vehicle_insurance_date),
      vehicle_insurance_expiry_date: getDateValue(vehicle?.vehicle_insurance_expiry_date),
    };
  };

  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({ type: 'upload_failure', payload: { doc, error: error.message } });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchVehicle({
        variables: {
          id: id,
        },
      });
    }
  }, [id, fetchVehicle]);

  useEffect(() => {
    if (updateError) {
      message.error(updateError.message);
      return;
    } else if (updateData?.update_vehicle) {
      message.success('Sucessfully Updated Vehicle');
      onSuccess && onSuccess(updateData?.update_vehicle);
      onClose && onClose();
    }
  }, [updateData, updateLoading, updateError, onClose, onSuccess]);

  const updateDocs = useCallback(
    async (id: string) => {
      if (documents.length > 0) {
        const _updateDocIds = documents.map((d: any) => d.id);
        const { response, error } = await addDocumentParents(
          _updateDocIds,
          [{ parent_type: 'vehicle', parent_id: id }],
          sessionData.id,
          process.env.DOCGEN_URL || ''
        );
        if (error) {
          message.error('Document upload failed');
        } else if (response) {
          dispatch({ type: 'reset_state' });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents, sessionData.id]
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    } else if (data?.create_vehicle) {
      message.success('Sucessfully Created Vehicle');
      updateDocs(data?.create_vehicle?.id).then(() => {
        onSuccess && onSuccess(data?.create_vehicle);
        onClose && onClose();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, onClose, onSuccess, uploadDoc]);

  useEffect(() => {
    if (fetchedError) {
      message.error(fetchedError.message);
    }
    if (fetchedData?.get_vehicle) {
      form.setFieldsValue(getInitValues(fetchedData?.get_vehicle));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData, fetchedError, form]);

  const convertDateToUnix = (dateStr?: string) => {
    if (!dateStr) return null;
    return new Date(dateStr).getTime() / 1000;
  };

  const getVehiclePayload = (values: any) => {
    return {
      ...values,
      acquisition_date: convertDateToUnix(values.acquisition_date),
      vehicle_registration_date: convertDateToUnix(values.vehicle_registration_date),
      vehicle_registration_expiry_date: convertDateToUnix(values.vehicle_registration_expiry_date),
      vehicle_insurance_date: convertDateToUnix(values.vehicle_insurance_date),
      vehicle_insurance_expiry_date: convertDateToUnix(values.vehicle_insurance_expiry_date),
    };
  };

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionData.id]
  );

  return (
    <Form
      form={form}
      initialValues={{
        vehicle_category: VEHICLE_CATEGORY[0]?.name,
        vehicle_fuel_type: FUEL_TYPE[0]?.name,
        ownership_type: ASSET_TYPES[0].key,
        company: sessionData?.company_account?.default_company,
      }}
      onFinish={(values) => {
        if (!!id) {
          const vehiclePayload = {
            ...getVehiclePayload(values),
            company_id: values?.company?.id || sessionData?.company_account?.default_company?.id,
          };

          updateVehicle({
            variables: {
              vehicle: omit(vehiclePayload, 'company'),
              id: id,
            },
          });
        } else {
          const vehiclePayload = {
            ...getVehiclePayload(values),
            company_id: values?.company?.id || sessionData?.company_account?.default_company?.id,
          };

          createVehicle({
            variables: {
              vehicle: omit(vehiclePayload, 'company'),
            },
          });
        }
      }}
      layout="vertical"
    >
      <Row gutter={18}>
        <Col span={6}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Ownership Type"
            name="ownership_type"
          >
            <Select
              placeholder="Select Ownership Type"
              style={{ width: '100%' }}
              onChange={(ownershipType) => {
                if (ownershipType === ASSET_TYPE_OWN)
                  form.setFieldValue('company', sessionData?.company_account?.default_company);
                else form.setFieldValue('company', null);
              }}
            >
              {ASSET_TYPES.map((at) => (
                <Select.Option key={at.key} value={at.key}>
                  {at.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Transporter" name="company">
            <CompanySearch searchProps={{ is_vendor: true }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Vehicle Category" name="vehicle_category">
            <Select placeholder="Select Vehicle Category" style={{ width: '100%' }}>
              {VEHICLE_CATEGORY.map((ct) => (
                <Select.Option key={ct.name} value={ct.name}>
                  {ct.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Vehicle License Plate Number"
            name="vehicle_license_plate_number"
          >
            <Input placeholder="Enter Vehicle License Plate Number" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Vehicle Chassis Number" name="vehicle_chassis_number">
            <Select
              mode="tags"
              style={{ width: '100%' }}
              options={[]}
              placeholder="Enter Vehicle Chassis #"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Acquisition Date" name="acquisition_date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Model" name="model">
            <DistinctColSearch field_id="vehicles_model" placeholder="Search Model ...." />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Make" name="make">
            <DistinctColSearch field_id="vehicles_make" placeholder="Search Make ...." />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Vehicle Fuel Type" name="vehicle_fuel_type">
            <Select placeholder="Select Vehicle Fuel Type" style={{ width: '100%' }}>
              {FUEL_TYPE.map((ft) => (
                <Select.Option value={ft.name} key={ft.name}>
                  {ft.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Max Weight Capacity" name="max_weight_capacity">
            <InputNumber min={0} style={{ width: '100%' }} placeholder="Max Weight Capacity" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Weight Unit" name="weight_unit">
            <Select defaultValue={'kgs'}>
              {[
                { name: 'kgs', label: 'Kgs' },
                { name: 'mts', label: 'Mts' },
                { name: 'cbm', label: 'Cbm' },
              ].map((wu) => (
                <Select.Option value={wu.name} key={wu.name}>
                  {wu.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Engine Number" name="engine_number">
            <Input placeholder="Engine Number" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Vehicle Registration Date" name="vehicle_registration_date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Vehicle Registration Expiry Date"
            name="vehicle_registration_expiry_date"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Vehicle Insurace Number" name="vehicle_insurance_number">
            <Input placeholder="Enter Vehicle Insurace Number" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Vehicle Insurance Coverage Type" name="vehicle_insurance_coverage_type">
            <Input placeholder="Enter Insurance Coverage Type" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Vehicle Insurance Amount" name="vehicle_insurance_amount">
            <InputNumber placeholder="Enter Vehicle Insurance Amount" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Vehicle Insurance Date" name="vehicle_insurance_date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Vehicle Insurance Expiry Date" name="vehicle_insurance_expiry_date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <>
            {id ? (
              <UploadedDocuments
                sessionData={sessionData}
                parent_id={id}
                parent_type={'vehicle'}
                docgen_url={process.env.DOCGEN_URL || ''}
              />
            ) : (
              <>
                <UploadDocumentModal onUpload={uploadDoc} sessionData={sessionData} />
                <DocumentsList
                  uploadingDocuments={uploadingDocuments}
                  uploadedDocuments={documents}
                  uploadingError={uploadingError}
                  onUpload={uploadDoc}
                  onDelete={deleteDoc}
                  sessionData={sessionData}
                />
              </>
            )}
          </>
        </Col>
      </Row>
    </Form>
  );
}
