import React, { useState, useRef, useEffect } from 'react';
import { Card, Button, Tooltip, message, Form } from '@shipmnts/pixel-hub';
import ContainerDetails from 'operations/components/ContainerDetails';
import { getSnapshot, cast } from 'mobx-state-tree';
import ContainerRequestsView from './ContainerRequestsView';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { omit as _omit } from 'lodash';
import { useMutation } from '@apollo/client';
import { UPDATE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { observer } from 'mobx-react-lite';
import { BookingType } from 'operations/modules/booking/constants';
import { errorMessageHandlerGraphQL } from 'common';

interface ContainerDetailsCardProps {
  containerRequests: ContainerRequestValue[];
  parent_id: string;
  parent_type: BookingType;
  onUpdate?: (value: OceanTransportOrderValue) => void;
  isLiveReefer?: boolean;
  disabled?: boolean;
  disableReason?: string;
  disableAdd?: boolean;
  disableFields?: string[];
}

type DestroyInterface<T> = T & {
  _destroy?: boolean;
};

const ContainerDetailsCard = observer(function ContainerDetailsCard(
  props: ContainerDetailsCardProps
): JSX.Element {
  const {
    containerRequests,
    parent_id,
    parent_type,
    onUpdate,

    isLiveReefer,
    disabled,
    disableReason,
    disableAdd,
    disableFields,
  } = props;
  const [containerEdit, setContainerEdit] = useState<{
    edit: boolean;
    beforeUpdate?: Array<ContainerRequestValue>;
  }>({ edit: false });
  const container_requests_ref = useRef<{ runValidation: () => boolean }>();
  const mutation = UPDATE_OCEAN_TRANSPORT_ORDER;

  const [updateParent, { data, loading, error }] = useMutation(mutation);

  useEffect(() => {
    if (!error && data && data[`update_${parent_type}`]) {
      const updated_parent = data[`update_${parent_type}`];
      message.success('Container Details Updated!');
      if (onUpdate) onUpdate(updated_parent);
      setContainerEdit({ edit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  if (containerEdit.edit) {
    return (
      <Form
        scrollToFirstError
        onFinish={(values: { container_requests?: ContainerRequestValue[] }) => {
          let foundError = false;
          foundError = Boolean(container_requests_ref?.current?.runValidation());
          if (!foundError) {
            const previousValues = containerEdit.beforeUpdate?.slice() || [];
            const newValues = values?.container_requests || [];
            previousValues.forEach((cr) => {
              if (!newValues.find((c) => c.id === cr.id))
                newValues.push({
                  ...cr,
                  _destroy: true,
                } as DestroyInterface<ContainerRequestValue>);
            });

            const updateValues = newValues.map((container_request: ContainerRequestValue) =>
              _omit(
                container_request,
                '_id',
                'quantity_fulfilled',
                'quantity_unfulfilled',
                'quantity_origin_port_gated_in',
                'quantity_picked_up',
                'quantity_loaded_on_vessel',
                'quantity_offloaded',
                'quantity_shutout',
                'quantity_cancelled'
              )
            );

            updateParent({
              variables: {
                [parent_type]: { id: parent_id, container_requests: updateValues },
              },
            });
          }
        }}
        initialValues={{
          container_requests: containerEdit.beforeUpdate,
        }}
      >
        <Card
          id="container_requests"
          title="Container Requests"
          extra={[
            <Button
              style={{ marginRight: '5px' }}
              key="cancel"
              disabled={loading}
              onClick={() => setContainerEdit({ edit: false })}
            >
              Cancel
            </Button>,
            <Button loading={loading} key="save" htmlType="submit" type="primary">
              Save
            </Button>,
          ]}
        >
          {error && errorMessageHandlerGraphQL(error)}
          <Form.Item
            noStyle
            name="container_requests"
            rules={[
              {
                validator: (rule, value) => {
                  if (!container_requests_ref?.current?.runValidation()) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ]}
          >
            <ContainerDetails
              ref={container_requests_ref}
              required_fields={['container_type_code', 'quantity']}
              isLiveReefer={isLiveReefer}
              disableAdd={disableAdd}
              disableFields={disableFields}
            />
          </Form.Item>
        </Card>
      </Form>
    );
  }

  return (
    <Card
      id="container_requests"
      title="Container Requests"
      extra={
        <Tooltip title={disableReason}>
          <Button
            disabled={disabled}
            onClick={() => {
              setContainerEdit({
                edit: true,
                beforeUpdate: containerRequests
                  ? getSnapshot<ContainerRequestValue[]>(cast(containerRequests)).slice()
                  : [],
              });
            }}
          >
            Edit
          </Button>
        </Tooltip>
      }
    >
      <ContainerRequestsView
        containerRequests={containerRequests}
        columns={[
          'container_type',
          'quantity',
          'weight_per_container',
          'weight_unit',
          'is_shipper_owned',
          'container_settings',
        ]}
      />
    </Card>
  );
});

export default ContainerDetailsCard;
