import { clone, isStateTreeNode } from 'mobx-state-tree';
import {
  CONTRACT_TYPE_COMPONENT_MAP,
  DUE_AGENT,
  DUE_AGENT_CONTRACT,
  DUE_CARRIER,
  DUE_CARRIER_CONTRACT,
  OCEAN_LOCALS,
  OCEAN_LOCALS_CONTRACT,
  RATE_TYPE_BUY,
  RATE_TYPE_NAMED_ACCOUNT,
} from './constants';
import { WeightSlabType } from './types';

export const validateSlabs = (data: WeightSlabType[]) => {
  const counts: { [key: string]: number } = {};
  data?.forEach((slab) => {
    const key = `${slab.start_weight}-${slab.end_weight}`;
    counts[key] = (counts[key] || 0) + 1;
  });

  if (Object.values(counts).some((val) => val >= 2))
    return `There shouldn't be any duplicate Weight Slabs`;
  const isDataIssue = data?.some((slab, idx) => {
    if (idx === 0) return false;
    const prev = data[idx - 1].end_weight || Infinity;
    return prev > slab.start_weight || slab.start_weight > (slab.end_weight || Infinity);
  });

  if (isDataIssue) return `Invalid weight slabs`;

  return null;
};

export const checkDuplicateAdditionalCharges = (data: any[]) => {
  const counts: { [key: string]: number } = {};
  data?.forEach((item) => {
    if (item._destroy) return;
    let key = `${item.charge_name}#${item.location_ids.sort().join(',')}`;
    if (!!item.terminal_ids && item.terminal_ids.length > 0) {
      key += `#${item.terminal_ids.sort().join(',')}`;
    }
    if (!!item.carrier_ids && item.carrier_ids.length > 0) {
      key += `#${item.carrier_ids.sort().join(',')}`;
    }
    if (!!item.commodity_type) {
      key += `#${item.commodity_type}`;
    }
    counts[key] = (counts[key] || 0) + 1;
  });
  return Object.values(counts).some((val) => val >= 2);
};
export const getSlabtitle = (
  index: number,
  weightSlabs: WeightSlabType[],
  unit?: string,
  type: 'title' | 'id' = 'title'
) => {
  const slab = weightSlabs[index];
  const { start_weight, end_weight } = slab;
  const weightSlabsLength = weightSlabs?.length;
  if (type === 'title')
    return weightSlabsLength === 1
      ? 'Charge Amount'
      : start_weight +
          (index === weightSlabsLength - 1 ? ` ${unit} +` : '-' + end_weight + ' ' + unit);
  return weightSlabsLength === 1
    ? 'charge_amount'
    : start_weight + (index === weightSlabsLength - 1 ? '+' : '-' + end_weight);
};

export const getContractProps = (contractType: string) => {
  return CONTRACT_TYPE_COMPONENT_MAP[contractType as keyof typeof CONTRACT_TYPE_COMPONENT_MAP]
    ?.contractProps;
};

export const getContractLink = (doc_type_id: string, rate_type: string) => {
  if (doc_type_id === 'RateManagement::RoadContract') {
    if (rate_type === 'sell') {
      return `/form/contract/road-sell`;
    }
  } else if (doc_type_id === 'RateManagement::AirContract') {
    if (rate_type === 'sell') return `/form/contract/air-sell`;
    else if (rate_type === 'buy') return `/form/contract/air-buy`;
    else if (rate_type === 'named_account') return `/form/contract/air-named-account`;
  } else if (doc_type_id === 'RateManagement::OceanContract') {
    if (rate_type === 'sell') return `/form/contract/ocean-sell`;
    else if (rate_type === 'buy') return `/form/contract/ocean-buy`;
    else if (rate_type === 'named_account') return `/form/contract/ocean-named-account`;
  } else if (doc_type_id === 'RateManagement::AirDueAgentContract') {
    return `/form/contract/due-agent`;
  } else if (doc_type_id === 'RateManagement::AirDueCarrierContract') {
    return `/form/contract/due-carrier`;
  } else if (doc_type_id === 'RateManagement::OceanLocalsContract') {
    return `/form/contract/ocean-locals`;
  }
  return;
};

export const cloneNode = (node: any) => {
  return isStateTreeNode(node) ? clone(node) : node;
};

export const getContractType = (type: string, rateType: string) => {
  if (type === DUE_AGENT) return DUE_AGENT_CONTRACT;
  if (type === DUE_CARRIER) return DUE_CARRIER_CONTRACT;
  if (type === OCEAN_LOCALS) return OCEAN_LOCALS_CONTRACT;
  else if (rateType === RATE_TYPE_BUY) return `${type}-buy`;
  else if (rateType === RATE_TYPE_NAMED_ACCOUNT) return `${type}-named-account`;
  else return `${type}-sell`;
};
