import { useLazyQuery, useMutation } from '@apollo/client';
import {
  addDocumentParents,
  Col,
  deleteUploadedDocument,
  DocumentsList,
  documentsStore,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  uploadDocument,
  UploadDocumentModal,
  UploadDocumentPayload,
  UploadedDocuments,
  UploadedDocumentType,
} from '@shipmnts/pixel-hub';
import { PhoneNumberInput, useSession } from 'common';
import React, { useCallback, useEffect, useReducer } from 'react';
import { CREATE_DRIVER, GET_DRIVER, UPDATE_DRIVER } from './graphql';

interface DriverFormProps {
  form: FormInstance;
  onClose: () => void;
  onSuccess: (value: any) => void;
  id?: string;
}

// petrol, disable, natural gas, electric
export default function DriverForm(props: DriverFormProps) {
  const { form, onClose, onSuccess, id } = props;
  const sessionData = useSession();
  const [createDriver, { data, error }] = useMutation(CREATE_DRIVER);
  const [
    updateDriver,
    { data: updateDriverData, loading: updateDriverLoading, error: updateDriverError },
  ] = useMutation(UPDATE_DRIVER);

  const [fetchDriver, { data: fetchedDriverData, error: fetchedDriverError }] =
    useLazyQuery(GET_DRIVER);

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({ type: 'upload_failure', payload: { doc, error: error.message } });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  const updateDocs = useCallback(
    async (id: string) => {
      if (documents.length > 0) {
        const _updateDocIds = documents.map((d: any) => d.id);
        const { response, error } = await addDocumentParents(
          _updateDocIds,
          [{ parent_type: 'driver', parent_id: id }],
          sessionData.id,
          process.env.DOCGEN_URL || ''
        );
        if (error) {
          message.error('Document upload failed');
        } else if (response) {
          dispatch({ type: 'reset_state' });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents, sessionData.id]
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
      return;
    } else if (data?.create_driver) {
      updateDocs(data?.create_driver?.id).then(() => {
        onSuccess && onSuccess(data?.create_driver);
        onClose && onClose();
        message.success('Sucessfully Created Driver');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (id) {
      fetchDriver({
        variables: {
          id: id,
        },
      });
    }
  }, [id, fetchDriver]);

  useEffect(() => {
    if (fetchedDriverError) {
      message.error(fetchedDriverError.message);
    }
    if (fetchedDriverData?.get_driver) {
      const driver_number = (fetchedDriverData.get_driver?.driver_contact_number || '').split(' ');
      form.setFieldsValue({
        ...fetchedDriverData?.get_driver,
        driver_contact_number:
          driver_number.length > 1
            ? {
                dialCode: driver_number[0],
                number: driver_number[1],
              }
            : undefined,
      });
    }
  }, [fetchedDriverData, fetchedDriverError, form]);

  useEffect(() => {
    if (updateDriverError) {
      message.error(updateDriverError.message);
      return;
    } else if (updateDriverData?.update_driver) {
      message.success('Sucessfully Created Driver');
      onSuccess && onSuccess(updateDriverData?.create_driver);
      onClose && onClose();
    }
  }, [updateDriverData, updateDriverLoading, updateDriverError, onClose, onSuccess]);

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionData.id]
  );

  return (
    <Form
      form={form}
      onFinish={(values) => {
        if (!!id) {
          updateDriver({
            variables: {
              driver: {
                ...values,
                driver_contact_number:
                  values.driver_contact_number.dialCode + ' ' + values.driver_contact_number.number,
              },
              id,
            },
          });
        } else {
          createDriver({
            variables: {
              driver: {
                ...values,
                driver_contact_number:
                  values.driver_contact_number.dialCode + ' ' + values.driver_contact_number.number,
                company_id: sessionData?.company_account?.default_company?.id,
              },
            },
          });
        }
      }}
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <Form.Item required rules={[{ required: true }]} label="Driver Name" name="driver_name">
            <Input placeholder="Enter Driver Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            required
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value?.dialCode || !value?.number) {
                    return Promise.reject(new Error('Required'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label="Driver Contact Number"
            name="driver_contact_number"
          >
            <PhoneNumberInput />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Driver License Number" name="driver_license_number">
            <Input placeholder="Enter Driver License Number" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <>
            {id ? (
              <UploadedDocuments
                sessionData={sessionData}
                parent_id={id}
                parent_type={'driver'}
                docgen_url={process.env.DOCGEN_URL || ''}
              />
            ) : (
              <>
                <UploadDocumentModal onUpload={uploadDoc} sessionData={sessionData} />
                <DocumentsList
                  uploadingDocuments={uploadingDocuments}
                  uploadedDocuments={documents}
                  uploadingError={uploadingError}
                  onUpload={uploadDoc}
                  onDelete={deleteDoc}
                  sessionData={sessionData}
                />
              </>
            )}
          </>
        </Col>
      </Row>
    </Form>
  );
}
