import React, { useEffect } from 'react';
import { Input, message, Drawer, Col, Select, Form } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { startCase as _startCase } from 'lodash';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import {
  BOOKING_CANCELLED_BY,
  BOOKING_CANCELLATION_REASONS,
} from 'operations/modules/reports/constants';
import { CANCEL_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { errorMessageHandlerGraphQL } from 'common';

import { LOAD_TYPE_LCL } from 'operations/baseConstants';
const { TextArea } = Input;

type CancelBookingFormProps = {
  onClose: () => void;
  booking: OceanTransportOrderValue;
  onSuccess?: (booking: OceanTransportOrderValue, sendEmail?: boolean) => void;
};

const CancelBookingForm = React.memo(function CancelBookingForm(
  props: CancelBookingFormProps
): JSX.Element {
  const { onClose, booking, onSuccess } = props;
  const cancelAllocatedResources =
    booking.isFullyUnallocated || booking.load_type === LOAD_TYPE_LCL;
  const [cancelBooking, { data, loading, error }] = useMutation(CANCEL_OCEAN_TRANSPORT_ORDER);

  useEffect(() => {
    if (!error && data?.cancel_ocean_transport_order) {
      message.success('Booking Cancelled Successfully!');
      onClose();
      if (onSuccess) onSuccess(data?.cancel_ocean_transport_order);
    }
  }, [error, data, onClose, onSuccess]);

  const [form] = Form.useForm();

  return (
    <Drawer
      title={`Cancel Ocean Booking`}
      width={'60%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Cancel Booking"
          loading={loading}
          onClose={onClose}
          onSave={form.submit}
        />
      }
    >
      <Form
        name="booking_cancellation"
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const variables = {
            ocean_transport_order_id: booking?.id,
            cancelled_by: values.cancellation_reason.split('----')[0],
            cancellation_reason: values.cancellation_reason.split('----')[1],
            remarks: values.remarks,
            cancel_allocated_resources: cancelAllocatedResources,
          };
          cancelBooking({ variables });
        }}
        initialValues={{
          remarks: booking.remarks,
        }}
      >
        {error && errorMessageHandlerGraphQL(error)}
        <Col span={12}>
          <Form.Item
            name="cancellation_reason"
            label="Select Reason For Cancellation"
            required
            rules={[{ required: true }]}
          >
            <Select showSearch allowClear={false} placeholder="Select Cancellation Reason">
              {BOOKING_CANCELLED_BY.map((cancelled_by: string) => (
                <Select.OptGroup key={cancelled_by} label={_startCase(cancelled_by)}>
                  {BOOKING_CANCELLATION_REASONS[cancelled_by].map((option, index) => (
                    <Select.Option
                      key={`${cancelled_by}_${index}`}
                      value={`${cancelled_by}----${option}`}
                    >
                      {_startCase(option)}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Form.Item name="remarks" label="Remarks For Cancellation">
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Drawer>
  );
});

export default CancelBookingForm;
