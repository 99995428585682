import React, { useEffect, useState, useCallback } from 'react';
import { setAxiosDefaults } from './utils/axiosDefaults';
import ApplicationLayout from './ApplicationLayout';
import { getClient } from '../../graphQL/configureApolloClient';
import { ApolloProvider } from '@apollo/client';
import { initializeSentry } from './utils/thirdPartyIntegrations';
import { LicenseManager } from '@ag-grid-enterprise/core';
import Spinner from 'src/components/Spinner';
import '@shipmnts/pixel-hub/dist/index.css';
import { useAuth } from '@workos-inc/authkit-react';
import { logoutUser } from 'shipmnts_navigation';
import { sessionKey } from './baseConstants';

LicenseManager.setLicenseKey(
  'basdhjk098uyghbncskmai0d98uyhbjnksaod98y7[v1]yh12300e57c62c6dcbf4e15d1f000c3d973c5'
);

const App = (props) => {
  const { user, getAccessToken, isLoading, signIn, signOut } = useAuth();

  const [apolloClient, setApolloClient] = useState();
  const logOut = useCallback(() => {
    logoutUser({
      skipAuthSet: false,
      sessionKey: sessionKey,
      alexUrl: process.env.ALEX_URL,
      onSuccess: signOut,
    });
  }, [signOut]);

  useEffect(() => {
    initializeSentry();
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (!user) {
      signIn({
        state: {
          path: window.location.pathname,
          search: window.location.search,
        },
      });
    } else {
      const setClient = async () => {
        setApolloClient(await getClient(getAccessToken, logOut));
      };
      setClient();
      setAxiosDefaults(getAccessToken, logOut);
    }
  }, [getAccessToken, isLoading, signIn, user, logOut]);

  if (apolloClient) {
    let session = localStorage.getItem(sessionKey);

    if (session && session !== '') {
      try {
        session = JSON.parse(session);
        const now_time = Date.now();
        const workos_user_id = user?.id;
        if (workos_user_id?.toString() !== session?.workos_user_id?.toString()) {
          session = undefined;
        }

        if ((session?.expires_at || 0) < now_time) {
          session = undefined;
        }
      } catch {
        session = undefined;
      }
    }

    return (
      <ApolloProvider client={apolloClient}>
        <ApplicationLayout>{props.children}</ApplicationLayout>
      </ApolloProvider>
    );
  }

  return (
    <div className="centered fullscreen">
      <Spinner tip="checking your credentials and logging you in..." />
    </div>
  );
};

export default App;
