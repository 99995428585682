import {
  AccountingDocument,
  Attachment,
  fetchUploadedDocuments,
  LinkedParentType,
  ShipmentDocument,
} from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { hasPermission } from '@shipmnts/pixel-hub';
import { PermissionValue, SessionDataValue } from 'operations/models/SessionData';
import { PERMISSION_QUOTATION } from './reports/constants';
import { erpnextApis } from 'network';
import {
  getGeneratedDocumentsPdf,
  uploadAccountingDocuments,
} from './shipment/components/PreAlertAndDispatch/documentPayloadHelper';
import { ApolloClient } from '@apollo/client';
import { SHIPMENT_TYPE_CONSOL } from './shipment/constants';
import { sortUploadedDocuments } from './shipment/components/PreAlertAndDispatch/UploadedDocumentsTable';
import { fetchDocumentList } from './reports/components/ShipmentDocumentReports/document';
import { errorMessageHandler } from 'common';
import {
  convertToArray,
  flattenDoc,
} from './shipment/components/DetailLayout/Documents/CreatedDocuments';
import { FieldDisableReason } from 'operations/commonTypeDefs';
import { FETCH_VOYAGE_SCHEDULE_BY_ID_DUPLICATE } from './booking/graphql/voyageSchedule';

export const fetchShipmentDocumentParents = (shipment: ShipmentValue) => {
  const shipment_id = shipment?.id;
  const ocean_transport_order_id = shipment?.ocean_transport_order_id;
  const fetchDocumentParents: LinkedParentType[] = [];
  if (shipment_id) {
    fetchDocumentParents.push({
      parent_ids: [shipment_id],
      parent_type: 'shipment',
    });
  }
  if (ocean_transport_order_id) {
    fetchDocumentParents.push({
      parent_ids: [ocean_transport_order_id],
      parent_type: 'ocean_transport_order',
    });
  }
  return fetchDocumentParents;
};

export const get_legs_by_routing_type = (value: Array<RoutingLegValue>, routing_type: string) => {
  return value.filter((leg: RoutingLegValue) => leg.routing_type === routing_type);
};

export const getNoPermissionTags = (permissions: PermissionValue[]) => {
  // ToDo: Confirm the doctype of this permission
  let tagsNoPermission: string[] = [];
  if (
    !hasPermission(permissions, { name: PERMISSION_QUOTATION, docType: 'SalesHub::InquiryOption' })
  ) {
    tagsNoPermission = ['Buy Quotation', 'Sell Quotation'];
  }
  return tagsNoPermission;
};

export const getFetchDocumentFunction = (
  shipment: ShipmentValue,
  client: ApolloClient<object>,
  sessionData: SessionDataValue,
  config_data?: any
) => {
  const fetchDocuments = async (
    selectedParties?: any[],
    fetchDocumentParents?: LinkedParentType[]
  ): Promise<{
    error: any;
    documents: Array<Attachment>;
  }> => {
    const output: {
      error: any;
      documents: Array<Attachment>;
    } = {
      error: null,
      documents: [],
    };
    const getDocumentURL = async (
      document: ShipmentDocument | AccountingDocument | Attachment,
      document_section: 'uploaded_documents' | 'generated_documents' | 'accounting_documents'
    ) => {
      switch (document_section) {
        case 'generated_documents': {
          const { documents, error } = await getGeneratedDocumentsPdf({
            generatedDocuments: [document] as any,
            client,
            sessionData,
            config_data,
          });
          return {
            document: {
              ...documents?.[0],
              ...documents?.[0]?.attachment,
              id: document?.id,
            },
            error,
          };
        }
        case 'accounting_documents': {
          const { documents, error } = await uploadAccountingDocuments(
            [document] as any,
            shipment.id,
            sessionData,
            process.env.DOCGEN_URL as string
          );
          return {
            document: documents?.[0],
            error,
          };
        }
        default:
          return {
            document: document,
          };
      }
    };

    if (shipment && sessionData?.company_account?.id) {
      const shipment_ids = [shipment, ...(shipment?.house_shipments || [])].map((sh) => sh.id);
      const oto_ids = (shipment?.ocean_transport_orders || []).map((oto) => oto.id);
      const parents: LinkedParentType[] = [{ parent_ids: shipment_ids, parent_type: 'shipment' }];
      if (oto_ids.length > 0)
        parents.push({
          parent_type: 'ocean_transport_order',
          parent_ids: oto_ids,
        });

      parents.push(...(fetchDocumentParents || []));

      // uploaded documents
      const { response: uploaded_documents, error: uploaded_documents_error } =
        await fetchUploadedDocuments(
          process.env.DOCGEN_URL as string,
          sessionData.id,
          sessionData?.company_account?.id,
          parents
        );
      if (uploaded_documents_error) output.error = uploaded_documents_error;
      else if (uploaded_documents?.data?.documents) {
        const documents =
          shipment.shipment_type === SHIPMENT_TYPE_CONSOL
            ? sortUploadedDocuments(uploaded_documents?.data?.documents)
            : uploaded_documents?.data?.documents;
        output.documents.push(
          ...documents.map((doc: any) => {
            return {
              ...doc,
              document_section: 'uploaded_documents',
              get_uploaded_document: getDocumentURL,
            };
          })
        );
      }

      // generated documents
      const { response: document_list, error } = await fetchDocumentList(shipment_ids);
      if (error) {
        const resolvedError = errorMessageHandler(error as string);
        output.error = resolvedError;
      } else {
        const rawDocuments = convertToArray(document_list, shipment);
        const documents = flattenDoc(rawDocuments, shipment);
        output.documents.push(
          ...documents
            ?.filter((doc) => doc.document_name !== 'CGM')
            .map((doc: any) => {
              return {
                ...doc,
                get_uploaded_document: getDocumentURL,
                document_section: 'generated_documents',
              };
            })
        );
      }

      //accounting documents
      const job_numbers = [shipment, ...(shipment?.house_shipments || [])].map(
        (sh) => sh.job_number
      );
      const parties = selectedParties?.filter((party: any) => party?.role !== 'Your Company');
      if (parties?.length === 1) {
        const { response: accounting_documents, error: accounting_documents_error } =
          await erpnextApis.fetchAccountingDocuments(job_numbers, parties[0].registered_name);
        if (accounting_documents_error) output.error = accounting_documents_error;
        else if (accounting_documents?.data?.message) {
          const documents = accounting_documents?.data?.message;
          output.documents.push(
            ...documents.map((doc: any) => {
              return {
                ...doc,
                get_uploaded_document: getDocumentURL,
                document_section: 'accounting_documents',
              };
            })
          );
        }
      }
    }

    return output;
  };
  return fetchDocuments;
};

export const getWhyCarrierIsDisabled = (
  shipment: ShipmentValue,
  carrier_field_name = 'carrier'
) => {
  const disabledFilters: Record<string, FieldDisableReason> = {};
  if (shipment?.inquiry_option?.carrier) {
    disabledFilters[carrier_field_name] = {
      disable: true,
      reason: `Carrier fetched from Linked Quote #${shipment?.inquiry_option?.quotation_number} to this order/shipment`,
    };
  }
  if (shipment?.carrier && shipment?.ocean_transport_orders?.length) {
    disabledFilters[carrier_field_name] = {
      disable: true,
      reason: 'Only same carrier booking can be created on this order/shipment',
    };
  }
  return disabledFilters;
};

export const getWhyVesselIsDisabled = (shipment: ShipmentValue, vessel_field_name = 'vessel') => {
  const disabledFilters: Record<string, FieldDisableReason> = {};
  if (shipment?.ocean_vessel && shipment?.ocean_transport_orders?.length) {
    disabledFilters[vessel_field_name] = {
      disable: true,
      reason: 'Only same vessel booking can be created on this order/shipment',
    };
  }
  return disabledFilters;
};

export const getIsSelectedCarrierMatchingWithVoyageSchedule = async (
  client: ApolloClient<object>,
  voyage_schedule_id: string,
  carrier_id: string
) => {
  const { data } = await client.query({
    query: FETCH_VOYAGE_SCHEDULE_BY_ID_DUPLICATE,
    variables: { id: voyage_schedule_id },
  });
  console.log(data);
  const voyageCarriers = data?.voyage_schedule?.carriers ?? [];
  const isMatch = voyageCarriers.some((carrier: any) => carrier.id === carrier_id);
  return isMatch;
};
