import React from 'react';
import { ApolloClient } from '@apollo/client';
import { hasPermission, message } from '@shipmnts/pixel-hub';
import { DISABLE_CONTACT } from 'network/graphql/contact';
import { UserContactValue } from 'operations/models/UserContact';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../constants';
import { StopOutlined, EditOutlined } from '@shipmnts/pixel-hub';
import { Action, PerformAction } from '../models';
import { ContactForm } from 'network';
import { PermissionValue } from 'operations/models/SessionData';
import { PERMISSION_DISABLE } from 'common/baseConstants';

export const enableUserContact = (
  userContact: UserContactValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
): Action => {
  const disablePermission = hasPermission(permissions, {
    name: PERMISSION_DISABLE,
    docType: 'Network::UserContact',
  });
  return {
    key: 'enable_sales_person',
    type: PRIMARY_TYPE,
    displayComponent: 'Enable',
    icon: <StopOutlined />,
    onClick: async (onSuccess: (() => void) | undefined) => {
      const { data, errors } = await client.mutate({
        mutation: DISABLE_CONTACT,
        variables: {
          id: userContact.id,
          is_disabled: false,
        },
      });
      if (errors) {
        console.error(errors);
        message.error(errors[0].message);
      } else if (data?.disable_contact) {
        message.success('Enable Contact');
        if (onSuccess) onSuccess();
      }
    },
    isEnable: userContact.is_disabled && disablePermission,
    description: 'Enable Contact',
  };
};

export const disableUserContact = (
  userContact: UserContactValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
): Action => {
  const disablePermission = hasPermission(permissions, {
    name: PERMISSION_DISABLE,
    docType: 'Network::UserContact',
  });

  return {
    key: 'disable_sales_person',
    type: NEGATIVE_TYPE,
    displayComponent: 'Disable',
    icon: <StopOutlined />,
    onClick: async (onSuccess: (() => void) | undefined) => {
      const { data, errors } = await client.mutate({
        mutation: DISABLE_CONTACT,
        variables: {
          id: userContact.id,
          is_disabled: true,
        },
      });
      if (errors) {
        console.error(errors);
        message.error(errors[0].message);
      } else if (data?.disable_contact) {
        message.success('Disabled Contact');
        if (onSuccess) onSuccess();
      }
    },
    isEnable: !userContact.is_disabled && disablePermission,
    description: 'Disable Contact',
  };
};

export const editUserContact = (
  userContact: UserContactValue,
  permissions: PermissionValue[]
): Action => {
  const editPermission = hasPermission(permissions, {
    name: PERMISSION_DISABLE,
    docType: 'Network::UserContact',
  });

  return {
    key: 'edit_user_contact',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    icon: <EditOutlined />,
    performAction: performContactEdit,
    isEnable: editPermission,
    description: 'Edit Contact',
  };
};
export const getUserContactActions = (
  userContact: UserContactValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
) => {
  return [
    editUserContact(userContact, permissions),
    enableUserContact(userContact, client, permissions),
    disableUserContact(userContact, client, permissions),
  ];
};

const performContactEdit = (payload: any): PerformAction => {
  const { userContact } = payload;
  return {
    actionParams: {
      contact: userContact,
      visible: true,
    },
    component: EditContact,
  };
};

type EditProps = {
  contact: UserContactValue;
  visible: boolean;
  onSuccess: () => void;
  onClose: () => void;
};

export const EditContact = (props: EditProps) => {
  const { contact, onSuccess, onClose } = props;
  const [visible, setVisible] = React.useState(props?.visible);

  return (
    <ContactForm
      setVisible={setVisible}
      visible={visible}
      contact={contact}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  );
};
