/**
  PURPOSE: to list down weight slab wise charges.

  CONFIRM: start_weight & end_weight will not be null in any case?
*/

import { Instance, getSnapshot, types } from 'mobx-state-tree';
import { DISCOUNT_TYPE_PERCENTAGE, WEIGHT_UNIT_KGS, WEIGHT_UNITS } from '../constants';

export const ContractCharge = types
  .model({
    id: types.maybe(types.identifier),
    container_type: types.maybeNull(types.string),
    start_weight: types.optional(types.number, 0),
    end_weight: types.custom({
      name: 'InfinityOrNull',
      fromSnapshot: (val) => val,
      toSnapshot: (val) => val,
      isTargetType: (val) => typeof val === 'number' || val === null,
      getValidationMessage: (value) =>
        typeof value === 'number' || value === null ? '' : 'Invalid type',
    }),
    weight_unit: types.maybeNull(types.enumeration(WEIGHT_UNITS)),
    charge_amount: types.maybeNull(types.number),
    min_charge_amount: types.maybeNull(types.number),
    discount: types.maybeNull(types.number),
    discount_type: types.maybeNull(types.string),
    charge_name: types.maybeNull(types.string),
    charge_tag: types.maybeNull(types.string),
    _destroy: types.maybe(types.optional(types.boolean, false)),
  })
  .actions((self) => ({
    setContainerType: (value: string | null) => (self.container_type = value),
    setChargeAmount: (value: number) => (self.charge_amount = Number(value)),
    setValue: (
      key: 'container_type' | 'weight_unit' | 'charge_name' | 'discount_type',
      value: string
    ) => {
      self[key] = value;
    },
    setWeight: (key: 'start_weight' | 'end_weight', value: number) => {
      self[key] = value;
    },
    setDiscount: (value: number) => (self.discount = value),
    setDiscountType: (value: string) => (self.discount_type = value),
    removeDiscount: () => {
      self.discount = null;
      self.discount_type = null;
    },
    setDestroy: (value: boolean) => (self._destroy = value),
  }))
  .views((self) => ({
    getValue: (
      key:
        | 'charge_amount'
        | 'container_type'
        | 'weight_unit'
        | 'min_charge_amount'
        | 'charge_name'
        | 'start_weight'
        | 'end_weight'
        | 'discount'
        | 'discount_type'
    ) => {
      return self[key];
    },
    getPayload: (removeIds = false) => {
      const charge = getSnapshot(self);
      const payload = {
        ...charge,
        id: charge.id === 'new' || removeIds ? null : charge.id,
        weight_unit: charge.weight_unit ? charge.weight_unit : WEIGHT_UNIT_KGS,
        _destroy: charge._destroy || false,
      };
      return payload;
    },
    getDiscountString: (discount_type?: string) => {
      if (self.discount && discount_type) {
        return `${self.discount}${discount_type === DISCOUNT_TYPE_PERCENTAGE ? '%' : ''}`;
      }
      return '';
    },
    isDestroyed: () => self._destroy,
  }));

export type ContractChargeValue = Instance<typeof ContractCharge>;
