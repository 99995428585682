// Todo: props - array of tags that should not be shown, select props, purpose (default value document)
import { gql, useMutation, useQuery } from '@apollo/client';
import { Select } from '@shipmnts/pixel-hub';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';

type Props = {
  value?: any;
  onChange?: (val: string[]) => void;
  purpose?: string;
  disabled?: boolean;
  singleTag?: boolean;
  autoFocus?: boolean;
};
const { Option } = Select;

// Query to fetch tags
export const FETCH_USER_TAG = gql`
  query fetchTag($purpose: String) {
    fetch_tags(purpose: $purpose) {
      tags
    }
  }
`;
// Update tag
export const UPDATE_USER_TAG = gql`
  mutation updateTags($tags: [String!]!, $purpose: String) {
    update_tags(tags: $tags, purpose: $purpose) {
      tags
    }
  }
`;

const TagsSearch = (props: Props) => {
  const { purpose = 'document', singleTag, autoFocus = true } = props;

  const { data, loading } = useQuery(FETCH_USER_TAG, { variables: { purpose: purpose } });

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagsData, settagsData] = useState<string[]>(['']);

  const [createTags] = useMutation(UPDATE_USER_TAG);

  useEffect(() => {
    const tags = data?.fetch_tags?.tags || [];
    settagsData(tags);
  }, [data]);
  useEffect(() => {
    if (props.value) {
      if (props.value.hasOwnProperty('record_details'))
        setSelectedTags(props.value?.record_details || []);
      else setSelectedTags(props.value);
    }
  }, [props.value]);

  const save_tags = (selected_tags: string[] | undefined) => {
    let new_tags = [];
    if (selected_tags) {
      new_tags = selected_tags.filter((item) => !selectedTags.includes(item));
      const new_custom_updated_list = [...new_tags];
      if (new_tags.length !== 0) {
        createTags({
          variables: {
            tags: new_custom_updated_list,
            purpose: purpose,
          },
        }).then((res) => {
          const tags = tagsData.concat(new_custom_updated_list);
          settagsData(uniq(tags));
        });
      }
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Select tags"
      mode="tags"
      loading={loading}
      value={selectedTags}
      onChange={(value: string[]) => {
        save_tags(value);
        setSelectedTags(value);
        if (props.onChange) {
          if (singleTag) props.onChange(value.length === 0 ? [] : [value[value.length - 1]]);
          else props.onChange(value);
        }
      }}
      autoFocus={autoFocus}
    >
      {tagsData.map((tag: string) => (
        <Option key={tag} value={tag}>
          {tag}
        </Option>
      ))}
    </Select>
  );
};

export default TagsSearch;
