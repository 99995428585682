// ToDo: Add new container and add container details is confusing
import { ShipmentValue } from 'operations/models/Shipment';
import CancelAirShipmentDrawer from 'operations/modules/reports/components/AirBookingReports/CancelAirShipmentDrawer';
import CancelShipmentDrawer from 'operations/modules/reports/components/ShipmentReports/CancelShipmentDrawer';
import DeleteHouseShipment from 'operations/modules/reports/components/ShipmentReports/DeleteHouseShipment';
import DelinkHouseShipment from 'operations/modules/reports/components/ShipmentReports/DelinkHouseShipment';
import PreAlertAndDispatchDrawer from 'operations/modules/reports/components/ShipmentReports/PreAlertAndDispatchDrawer';
import ShipmentCreditApproval from 'operations/modules/reports/components/ShipmentReports/ShipmentCreditApproval';
import { PerformAction } from '../models';
import { SessionDataValue } from 'operations/models/SessionData';
import FinaliseConsol from 'operations/modules/shipment/components/FinaliseConsol';
import AddContainerDetails from 'operations/modules/shipment/components/DetailLayout/Containers/AddContainerDetails';
import { TrackerLayout, RenderEventsForm } from 'common';
import AddNewHouseShipment from 'operations/modules/shipment/components/AddNewHouseShipment';
import AccountingClose from 'operations/modules/shipment/components/AccountingClose';
import AccountingReopen from 'operations/modules/shipment/components/AccountingReopen';
import { AccessibleByBranches } from '@shipmnts/pixel-hub';
import AddNewContainer from 'operations/modules/shipment/components/DetailLayout/Containers/AddNewContainer';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';

import { EVENT_EMPTY_PICKED_UP } from 'operations/modules/actionHelper/constants';
import LinkRoadShipmentDrawer from 'operations/modules/shipment/components/ShipmentForm/LinkRoadShipmentDrawer';
import UpdateOrSplitOtoWrapper from 'operations/modules/oto/UpdateOrSplitOtoWrapper';
import CreateShipmentFromConsol from 'operations/modules/shipment/components/ShipmentForm/CreateShipmentFromConsol';
import { AddCollaborators, PrintPreviewDocType } from '@shipmnts/pixel-hub';
import UpdateProductsQtyFromShipment from 'operations/modules/orderManagement/UpdateProductsQtyFromShipment';
import CreateShipmentFromExportCustomerOrder from 'operations/modules/booking/components/CreateShipmentFromExportCustomerOrder';
import CreateBookingOrderFromBR from 'operations/modules/shipment/components/NewShipmentForm/CreateBookingOrderFromBr';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import UpdateRoutingOto from 'operations/modules/oto/UpdateRoutingOto';
import OffloadContainers from 'operations/modules/shipment/components/OffloadContainers';
import ChangeAllocationOfBooking from 'operations/modules/shipment/components/ChangeAllocationBooking';
import MergeShipment from 'operations/modules/shipment/components/MergeShipment';
import AllocateOTOReport from 'operations/modules/reports/components/ShipmentReports/AllocateOTOReport';
import CancelShipmentForm from 'operations/modules/reports/components/ShipmentReports/CancelShipmentForm';
import AssignVehicleFormTable from 'operations/modules/shipment/components/ShipmentForm/AssignVehicleFormDrawer';
import CreateAndAssignLoad from 'operations/modules/shipment/components/ShipmentForm/CreateAndAssignLoad';
import { BOOKING_TYPE_SHIPPING_LINE } from 'operations/baseConstants';
import SplitAndCreateBookingDrawer from 'operations/modules/oto/SplitAndCreateBookingDrawer';
import {
  BOOKING_TYPE_BOOKING_REQUEST,
  BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
} from 'operations/modules/booking/constants';
import UpdateTeamActionRenderer from 'common/components/Team/UpdateTeamDrawer';
import ShareShipment from 'operations/modules/shipment/components/ShareShipment';
import { ApolloClient } from '@apollo/client';
import { getFetchDocumentFunction } from 'operations/modules/helpers';

interface ShipmentActionType {
  shipment: ShipmentValue;
  setEmailProps: (emailProps: any) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  oceanTransportOrder?: OceanTransportOrderValue;
  history: History;
  navigate: any;
  config_data?: any;
  client: ApolloClient<object>;
}

export const cancelShipmentRenderer = (payload: ShipmentActionType) => {
  const { shipment, setEmailProps, setVisible, sessionData, client, config_data } = payload;

  const master_document = shipment?.shipment_documents?.find(
    (document) => document.document_type === 'master'
  );
  return {
    actionParams: {
      shipment: shipment,
      shipment_document_master: master_document,
      cancellationType: 'Cancellation',
      onSuccess: (shipment: ShipmentValue, sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: 'Booking Request Cancelled',
            action_name: 'booking_request_cancelled',
            resource_ids: [shipment.id],
            showMarkDown: true,
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
              : undefined,
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
          });
          setVisible(true);
        }
      },
    },
    component: shipment.freight_type === 'air' ? CancelAirShipmentDrawer : CancelShipmentForm,
  };
};

export const backtoTownRenderer = (payload: ShipmentActionType): PerformAction => {
  const { shipment } = payload;
  const master_document = shipment?.shipment_documents?.find(
    (document) => document.document_type === 'master'
  );
  return {
    actionParams: {
      shipment,
      shipment_document_master: master_document,
      cancellationType: 'Back to Town',
    },
    component: shipment.freight_type === 'air' ? CancelAirShipmentDrawer : CancelShipmentDrawer,
  };
};

export const dispatchDocketRenderer = (payload: ShipmentActionType): PerformAction => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
      actionName: 'dispatch_document',
      defaultShipmentPartyType: 'customer',
      emailModaltitle: `Send Document Dispatch email for JOB # ${
        shipment.job_number ? shipment.job_number : shipment.id
      }`,
      emailTemplateActionName: 'document_dispatch_notification',
    },
    component: PreAlertAndDispatchDrawer,
  };
};

export const allocateOTOReportRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
    },
    component: AllocateOTOReport,
  };
};

export const offloadContainersFormRenderer = (payload: ShipmentActionType) => {
  const { shipment, setEmailProps, setVisible } = payload;

  return {
    actionParams: {
      shipment,
      onSuccess: (event_ids: string[], sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: `Mark Selected Containers as Offloaded`,
            fetchDocumentParents: [
              {
                parent_type: 'tracking_event',
                parent_ids: event_ids,
              },
            ],
            action_name:
              event_ids.length === 1
                ? 'tracking_event_notification'
                : 'tracking_event_notification_bulk',
            resource_ids: event_ids,
          });
          setVisible(true);
        }
      },
    },
    component: OffloadContainers,
  };
};

export const performShipmentEvent = (payload: ShipmentActionType): PerformAction => {
  const { shipment, sessionData, extraProps } = payload;
  const eventName = extraProps?.event_name;
  return {
    actionParams: {
      id: shipment.id,
      referenceType: 'Shipment::Shipment',
      eventName: eventName,
      type: extraProps?.event_type,
      session: sessionData,
      onSuccess: () => {
        // TODO: on success
      },
    },
    component: RenderEventsForm,
  };
};

export const creditControlRenderer = (payload: ShipmentActionType): PerformAction => {
  const { shipment, extraProps, setEmailProps, setVisible, sessionData, config_data, client } =
    payload;
  return {
    actionParams: {
      shipment_id: shipment.id,
      shipment_type: shipment.shipment_type,
      job_number: shipment.job_number,
      shipment_booking_number: shipment.shipment_booking_number,
      action_name: extraProps.action_name,
      onSuccess: (id: any) => {
        if (id) {
          setEmailProps({
            title: 'Send Email Notification',
            action_name: 'shipment_credit_status_changed',
            resource_ids: [id],
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
              : undefined,
          });
          setVisible(true);
        }
      },
    },
    component: ShipmentCreditApproval,
  };
};

export const delinkHouseShipmentRenderer = (payload: ShipmentActionType): PerformAction => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
    },
    component: DelinkHouseShipment,
  };
};

export const deleteHouseShipmentRenderer = (payload: ShipmentActionType): PerformAction => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
    },
    component: DeleteHouseShipment,
  };
};

export const renderShipmentEventTimeLine = (payload: ShipmentActionType): PerformAction => {
  const { shipment, sessionData } = payload;
  return {
    actionParams: {
      id: shipment.id,
      referenceType: 'Shipment::Shipment',
      isOpen: true,
      onClose: () => {
        // TODO: On Close
      },
      onSuccess: () => {
        // TODO: on success
      },
      session: sessionData,
    },
    component: TrackerLayout,
  };
};

export const addShipmentContainerDetailsActionRenderer = (payload: ShipmentActionType) => {
  const { shipment, setEmailProps, setVisible, sessionData } = payload;
  return {
    actionParams: {
      shipment: shipment,
      onSuccess: (shipment_containers: ShipmentContainerValue[], sendEmail: boolean) => {
        if (sendEmail) {
          const event_ids = shipment_containers
            .map(
              (container: ShipmentContainerValue) =>
                container.tracking_events?.find((event) => event.name === EVENT_EMPTY_PICKED_UP)
                  ?.id || ''
            )
            .filter((c) => c !== '');
          if (event_ids.length > 0) {
            setEmailProps({
              title: 'Pickup Confirmation',
              action_name: 'tracking_event_notification_bulk',
              docTypeId: 'Shipment::TrackingEvent',
              resource_ids: event_ids,
              fetchDocumentParents: [
                {
                  parent_type: 'tracking_event',
                  parent_ids: event_ids,
                },
              ],
              companies_roles_mapping: sessionData
                ? shipment.getAllPartiesRolesMapping(sessionData)
                : [],
              showMarkDown: true,
            });
            setVisible(true);
          }
        }
      },
      onClose: () => {
        setVisible(false);
      },
    },
    component: AddContainerDetails,
  };
};

export const createContainerActionRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
    },
    component: AddNewContainer,
  };
};

export const sendPreAlertRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
      actionName: 'send_pre_alert',
      defaultShipmentPartyType: 'destination_agent',
      emailModaltitle: `Send Pre-alert email for JOB # ${
        shipment.job_number ? shipment.job_number : shipment.id
      }`,
      emailTemplateActionName: 'pre_alert_notification',
    },
    component: PreAlertAndDispatchDrawer,
  };
};

export const finaliseConsolRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
    },
    component: FinaliseConsol,
  };
};

export const addNewHouseShipmentRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
    },
    component: AddNewHouseShipment,
  };
};

export const addNewHouseForRoadShipmentRender = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
      isAddNewHouse: true,
    },
    component: CreateAndAssignLoad,
  };
};

export const jobCloseRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
    },
    component: AccountingClose,
  };
};

export const reopenJobRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment,
    },
    component: AccountingReopen,
  };
};

export const performShipmentSharePDF = (payload: any): PerformAction => {
  const { shipment } = payload;

  return {
    actionParams: {
      docTypeId: shipment.id,
      docType: 'Shipment::Shipment',
      doc: shipment,
    },
    component: ShareShipment,
  };
};

export const shareJobRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
    },
    component: AccessibleByBranches,
  };
};

export const addCreateShipmentFromBrRenderer = (payload: ShipmentActionType) => {
  const { shipment, extraProps } = payload;

  // TODO: render this by lcl and fcl
  return {
    actionParams: {
      shipment: shipment,
      shipmentType: extraProps.shipmentType,
      action: extraProps.action,
    },
    component: CreateShipmentFromExportCustomerOrder,
  };
};

export const CreateBookingOrderFromShipmentRenderer = (payload: ShipmentActionType) => {
  const { shipment, extraProps, sessionData, setEmailProps, setVisible, config_data, client } =
    payload;

  // TODO: render this by lcl and fcl
  return {
    actionParams: {
      shipment: shipment,
      booking_type: extraProps.type,
      load_type: shipment?.load_type,
      onSuccess: (allocate_ocean_transport_order: any, sendEmail: boolean) => {
        if (sendEmail) {
          setEmailProps({
            title: 'Booking Confirmation',
            action_name: 'booking_confirmation',
            resource_ids: [shipment.id],
            fetchDocumentParents: [
              {
                parent_type: BOOKING_TYPE_BOOKING_REQUEST,
                parent_ids: [shipment.id],
              },
              {
                parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
                parent_ids: [allocate_ocean_transport_order?.id],
              },
            ],
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
            showMarkDown: true,
            fetchDocuments: client
              ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
              : undefined,
          });
          setVisible(true);
        }
      },
    },
    component: CreateBookingOrderFromBR,
  };
};
export const linkRoadShipmentRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
    },
    component: LinkRoadShipmentDrawer,
  };
};
export const updateOrSplitRollOverRenderer = (payload: ShipmentActionType) => {
  const { shipment, setEmailProps, setVisible, sessionData } = payload;

  return {
    actionParams: {
      shipment: shipment,
      onSuccess: (sendEmail: boolean, resource_ids: string[]) => {
        if (sendEmail) {
          setEmailProps({
            title: `Send Routing Update Email`,
            fetchDocumentParents: [
              {
                parent_type: 'tracking_event',
                parent_ids: resource_ids,
              },
            ],
            action_name:
              resource_ids.length === 1
                ? 'tracking_event_notification'
                : 'tracking_event_notification_bulk',
            resource_ids: resource_ids,
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
          });
          setVisible(true);
        }
      },
    },
    component: UpdateOrSplitOtoWrapper,
  };
};

export const rolloverOrShutoutRenderer = (payload: ShipmentActionType) => {
  const { shipment, setEmailProps, setVisible, sessionData } = payload;
  return {
    actionParams: {
      shipment: shipment,
      onSuccess: (sendEmail: boolean, resource_ids: string[]) => {
        if (sendEmail) {
          setEmailProps({
            title: `Send Routing Update Email`,
            fetchDocumentParents: [
              {
                parent_type: 'tracking_event',
                parent_ids: resource_ids,
              },
            ],
            action_name:
              resource_ids.length === 1
                ? 'tracking_event_notification'
                : 'tracking_event_notification_bulk',
            resource_ids: resource_ids,
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
          });
          setVisible(true);
        }
      },
    },
    component: UpdateRoutingOto,
  };
};

export const updateOceanRoutingDetails = (payload: ShipmentActionType) => {
  const { shipment, setEmailProps, setVisible, sessionData } = payload;
  const oto =
    (shipment.ocean_transport_orders || []).find(
      (oto: OceanTransportOrderValue) => oto.booking_type === BOOKING_TYPE_SHIPPING_LINE
    ) || shipment?.ocean_transport_order?.[0];
  return {
    actionParams: {
      shipment: shipment,
      splitFromShipment: true,
      containers: shipment.shipment_containers,
      rolloverOrShutout: 'rollover',
      ocean_transport_order: {
        load_type: shipment.load_type,
        global_carrier: shipment.carrier,
        ...oto,
        routing_legs: shipment.routing_legs,
      },
      onSuccess: (sendEmail: boolean, resource_ids: string[]) => {
        if (sendEmail) {
          setEmailProps({
            title: `Send Routing Update Email`,
            fetchDocumentParents: [
              {
                parent_type: 'tracking_event',
                parent_ids: resource_ids,
              },
            ],
            action_name:
              resource_ids.length === 1
                ? 'tracking_event_notification'
                : 'tracking_event_notification_bulk',
            resource_ids: resource_ids,
            companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
          });
          setVisible(true);
        }
      },
    },
    component: SplitAndCreateBookingDrawer,
  };
};

export const createNewRoadShipmentRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;

  return {
    actionParams: {
      shipment: shipment,
      shipmentType: 'house',
    },
    component: CreateShipmentFromConsol,
  };
};

export const assignVehicleRender = (payload: ShipmentActionType) => {
  const { shipment } = payload;

  return {
    actionParams: {
      shipment: shipment,
      shipmentType: 'house',
    },
    component: AssignVehicleFormTable,
  };
};
export const assignLoadRender = (payload: ShipmentActionType) => {
  const { shipment } = payload;

  return {
    actionParams: {
      shipment: shipment,
    },
    component: CreateAndAssignLoad,
  };
};

export const performUpdateProductsQty = (payload: ShipmentActionType): PerformAction => {
  const { shipment } = payload;
  return {
    actionParams: {
      shipment: shipment,
    },
    component: UpdateProductsQtyFromShipment,
  };
};
export const addCollaboratorsRenderer = (payload: ShipmentActionType): PerformAction => {
  const { shipment } = payload;

  return {
    actionParams: {
      referenceId: shipment?.id,
      referenceType: 'Shipment::Shipment',
      id: shipment?.job_number,
    },
    component: AddCollaborators,
  };
};

export const changeAllocationRenderer = (payload: ShipmentActionType) => {
  const { shipment, extraProps } = payload;

  return {
    actionParams: {
      shipment,
      ...extraProps,
    },
    component: ChangeAllocationOfBooking,
  };
};

export const mergeShipmentRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;

  return {
    actionParams: {
      shipment,
    },
    component: MergeShipment,
  };
};

export const shareCroRenderer = (payload: ShipmentActionType): PerformAction => {
  const { shipment, sessionData } = payload;
  return {
    actionParams: {
      docType: 'Shipment::Shipment',
      resource_id: shipment?.id,
      emailProps: {
        templateActionName: `booking_confirmation`,
        fetchDocumentParents: [
          {
            parent_type: BOOKING_TYPE_BOOKING_REQUEST,
            parent_ids: [shipment.id],
          },
          {
            parent_type: BOOKING_TYPE_OCEAN_TRANSPORT_ORDER,
            parent_ids: shipment.ocean_transport_orders?.map((oto) => oto.id),
          },
        ],
        companies_roles_mapping: shipment.getAllPartiesRolesMapping(
          sessionData?.company_account?.default_company
        ),
      },
    },
    component: PrintPreviewDocType,
  };
};

export const updateTeamRenderer = (payload: ShipmentActionType) => {
  const { shipment } = payload;
  const initialValues = {
    teams: [
      ...(shipment?.teams ?? []),
      {
        role: 'sales',
        sales_person: shipment?.sales_agent ?? {},
      },
    ],
  };
  return {
    actionParams: {
      initialValues: initialValues,
      reference_type: 'Shipment::Shipment',
      reference_id: shipment?.id,
    },
    component: UpdateTeamActionRenderer,
  };
};
