import React from 'react';
import { Action } from '../models';
import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';
import {
  ROUTING_TYPE_MAIN_CARRIAGE,
  getCarriageWiseRoutingLegs,
} from 'operations/models/RoutingLeg';
import {
  LOAD_TYPE_FCL,
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_SELF,
} from 'operations/baseConstants';
import {
  STATUS_REQUESTED,
  STATUS_CONFIRMED,
  STATUS_CANCELLED,
} from 'operations/modules/reports/constants';
import {
  sendConfirmationActionRenderer,
  cancelBookingRenderer,
  deallocateOtoRenderer,
  createCOFromOTORenderer,
  updateOtoRenderer,
} from './otoActionRender';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE } from '../constants';
import { duplicateBookingOrder } from 'operations/modules/booking/helpers/DuplicateBookingHelper';

import {
  TagsOutlined,
  CopyOutlined,
  CarryOutOutlined,
  DisconnectOutlined,
  EditOutlined,
} from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { CustomIcon, dayjs } from '@shipmnts/pixel-hub';
import { TRADE_TYPE_EXPORT, TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';
import { getIsCustomClearanceByServices } from 'operations/modules/shipment/components/NewShipmentForm/helpers';

export const isEtdCrossedOto = (oto: OceanTransportOrderValue) => {
  const carriageWiseRoutingLegs = getCarriageWiseRoutingLegs(oto.routing_legs);
  const main_carriage_routing_legs = carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const etd = main_carriage_routing_legs?.[0].estimated_time_of_departure;
  let isEtdCrossed = false;
  if (etd) isEtdCrossed = dayjs(etd).isBefore(dayjs(new Date()));
  return isEtdCrossed;
};

export const sendConfirmationAction = (oto: OceanTransportOrderValue): Action => {
  const carriageWiseRoutingLegs = getCarriageWiseRoutingLegs(oto.routing_legs);
  const main_carriage_routing_legs = carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const etd = main_carriage_routing_legs?.[0].estimated_time_of_departure;
  let isEtdCrossed = false;
  if (etd) isEtdCrossed = dayjs(etd).isBefore(dayjs(new Date()));

  return {
    key: 'confirm_booking',
    type: PRIMARY_TYPE,
    icon: <CustomIcon icon="CheckCircleIcon" />,
    displayComponent: 'Confirm Booking',
    performAction: sendConfirmationActionRenderer,
    isEnable:
      oto.status !== STATUS_CANCELLED &&
      oto.status === STATUS_REQUESTED &&
      !isEtdCrossed &&
      oto.load_type === LOAD_TYPE_FCL,
  };
};

export const createCustomerOrderFromOTO = (oto: OceanTransportOrderValue): Action => {
  return {
    key: 'create_booking_request',
    type: PRIMARY_TYPE,
    displayComponent: 'Create & Allocate',
    icon: <TagsOutlined />,
    performAction: createCOFromOTORenderer,
    isEnable:
      oto.status === STATUS_CONFIRMED &&
      oto.is_unfulfilled &&
      !(oto.isHazardous && !oto.isFullyUnallocated),
    description: 'Create a customer Order and Allocate to booking',
  };
};

export const cancelBooking = (oto: OceanTransportOrderValue): Action => {
  const isShipmentCreated = oto?.shipment ? true : false;
  return {
    key: 'cancel_booking',
    type: NEGATIVE_TYPE,
    displayComponent: 'Cancel Ocean Booking',
    icon: <CustomIcon icon="RemoveCircleIcon" />,
    description: 'Cancel Booking with Carrier / Agent',
    performAction: cancelBookingRenderer,
    isEnable:
      oto?.shipment?.trade_type !== TRADE_TYPE_IMPORT &&
      !getIsCustomClearanceByServices(oto?.shipment?.services) &&
      oto.status !== STATUS_CANCELLED &&
      !isShipmentCreated &&
      !oto.isAnyContainerPickedUp(),
  };
};

export const extendValidity = (oto: OceanTransportOrderValue): Action => {
  return {
    key: 'extend_validity',
    type: SECONDARY_TYPE,
    displayComponent: 'Edit Booking',
    icon: <CarryOutOutlined />,
    description: 'Send Revalidation Request to carrier and extend validity details',
    performAction: updateOtoRenderer,
    isEnable:
      oto.status !== STATUS_CANCELLED && oto.load_type === LOAD_TYPE_FCL && !isEtdCrossedOto(oto),
  };
};

export const deallocateBookingFromShipment = (
  oceanTransportOrder: OceanTransportOrderValue,
  shipment: ShipmentValue
): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'deallocate_booking_order',
    displayComponent: 'Delink',
    icon: <DisconnectOutlined />,
    isEnable:
      shipment &&
      shipment?.trade_type !== TRADE_TYPE_IMPORT &&
      !getIsCustomClearanceByServices(shipment?.services) &&
      shipment?.load_type === LOAD_TYPE_FCL &&
      oceanTransportOrder.booking_type !== BOOKING_TYPE_SELF &&
      !oceanTransportOrder.isAnyContainerPickedUp(),
    extraProps: { oceanTransportOrder, shipment },
    performAction: deallocateOtoRenderer,
  };
};

export const duplicateOTO = (oto: OceanTransportOrderValue, navigate: any): Action => {
  return {
    key: 'duplicate_oto',
    type: TERTIARY_TYPE,
    displayComponent: 'Duplicate Booking',
    isEnable: oto.status !== STATUS_CANCELLED && oto.booking_type === BOOKING_TYPE_SHIPPING_LINE,
    icon: <CopyOutlined />,
    onClick: () => {
      duplicateBookingOrder(oto, navigate);
    },
  };
};

export const updateOto = (
  oto: OceanTransportOrderValue,
  shipment: ShipmentValue | null,
  showEdit?: boolean
): Action => {
  return {
    type: SECONDARY_TYPE,
    key: oto.id,
    displayComponent: showEdit
      ? `Edit ${oto.booking_number || 'Booking'}`
      : oto.booking_number || 'Booking',
    icon: <EditOutlined />,
    isEnable:
      oto.status !== STATUS_CANCELLED &&
      (shipment?.trade_type === TRADE_TYPE_EXPORT
        ? !shipment?.isDocumentFinalised('house') &&
          !shipment?.isMasterDocumentExecuted() &&
          !shipment?.isHouseDocumentExecuted()
        : true),
    performAction: updateOtoRenderer,
    extraProps: {
      oto: oto,
    },
  };
};

export const getOTOActions = (oto: OceanTransportOrderValue | undefined, navigate: any) => {
  if (!oto) return [];
  oto = OceanTransportOrder.create(oto);
  return [
    sendConfirmationAction(oto),
    createCustomerOrderFromOTO(oto),
    cancelBooking(oto),
    updateOto(oto, oto.shipment, true),
    duplicateOTO(oto, navigate),
    deallocateBookingFromShipment(oto, oto.shipment),
  ];
};
