import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Typography,
  message,
  EditOutlined,
  DeleteOutlined,
  GlobalSearch,
  SelectPipeline,
  CompanySearch,
  RichTextEditorWrapper,
  UploadedDocuments,
  UploadDocumentModal,
  DocumentsList,
  documentsStore,
  UploadDocumentPayload,
  UploadedDocumentType,
  addDocumentParents,
  deleteUploadedDocument,
  uploadDocument,
  DatePicker,
  disablePastDate,
  convertToDayJs,
} from '@shipmnts/pixel-hub';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { CREATE_TICKET, FETCH_TICKET, UPDATE_TICKET } from '../graphql/ticket';
import { useLazyQuery, useMutation } from '@apollo/client';

import { refClassTransformer } from '@shipmnts/pixel-hub';
import AssociationTable from './AssociationTable';

import { transformReferences } from '../utils/utils';
import { useSession } from 'common';
import { TicketDataSourceItem } from './types';
import { EDIT_FIELD } from 'common/utils/graphql';

interface TicketFormProps {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  edit?: boolean;
  ticket_id?: string;
  onSuccess?: () => void;
  onClose?: () => void;
  dataSource?: TicketDataSourceItem[];
}
const TicketForm = (props: TicketFormProps) => {
  const { visible, setVisible, edit, ticket_id } = props;
  const [form] = Form.useForm();
  // Queries / Mutations
  const [createTicket, { data, loading: loadingCreate, error }] = useMutation(CREATE_TICKET);
  const [updateTicket, { data: updateData, loading: loadingUpdate, error: updateError }] =
    useMutation(UPDATE_TICKET);
  const [fetchTicket, { data: ticketData, loading: loadingFetch, error: errorFetch, refetch }] =
    useLazyQuery(FETCH_TICKET);
  const [editFields, { loading }] = useMutation(EDIT_FIELD);

  // States
  const [isAddAnother, setIsAddAnother] = useState(false);
  const [dataSource, setDataSource] = useState<
    {
      link_type: string | null | undefined;
      linked_record: any | null;
    }[]
  >([
    {
      link_type: null,
      linked_record: null,
    },
  ]);
  const [deletedDataSource, setDeletedDataSource] = useState<
    {
      linked_record: string | null;
      is_deleted: boolean | null;
    }[]
  >([]);
  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });
  const [formValues, setFormValues] = useState(null);

  const session = useSession();

  useEffect(() => {
    if (props?.dataSource) {
      setDataSource(props.dataSource);
    }
  }, [props.dataSource]);
  const onClose = () => {
    if (isAddAnother) {
      resetTicketForm();
      if (setVisible) setVisible(true);
      setIsAddAnother(false);
      setDataSource([]);
      if (props.onSuccess) props.onSuccess();
      return;
    }
    if (setVisible) setVisible(false);
    if (props.onSuccess) props.onSuccess();
    if (props.onClose) props.onClose();
  };

  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({
        type: 'upload_failure',
        payload: { doc, error: error.message },
      });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        session.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session.id]
  );
  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const updateDocs = useCallback(
    async (ticket_id: string) => {
      if (documents.length > 0) {
        const _updateDocIds = documents.map((d) => d.id);
        const { response, error } = await addDocumentParents(
          _updateDocIds,
          [{ parent_type: 'ticket', parent_id: ticket_id }],
          session.id,
          process.env.DOCGEN_URL || ''
        );
        if (error) {
          message.error('Document upload failed');
        } else if (response) {
          dispatch({ type: 'reset_state' });
        }
      }
    },
    [documents, session.id]
  );

  useEffect(() => {
    if (edit) {
      fetchTicket({
        variables: { id: ticket_id },
      });
      if (errorFetch) {
        message.error('Failed to fetch ticket');
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (ticketData) {
      const formData = ticketData?.service_management_ticket;
      const settingValues = {
        ...formData,
        target_resolution_date: convertToDayJs(formData?.target_resolution_date),
        pipeline: formData?.pipeline?.id,
        pipeline_status: formData?.current_state?.id,
      };
      form.setFieldsValue(settingValues);

      const refs = transformReferences(ticketData.service_management_ticket?.references);
      setDataSource(refs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData]);

  const CreationSucessRender = (resp: any) => {
    const data = resp?.data?.create_ticket;

    return (
      <>
        <a href={`/view/ticket/${data.id}`} target="_blank" rel="noreferrer">
          {data.ticket_number}
        </a>
        {' created successfully'}
      </>
    );
  };
  useEffect(() => {
    if (error) {
      message.error('Failed to create ticket');
    } else if (data) {
      const id = data?.create_ticket?.id;
      if (formValues && id) {
        editFields({
          variables: {
            changes: [
              {
                id: id,
                doc_type_id: 'ServiceManagement::Ticket::Ticket',
                fields_changes: JSON.stringify(formValues),
              },
            ],
          },
        }).then((res) => {
          message.success(<CreationSucessRender data={data} />);
          updateDocs(data.id);
          form.resetFields(['linked_records']); //not working (fix-me)
          resetTicketForm();
          onClose();
        });
      } else {
        message.success(<CreationSucessRender data={data} />);
        updateDocs(data.id);
        form.resetFields(['linked_records']); //not working (fix-me)
        resetTicketForm();
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loadingCreate, error]);

  useEffect(() => {
    if (updateError) {
      message.error('Failed to update ticket');
    } else if (updateData) {
      message.success('Ticket updated successfully');
      if (onClose) onClose();
      if (refetch) refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, loadingUpdate, updateError]);

  useEffect(() => {
    if (!edit) {
      form.setFieldValue('assigned_to', { ...session, id: session.user_contact_id || session.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, edit]);

  const defaultColumns = [
    {
      title: 'Object Type',
      dataIndex: 'link_type',
      editable: true,
      renderComponent: 'select',
      componentProps: {
        showGroup: false,
        options: [
          { key: 'Shipment', name: 'Shipment' },
          { key: 'Container', name: 'Container' },
          { key: 'Company', name: 'Company' },
          { key: 'Sales Invoice', name: 'Sales Invoice' },
          { key: 'Purchase Invoice', name: 'Purchase Invoice' },
        ],
        placeholder: 'Select Type',
      },
      required: true,
    },
    {
      title: 'Linked Record',
      dataIndex: 'linked_record',
      editable: true,
      renderComponent: 'custom_field',
      customComponent: GlobalSearch,
      componentProps: {},
      required: true,
    },
    {
      title: 'Action',
      render: (_: string, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DeleteOutlined
                style={{
                  color: '#E70000D9',
                  cursor: 'pointer',
                  fontSize: '17px',
                }}
              />
            </div>
          </Popconfirm>
        ) : null,
      align: 'center',
    },
  ];

  const onFieldChange = useCallback(
    (key: string, value: any, index: number) => {
      const doc_ref_id = dataSource[index]?.linked_record?.doc_ref_id;

      if (key === 'linked_record') {
        if (value) value.doc_ref_id = doc_ref_id;
      }
      const newData = [...dataSource];
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        [key]: value,
      });
      setDataSource(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource]
  );

  const columns = defaultColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any, rowIndex: number) => {
        return {
          record,
          rowIndex,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          renderComponent: col.renderComponent,
          componentProps:
            col.dataIndex === 'link_type'
              ? {
                  ...col.componentProps,
                  onChange: (value: string) => {
                    onFieldChange(col.dataIndex, value, rowIndex);
                    setDataSource((prevDataSource) => {
                      const newData = [...prevDataSource];
                      const item = newData[rowIndex];
                      newData.splice(rowIndex, 1, {
                        ...item,
                        linked_record: {
                          doc_ref_id: item.linked_record?.doc_ref_id,
                        },
                      });
                      return newData;
                    });
                  },
                }
              : {
                  ...col.componentProps,
                  doc_type: refClassTransformer[record['link_type']],
                  disabled: record['link_type'] === null,
                  value: dataSource[rowIndex].linked_record,
                },
          onFieldChange,
          required: col.required,
          customComponent: col.customComponent,
          //   errorsPresent: record[col.dataIndex] === '' || record[col.dataIndex] === null,
        };
      },
    };
  });

  const handleDelete = (record: any) => {
    const newData = dataSource.filter((item: any) => item !== record);
    record._destroy = true;
    setDeletedDataSource([...deletedDataSource, record]);
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData = {
      link_type: null,
      linked_record: null,
    };
    setDataSource([...dataSource, newData]);
  };
  const resetTicketForm = () => {
    form.resetFields();
    form.setFieldValue('assigned_to', {
      ...session,
      id: session.user_contact_id || session.id,
    });
  };

  if (loadingFetch || loadingCreate) {
    return <></>;
  }

  return (
    <div>
      <Drawer
        title={
          <>
            {!edit
              ? 'Create New Ticket'
              : `Update ${ticketData?.service_management_ticket?.ticket_number}`}{' '}
            <EditOutlined />
          </>
        }
        onClose={() => {
          if (onClose) onClose();
          if (setVisible) setVisible(false);
          resetTicketForm();
          setDataSource([
            {
              link_type: null,
              linked_record: null,
            },
          ]);
        }}
        open={visible}
        width={'725px'}
        footer={[
          <div key="actions" style={{ textAlign: 'right' }}>
            <Button
              key="cancel_ticket"
              onClick={onClose}
              disabled={loading || loadingCreate || loadingUpdate}
            >
              Cancel
            </Button>
            {!edit && (
              <Button
                key="add_another_ticket"
                onClick={() => {
                  form.submit();
                  setIsAddAnother(true);
                }}
                style={{ marginLeft: '3px' }}
              >
                Create and Add Another
              </Button>
            )}

            <Button
              style={{ marginLeft: '3px' }}
              type="primary"
              key="create_ticket"
              onClick={() => {
                form.submit();
              }}
              disabled={loading || loadingCreate || loadingUpdate}
              loading={loading || loadingCreate || loadingUpdate}
            >
              {!edit ? 'Create Ticket' : 'Update Ticket'}
            </Button>
          </div>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async (values: any) => {
            const payload = {
              ticket: {
                title: values.title,
                priority: values.priority,
                description: values.description,
                raised_by_company_id: values.raised_by_company?.id,
                raised_by_user_id: values.raised_by_user?.id,
                resolution_by_user_id: values.resolution_by_user?.id,
                resolution_by_company_id: values.resolution_by_company?.id,
                current_state_id: values.pipeline_status,
                pipeline_id: values.pipeline,
                assigned_to_id: values.assigned_to?.id || '',
                target_resolution_date: values.target_resolution_date?.unix(),
                references:
                  dataSource.length > 0 && dataSource[0].link_type !== null
                    ? dataSource?.map((record: any) => {
                        return {
                          id: record.linked_record?.doc_ref_id,
                          reference_id: record.linked_record?.id,
                          reference_type:
                            record?.link_type === 'Invoice'
                              ? refClassTransformer[record.linked_record?.type]
                              : refClassTransformer[record.link_type],
                        };
                      })
                    : [],
              },
            };

            if (!edit) {
              createTicket({
                variables: payload,
              });
              if (!error) {
                setDataSource([
                  {
                    link_type: null,
                    linked_record: null,
                  },
                ]);
              }
            } else {
              const originalRefIds = new Set(
                ticketData.service_management_ticket?.references?.map(
                  (ref: any) => ref.reference_id
                )
              );
              const deletedRefs = deletedDataSource
                .filter((record: any) => originalRefIds.has(record.linked_record?.id))
                .map((record: any) => {
                  return {
                    id: record.linked_record?.doc_ref_id,
                    reference_id: record.linked_record?.id,
                    reference_type: refClassTransformer[record.link_type],
                    is_deleted: record.is_deleted,
                    _destroy: record._destroy,
                  };
                });
              const deletedRefIds = new Set(deletedRefs.map((ref: any) => ref.reference_id));
              const payloadRefs = payload.ticket.references.filter(
                (ref: any) => !deletedRefIds.has(ref.reference_id)
              );

              const updatedRefs = [...payloadRefs, ...deletedRefs];
              const updatePayload = {
                ticket: {
                  id: ticket_id,
                  ...payload.ticket,
                  references: updatedRefs,
                },
              };

              updateTicket({
                variables: updatePayload,
              });
            }
          }}
        >
          <Row gutter={[10, 1]}>
            <Col span={24}>
              <Form.Item
                label={'Ticket Title'}
                name={'title'}
                rules={[{ required: true, message: 'Please enter Ticket Title' }]}
              >
                <Input placeholder="Enter Ticket Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <SelectPipeline
                doctype="ServiceManagement::Ticket::Ticket"
                form={form}
                resource_id={ticket_id || ''}
                onFormSubmit={setFormValues}
              />
            </Col>
            <Col span={24}>
              <Form.Item label={'Description'} name={'description'}>
                <RichTextEditorWrapper
                  toolbarOptions={{
                    container: [
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ color: [] }, { background: [] }],
                      ['emoji'],
                      [{ indent: '-1' }, { indent: '+1' }],
                    ],
                  }}
                  height={'12vh'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'Ticket Assignee'} name={'assigned_to'}>
                <GlobalSearch doc_type="Network::UserContact" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'Priority'} name={'priority'} initialValue={'Medium'}>
                <Select
                  options={[
                    { label: 'High', value: 'High' },
                    { label: 'Medium', value: 'Medium' },
                    { label: 'Low', value: 'Low' },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'Raised By Company'} name={'raised_by_company'}>
                <CompanySearch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle dependencies={['raised_by_company']}>
                {({ getFieldValue }) => {
                  const company = getFieldValue('raised_by_company');
                  // if (company) {
                  return (
                    <Form.Item label={'Raised By User'} name={'raised_by_user'}>
                      <GlobalSearch
                        doc_type="Network::UserContact"
                        extraProps={{ company: company, showSelf: false }}
                      />
                    </Form.Item>
                  );
                  // }
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'Target Resolution Date'} name={['target_resolution_date']}>
                <DatePicker
                  showTime={false}
                  style={{ width: '100%' }}
                  disabledDate={disablePastDate}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'Associations'} name={'link_records'}>
                <AssociationTable dataSource={dataSource} columns={columns} />
              </Form.Item>
              <Button onClick={handleAdd} size="small" style={{ marginTop: '.5em' }}>
                {`${String.fromCodePoint(43)} Add Row`}
              </Button>
            </Col>
            <Col span={24} sm={24}>
              <Typography.Title level={4} style={{ color: 'var(--color-primary)' }}>
                Upload Documents
              </Typography.Title>
              {ticketData ? (
                <UploadedDocuments
                  sessionData={session}
                  parent_id={ticketData?.service_management_ticket?.id}
                  parent_type={'ticket'}
                  docgen_url={process.env.DOCGEN_URL || ''}
                />
              ) : (
                <div>
                  <UploadDocumentModal
                    onUpload={uploadDoc}
                    sessionData={session}
                    initialUploadDocumentTags={['TIC']}
                  />
                  <DocumentsList
                    uploadingDocuments={uploadingDocuments}
                    uploadedDocuments={documents}
                    uploadingError={uploadingError}
                    onUpload={uploadDoc}
                    onDelete={deleteDoc}
                    sessionData={session}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default TicketForm;
