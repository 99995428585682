import { Form, Row, Col, DatePicker, Input, GlobalSearch } from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import { ROW_GUTTER } from 'operations/modules/shipment/constants';
import React from 'react';

export const ShipmentDeliveryOrder = () => {
  const disabledDate = (current: any) => {
    return current && current.valueOf() <= Date.now();
  };
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={6}>
          <Form.Item name="carrier_do_number" label="Carrier DO Number">
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="carrier_validity_date" label="Carrier Validity Date">
            <DatePicker
              showTime
              style={{ width: '100%' }}
              format="DD-MM-YYYY HH-mm"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="empty_return_location" label="Empty Return Location">
            <GlobalSearch
              doc_type="Global::Location"
              searchProps={{ type: ['CFS', 'Seaport', 'ICD'] }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="surveyor" label="Surveyor name">
            <AddressCompanySearch
              companySearchProps={{
                searchProps: { is_vendor: true },
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
