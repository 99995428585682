import React, { useRef } from 'react';
import { GlobalViewLayout } from '@shipmnts/pixel-hub';
import { GridOptions } from '@ag-grid-community/core';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';

interface StockSummaryTableProps {
  gridRef?: React.MutableRefObject<GridOptions<any> | undefined>;
  rowSelection?: 'multiple' | 'single';
  transaction?: WarehouseTransactionValue;
  purposeOfTransfer?: string;
  transaction_type?: string;
  customer?: any;
  branch: any;
}

const getGlobalView = () => {
  const globalView: any = {
    id: '1',
    name: 'Transaction',
    color: '#eeeeee',
    doc_type_id: 'Wms::StockSummary',
    definition: {
      sort: [
        {
          field_id: 'wms_stock_summary_transaction_date',
          direction: 'asc',
        },
      ],
      fields: [
        {
          id: 'wms_stock_summary_product_id',
          label: 'Product',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          cell_renderer: { args: {}, function: 'record_loader_render' },
          record_loader_config: '{"doc_type": "Wms::Product", "attribute": "product_name"}',
          filter_options: '{"doc_type": "Wms::Product"}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_product_code',
          label: 'Cust. Product Code',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_receipt_number',
          label: 'Receipt #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_transaction_date',
          label: 'Receipt Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'DateTime',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
        },
        {
          id: 'wms_stock_summary_warehouse_unit_id',
          label: 'Location',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          record_loader_config: '{"doc_type": "Wms::WarehouseUnit", "attribute": "node_code"}',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type": "Wms::WarehouseUnit"}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_customer_company_id',
          label: 'Customer',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Search',
          filterable: true,
          record_loader_config: '{"doc_type": "Network::Company", "attribute": "registered_name"}',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type": "Network::Company"}',
          groupable: true,
        },

        {
          id: 'wms_stock_summary_packing_type',
          label: 'Packing Type',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_qty',
          label: 'Qty',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Float',
          filterable: true,
          filter_options: '{}',
          cell_renderer: {},
        },
        {
          id: 'wms_stock_summary_outer_packing_type',
          label: 'Outer Packing Type',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_inner_by_outer_qty',
          label: 'Inner/Outer Qty',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Float',
          filterable: true,
          filter_options: '{}',
          cell_renderer: {},
        },
        {
          id: 'wms_stock_summary_gross_volume',
          label: 'Gross Volume',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Float',
          filterable: true,
          cellRenderer: {},
          filter_options: '{}',
        },
        {
          id: 'wms_stock_summary_gross_weight',
          label: 'Gross Weight',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'Float',
          filterable: true,
          cellRenderer: {},
          filter_options: '{}',
        },

        {
          id: 'wms_stock_summary_batch_number',
          label: 'Batch #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_serial_number',
          label: 'Serial #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_shipment_document_number',
          label: 'BL/AWB #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'wms_stock_summary_customer_ref_code',
          label: 'Customer Ref Code#',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: false,
        },
        {
          id: 'wms_stock_summary_invoice_number',
          label: 'Invoice #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_container_number',
          label: 'Container #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_customs_ref_code',
          label: 'Customs Ref #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_lot_number',
          label: 'Lot #',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
        {
          id: 'wms_stock_summary_exp_date',
          label: 'Exp Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'DateTime',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
        },
        {
          id: 'wms_stock_summary_prod_date',
          label: 'Prod Date',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'DateTime',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
        },
        {
          id: 'wms_stock_summary_country_of_origin',
          label: 'Country Of Origin',
          width: 160,
          section: 'Basic',
          sortable: true,
          field_type: 'String',
          filterable: true,
          cell_renderer: {},
          filter_options: '{}',
          groupable: true,
        },
      ],
      filters: [],
    },
    type: 'grid',
    standard_report: true,
    is_disabled: false,
    global: true,
  };

  return globalView;
};
const getDefaultFilters: any = (
  transaction: any,
  purposeOfTransfer: any,
  customer: any,
  branch: any,
  transaction_type?: string
) => {
  const POSSIBLE_KEYS =
    transaction_type === 'receipt' ? ['receipt_number'] : ['packing_type', 'product_name'];
  const filters: any = [];
  if (
    !!transaction?.shipment_parties?.find((value: any) => value.name === 'customer')?.party_company
      ?.id ||
    !!customer
  ) {
    filters.push({
      field_id: 'wms_stock_summary_customer_company_id',
      condition: {
        type: 'equals',
        value:
          transaction?.shipment_parties?.find((value: any) => value.name === 'customer')
            ?.party_company?.id || customer,
      },
    });
  }
  if (transaction)
    POSSIBLE_KEYS.forEach((value) => {
      // eslint-disable-next-line array-callback-return
      const filterValue: any = [];
      transaction?.warehouse_transaction_products?.forEach((val: any) => {
        if (!!val[value]) filterValue.push(val[value]);
      });
      if (filterValue.length)
        filters.push({
          field_id: `wms_stock_summary_${value}`,
          condition: {
            type: 'in',
            values: filterValue,
          },
        });
    });

  return [
    {
      field_id: 'wms_stock_summary_branch_id',
      condition: { type: 'equals', value: branch },
    },
    ...filters,
  ];
};

const StockSummaryTable = (props: StockSummaryTableProps) => {
  const { gridRef, transaction, purposeOfTransfer, customer, branch, transaction_type } = props;
  const globalView = useRef(getGlobalView());
  const defaultFilters = useRef(
    getDefaultFilters(transaction, purposeOfTransfer, customer, branch)
  );

  return (
    <>
      <GlobalViewLayout
        gridRef={gridRef}
        doc_type_id={'Wms::StockSummary'}
        globalView={globalView.current}
        defaultFilters={defaultFilters.current}
        quick_filter_field_ids={[
          ...(transaction_type === 'goods_transfer'
            ? ['wms_stock_summary_customer_company_id']
            : []),
          'wms_stock_summary_transaction_date',
          'wms_stock_summary_product_name',
          'wms_stock_summary_product_code',
          'wms_stock_summary_warehouse_unit_id',
          'wms_stock_summary_invoice_number',
          'wms_stock_summary_container_number',
          'wms_stock_summary_serial_number',
          'wms_stock_summary_customs_ref_code',
          'wms_stock_summary_batch_number',
          'wms_stock_summary_lot_number',
          'wms_stock_summary_pallet_no',
          'wms_stock_summary_exp_date',
          'wms_stock_summary_prod_date',
          'wms_stock_summary_country_of_origin',
        ]}
        reportConfig={{
          rowSelection: 'multiple',
        }}
        height="65vh"
        showCheckBoxOnHeader={true}
      />
    </>
  );
};

export default StockSummaryTable;
